<template>
  <ion-header>
    <div class="p">
      <div class="container">
        <div class="box">
          <span>
            <div class="flex vertical centered"><ion-menu-button color="secondary" menu="start"></ion-menu-button></div>
          </span>
        </div>

        <div class="box main" @click="goHome">
          <span>
            <img :src="organisation.logo" alt="organisation.name" v-if="organisation?.logo" />
            <img src="/img/logos/logo_white.png" v-else
          /></span>
        </div>

        <div class="box">
          <div class="flex autowidth" v-if="organisation?.showWelsh">
            <span
              @click="setLanguage('cy')"
              class="languageSelector"
              :style="{ textDecoration: $i18n.locale == 'cy' ? 'underline' : 'none' }"
              >cy</span
            >

            <span class="languageSelector o5"> | </span>

            <span
              @click="setLanguage('en')"
              class="languageSelector"
              :style="{ textDecoration: $i18n.locale == 'en' ? 'underline' : 'none' }"
              >en</span
            >

            <span v-if="$attrs.onShowInfo"
              ><ion-icon :icon="informationCircle" color="primary" size="large" @click="$emit('showInfo')" /></span
            ><span v-if="$attrs.onShare"><ion-icon :icon="share" color="primary" size="large" @click="$emit('share')" /></span>
          </div>
        </div>
      </div>
    </div>
  </ion-header>
</template>

<script>
import { share, informationCircle } from "ionicons/icons";
import { useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default {
  props: ["backLabel"],
  setup(props) {
    const organisationStore = useOrganisationStore();
    const { organisation } = storeToRefs(organisationStore);
    const router = useRouter();
    const i18n = useI18n();

    const isProduction = process.env.VUE_APP_MODE == "production";

    const goHome = () => {
      router.push({ name: "Home" });
    };

    const setLanguage = (code) => {
      i18n.locale.value = code;
    };

    return {
      // variables
      isProduction,
      organisation,
      // methods
      goHome,
      setLanguage,
      //   icons
      share,
      informationCircle,
    };
  },
};
</script>

<style scoped>
ion-header {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
.container {
  display: flex;
  align-items: center;
}
.box {
  flex: 1 0 10px;
  display: flex;
  justify-content: center;
}

.box.main {
  flex: 3 0 10px;
}

.box.main,
.box.main img {
  height: 30px;
  object-fit: contain;
}

.box:first-child > span {
  margin-right: auto;
}

.box:last-child > div {
  margin-left: auto;
  text-align: right;
}

.languageSelector {
  color: var(--ion-color-dark-contrast);
  font-family: Bold;
}
</style>
