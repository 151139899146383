<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div class="flex vertical">
        <div class="flex">
          <div><img src="/img/logos/logo text black.png" alt="logo" height="50" /></div>
          <div class="boldFont underline fs" @click="login">LOGIN</div>
        </div>

        <div class="flex autowidth centered" v-if="!isMobile">
          <div class="flex vertical top centered homeContent">
            <div class="titleFont homeTitle">A better<br />workplace<br />experience</div>
            <div class="titleFont homeSubtitle">Dynamic technology user guides and live feedback on the performance of your space</div>
            <div class="homeText">Get early access</div>
            <div class="fullwidth" v-if="!signedup">
              <ion-input type="email" placeholder="Enter your email" class="homeInput" v-model="email"></ion-input>
            </div>
            <div class="fullwidth" v-if="!signedup" @click="signup">
              <ion-button color="secondary" expand="block" class="signupButton" :disabled="!isEmailValid(email)">Sign Up</ion-button>
            </div>
            <div class="cs" v-else>
              Thank you for your interest in Alpha Victor, we'll be in touch to discuss how we can build a better workplace experience for
              your organisation.
            </div>
          </div>
          <div class="fullheight iphone" v-if="!isMobile">
            <img src="/img/iphone13.png" alt="screen" class="fullheight fullwidth contain" />
          </div>
        </div>

        <div class="flex vertical top centered fullheight mobile" v-else>
          <img src="/img/iphone13.png" alt="" class="background" />
          <div class="titleFont homeTitle">A better workplace experience</div>
          <div class="titleFont homeSubtitle">Dynamic technology user guides and live feedback on the performance of your space</div>
          <div class="homeText">Get early access</div>
          <div class="fullwidth" v-if="!signedup">
            <ion-input type="email" placeholder="Enter your email" class="homeInput" v-model="email"></ion-input>
          </div>
          <div class="fullwidth" v-if="!signedup" @click="signup">
            <ion-button color="secondary" expand="block" class="signupButton" :disabled="!isEmailValid(email)">Sign Up</ion-button>
          </div>
          <div class="cs" v-else>
            Thank you for your interest in Alpha Victor, we'll be in touch to discuss how we can build a better workplace experience for
            your organisation.
          </div>
        </div>

        <div class="flex" v-if="!isMobile">
          <div class="underline boldFont fs" @click="login">ALREADY HAVE ACCESS?</div>
          <div class="flex autowidth">
            <!-- <img src="/img/appstores/google-play-badge.png" alt="Google Play" height="50" class="mr" href="" />
            <img src="/img/appstores/app_store.svg" alt="Google Play" height="50" href="" /> -->
          </div>
        </div>

        <div class="flex vertical autoheight" v-else>
          <div class="underline boldFont fs" @click="login">ALREADY HAVE ACCESS?</div>
          <div class="flex autowidth">
            <!-- <img src="/img/appstores/google-play-badge.png" alt="Google Play" height="50" class="mr" href="" />
            <img src="/img/appstores/app_store.svg" alt="Google Play" height="50" href="" /> -->
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
import { runFunction } from "@/composables/firebase";
import { isEmailValid } from "@/composables/utilities";
import { ref } from "@vue/reactivity";
import { isPlatform } from "@ionic/core";
import { computed } from "@vue/runtime-core";

export default {
  setup() {
    const router = useRouter();
    const signedup = ref(false);
    const email = ref();
    const login = () => {
      router.push({ name: "LoginWithPassword" });
    };

    const isMobile = computed(() => {
      return isPlatform("mobile") && !isPlatform("tablet");
    });

    const signup = async () => {
      if (!isEmailValid(email.value)) return;
      const data = {
        email: email.value,
        subject: "Alpha Victor Signup",
      };
      signedup.value = true;
      runFunction("responseRequest", data);
    };

    return {
      login,
      email,
      signup,
      isMobile,
      isEmailValid,
      signedup,
    };
  },
};
</script>

<style scoped>
.homeTitle {
  font-size: 4em;
  font-weight: 900;
  line-height: 1em;
}

.mobile .background {
  position: absolute;
  left: 0px;
  top: 0px;
  object-fit: contain;
  height: 100%;
  width: 100%;
  opacity: 0.15;
  z-index: -100;
  margin: 3em 0em 0em 3em;
}

.mobile .homeTitle {
  font-size: 3em;
}

.homeSubtitle {
  margin: 1em 0rem;
  font-weight: 300;
  line-height: 1em;
  font-size: 2em;
  max-width: 400px;
}

.mobile .homeSubtitle {
  font-size: 1.5em;
}

.homeText {
  font-size: 1.5rem;
  font-weight: 100;
}

.mobile .homeText {
  font-size: 1.2em;
}

.homeInput {
  margin: 1.5rem 0rem;
  border: 1px solid lightgray;
  width: 100%;
  max-width: 400px;
}

.signupButton {
  max-width: 200px;
}

.mobile .signupButton {
  max-width: 100%;
}

.iphone {
  width: 100%;
  max-width: 600px;
  margin-left: 6em;
}
</style>
