<template>
  <ion-page>
    <av-header />
    <ion-content v-if="space" :scroll-y="false">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="space?.name" edit="true" />
        <div class="fullwidth ph pt scroll">
          <div class="action" @click="go('EditSpaceDetails')">
            <ion-label class="fullwidth keepOnTop">{{ t("details") }}</ion-label
            ><ion-icon :icon="chevronForward" />
          </div>

          <div class="action" @click="go('EditSpaceDecisionTree')">
            <ion-label class="fullwidth keepOnTop">{{ t("decision_tree") }}</ion-label
            ><ion-icon :icon="chevronForward" />
          </div>

          <div class="action">
            <ion-label class="fullwidth keepOnTop">{{ t("checklists") }}</ion-label>
            <MultiSelect
              slot="end"
              v-model="space.checklistIDs"
              :options="groupedChecklists"
              optionGroupLabel="label"
              optionGroupChildren="items"
              optionLabel="label"
              optionValue="value"
              placeholder="Select checklists"
              display="chip"
              :showToggleAll="false"
              @change="markDirty"
            />
          </div>
          <div class="action" @click="go('EditSpaceEquipment')">
            <ion-label class="fullwidth keepOnTop">{{ t("devices") }}</ion-label
            ><ion-icon :icon="chevronForward" />
          </div>
          <div class="action" @click="go('EditSpacePanorama')">
            <ion-label class="fullwidth keepOnTop">{{ t("panorama") }}</ion-label
            ><ion-icon :icon="chevronForward" />
          </div>

          <div class="action" @click="go('EditSpaceFeatures')">
            <ion-label class="fullwidth keepOnTop">{{ t("features") }}</ion-label
            ><ion-icon :icon="chevronForward" />
          </div>
          <div class="action" @click="go('EditSpacePhotos')">
            <ion-label class="fullwidth keepOnTop">{{ t("photos") }}</ion-label
            ><ion-icon :icon="chevronForward" />
          </div>
          <div class="action" @click="go('EditSpaceDocuments')">
            <ion-label class="fullwidth keepOnTop">{{ t("documents") }}</ion-label
            ><ion-icon :icon="chevronForward" />
          </div>
          <div class="action" @click="go('EditSpaceBeacon')">
            <ion-label class="fullwidth keepOnTop">{{ t("beacon") }}</ion-label
            ><ion-icon :icon="chevronForward" />
          </div>
          <div class="action" @click="go('EditSpaceIntegrations')" v-if="organisation.ubi_id">
            <ion-label class="fullwidth keepOnTop">{{ t("integrations") }}</ion-label>
            <div>&gt;</div>
          </div>
          <div class="action" @click="go('EditSpaceQR')">
            <ion-label class="fullwidth keepOnTop">{{ t("qr_code") }}</ion-label
            ><ion-icon :icon="chevronForward" />
          </div>
        </div>
        <div class="grow"></div>
        <div class="fullwidth">
          <ion-button expand="block" color="danger" class="m2" @click="confirmDelete">{{ t("delete_space") }}</ion-button>
        </div>
        <!-- <nav-footer label="Spaces List" @back="cancel" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import MultiSelect from "primevue/multiselect";
import { computed, watch, ref } from "vue";
import { objectToArray } from "../../composables/utilities";

const route = useRoute();
const router = useRouter();
let organisationID = route.params.organisationID;
let locationID = route.params.locationID;
let floorID = route.params.floorID;
let spaceID = route.params.spaceID;
let organisationStore = useOrganisationStore();
const { organisation, location, floor, space } = storeToRefs(organisationStore);
const { t } = useI18n();
onIonViewWillEnter(() => {
  organisationStore.initialise(organisationID, locationID, floorID, spaceID);
});
const go = (name) => {
  router.push({ name: name, params: { organisationID, locationID, floorID, spaceID } });
};
const dirty = ref(false);

let backup;

const save = async () => {
  organisationStore.saveRecord(
    `organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces`,
    space.value.id,
    {
      ...space.value,
    },
    ["checklists", "dueChecklists", "completedChecklists"]
  );
  router.back();
};

const markDirty = () => {
  dirty.value = true;
};

const createBackup = () => {
  backup = space.value?.decisionTreeID;
};
watch(space, createBackup);
createBackup();

const back = async () => {
  if (dirty.value) {
    const alert = await alertController.create({
      cssClass: "alert",
      header: "Are you sure?",
      message: "You have unsaved changes.",
      buttons: [
        {
          text: "Save & Close",
          handler: () => {
            save();
          },
        },
        { text: "Cancel" },
        {
          text: "Discard Changes",
          role: "destructive",
          handler: () => {
            space.value.decisionTreeID = backup;
            router.back();
          },
        },
      ],
    });
    await alert.present();
  } else {
    router.back();
  }
};

const groupedChecklists = computed(() => {
  const result = [];
  const arr = objectToArray(organisation.value.checklists);
  const weekly = arr
    .filter((a) => a.frequency == "weekly")
    .map((a) => {
      return { label: a.name, value: a.id };
    });
  const monthly = arr
    .filter((a) => a.frequency == "monthly")
    .map((a) => {
      return { label: a.name, value: a.id };
    });

  if (weekly.length > 0) result.push({ label: "Weekly", items: weekly });
  if (monthly.length > 0) result.push({ label: "Monthly", items: monthly });
  return result;
});

const confirmDelete = async () => {
  const alert = await alertController.create({
    cssClass: "alert",
    header: "Are you sure?",
    message: "Deleting this space is final and cannot be undone.",
    buttons: [
      {
        text: "Delete",
        role: "destructive",
        handler: () => {
          organisationStore.deleteSpace(space.value);
          router.back();
        },
      },
      { text: "Cancel" },
    ],
  });
  await alert.present();
};
</script>
