<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd" slot="fixed">
        <view-header @back="back" title="Report an Issue" :subtitle="space?.name" class="fullwidth" />
        <div class="scroll p fullwidth flex vertical">
          <ion-textarea
            class="fullheight"
            v-model="data.message"
            rows="10"
            placeholder="Please enter details about the issue you're experiencing. The issue won't reflect immediately on the space, while we review details."
          ></ion-textarea>
        </div>
        <div class="grow"></div>
        <div class="fullwidth">
          <ion-button class="m2 bright" expand="block" @click="reportIssue()">Report Issue</ion-button>
        </div>
        <!-- <nav-footer label="Back" @back="goBack" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";
import { reactive } from "vue";
import { toast } from "@/composables/utilities";
import { runFunction } from "@/composables/firebase";

export default {
  setup() {
    const organisationStore = useOrganisationStore();
    const userStore = useUserStore();
    const { profile } = storeToRefs(userStore);

    const { floor, location, organisation, space, manufacturers, assetTypes, models } = storeToRefs(organisationStore);
    const route = useRoute();
    const router = useRouter();
    const spaceID = route.params.spaceID;
    const floorID = route.params.floorID;
    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const data = reactive({});

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
    });

    const back = () => {
      router.back();
    };

    const reportIssue = async () => {
      data.name = `${profile.value?.firstname || ""} ${profile.value?.lastname || ""}`;
      if (data.name == " ") data.name = "";
      data.email = profile.value?.id;
      data.phone = profile.value?.phone;
      data.subject = `Alpha Victor issue report`;
      data.space = space.value;
      data.floor = floor.value;
      data.location = location.value;
      data.organisation = organisation.value;
      runFunction("reportIssue", data);
      toast("Your issue has been reported, thank you.");
      back();
    };

    return {
      data,
      space,
      back,
      reportIssue,
    };
  },
};
</script>

<style scoped>
.bg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}
</style>
