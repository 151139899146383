// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar[data-v-0cb8f124] {
  border-radius: 3em;
  margin: calc(20px + var(--ion-safe-area-top)) 20px 20px;
  width: calc(100% - 40px);
}
.toolbar a[data-v-0cb8f124] {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}
.toolbar .headerLogo[data-v-0cb8f124] {
  height: 2em;
}
.organisationsContainer[data-v-0cb8f124] {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  grid-auto-flow: row;
  grid-auto-rows: 150px;
  grid-gap: 20px;
  padding: 20px;
  justify-content: center;
  overflow-y: auto;
}
.organisationsContainer .organisationCard[data-v-0cb8f124] {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.organisationsContainer .organisationCard img[data-v-0cb8f124] {
  width: 100%;
  height: 70px;
  padding: 5px;
  object-fit: contain;
}
@media (max-width: 500px) {
.organisationsContainer .organisationCard img[data-v-0cb8f124] {
    height: 40px;
    padding: 0px;
}
}
.organisationsContainer .organisationCard .cardFooter[data-v-0cb8f124] {
  display: flex;
  justify-content: center;
  text-align: center;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
