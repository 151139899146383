<template>
  <ion-page>
    <av-header />

    <ion-content :scroll-y="false" v-if="organisation">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="organisation.name" :subtitle="t('integrations')" edit="true" />
        <div class="fullwidth ph pt scroll">
          <div class="action" @click="go('EditOrganisationUbiqisense')">
            <ion-label color="secondary" class="fullwidth keepOnTop">Ubiqisense</ion-label><ion-icon :icon="chevronForward" />
          </div>
          <div class="action" @click="go('EditOrganisationPronestor')">
            <ion-label color="secondary" class="fullwidth keepOnTop">Pronestor</ion-label><ion-icon :icon="chevronForward" />
          </div>
        </div>
        <div class="grow"></div>
        <!-- <nav-footer :label="organisation.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default {
  name: "Description",
  setup() {
    const dirty = ref(false);
    const route = useRoute();
    const router = useRouter();
    let organisationID = route.params.organisationID;
    const organisationStore = useOrganisationStore();
    const { organisation } = storeToRefs(organisationStore);
    const { t } = useI18n();

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID);
    });

    const go = (name) => {
      router.push({ name: name, params: { organisationID } });
    };

    const back = () => {
      router.back();
    };
    return {
      // variables
      organisation,
      // methods
      go,
      back,
      t,
      // icons
      chevronForward,
    };
  },
};
</script>
