<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical" slot="fixed">
        <view-header @back="back" title="Organisations" subtitle="Select" class="fullwidth" />
        <ion-list class="fullwidth p2 scroll">
          <ion-radio-group v-model="organisationData.selectedOrganisationID">
            <ion-item v-for="organisation in filteredOrganisations" :key="organisation">
              <img
                @click="selectOrganisation(organisation.id)"
                :src="organisation.logo || '/img/logos/large_black.png'"
                style="object-fit: contain; width: 75px; padding: 10px 0px"
                slot="start"
              />
              <ion-label>{{ organisation.name }}</ion-label>
              <ion-radio mode="ios" :value="organisation.id" slot="end"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
        <div class="grow"></div>
        <!-- <nav-footer label="Back" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useOrganisationStore } from "@/stores/organisation";
import { onMounted } from "vue";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const organisationStore = useOrganisationStore();
    const { filteredOrganisations } = storeToRefs(organisationStore);
    const organisationData = organisationStore.data;
    const { profile } = storeToRefs(userStore);

    onMounted(() => {
      organisationStore.initialise();
      organisationStore.getLogos();
    });

    const save = async () => {
      userStore.saveProfile();
    };

    const selectOrganisation = (id) => {
      profile.value.currentOrganisationID = id;
      organisationData.selectedOrganisationID = id;
      save();
    };

    const back = () => {
      router.back();
    };

    return { filteredOrganisations, organisationData, back, save, selectOrganisation };
  },
};
</script>

<style scoped></style>
