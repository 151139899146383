<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd" slot="fixed">
        <view-header @back="back" :title="video?.title" class="fullwidth" size="small" />
        <div class="scroll fullwidth flex vertical">
          <div ref="playerRef" id="player" />

          <div id="controls" class="flex mt ph2">
            <ion-toggle v-model="data.autoplay" color="primary">Autoplay</ion-toggle>
          </div>

          <div id="stepContainer" class="ph2" v-if="video?.chapters">
            <div
              v-for="(chapter, index) in video.chapters"
              :key="index"
              :style="{ opacity: index + 1 == currentChapter ? 1 : 0.3 }"
              @click="chapterClicked(index)"
            >
              <div class="chapterTitle">
                <ion-icon
                  :icon="data.playing ? pauseCircleOutline : playCircleOutline"
                  :style="{ opacity: index + 1 == currentChapter ? 1 : 0 }"
                  color="primary"
                />
                <div>
                  <div>{{ chapter.title }}</div>
                  <div class="chapterSubtitle" v-if="chapter.subtitle">{{ chapter.subtitle }}Subtitle</div>
                </div>
              </div>

              <div class="html" v-html="chapter.html"></div>
            </div>
          </div>
        </div>
        <div class="grow"></div>
        <div class="mb2 fullwidth">
          <div
            class="safe-bottom fullwidth pb2 ph2"
            v-if="location?.contact?.sos && (user?.roles?.includes('vip') || !location?.restrictSOS)"
          >
            <SlideSwitch
              :label="location.contact?.sos_label || 'slide to call SOS'"
              :slidingLabel="location.contact?.sos_label_active || 'Contacting SOS'"
              @unlock="callSOS"
            />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";
import Player from "@vimeo/player";
import { playCircleOutline, pauseCircleOutline } from "ionicons/icons";
import { onMounted, reactive, ref } from "vue";
import mixpanel from "mixpanel-browser";
import { useUserStore } from "@/stores/user";
import SlideSwitch from "@/components/SlideSwitch";

export default {
  components: { SlideSwitch },
  setup() {
    const organisationStore = useOrganisationStore();
    const { anonymous, organisation, floor, space, decisionTree, location } = storeToRefs(organisationStore);
    const route = useRoute();
    const router = useRouter();
    const videoID = route.params.videoID;
    const vimeoID = route.params.vimeoID;
    const hash = route.params.hash;
    const organisationID = organisation.value.id;
    const locationID = location.value.id;
    const floorID = floor.value.id;
    const spaceID = space.value.id;
    let player;
    const playerRef = ref();
    const chapters = ref([]);
    const currentChapter = ref(1);
    const data = reactive({
      hasPlayed: false,
      pauseOnChapters: true,
      loop: false,
      playing: false,
    });
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    const resolvedOrganisation = computed(() => organisation.value || anonymous.value.organisation);
    const video = computed(() => resolvedOrganisation.value?.videos?.[videoID]);

    onMounted(async () => {
      player = new Player(playerRef.value, {
        url: `https://player.vimeo.com/video/${vimeoID}?h=${hash}`,
        responsive: true,
        loop: true,
        muted: false,
        autoplay: false,
        controls: true,
      });

      player.on("chapterchange", async (playerData) => {
        if (data.hasPlayed && (!data.autoplay || (currentChapter.value == video.value.chapters.length && playerData?.index == 1))) {
          player.pause();
          data.playing = false;
        }
        currentChapter.value = playerData?.index;
        data.hasPlayed = true;
      });

      chapters.value = await player.getChapters();
    });

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID);
    });

    const chapterClicked = async (chapter) => {
      if (chapter + 1 == currentChapter.value) {
        if (data.playing) {
          player.pause();
          data.playing = false;
        } else {
          player.play();
          data.playing = true;
        }
      } else {
        await player.setCurrentTime(chapters.value[chapter].startTime);
        player.play();
        data.playing = true;
      }
    };

    const callSOS = async () => {
      mixpanel.track("DT Goal Reached", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        type: "SOS",
      });
      if (location.value.contact.sosUseEmail) {
        placeCall(location.value.contact?.sos);
      } else {
        sendEmail(location.value.contact?.sos_email);
      }
    };

    const placeCall = (phoneNumber) => {
      mixpanel.track("Phone Call Placed", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        number: phoneNumber,
      });
      mixpanel.track("DT Goal Reached", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        type: "Phone Call",
      });
      window.open(`tel:${phoneNumber}`, "_blank");
    };

    const sendEmail = (emailAddress) => {
      mixpanel.track("Email Sent", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        email: emailAddress,
      });
      mixpanel.track("DT Goal Reached", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        type: "Email",
      });
      router.push({ name: "Email", params: { organisationID, locationID, floorID, spaceID } });
    };

    const back = () => {
      router.back();
    };

    return {
      chapterClicked,
      video,
      playCircleOutline,
      pauseCircleOutline,
      player,
      playerRef,
      data,
      chapters,
      currentChapter,
      callSOS,
      user,
      back,
      location,
    };
  },
};
</script>

<style scoped>
.footer {
  font-family: Title;
  color: var(--ion-color-secondary);
  font-size: 1.6em;
  text-transform: uppercase;
  background: var(--ion-color-light-tint);
}

#stepContainer {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  text-align: left;
}

#player {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.chapterTitle {
  margin: 0px 0px 5px;
  font-family: Title;
  font-size: 1.1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.chapterSubtitle {
  margin-top: -5px;
  font-size: 0.5em;
  font-family: Regular;
}

.html {
  font-size: 0.8rem;
  display: flex;
  align-content: flex-start;
  padding: 0px;
  margin: 0px;
}

.html:deep(p) {
  margin: 0px 0px 10px 0px;
}

.html {
  margin-bottom: 10px;
}

#controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8em;
  margin-bottom: 20px;
}

.activeChapter {
  opacity: 1;
}

.inactiveChapter {
  opacity: 0.5;
}

.playerControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.7em;
}
</style>
