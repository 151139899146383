<template>
  <ion-page>
    <av-header />
    <ion-content class="p">
      <div class="flex vertical bgd">
        <view-header @back="back" :title="floor?.name" :subtitle="t('spaces')" edit="true" />

        <div class="ph pt fullwidth scroll">
          <ion-item lines="none" class="fullwidth">
            <ion-toggle color="secondary" v-model="allowSpacesReorder">{{ t("reorder_spaces") }}</ion-toggle>
          </ion-item>
          <ion-reorder-group :disabled="!allowSpacesReorder" @ionItemReorder="reorderSpaces($event)" class="fullwidth">
            <ion-item class="fullwidth" v-for="space in sortedSpaces" :key="space.id" @click="editSpace(space.id)">
              <div class="title">{{ space?.name }}</div>
              <ion-icon :icon="chevronForward" v-if="!allowSpacesReorder" color="primary" slot="end" size="small" />
              <ion-reorder v-else slot="end" />
            </ion-item>
          </ion-reorder-group>
        </div>
        <div class="grow"></div>
        <div class="fullwidth">
          <ion-button @click="addSpace" expand="block" class="bright m2">{{ t("add_space") }}</ion-button>
        </div>
        <!-- <nav-footer :label="floor.name" @back="cancel" /> -->
      </div>
    </ion-content></ion-page
  >
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { useShortcodeStore } from "@/stores/shortcode";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { onIonViewWillEnter } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { sortObjectByProperty } from "@/composables/utilities";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const organisationStore = useOrganisationStore();
    const organisationID = route.params.organisationID;
    const locationID = route.params.locationID;
    const floorID = route.params.floorID;
    const { organisation, location, floor } = storeToRefs(organisationStore);
    const shortcodeStore = useShortcodeStore();
    const { t } = useI18n();

    const allowSpacesReorder = ref(false);

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID);
    });

    const addSpace = async () => {
      const shortcode = shortcodeStore.getShortcode();
      const newSpaceID = await organisationStore.addRecord(
        `organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces`,
        {
          name: "New Space",
          shortcode,
        }
      );
      await organisationStore.addRecord("shortcodes", {
        organisationID,
        locationID,
        floorID,
        newSpaceID,
        allowAnonymous: organisation.value.allowAnonymous || false,
        localAnonymousOnly: organisation.value.localAnonymousOnly || true,
      });
      editSpace(newSpaceID);
    };

    const editSpace = (spaceID) => {
      router.push({ name: "EditSpace", params: { organisationID, locationID, floorID, spaceID } });
    };

    const back = () => {
      router.back();
    };

    const reorderSpaces = (e) => {
      const result = e.detail.complete(sortedSpaces.value);
      for (const [i, item] of result.entries()) {
        floor.value.spaces[item.id].index = i;
        organisationStore.saveRecord(`organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces`, item.id, item);
      }
    };

    const sortedSpaces = computed(() => {
      return sortObjectByProperty(floor.value?.spaces, "index");
    });

    return { organisation, location, floor, sortedSpaces, allowSpacesReorder, addSpace, editSpace, reorderSpaces, back, t, chevronForward };
  },
};
</script>

<style></style>
