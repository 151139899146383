// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
  border-radius: 3em;
  margin: calc(20px + var(--ion-safe-area-top)) 20px 20px;
  width: calc(100% - 40px);
}
.toolbar a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}
.toolbar .headerLogo {
  height: 2em;
}
.p-tieredmenu {
  background: var(--ion-color-light);
}
.p-tieredmenu .p-menuitem.danger .p-menuitem-link,
.p-tieredmenu .p-menuitem.danger .p-menuitem-icon {
  color: var(--ion-color-danger);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
