// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container[data-v-e246a876] {
  position: relative;
  height: 50px;
}
.message[data-v-e246a876] {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-danger-contrast);
  background: var(--ion-color-danger);
  text-transform: uppercase;
}
.message.sliding[data-v-e246a876] {
  color: var(--ion-color-warning-contrast);
  background: var(--ion-color-warning);
}
.outer[data-v-e246a876] {
  background: var(--ion-color-warning);
  display: inline-block;
  width: 100%;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 5px;
  overflow: hidden;
}
.outer .lock[data-v-e246a876] {
  height: 50px;
  aspect-ratio: 1;
  background: var(--ion-color-danger-tint);
  cursor: pointer;
  position: relative;
  left: 0;
  transition: background 0.2s ease-out, left 0.3s ease-out;
  border-radius: 5px;
  border: 3px solid var(--ion-color-light);
}
.outer .lock.dragging[data-v-e246a876] {
  transition: none;
}
.container .outer.unlocked[data-v-e246a876] {
  opacity: 0.8;
}
.outer.unlocked .lock[data-v-e246a876] {
  background: var(--ion-color-warning);
  cursor: none;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
