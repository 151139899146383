// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.logo[data-v-1c332b58] {
  height: 20vh !important;
  filter: brightness(0) invert(1);
}
#onboarding-pagination[data-v-1c332b58] {
  position: absolute;
  right: 10px;
  width: 2rem;
  border-radius: 1rem;
  background: rgba(var(--ion-color-light-rgb), 0.5);
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
}
.permissionMessage[data-v-1c332b58] {
  min-height: 100px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
