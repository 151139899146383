<template>
  <ion-page>
    <ion-content>
      <div v-for="organisation in spaceReport" :key="organisation.id">
        <div class="organisation">{{ organisation.name }}</div>
        <div v-for="location in organisation.locations" :key="location.id">
          <div class="location">{{ location.name }}</div>
          <div v-for="floor in location.floors" :key="floor.id">
            <div class="floor">{{ floor.name }}</div>
            <div v-for="space in floor.spaces" :key="space.id">
              <div class="space">{{ space.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent, onIonViewDidEnter } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { ref } from "vue";
const organisationStore = useOrganisationStore();
const spaceReport = ref();

onIonViewDidEnter(async () => {
  spaceReport.value = await organisationStore.getSpaceReport();
});
</script>

<style lang="scss" scoped>
.organisation {
  color: yellow;
  font-size: 1.4em;
  padding-top: 20px;
}

.location {
  font-size: 1.2em;
  padding-left: 20px;
  margin: 5px;
}

.floor {
  font-size: 1em;
  margin: 5px;
  padding-left: 40px;
}

.space {
  padding-left: 60px;
  font-size: 0.8em;
}
</style>
