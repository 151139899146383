<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="organisation">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="organisation.name" :subtitle="t('integrations')" edit="true" />
        <div class="fullwidth ph pt scroll">
          <ion-item @click="editChecklist(checklist.id)" v-for="checklist in organisation.checklists" :key="checklist.id">
            <div>{{ checklist.name }}</div>
            <ion-icon slot="end" color="light" :icon="chevronForward" />
          </ion-item>

          <ion-item lines="none" style="margin-top: 20px">
            <ion-button @click="addChecklist">Add new checklist</ion-button>
          </ion-item>
        </div>
        <div class="grow"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { onIonViewWillEnter } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

const route = useRoute();
const router = useRouter();
let organisationID = route.params.organisationID;
const organisationStore = useOrganisationStore();
const { organisation } = storeToRefs(organisationStore);
const { t } = useI18n();

onIonViewWillEnter(() => {
  organisationStore.initialise(organisationID);
});

const editChecklist = (checklistID) => {
  router.push({ name: "EditChecklist", params: { checklistID: checklistID } });
};

const deleteChecklist = (checklistID) => {
  organisationStore.deleteChecklist(checklistID);
};

const addChecklist = async () => {
  const newChecklistID = await organisationStore.addChecklist();
  editChecklist(newChecklistID);
};

const go = (name) => {
  router.push({ name: name, params: { organisationID } });
};

const back = () => {
  router.back();
};
</script>
