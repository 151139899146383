<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="location">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="location.name" :subtitle="t('edit_details')" edit="true" />

        <div class="fullwidth scroll">
          <ion-item class="fullwidth">
            <ion-input
              :label="t('location_name')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('name')"
              v-model="location.name"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
          </ion-item>

          <ion-item class="fullwidth" v-if="location.contact">
            <ion-input
              :label="t('main_contact')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('name')"
              v-model="location.contact.name"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
            <ion-input
              aria-label="email"
              color="secondary"
              :placeholder="t('email')"
              v-model="location.contact.email"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
            <ion-input
              aria-label="phone"
              color="secondary"
              :placeholder="t('phone')"
              v-model="location.contact.phone"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
          </ion-item>
          <ion-item class="fullwidth" v-if="location.contact">
            <div class="fullwidth">
              <ion-label position="stacked" color="secondary">{{ t("sos_details") }}</ion-label>

              <div class="flex left">
                <ion-toggle color="secondary" v-model="location.contact.sosUseEmail" class="mr2" @ionChange="markDirty"
                  >Use email</ion-toggle
                >
              </div>
              <ion-input
                color="secondary"
                v-model="location.contact.sos_email"
                @ionInput="markDirty()"
                required="true"
                :label="t('sos_email')"
                label-placement="floating"
                v-if="location.contact.sosUseEmail"
              ></ion-input>
              <ion-input
                color="secondary"
                :placeholder="t('sos_number')"
                v-model="location.contact.sos"
                @ionInput="markDirty()"
                required="true"
                :label="t('sos_number')"
                label-placement="floating"
                v-else
              ></ion-input>
              <ion-input
                color="secondary"
                aria-label="sos label"
                :placeholder="t('custom_sos_label')"
                v-model="location.contact.sos_label"
                @ionInput="markDirty()"
                required="true"
              ></ion-input>
              <ion-input
                color="secondary"
                aria-label="sos active label"
                :placeholder="t('custom_sos_label_active')"
                v-model="location.contact.sos_label_active"
                @ionInput="markDirty()"
                required="true"
              ></ion-input>
            </div>
          </ion-item>

          <ion-item class="fullwidth" v-if="location.wifi">
            <ion-input
              :label="t('public_wifi')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('ssid')"
              v-model="location.wifi.name"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
            <ion-input
              color="secondary"
              :placeholder="t('password')"
              v-model="location.wifi.password"
              @ionInput="markDirty()"
              required="true"
              type="password"
            ></ion-input>
          </ion-item>

          <ion-item class="fullwidth">
            <ion-input
              :label="t('shortcode_prefix')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('prefix')"
              v-model="location.prefix"
              @ionChange="updatePrefix()"
              @ionInput="markDirty()"
              required="true"
              maxlength="3"
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-toggle color="secondary" v-model="location.restrictSOS" class="mr2" @ionChange="markDirty"
              >Limit SOS to VIP users</ion-toggle
            >
          </ion-item>

          <ion-item>
            <ion-toggle color="secondary" v-model="location.allowAnonymousReporting" class="mr2" @ionChange="markDirty"
              >Allow anonymous issue reporting</ion-toggle
            >
          </ion-item>
        </div>

        <div class="grow"></div>
        <!-- <nav-footer :label="location.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;

    const organisationStore = useOrganisationStore();
    const { organisation, location } = storeToRefs(organisationStore);
    const { t } = useI18n();

    const dirty = ref(false);

    let backup;

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID);
    });

    const save = async () => {
      organisationStore.saveRecord(`organisations/${organisationID}/locations`, locationID, location.value, ["floors"]);
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const createBackup = () => {
      backup = location.value || {};
    };

    const updatePrefix = () => {
      location.value.prefix = location.value.prefix.toUpperCase();
    };

    watch(location, createBackup);
    createBackup();

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                location.value = backup;
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const go = (name) => {
      router.push({ name: name, params: { locationID } });
    };

    return {
      // variables
      organisation,
      location,
      //methods
      updatePrefix,
      go,
      markDirty,
      back,
      t,
      // icons
      chevronForward,
    };
  },
};
</script>
