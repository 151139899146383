<template>
  <ion-page>
    <ion-content>
      <div id="onboarding-pagination"></div>
      <div ref="effectContainer" class="effectContainer">
        <div
          v-for="(page, i) in pages"
          :key="i"
          class="effect"
          :style="{ transform: page.transform, backgroundColor: page.style[platform].backgroundColor, color: page.style[platform].color }"
        ></div>
      </div>
      <swiper
        slot="fixed"
        :pagination="pagination"
        effect="creative"
        :creativeEffect="creativeEffect"
        direction="vertical"
        :modules="modules"
        :speed="500"
        :resistanceRatio="0"
        :grabCursor="true"
        class="swiper"
        @swiper="setSwiper"
      >
        <swiper-slide v-for="(page, index) in pages" :key="index" class="swiper-slide">
          <div class="flex vertical pb" :class="{ avlogo: page.bullets }">
            <div class="flex vertical safe-bottom">
              <div class="flex vertical centered slideContainer">
                <img src="/img/logos/logo_white.png" class="logo" v-if="index == 0" />
                <ion-label class="titleFont f2" :style="{ color: page.style[platform].color }" :class="{ cd: page.bullets }">{{
                  page.title
                }}</ion-label>

                <div v-if="page.icon">
                  <ion-icon :icon="page.icon" class="f8" :style="{ color: page.style[platform].color }" />
                </div>

                <div
                  v-for="(bullet, index) in page.bullets"
                  :key="index"
                  class="bullet"
                  :style="{ color: page.style[platform].color }"
                  :class="{ left: index % 2 == 0, right: index % 2 == 1 }"
                >
                  {{ bullet }}
                </div>

                <div class="fl mh2">
                  <div class="fullwidth" :style="{ color: page.style[platform].color }">{{ page.description }}</div>
                </div>

                <div class="ph2 fullwidth" v-if="page.destination">
                  <ion-button @click="go(page.destination)" expand="block" color="success">Get Started</ion-button>
                </div>

                <div class="flex ph2" v-if="page.permission && !page.answer">
                  <ion-button @click="acceptPermission(page)" color="success" expand="block" class="equal mt">OK</ion-button>
                  <ion-button @click="rejectPermission(page)" color="primary" expand="block" class="equal mt">Not Now</ion-button>
                </div>

                <div class="ph2 fullwidth" v-if="page.answer">
                  <ion-button disabled v-if="page.answer == Answer.ok" color="success" expand="block" class="mt"
                    >Permission Granted</ion-button
                  >
                  <ion-button v-if="page.answer == Answer.notNow" color="block" expand="block" class="mt" @click="acceptPermission(page)"
                    >Grant Later</ion-button
                  >
                  <ion-button v-if="page.answer == Answer.declined" color="danger" expand="block" class="mt" @click="retryPermission(page)"
                    >Permission Declined</ion-button
                  >
                </div>
                <div class="permissionMessage">
                  <div v-if="page.answer">
                    <div v-if="page.answer == Answer.notNow" :style="{ color: page.style[platform].color }" class="mt fs ph">
                      If you change your mind, click the button above or go to the "Settings" section.
                    </div>
                    <div v-if="page.answer == Answer.declined" :style="{ color: page.style[platform].color }" class="mt fs ph">
                      Click the button above to go to your device settings to change your selection.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </ion-content>
  </ion-page>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectCreative, Pagination } from "swiper";
import "@ionic/vue/css/ionic-swiper.css";
import { share, qrCode, location, compass, camera, radio, checkmarkCircle } from "ionicons/icons";
import { ref } from "vue-demi";
import { isPlatform } from "@ionic/core";
import { useRouter } from "vue-router";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic";
import { onIonViewWillEnter } from "@ionic/vue";

export default {
  components: { Swiper, SwiperSlide },
  setup() {
    const modules = [Pagination, EffectCreative];
    const effectContainer = ref();
    const swiper = ref();
    const router = useRouter();
    let _swiper;
    const effectStyles = ref({});
    const Permission = {
      location: "location",
      beacons: "beacons",
      camera: "camera",
    };
    const Platform = {
      ios: "ios",
      android: "android",
      web: "web",
    };
    const styles = {
      primary: { backgroundColor: "black", color: "white" },
      secondary: { backgroundColor: "#2900ff", color: "white" },
      tertiary: { backgroundColor: "white", color: "black" },
    };
    const platform = ref(
      isPlatform("ios") && isPlatform("cordova")
        ? Platform.ios
        : isPlatform("android") && isPlatform("cordova")
        ? Platform.android
        : Platform.web
    );
    const Answer = {
      ok: "OK",
      notNow: "NotNow",
      declined: "Declined",
    };

    const pages = ref(
      [
        {
          icon: qrCode,
          description: "Scan a QR code, or enter a shortcode to find details for a room or space.",
          style: { ios: styles.primary, android: styles.primary, web: styles.primary },
        },
        {
          title: "Camera Permission",
          icon: camera,
          description: "We need permission to use your camera to scan QR codes.",
          permission: Permission.camera,
          style: { ios: styles.secondary, android: styles.secondary },
        },
        {
          title: "What's Near",
          icon: location,
          description: "We can let you know about your local environment.",
          style: { ios: styles.tertiary, android: styles.tertiary },
        },
        {
          title: "Location Permission",
          icon: compass,
          description: "We need permission to detect your location in order to show you relevant local information.",
          permission: Permission.location,
          style: { ios: styles.primary, android: styles.primary },
        },
        {
          title: "Beacon Permission",
          icon: radio,
          description: "We need permission to use Bluetooth to show you information about the devices in your current location.",
          permission: Permission.beacons,
          style: { android: styles.secondary },
        },
        {
          title: "View Details",
          bullets: ["View equipment details and manuals", "Virtually visit rooms", "See and report issues", "Get help with your meetings"],
          style: { ios: styles.secondary, android: styles.tertiary, web: styles.secondary },
        },
        {
          title: "Favourite & Share",
          icon: share,
          description: "Add a room to a shortlist and share it with your colleagues and visitors.",
          style: { ios: styles.tertiary, android: styles.primary, web: styles.tertiary },
        },
        {
          title: "That's It!",
          icon: checkmarkCircle,
          description: "Click below to get start using Alpha Victor.",
          style: { ios: styles.primary, android: styles.secondary, web: styles.primary },
          destination: "Home",
        },
      ].filter((page) => Object.keys(page.style).includes(platform.value))
    );

    const onTranslate = (swiper) => {
      for (let i = 0; i < pages.value.length; i++) {
        const speed = 0.7;
        const pageProgress = swiper.progress * (pages.value.length - 1) - i;
        const progress = 1 - Math.max(Math.min(Math.abs(pageProgress * speed), 1), 0);
        if (pageProgress < 0) {
          pages.value[i].transform = `scaleY(${2 * progress}) scaleX(${4 * progress}) translateY(-${progress * 50}%)`;
        } else {
          pages.value[i].transform = `scale(2) translateY(-50%)`;
        }
      }
    };

    const creativeEffect = {
      progressMultiplier: 3,
      prev: {
        opacity: 0,
        translate: [0, -150, -1000],
      },
      next: {
        opacity: 0,
        translate: [0, 128, 0],
      },
    };

    const pagination = {
      el: "#onboarding-pagination",
      clickable: true,
    };

    const onSlideChange = async (swiper) => {
      const page = pages.value[swiper.activeIndex];
      swiper.allowTouchMove = !(page.permission && !page.answer);
    };

    const setSwiper = (swiper) => {
      _swiper = swiper;
      swiper.on("setTranslate", onTranslate);
      swiper.on("transitionEnd", onSlideChange);
    };

    Diagnostic.registerLocationStateChangeHandler((status) => {
      const page = pages.value.find((p) => p.permission == Permission.location);
      switch (status) {
        case Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
        case Diagnostic.permissionStatus.GRANTED: {
          page.answer = Answer.ok;
          break;
        }
        case Diagnostic.permissionStatus.DENIED_ALWAYS: {
          page.answer = Answer.declined;
          break;
        }
      }
    });

    Diagnostic.registerBluetoothStateChangeHandler((status) => {
      const page = pages.value.find((p) => p.permission == Permission.location);
      switch (status) {
        case Diagnostic.permissionStatus.GRANTED: {
          page.answer = Answer.ok;
          break;
        }
        case Diagnostic.permissionStatus.DENIED_ALWAYS: {
          page.answer = Answer.declined;
          break;
        }
      }
    });

    const retryPermission = async (page) => {
      if (isPlatform("android")) {
        switch (page.permission) {
          case Permission.location: {
            Diagnostic.switchToLocationSettings();
            break;
          }
          case Permission.beacons: {
            Diagnostic.switchToBluetoothSettings();
            break;
          }
          case Permission.camera: {
            Diagnostic.switchToSettings();
          }
        }
      }
      if (isPlatform("ios")) {
        Diagnostic.switchToSettings();
      }
    };

    const acceptPermission = async (page) => {
      switch (page.permission) {
        case Permission.location: {
          const status = await Diagnostic.requestLocationAuthorization();
          switch (status) {
            case Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
            case Diagnostic.permissionStatus.GRANTED: {
              page.answer = Answer.ok;
              break;
            }
            case Diagnostic.permissionStatus.DENIED_ALWAYS: {
              page.answer = Answer.declined;
              break;
            }
            default: {
              page.answer = Answer.notNow;
            }
          }
          _swiper.allowTouchMove = true;
          break;
        }

        case Permission.camera: {
          const status = await Diagnostic.requestCameraAuthorization();
          switch (status) {
            case Diagnostic.permissionStatus.GRANTED: {
              page.answer = Answer.ok;
              break;
            }
            case Diagnostic.permissionStatus.DENIED_ALWAYS: {
              page.answer = Answer.declined;
              break;
            }
            default: {
              page.answer = Answer.notNow;
            }
          }
          _swiper.allowTouchMove = true;
          break;
        }

        case Permission.beacons: {
          const btPermissions = ["BLUETOOTH_SCAN", "BLUETOOTH_CONNECT"];
          Diagnostic.requestBluetoothAuthorization(
            () => {
              Diagnostic.registerBluetoothStateChangeHandler((state) => {
                switch (state) {
                  case Diagnostic.bluetoothState.UNAUTHORIZED: {
                    page.answer = Answer.declined;
                    break;
                  }
                  default: {
                    page.answer = Answer.ok;
                  }
                }
              });
              page.answer = Answer.ok;
              _swiper.allowTouchMove = true;
            },
            (e) => {
              console.log("Error", e);
            },
            btPermissions
          );

          break;
        }

        default: {
          console.log("No permission detected");
        }
      }
    };

    const rejectPermission = (page) => {
      page.answer = Answer.notNow;
      _swiper.allowTouchMove = true;
    };

    const go = (destination) => {
      window.localStorage.setItem("onboardingStatus", "Complete");
      router.push({ name: destination });
    };

    onIonViewWillEnter(() => {
      _swiper.setProgress(0);
      pages.value.forEach(async (page) => {
        if (page.permission) {
          switch (page.permission) {
            case Permission.location: {
              const status = await Diagnostic.getLocationAuthorizationStatus();
              if ([Diagnostic.permissionStatus.GRANTED, Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE].includes(status)) {
                page.answer = Answer.ok;
              } else if (status == Diagnostic.permissionStatus.DENIED_ALWAYS) {
                page.answer = Answer.declined;
              }
              break;
            }

            case Permission.camera: {
              const status = await Diagnostic.getCameraAuthorizationStatus();
              if (status == Diagnostic.permissionStatus.GRANTED) {
                page.answer = Answer.ok;
              } else if (status == Diagnostic.permissionStatus.DENIED_ALWAYS) {
                page.answer = Answer.declined;
              }
              break;
            }
          }
        }
      });
    });

    return {
      // methods
      setSwiper,
      acceptPermission,
      rejectPermission,
      retryPermission,
      go,
      // variables
      swiper,
      pages,
      modules,
      pagination,
      effectContainer,
      creativeEffect,
      effectStyles,
      Answer,
      platform,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "swiper/scss";
@import "swiper/scss/effect-creative";
@import "swiper/scss/pagination";

.effectContainer {
  overflow: hidden;
}

.effectContainer,
.effect {
  position: absolute;
  top: 0px;
  left: 0%;
  width: 100%;
  height: 100%;
}

.effect {
  border-radius: 50%;
  transform-origin: center center;
  top: 100%;
}

.swiper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 0px 40px 50px;
}
</style>
<style scoped>
.logo {
  height: 20vh !important;
  filter: brightness(0) invert(1);
}

#onboarding-pagination {
  position: absolute;
  right: 10px;
  width: 2rem;
  border-radius: 1rem;
  background: rgba(var(--ion-color-light-rgb), 0.5);
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
}

.permissionMessage {
  min-height: 100px;
}
</style>

<style>
#onboarding-pagination .swiper-pagination-bullet {
  background-color: var(--ion-color-menu);
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  opacity: 0.1;
}

#onboarding-pagination .swiper-pagination-bullet-active {
  opacity: 1;
}
</style>
