// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.file[data-v-4a8963e9] {
  width: 150px;
}
.doctypeLabel[data-v-4a8963e9] {
  border-bottom: 0.5px solid rgba(var(--ion-color-dark-contrast-rgb), 0.5);
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
