<template>
  <ion-page>
    <av-header />

    <ion-content :scroll-y="false" v-if="organisation">
      <input type="file" @change="addBackground($event)" ref="backgroundSelector" hidden />

      <div class="flex vertical nogap left bgd" slot="fixed">
        <view-header @back="back" :title="organisation.name" :subtitle="t('edit_background')" edit="true" />

        <div class="grow fullwidth">
          <img :src="organisation.theme?.background || '/img/placeholders/transparent.png'" class="p2 cover" />
        </div>

        <div class="flex p2 mb2">
          <ion-button @click="triggerAddBackground" expand="block" class="bright grow">{{
            t(organisation.theme?.background ? "change_background" : "add_background")
          }}</ion-button>
          <ion-button @click="deleteLogo" :disabled="!organisation.theme?.background" color="danger" expand="block">{{
            t("delete")
          }}</ion-button>
        </div>

        <!-- <nav-footer :label="organisation.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default {
  name: "Description",
  setup() {
    const dirty = ref(false);
    const router = useRouter();
    const organisationID = useRoute().params.organisationID;
    const organisationStore = useOrganisationStore();
    const { organisation } = storeToRefs(organisationStore);
    const backgroundSelector = ref();
    const logoSelector = ref();
    const { t } = useI18n();
    let backup;

    onIonViewWillEnter(() => organisationStore.initialise(organisationID));

    const save = async () => {
      organisationStore.saveRecord("organisations", organisationID, organisation.value, [
        "decisionTrees",
        "locations",
        "articles",
        "videos",
        "issues",
        "loaded",
        "imageLoaded",
      ]);
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const triggerAddBackground = () => {
      backgroundSelector.value.click();
    };
    const triggerAddLogo = () => {
      backgroundSelector.value.click();
    };

    const addBackground = async (event) => {
      const files = event.target.files;
      const file = files[0];
      organisationStore.addBackground(organisationID, file);
      backgroundSelector.value.value = "";
    };

    const addLogo = async (event) => {
      const files = event.target.files;
      const file = files[0];
      organisationStore.addLogo(organisationID, file);
      logoSelector.value.value = "";
    };

    const deleteBackground = async () => {
      await organisationStore.deleteBackground(organisationID);
    };

    const deleteLogo = async () => {
      await organisationStore.deleteLogo(organisationID);
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      organisation,
      backgroundSelector,
      logoSelector,
      // methods
      markDirty,
      back,
      triggerAddBackground,
      triggerAddLogo,
      addBackground,
      addLogo,
      deleteBackground,
      deleteLogo,
      t,
      // icons
      chevronForward,
    };
  },
};
</script>
