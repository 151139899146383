// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#loginContainer[data-v-17c846a0] {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.formItems[data-v-17c846a0] {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
#loginContainer ion-item[data-v-17c846a0],
#loginContainer ion-button[data-v-17c846a0] {
  width: 100%;
  max-width: 500px;
}
.footerContainer[data-v-17c846a0] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: env(safe-area-inset-bottom);
}
.footerContainer ion-button[data-v-17c846a0] {
  flex: 1 0 1px;
  font-size: 0.7em;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
