// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar[data-v-3ffae6b2] {
  border-radius: 3em;
  margin: calc(20px + var(--ion-safe-area-top)) 20px 20px;
  width: calc(100% - 40px);
}
.toolbar a[data-v-3ffae6b2] {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}
.toolbar .headerLogo[data-v-3ffae6b2] {
  height: 2em;
}
ion-menu ion-list[data-v-3ffae6b2] {
  --background: transparent;
}
ion-menu ion-header[data-v-3ffae6b2],
ion-menu ion-footer[data-v-3ffae6b2] {
  background: var(--ion-color-menu);
  color: var(--ion-color-menu-contrast);
}
ion-menu .content[data-v-3ffae6b2] {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--ion-color-menu);
}
ion-menu .sectionTitle[data-v-3ffae6b2] {
  width: 100%;
  font-size: 0.8em;
  font-family: Bold;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(var(--ion-color-menu-contrast-rgb), 0.5);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
