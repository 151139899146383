// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.v-enter-active[data-v-3ffae6b2],
.v-leave-active[data-v-3ffae6b2] {
  transition: opacity 0.5s ease;
}
.v-enter-from[data-v-3ffae6b2],
.v-leave-to[data-v-3ffae6b2] {
  opacity: 0;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
