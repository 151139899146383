<template>
  <ion-content>
    <div class="p2 cl">
      <div class="titleFont f2">Admin functions</div>

      <ion-item>
        <ion-select
          v-model="organisationID"
          label="Target organisation"
          label-position="stacked"
          interface="action-sheet"
          :interface-options="popoverOptions"
        >
          <ion-select-option v-for="organisation in organisations" :key="organisation.id" :value="organisation.id">{{
            organisation.name
          }}</ion-select-option>
        </ion-select>
      </ion-item>

      <div class="m2 p2 bdd" v-if="organisationID">
        <ion-item lines="none">
          <div class="cp" style="color: white">Use a template to create a new decision tree in this organisation.</div>
        </ion-item>
        <ion-item lines="none">
          <ion-select
            v-model="templateID"
            label="Templates"
            label-placement="stacked"
            interface="action-sheet"
            :interface-options="popoverOptions"
          >
            <ion-select-option v-for="template in templates" :key="template.id" :value="template.id">{{
              template.title
            }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item v-if="templateID">
          <ion-button @click="addTree">Add demo tree to organisation</ion-button>
        </ion-item>
      </div>

      <div class="m2 p2 bdd" v-if="organisationID">
        <ion-item lines="none">
          <div class="cs">Save an existing decision tree as a template for re-use. Rename the tree before saving if required.</div>
        </ion-item>
        <ion-item lines="none">
          <ion-select
            v-model="decisionTreeID"
            label="Decision Trees"
            label-placement="stacked"
            interface="action-sheet"
            :interface-options="popoverOptions"
          >
            <ion-select-option v-for="tree in decisionTrees" :key="tree.id" :value="tree.id">{{ tree.title }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item lines="none" v-if="selectedTree">
          <ion-input v-model="selectedTree.title" label="Cloned name" label-placement="stacked"></ion-input>
          <ion-button @click="cloneTree">Clone decision tree as demo</ion-button>
          <ion-button @click="duplicateTree">Duplicate tree within organisation</ion-button>
        </ion-item>
      </div>
    </div>
  </ion-content>
</template>

<script setup>
import { getFirestore, collection, query, addDoc, getDocs, getDoc, doc, setDoc } from "@firebase/firestore";
import { onMounted, ref, watch } from "vue";
import { IonSelect, IonSelectOption } from "@ionic/vue";

const organisationID = ref();
const decisionTreeID = ref();
const templateID = ref("");
const organisations = ref([]);
const decisionTrees = ref([]);
const templates = ref([]);
const selectedTree = ref();

const db = getFirestore();

const popoverOptions = {
  cssClass: "popover",
};

const updateOrganisation = async () => {
  const dtCol = await getDocs(query(collection(db, `organisations/${organisationID.value}/decisionTrees`)));
  decisionTrees.value = dtCol.docs.map((dt) => ({ id: dt.id, ...dt.data() }));
};

const updateTemplates = async () => {
  const tCol = await getDocs(query(collection(db, `demoTrees`)));
  templates.value = tCol.docs.map((t) => t.data());
};

onMounted(async () => {
  const oCol = await getDocs(query(collection(db, `organisations`)));
  organisations.value = oCol.docs.map((o) => o.data());
  updateOrganisation();
  updateTemplates();
});

watch(organisationID, updateOrganisation);

watch(decisionTreeID, () => {
  selectedTree.value = decisionTrees.value.find((t) => t.id == decisionTreeID.value);
});

const cloneTree = async () => {
  const dRes = await getDocs(
    query(collection(db, `/organisations/${organisationID.value}/decisionTrees/${decisionTreeID.value}/destinations`))
  );
  const oRes = await getDocs(
    query(collection(db, `/organisations/${organisationID.value}/decisionTrees/${decisionTreeID.value}/outcomes`))
  );
  const aRes = await getDocs(query(collection(db, `/organisations/${organisationID.value}/articles`)));
  const vRes = await getDocs(query(collection(db, `/organisations/${organisationID.value}/videos`)));

  const destinations = dRes.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  const outcomes = oRes.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  const articles = aRes.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  const videos = vRes.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  setDoc(doc(db, `demoTrees`, selectedTree.value.id), selectedTree.value);

  destinations.forEach((d) => {
    setDoc(doc(db, `demoTrees/${selectedTree.value.id}/destinations`, d.id), d);
    if (d.type == "Article") {
      const aDoc = articles.find((a) => a.id == d.destinationID);
      if (aDoc) setDoc(doc(db, `demoTrees/${selectedTree.value.id}/articles`, aDoc.id), aDoc);
    }
    if (d.type == "Video") {
      const vDoc = videos.find((a) => a.id == d.destinationID);
      if (vDoc) setDoc(doc(db, `demoTrees/${selectedTree.value.id}/videos`, vDoc.id), vDoc);
    }
  });

  outcomes.forEach((d) => setDoc(doc(db, `demoTrees/${selectedTree.value.id}/outcomes`, d.id), d));
  updateTemplates();
};

const duplicateTree = async () => {
  const dRes = await getDocs(
    query(collection(db, `/organisations/${organisationID.value}/decisionTrees/${decisionTreeID.value}/destinations`))
  );
  const oRes = await getDocs(
    query(collection(db, `/organisations/${organisationID.value}/decisionTrees/${decisionTreeID.value}/outcomes`))
  );

  const destinations = dRes.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  const outcomes = oRes.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  const decisionTreeDoc = await getDoc(doc(db, `/organisations/${organisationID.value}/decisionTrees`, decisionTreeID.value));
  const decisionTreeDocData = decisionTreeDoc.data();

  const newDecisionTreeDoc = await addDoc(collection(db, `organisations/${organisationID.value}/decisionTrees`), {
    ...decisionTreeDocData,
    title: selectedTree.value.title,
  });

  destinations.forEach((d) => {
    setDoc(doc(db, `organisations/${organisationID.value}/decisionTrees/${newDecisionTreeDoc.id}/destinations`, d.id), d);
  });

  outcomes.forEach((d) =>
    setDoc(doc(db, `organisations/${organisationID.value}/decisionTrees/${newDecisionTreeDoc.id}/outcomes`, d.id), d)
  );
};

const addTree = async () => {
  const dRes = await getDocs(query(collection(db, `/demoTrees/${templateID.value}/destinations`)));
  const oRes = await getDocs(query(collection(db, `/demoTrees/${templateID.value}/outcomes`)));
  const aRes = await getDocs(query(collection(db, `/demoTrees/${templateID.value}/articles`)));
  const vRes = await getDocs(query(collection(db, `/demoTrees/${templateID.value}/videos`)));

  const destinations = dRes.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  const outcomes = oRes.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  const articles = aRes.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  const videos = vRes.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  const treeRes = await getDoc(doc(db, "/demoTrees", templateID.value));
  const tree = { id: treeRes.id, ...treeRes.data() };

  await setDoc(doc(db, `organisations/${organisationID.value}/decisionTrees`, templateID.value), tree);

  destinations.forEach((d) => {
    setDoc(doc(db, `organisations/${organisationID.value}/decisionTrees/${templateID.value}/destinations`, d.id), d);
    if (d.type == "Article") {
      const aDoc = articles.find((a) => a.id == d.destinationID);
      if (aDoc) setDoc(doc(db, `organisations/${organisationID.value}/articles`, aDoc.id), aDoc);
    }
    if (d.type == "Video") {
      const vDoc = videos.find((a) => a.id == d.destinationID);
      if (vDoc) setDoc(doc(db, `organisations/${organisationID.value}/videos`, vDoc.id), vDoc);
    }
  });

  outcomes.forEach((d) => setDoc(doc(db, `organisations/${organisationID.value}/decisionTrees/${templateID.value}/outcomes`, d.id), d));
};
</script>

<style>
.action-sheet-button {
  --background: white;
}
</style>
