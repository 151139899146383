<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div id="articleContainer" v-if="article" class="flex vertical p">
        <div class="fullwidth">
          <ion-item>
            <ion-input
              :label="t('title')"
              label-placement="stacked"
              :placeholder="t('title')"
              v-model="article.title"
              @ionInput="isDirty = true"
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-input
              :label="t('subtitle')"
              label-placement="stacked"
              :placeholder="t('subtitle')"
              v-model="article.subtitle"
              @ionInput="dirty = true"
            ></ion-input>
          </ion-item>
        </div>
        <div class="grow fullwidth mt">
          <QuillEditor
            class="editor bdp cs"
            theme="bubble"
            toolbar="essential"
            v-model:content="article.html"
            contentType="html"
            @textChange="dirty = true"
          ></QuillEditor>
        </div>
        <div class="footer p flex">
          <ion-button @click="save" :disabled="!dirty" expand="block" color="secondary" class="grow">{{ t("save") }}</ion-button>
          <ion-button @click="cancel" expand="block" color="warning">{{ t("cancel") }}</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";
import { useI18n } from "vue-i18n";

export default {
  components: { QuillEditor },
  setup() {
    const organisationStore = useOrganisationStore();
    const { articles } = storeToRefs(organisationStore);
    const { t } = useI18n();

    const dirty = ref(false);
    const route = useRoute();
    const router = useRouter();
    const organisationID = route.params.organisationID;
    const articleID = route.params.articleID;

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID);
    });

    const article = computed(() => articles?.value && articles.value[articleID]);

    const save = async () => {
      organisationStore.saveRecord(`organisations/${organisationID}/articles`, articleID, article.value);
      router.back();
    };

    const cancel = () => {
      router.back();
    };

    return { article, dirty, save, cancel, t };
  },
};
</script>

<style scoped>
ion-footer {
  display: flex;
  justify-content: center;
  background: var(--ion-color-dark);
  padding: 20px;
}

ion-item {
  width: 100%;
}

#articleContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}
</style>
