<template>
  <ion-page>
    <av-header />
    <ion-content scroll-y="false">
      <div class="flex vertical" slot="fixed" v-if="space">
        <view-header @back="back" :title="space?.name" :subtitle="$t('gallery')" class="fullwidth" />
        <swiper :pagination="pagination" :modules="modules" direction="vertical" class="fullheight">
          <swiper-slide v-for="(photo, index) in space.photos" :key="index">
            <div class="fullheight">
              <img :src="photo.url" class="fill cover" />
            </div>
            <div
              class="p cd boldFont fs"
              style="position: absolute; bottom: 20px; background: rgba(var(--ion-color-light-rgb), 0.9); border-radius: 5px"
              v-if="photo.description"
            >
              {{ photo.description }}
            </div>
          </swiper-slide>
        </swiper>
        <!-- <nav-footer label="Back" @back="back" /> -->
      </div>
      <wait v-else />

      <div id="swiper-pagination"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import { chevronBackCircleOutline, shieldCheckmarkOutline } from "ionicons/icons";
import { useOrganisationStore } from "@/stores/organisation";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/vue/css/ionic-swiper.css";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";
import mixpanel from "mixpanel-browser";

export default {
  components: { Swiper, SwiperSlide },
  setup() {
    const organisationStore = useOrganisationStore();
    const route = useRoute();
    const router = useRouter();
    const spaceID = route.params.spaceID;
    const floorID = route.params.floorID;
    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const { organisation, location, floor, space } = storeToRefs(organisationStore);
    const modules = [Pagination];
    const pagination = {
      el: "#swiper-pagination",
      clickable: true,
    };

    onIonViewWillEnter(async () => {
      await organisationStore.initialise(organisationID, locationID, floorID, spaceID);
      mixpanel.track("Gallery Viewed", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
      });
    });

    const back = () => {
      router.back();
    };

    return {
      back,
      space,
      chevronBackCircleOutline,
      shieldCheckmarkOutline,
      modules,
      pagination,
    };
  },
};
</script>

<style>
#swiper-pagination {
  position: absolute;
  right: 10px;
  width: 2rem;
  border: 1px solid var(--ion-color-light);
  border-radius: 1rem;
  background: var(--ion-color-secondary-contrast);
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
}

.swiper-pagination-bullet {
  background-color: var(--ion-color-secondary);
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}
</style>
