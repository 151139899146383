<template>
  <IonApp>
    <IonSplitPane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay" :swipe-gesture="false" v-show="showMenu">
        <ion-content>
          <div class="content safe-top safe-bottom" slot="fixed">
            <div class="flex centered p2">
              <!-- <ion-chip v-if="!isProduction" color="warning" :outline="true">{{ $t("development_environment") }} </ion-chip> -->
              <img src="/img/logos/logo_white.png" alt="Alpha Victor" @click="goHome" style="height: 50px" />
            </div>
            <div class="scroll flex vertical left">
              <ion-list class="fullwidth">
                <div v-for="(item, index) in menuItems" :key="index">
                  <ion-item
                    :router-link="item.routerLink"
                    v-if="hasPermissions(item.requiresRegistered, item.requiredRoles)"
                    @click="closeMenu"
                    lines="none"
                  >
                    <ion-icon slot="end" :icon="chevronForward" color="secondary" size="small" />
                    <ion-label color="secondary">{{ $t(item.label) }}</ion-label>
                  </ion-item>
                </div>
              </ion-list>

              <ion-item
                v-if="!userData.firebaseUser?.isAnonymous && !userData.firebaseUser?.emailVerified"
                router-link="/verify-email"
                @click="closeMenu"
                lines="none"
                class="mt2 fullwidth"
              >
                <ion-icon slot="start" :icon="alertCircle" color="secondary" />
                <ion-label color="secondary">{{ $t("verify_your_email") }}</ion-label>
              </ion-item>

              <ion-list v-if="supportVisible" class="mt2 fullwidth">
                <ion-item lines="none">
                  <ion-label color="secondary" class="sectionTitle">{{ $t("support") }}</ion-label></ion-item
                >
                <div v-for="(item, index) in supportMenuItems" :key="index">
                  <ion-item :router-link="item.routerLink" v-if="hasPermissions(true, item.requiredRoles)" @click="closeMenu" lines="none">
                    <ion-label color="secondary">{{ $t(item.label) }}</ion-label>
                    <ion-icon slot="end" :icon="chevronForward" color="secondary" size="small" />
                  </ion-item>
                </div>
              </ion-list>

              <ion-list v-if="adminVisible" class="mt2 fullwidth">
                <ion-item lines="none">
                  <ion-label color="secondary" class="sectionTitle">{{ $t("administration") }}</ion-label></ion-item
                >
                <div v-for="(item, index) in adminMenuItems" :key="index">
                  <ion-item :router-link="item.routerLink" v-if="hasPermissions(true, item.requiredRoles)" @click="closeMenu" lines="none">
                    <ion-label color="secondary">{{ $t(item.label) }}</ion-label>
                    <ion-icon slot="end" :icon="chevronForward" color="secondary" size="small" />
                  </ion-item>
                </div>
              </ion-list>

              <ion-list class="mt2 fullwidth">
                <ion-item lines="none">
                  <ion-label color="secondary" class="sectionTitle">{{ $t("options") }}</ion-label></ion-item
                >
                <div v-for="(item, index) in otherMenuItems" :key="index">
                  <ion-item
                    :router-link="item.routerLink"
                    v-if="hasPermissions(item.requiresRegistered, item.requiredRoles)"
                    @click="closeMenu"
                    lines="none"
                  >
                    <ion-icon slot="end" :icon="chevronForward" color="secondary" size="small" />
                    <ion-label color="secondary">{{ $t(item.label) }}</ion-label>
                  </ion-item>
                </div>
              </ion-list>
            </div>

            <div>
              <ion-button
                @click="handleLogin"
                expand="block"
                class="mt2 mh2"
                color="secondary"
                v-if="userData?.firebaseUser && !userData?.firebaseUser?.isAnonymous"
              >
                <ion-label>{{ $t("logout") }}</ion-label>
              </ion-button>

              <div class="flex centered mt pb2">
                <div class="fs cl">
                  {{ userData?.firebaseUser?.email }}
                </div>
                <ion-icon v-if="userData?.firebaseUser?.emailVerified" class="ft cs" :icon="checkmark" />
              </div>
            </div>
          </div>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content" />
      <Transition>
        <div class="flex absolute centered fullheight bgl o9" v-if="!isConnected">
          <div class="flex autoheight vertical">
            <div class="titleFont f2 uppercase">No network</div>
            <div>Please check your connection</div>
          </div>
        </div>
      </Transition>
    </IonSplitPane>
  </IonApp>
</template>

<script setup>
import { IonApp, IonRouterOutlet, IonMenu, IonSplitPane, menuController } from "@ionic/vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { App } from "@capacitor/app";
import { SplashScreen } from "@capacitor/splash-screen";
import { useUserStore } from "@/stores/user";
import { Network } from "@capacitor/network";
import {
  informationCircle,
  person,
  settings,
  bookmarks,
  book,
  business,
  people,
  list,
  documentText,
  grid,
  cube,
  desktop,
  calendarNumber,
  mail,
  checkmark,
  alertCircle,
  chevronForward,
} from "ionicons/icons";
import { storeToRefs } from "pinia";

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const userData = userStore.data;
const { currentOrganisationID } = storeToRefs(userStore);
const isConnected = ref(true);
let connectionTimer;

const adminVisible = computed(() => {
  const roles = userData.user?.roles;
  if (!(roles?.length > 0)) return false;
  return roles.some((r) => ["admin", "organisationAdmin", "manufacturerAdmin"].includes(r));
});

const supportVisible = computed(() => {
  const roles = userData.user?.roles;
  if (!(roles?.length > 0)) return false;
  return roles.some((r) => ["admin", "support"].includes(r));
});

SplashScreen.hide();

const showMenu = computed(() => {
  const pagesDontHaveMenu = [
    "EmbedPDF",
    "Onboarding",
    "StaticHome",
    "Login",
    "LoginWithPassword",
    "Register",
    "Reminder",
    "Validatemail",
    "MagicLink",
  ];
  return !pagesDontHaveMenu.includes(route.name);
});

const updateConnectionStatus = async () => {
  const currentStatus = await Network.getStatus();
  if (!currentStatus.connected) {
    connectionTimer = setTimeout(() => {
      isConnected.value = false;
    }, 3000);
  } else {
    isConnected.value = true;
    if (connectionTimer) clearTimeout(connectionTimer);
  }
};

onMounted(async () => {
  updateConnectionStatus();
  Network.addListener("networkStatusChange", updateConnectionStatus);

  App.addListener("appUrlOpen", (event) => {
    if (/^https:\/\/.{0,10}\.?alphavictor\.com\/auth/.test(event.url)) {
      router.push({ name: "Auth", params: { url: event.url } });
      return;
    }

    if (/^https:\/\/.{0,10}\.?alphavictor\.com\/qr/.test(event.url)) {
      const slug = event.url.split("alphavictor.com").pop();
      if (slug) {
        router.push({ path: slug });
      }
    }

    if (/^https:\/\/.{0,10}\.?alphavictor\.com\/link/.test(event.url)) {
      const slug = event.url.split("alphavictor.com").pop();
      if (slug) {
        router.push({ path: slug });
      }
    }
  });
});

const handleLogin = async () => {
  menuController.close();
  if (!userData?.firebaseUser?.isAnonymous) {
    await userStore.logout();
    router.push({ name: "Home" });
  } else {
    router.push({ name: "LoginWithPassword" });
  }
};

const closeMenu = () => {
  menuController.close();
};

const goHome = () => {
  router.push("/");
  menuController.close();
};

const hasPermissions = (requiresRegistered, permissions) => {
  if (requiresRegistered && !userData.firebaseUser.emailVerified) return false;
  if (!permissions) return true;
  if (!userData?.user.roles) return false;
  if (userData?.user.roles?.includes("admin")) return true;
  return permissions.every((item) => userData?.user.roles.includes(item));
};

const menuItems = ref([
  {
    label: "favourites",
    routerLink: "/favourites",
    requiresRegistered: true,
    icon: bookmarks,
  },
  {
    label: "recently_viewed",
    routerLink: "/recent",
    requiresRegistered: true,
    icon: calendarNumber,
  },
  {
    label: "find_space",
    routerLink: `/organisation/view/`,
    requiresRegistered: true,
    icon: cube,
  },
]);

const otherMenuItems = ref([
  {
    label: "my_details",
    routerLink: "/account",
    requiresRegistered: true,
    icon: person,
  },
  {
    label: "settings",
    routerLink: "/settings",
    icon: settings,
  },
  {
    label: "change_organisation",
    routerLink: "/select-organisation",
    requiresRegistered: true,
    icon: business,
  },
  {
    label: "terms_and_conditions",
    routerLink: "/terms",
    requiresRegistered: false,
    icon: book,
  },
  {
    label: "about_alpha_victor",
    routerLink: "/about",
    requiresRegistered: false,
    icon: informationCircle,
  },
  {
    label: "contact_us",
    routerLink: "/contact",
    requiresRegistered: false,
    icon: mail,
    lines: "none",
  },
]);

const supportMenuItems = computed(() => [
  {
    label: "checklists",
    routerLink: `/support/checklists/${currentOrganisationID.value}`,
    requiresRegistered: true,
    requiredRoles: ["support"],
    icon: list,
  },
  // {
  //   label: "reports",
  //   routerLink: `/support/reports/${currentOrganisationID.value}`,
  //   requiresRegistered: true,
  //   requiredRoles: ["support"],
  //   icon: documentText,
  // },
]);

const adminMenuItems = ref([
  {
    label: "organisations",
    routerLink: "/organisations/list",
    requiresRegistered: true,
    requiredRoles: ["organisationAdmin"],
    icon: business,
  },

  {
    label: "asset_types",
    routerLink: "/assetTypes/list",
    requiresRegistered: true,
    requiredRoles: ["manufacturerAdmin"],
    icon: grid,
  },
  {
    label: "manufacturers",
    routerLink: "/manufacturers/list",
    requiresRegistered: true,
    requiredRoles: ["manufacturerAdmin"],
    icon: people,
  },
  {
    label: "models",
    routerLink: "/models/list",
    requiresRegistered: true,
    requiredRoles: ["manufacturerAdmin"],
    icon: desktop,
  },
]);

const verifyEmail = () => {
  router.push({ name: "VerifyEmail" });
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<style lang="scss" scoped>
ion-menu {
  ion-list {
    --background: transparent;
  }

  ion-header,
  ion-footer {
    background: var(--ion-color-menu);
    color: var(--ion-color-menu-contrast);
  }

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--ion-color-menu);
  }

  .sectionTitle {
    width: 100%;
    font-size: 0.8em;
    font-family: Bold;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(var(--ion-color-menu-contrast-rgb), 0.5);
    // padding-top: 10px;
  }
}
</style>
