// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.scroll[data-v-2216f6f8] {
  height: 100%;
}
.canvas[data-v-2216f6f8] {
  background: var(--ion-color-light);
  border: 1px solid var(--ion-color-dark-contrast);

  margin: 10px;
}
#pageContainer[data-v-2216f6f8] {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
#container[data-v-2216f6f8] {
  flex-grow: 1;
  overflow: hidden;
}
ion-footer[data-v-2216f6f8] {
  display: flex;
  justify-content: center;
  padding: 20px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
