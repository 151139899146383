<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="space">
      <div class="flex vertical nogap noScroll bgd">
        <view-header @back="back" :title="space.name" :subtitle="t('edit_beacon')" edit="true" />

        <div class="flex vertical ph pt noScroll">
          <div class="flex">
            <ion-item class="fullwidth">
              <ion-input
                :label="t('major')"
                label-placement="stacked"
                color="secondary"
                :placeholder="t('major')"
                v-model="currentBeacon.major"
                @ionInput="markDirty()"
                required="true"
              ></ion-input>
            </ion-item>
            <ion-item class="fullwidth">
              <ion-input
                :label="t('minor')"
                label-placement="stacked"
                color="secondary"
                :placeholder="t('minor')"
                v-model="currentBeacon.minor"
                @ionInput="markDirty()"
                required="true"
              ></ion-input>
            </ion-item>
          </div>
          <div class="fullwidth">
            <ion-button expand="block" class="bright" @click="toggleScanning">{{
              t(isRanging ? "stop_scanning" : "scan_for_beacons")
            }}</ion-button>
          </div>
          <div class="mt2 scroll fullwidth">
            <ion-item v-for="(unusedBeacon, i) in unusedBeacons" :key="i" @click="selectBeacon(unusedBeacon)">
              <img src="/img/ui/beacon.svg" class="beaconImage" slot="start" />
              <ion-label color="secondary">{{ unusedBeacon.major }}:{{ unusedBeacon.minor }}</ion-label>
              <div slot="end" class="cs">
                <svg
                  width="20px"
                  viewBox="0 0 230 170"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <title>Power</title>
                  <g id="PowerBars" stroke="currentColor" stroke-width="8" fill="none">
                    <rect
                      id="Rectangle"
                      x="200"
                      y="20"
                      width="30"
                      height="150"
                      :fill="getColour(unusedBeacon, 5)"
                      :stroke="getColour(unusedBeacon, 5)"
                    ></rect>
                    <rect
                      id="Rectangle-Copy"
                      x="150"
                      y="50"
                      width="30"
                      height="120"
                      :fill="getColour(unusedBeacon, 4)"
                      :stroke="getColour(unusedBeacon, 5)"
                    ></rect>
                    <rect
                      id="Rectangle-Copy-2"
                      x="100"
                      y="80"
                      width="30"
                      height="90"
                      :fill="getColour(unusedBeacon, 3)"
                      :stroke="getColour(unusedBeacon, 5)"
                    ></rect>
                    <rect
                      id="Rectangle-Copy-3"
                      x="50"
                      y="110"
                      width="30"
                      height="60"
                      :fill="getColour(unusedBeacon, 2)"
                      :stroke="getColour(unusedBeacon, 5)"
                    ></rect>
                    <rect
                      id="Rectangle-Copy-4"
                      x="0"
                      y="140"
                      width="30"
                      height="30"
                      :fill="getColour(unusedBeacon, 1)"
                      :stroke="getColour(unusedBeacon, 5)"
                    ></rect>
                  </g>
                </svg>
              </div>
            </ion-item>
          </div>
          <div class="grow"></div>
        </div>

        <div class="grow"></div>
        <!-- <nav-footer :label="space.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { useBeaconStore } from "@/stores/beacons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;

    const organisationStore = useOrganisationStore();
    const beaconStore = useBeaconStore();

    const { organisation, location, floor, space } = storeToRefs(organisationStore);
    const { unusedBeacons, beacons, isRanging } = storeToRefs(beaconStore);

    const { t } = useI18n();

    let originalBeacon;

    const currentBeacon = ref({});
    const dirty = ref(false);

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
      beaconStore.start(false);
    });

    const save = async () => {
      if (originalBeacon?.id) organisationStore.deleteRecord(`organisations/${organisationID}/beacons`, originalBeacon.id);
      delete currentBeacon.value?.used;
      delete currentBeacon.value?.accuracy;
      delete currentBeacon.value?.average;
      delete currentBeacon.value?.readings;
      organisationStore.addRecord(`organisations/${organisationID}/beacons`, {
        ...currentBeacon.value,
        organisationID,
        locationID,
        floorID,
        spaceID,
      });

      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const createBackup = () => {
      if (!originalBeacon) {
        originalBeacon = beacons.value?.find(
          (b) => b.spaceID == spaceID && b.locationID == locationID && b.floorID == floorID && b.spaceID == spaceID
        );
        if (originalBeacon) {
          currentBeacon.value = originalBeacon;
        }
      }
    };

    watch(beacons, createBackup);
    createBackup();

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const toggleScanning = () => {
      if (isRanging.value) {
        beaconStore.stop();
      } else {
        beaconStore.start(false);
      }
    };

    const getColour = (beacon, strength) => {
      const rssi = beacon.average;
      const power = rssi > -70 ? 5 : rssi > -77 ? 4 : rssi > -84 ? 3 : rssi > -91 ? 2 : 1;
      return power >= strength ? "currentColor" : "none";
    };

    const go = (name) => {
      router.push({ name: name, params: { locationID } });
    };

    const selectBeacon = (newBeacon) => {
      markDirty();
      currentBeacon.value = newBeacon;
    };

    return {
      // variables
      organisation,
      location,
      floor,
      space,
      currentBeacon,
      unusedBeacons,
      isRanging,
      //methods
      go,
      markDirty,
      back,
      getColour,
      toggleScanning,
      selectBeacon,
      t,
      // icons
      chevronForward,
    };
  },
};
</script>

<style scoped>
.beaconImage {
  height: 40px;
}
</style>
