<template>
  <div @click="back" class="uppercase flex nogap left boldFont pl pt2 pb cs pointer">&lt; {{ backLabel }}</div>
  <div class="flex centered fh mt2 titleFont uppercase" v-if="showTitle"><slot></slot></div>
</template>
<script>
export default {
  props: ["backLabel"],
  emits: ["back"],
  setup(props, { emit, slots }) {
    const back = () => {
      emit("back");
    };

    const showTitle = slots.default;

    return {
      // variables
      showTitle,
      // methods
      back,
      // icons
    };
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
