// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bg[data-v-49b08412] {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}
.bg img[data-v-49b08412] {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}
.tight[data-v-49b08412] {
  line-height: 0.9em;
}
.footer[data-v-49b08412] {
  font-family: Title;
  color: var(--ion-color-secondary);
  font-size: 1.6em;
  text-transform: uppercase;
  background: var(--ion-color-light-tint);
}
.smallButton[data-v-49b08412] {
  height: 1.5rem;
  font-size: 0.7rem;
}
.errorCount[data-v-49b08412] {
  margin: -20px 0px;
}
.bordered[data-v-49b08412] {
  flex-direction: column;
  border: 1px solid var(--ion-color-light);
}
.actionCell[data-v-49b08412] {
  flex-direction: column;
}
.imessage[data-v-49b08412] {
  background-color: var(--ion-color-primary);
  display: flex;
  flex-direction: column;
  font-family: Regular;
  font-size: 1rem;
  max-width: 600px;
}
.imessage p[data-v-49b08412] {
  border-radius: 1.15rem;
  max-width: 75%;
  padding: 1.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
}
.imessage p[data-v-49b08412]::before,
.imessage p[data-v-49b08412]::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}
p.from-me[data-v-49b08412] {
  align-self: flex-end;
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-light);
}
p.from-me[data-v-49b08412]::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid var(--ion-color-menu);
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}
p.from-me[data-v-49b08412]::after {
  background-color: var(--ion-color-primary);
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}
p[class^="from-"][data-v-49b08412] {
  width: 90%;
  margin: 5px;
}
p.from-them[data-v-49b08412] {
  align-items: flex-start;
  background-color: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
}
p.from-them[data-v-49b08412]:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid var(--ion-color-light);
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}
p.from-them[data-v-49b08412]::after {
  background-color: var(--ion-color-primary);
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}
p[class^="from-"].emoji[data-v-49b08412] {
  background: none;
  font-size: 2.5rem;
}
p[class^="from-"].emoji[data-v-49b08412]::before {
  content: none;
}
.option[data-v-49b08412] {
  background-color: var(--ion-color-menu) !important;
  color: var(--ion-color-menu-contrast) !important;
}
.option[data-v-49b08412]::before,
.option[data-v-49b08412]:after {
  display: none;
}
.no-tail[data-v-49b08412]::before {
  display: none;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
