import { IBeacon } from "@awesome-cordova-plugins/ibeacon";
import { collection, onSnapshot } from "firebase/firestore";
import { computed, reactive, ref, watch } from "vue";
import { db, rcValues } from "@/composables/firebase";
import { defineStore, storeToRefs } from "pinia";
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { isPlatform } from "@ionic/vue";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic";

export const useBeaconStore = defineStore("beaconStore", () => {
  const userStore = useUserStore();
  const organisationStore = useOrganisationStore();
  const userData = userStore.data;

  const isRanging = ref(false);
  const autoSwitch = ref(false);
  let outOfRangeTimer;

  let unsubscribe;
  let delegate;
  let regions = [];

  const { organisations } = storeToRefs(organisationStore);

  const data = reactive({
    manufacturers: [
      { name: "Kontakt", uuid: "f7826da6-4fa2-4e98-8024-bc5b71e0893e" },
      { name: "Ubiqisense", uuid: "df10b3a8-ab58-478f-a210-f031c1a070dc" },
    ],
  });

  const flush = () => {
    if (unsubscribe) unsubscribe();
  };

  const nearestValidBeacon = computed(() => data.nearestValidBeacon);
  const nearestSpace = computed(() => data.nearestSpace);
  // const uuid = computed(() => data.uuid);
  const manufacturers = computed(() => data.manufacturers);

  let beaconSamples = [];

  const beacons = computed(() => data.beacons);
  const unusedBeacons = computed(() => data.unusedBeacons);
  const validBeacons = computed(() => data.validBeacons);

  const getBeacons = () => {
    data.beacons = [];
    if (userStore.user?.organisations) {
      for (const organisationID of userStore.user.organisations) {
        const col = collection(db, `organisations/${organisationID}/beacons`);
        unsubscribe = onSnapshot(col, (results) => {
          //Add all new items in collection
          for (const doc of results.docs) {
            data.beacons.push({ id: doc.id, ...doc.data() });
          }

          //Remove any items that have been removed from the collection
          const existingIDs = results.docs.map((item) => item.id);
          data.beacons = data.beacons.filter((b) => !(b.organisationID == organisationID && !existingIDs.includes(b.id)));
        });
      }
    }
  };

  watch(() => userStore.user, getBeacons);

  const init = async () => {
    if (!isPlatform("cordova")) return;

    const permissionStatus = await Diagnostic.getLocationAuthorizationStatus();
    if (![Diagnostic.permissionStatus.GRANTED, Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE].includes(permissionStatus)) return;

    //Set region
    try {
      regions = [];
      data.manufacturers.forEach((manufacturer) => {
        const newRegion = IBeacon.BeaconRegion(manufacturer.name, manufacturer.uuid);
        regions.push(newRegion);
      });

      // set up delegate
      delegate = IBeacon.Delegate();
      delegate.didChangeAuthorizationStatus = didChangeAuthorizationStatus;
      delegate.didDetermineStateForRegion = didDetermineStateForRegion;
      delegate.monitoringDidFailForRegionWithError = monitoringDidFailForRegionWithError;
      delegate.didRangeBeaconsInRegion = didRangeBeaconsInRegion;
      delegate.didEnterRegion = didEnterRegion;
      delegate.didExitRegion = didExitRegion;
      delegate.didRangeBeaconsInRegion = didRangeBeaconsInRegion;

      getBeacons();
    } catch (error) {
      console.log("error setting up beacons", error);
    }
  };

  const didChangeAuthorizationStatus = (status) => {
    console.log();
  };

  const didDetermineStateForRegion = (state) => {
    console.log();
  };

  const monitoringDidFailForRegionWithError = (e) => {
    console.log("DEBUG Error monitoring", e);
  };

  const didEnterRegion = (region) => {
    console.log();
  };

  const didExitRegion = (region) => {
    try {
      if (region) IBeacon.stopMonitoringForRegion(region);
    } catch (error) {
      console.log("DEBUG Error exiting region", error);
    }
  };

  const start = async (auto = false) => {
    if (!isPlatform("cordova") || userStore?.data?.profile?.disableBeacons) return;

    const checkForRegion = (resolve) => {
      if (regions.length > 0 && Object.keys(userStore?.data?.profile).length > 0) {
        try {
          isRanging.value = true;
          autoSwitch.value = auto;
          beaconSamples.length = 0;
          regions.forEach((region) => {
            IBeacon.startMonitoringForRegion(region);
            IBeacon.startRangingBeaconsInRegion(region);
          });
        } catch (error) {
          console.log("DEBUG Error starting ranging", error);
        }

        resolve();
      } else {
        setTimeout(checkForRegion.bind(this, resolve), 50);
      }
    };

    return new Promise(checkForRegion);
  };

  const stop = () => {
    data.nearestSpace = null;
    isRanging.value = false;
    if (!isPlatform("cordova") || regions.length == 0) return;
    try {
      regions.forEach((region) => {
        IBeacon.stopRangingBeaconsInRegion(region);
      });
    } catch (error) {
      console.log("Error stopping ranging", error);
    }
  };

  const monitorSpaceForExit = (major, minor) => {
    // try {
    //   const spaceRegion = IBeacon.BeaconRegion("spaceBeacon", data.uuid, major, minor);
    //   if (!isPlatform("cordova") || !spaceRegion) return;
    //   IBeacon.startMonitoringForRegion(spaceRegion);
    // } catch (error) {
    //   console.log("DEBUG Error monitoring for exit", error);
    // }
  };

  const didRangeBeaconsInRegion = async (result) => {
    if (beaconSamples.length > rcValues.beaconRangeCount - 1) beaconSamples.shift();
    beaconSamples.push(result.beacons);

    const sortedBeacons = beaconSamples
      .reduce((acc, sample) => {
        for (const beacon of sample) {
          beacon.uuid = result.region?.uuid;
          let foundBeacon = acc.find((b) => b.major == beacon.major && b.minor == beacon.minor);
          if (!foundBeacon) {
            const used = data.beacons.find((b) => b.major == beacon.major && b.minor == beacon.minor);
            acc.push({ major: beacon.major, minor: beacon.minor, readings: [], used });
            foundBeacon = acc[acc.length - 1];
          }
          if (beacon.rssi < 0) foundBeacon.readings.push(beacon.rssi);
          foundBeacon.accuracy = Math.round((100 * foundBeacon.readings.length) / rcValues.beaconRangeCount);
          foundBeacon.average =
            foundBeacon.readings.length == 0
              ? -100
              : Math.round(foundBeacon.readings.reduce((a, i) => a + i) / foundBeacon.readings.length);
        }
        return acc;
      }, [])
      .sort((a, b) => b.average - a.average)
      .filter((b) => b.accuracy > rcValues.beaconRequiredAccuracy);

    data.validBeacons = sortedBeacons.filter((b) => b.used);
    data.unusedBeacons = sortedBeacons.filter((b) => !b.used);
    data.nearestValidBeacon = data.validBeacons?.length > 0 && data.validBeacons[0];
    // data.validBeacons.forEach((b) => {
    //   console.log("+++>", b.major, b.minor, "#".repeat(b.readings?.length));
    // });
    data.sortedBeacons = sortedBeacons;

    const organisationID = data.nearestValidBeacon?.used?.organisationID;
    const locationID = data.nearestValidBeacon?.used?.locationID;
    const floorID = data.nearestValidBeacon?.used?.floorID;
    const spaceID = data.nearestValidBeacon?.used?.spaceID;

    if (autoSwitch.value && spaceID) {
      //Mechanism to clear nearest beacon if no beacon detected for 15 seconds.
      if (outOfRangeTimer) {
        clearTimeout(outOfRangeTimer);
      }
      outOfRangeTimer = setTimeout(() => {
        data.nearestSpace = null;
      }, 15000);

      await organisationStore.initialise(organisationID, locationID, floorID, spaceID);
      data.nearestSpace = organisations.value?.[organisationID]?.locations?.[locationID]?.floors?.[floorID]?.spaces?.[spaceID];
    }
  };

  init();

  return {
    start,
    stop,
    monitorSpaceForExit,
    flush,
    // uuid,
    manufacturers,
    isRanging,
    nearestValidBeacon,
    nearestSpace,
    beacons,
    unusedBeacons,
    validBeacons,
  };
});
