// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("img/transparent.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/theme/backgrounds/lines-no-logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
ion-content[data-v-91902b2c] {
  background: var(--ion-background-color);
}
.masked-container[data-v-91902b2c] {
  width: 100%;
  height: 100%;
  --background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.scannerOff[data-v-91902b2c]:before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: var(--theme-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  filter: grayscale(100%) brightness(50%);
}
.scannerButton[data-v-91902b2c] {
  position: absolute;
  top: 70%;
  width: 100%;
  padding: 0% 10%;
  margin: 0px;
}
.scannerOff[data-v-91902b2c],
.scannerOn[data-v-91902b2c],
.scannerButton[data-v-91902b2c] {
  opacity: 0;
  transition: opacity 0.5s;
}
.scannerOff.visible[data-v-91902b2c],
.scannerOn.visible[data-v-91902b2c],
.scannerButton.visible[data-v-91902b2c] {
  opacity: 1;
  transition: opacity 0.5s;
}
.scannerLabel[data-v-91902b2c] {
  position: absolute;
  top: 25%;
  transform: translateY(-50%);
  left: 20%;
  width: 60%;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 5px;
  pointer-events: all !important;
}
.frame[data-v-91902b2c] {
  position: absolute;
  height: 20px;
}
.frame.top[data-v-91902b2c] {
  top: 10%;
}
.frame.left[data-v-91902b2c] {
  left: 20%;
}
.frame.right[data-v-91902b2c] {
  right: 20%;
}
.frame.bottom[data-v-91902b2c] {
  bottom: 60%;
}
#shortcodeContainer[data-v-91902b2c] {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 45%;
  left: 10%;
  width: 80%;
}
#shortcode[data-v-91902b2c] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--ion-color-dark);
  font-size: 2em;
  padding: 0px 20px;
}
#shortcodeHint[data-v-91902b2c] {
  position: absolute;
  width: calc(100% - 40px);
  text-align: center;
  font-size: 1rem;
}
#noCode[data-v-91902b2c] {
  position: relative;
}
.visible[data-v-91902b2c] {
  opacity: 0.6;
  transition: opacity 0.3s ease-in;
}
.hidden[data-v-91902b2c] {
  opacity: 0;
  transition: opacity 0.1s ease-in;
}
#shortcodeButton[data-v-91902b2c] {
  font-size: 1rem;
}
#register[data-v-91902b2c] {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  bottom: 0px;
  width: 100%;
  height: 35%;
  padding: 40px;
  background-color: var(--ion-color-light);
  opacity: 0;
  animation: 0.3s fadeIn-91902b2c;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  border-radius: 50px 50px 0px 0px;
}
#register ion-button[data-v-91902b2c] {
  margin-bottom: var(safe-area-inset-bottom);
  font-size: 1rem;
}
#welcome[data-v-91902b2c] {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 25%;
  opacity: 0;
  animation: 0.3s fadeIn-91902b2c;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
.welcomeTitle[data-v-91902b2c] {
  color: var(--ion-color-dark);
  font-family: Title;
  font-size: 1.8em;
}
.welcomeDescription[data-v-91902b2c] {
  text-align: center;
  color: var(--ion-color-dark);
  font-size: 0.8em;
}
.welcomeButton[data-v-91902b2c] {
  width: 80%;
  height: 2.4em;
  font-size: 1.4em;
}
.actionList[data-v-91902b2c] {
  width: 100%;
  padding: 20px;
}
/* 
.action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-family: Title;
  border-bottom: 1px solid rgba(var(--ion-color-dark-rgb), 0.5);
  padding: 20px 10px;
  font-size: 0.8em;
} */
.action[data-v-91902b2c]:first-child {
  border-top: 1px solid rgba(var(--ion-color-dark-rgb), 0.5);
}
@keyframes fadeIn-91902b2c {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
.shortcode[data-v-91902b2c] {
  color: var(--ion-color-dark-contrast);
  font-family: Bold;
  --padding-start: 10px;
}
.shortcodeContainer[data-v-91902b2c] {
  background: var(--ion-color-dark);
  border: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.5);
  border-radius: 5px;
}
.showLines[data-v-91902b2c] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-size: cover;
}
ion-button.bright[data-v-91902b2c] {
  --background: var(--ion-color-menu);
  color: var(--ion-color-menu-contrast);
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
