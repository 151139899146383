<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd" slot="fixed" v-if="organisation?.loaded">
        <view-header @back="back" :title="`${checklistResponse?.name}${space?.name ? ` - ${space?.name}` : ''}`" class="fullwidth" />
        <input type="file" @change="addPhoto" ref="photoSelector" multiple hidden />
        <div class="scroll p fullwidth bgd">
          <Message v-if="checklistResponse?.isSubmitted">This checklist has already been submitted</Message>
          <Message v-if="!checklistResponse?.isSubmitted && checklistResponse?.date"
            >Due on {{ formatFirestoreDate(checklistResponse?.date) }}</Message
          >
          <Panel
            v-for="task in checklistResponse?.responses"
            :key="task.id"
            :toggleable="task.allowedNotes || task.allowedPhoto"
            class="panel"
            collapsed
          >
            <template #header>
              <div class="flex align-items-center">
                <div>
                  <Checkbox
                    v-model="task.pass"
                    :inputId="task.key"
                    :disabled="checklistResponse?.isSubmitted"
                    slot="start"
                    :inputClass="{ pass: task.pass, fail: task.pass != null && task.pass == false, empty: task.pass == null }"
                  /><label :for="task.id" class="taskLabel">{{ task.description }}</label>
                </div>
              </div>
            </template>

            <Textarea
              v-model="task.notes"
              rows="5"
              :disabled="space.checklistCompletedToday"
              autoResize
              class="notes"
              placeholder="Enter notes"
            />
            <div class="taskPhotos">
              <div v-for="photo in task.photos" :key="photo.url" class="taskPhoto">
                <img :src="photo.url" />
              </div>
              <div class="taskPhoto" v-if="!checklistResponse?.isSubmitted">
                <Button icon="pi pi-plus" @click="triggerAddPhoto(task)" label="Photo" />
              </div>
            </div>
          </Panel>

          <Button
            label="Submit"
            severity="success"
            class="fullwidth mt2"
            @click="submitChecklistResponse"
            :disabled="!formComplete || checklistResponse?.isSubmitted"
          />
        </div>
        <div class="grow"></div>
      </div>
      <wait v-else>Loading</wait>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";
import { computed, watch } from "vue";
import { documentText, image } from "ionicons/icons";
import { ref } from "vue";
import { toast, filterObject } from "@/composables/utilities";
import { formatFirestoreDate } from "@/composables/firebase";

const organisationStore = useOrganisationStore();
const userStore = useUserStore();
const { organisation, space } = storeToRefs(organisationStore);
const { firebaseUser, user, profile } = storeToRefs(userStore);
const route = useRoute();
const router = useRouter();
const spaceID = route.params.spaceID;
const floorID = route.params.floorID;
const locationID = route.params.locationID;
const organisationID = route.params.organisationID;
const checklistID = route.params.checklistID;
const photoSelector = ref();
let photoTask;

onIonViewWillEnter(async () => {
  await organisationStore.initialise(organisationID, locationID, floorID, spaceID);
});

const checklistResponse = computed(() => space.value?.checklists?.[checklistID]);

const addPhoto = async () => {
  const files = Array.from(event.target.files);
  files.forEach(async (file) => {
    await organisationStore.addChecklistTaskPhoto(checklistResponse, photoTask, file);
    photoSelector.value.value = "";
  });
};

const triggerAddPhoto = (task) => {
  photoTask = task;
  photoSelector.value.click();
};

const formComplete = computed(() => {
  const completedResponses = filterObject(checklistResponse.value?.responses, (r) => r.pass == null);
  if (!completedResponses) return false;
  return Object.keys(completedResponses).length == 0;
});

const submitChecklistResponse = async () => {
  toast("Checklist submitted");
  checklistResponse.value.isSubmitted = true;
  checklistResponse.value.submissionDate = new Date();
  const failedResponses = filterObject(checklistResponse.value.responses, (r) => !r.pass);
  checklistResponse.value.didFail = Object.keys(failedResponses).length > 0;
  await organisationStore.saveRecord(
    `organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces/${spaceID}/checklistResponses`,
    checklistResponse.value.id,
    checklistResponse.value
  );
  back();
};

const back = () => {
  router.back();
};
</script>

<style lang="scss" scoped>
.panel {
  margin-bottom: 10px;
  Textarea {
    width: 100%;
    margin-bottom: 10px;
  }
}

.taskLabel {
  margin-left: 10px;
}

.taskPhotos {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.taskPhoto {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  max-width: 50%;
  aspect-ratio: 1;
  border: 2px solid var(--ion-color-light);
  border-radius: 5px;
}

:deep(.pass ~ .p-checkbox-box) {
  background: green;
}

:deep(.fail ~ .p-checkbox-box) {
  background: red;
}

:deep(.p-checkbox-icon) {
  color: white;
}
</style>
