<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="location">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="location.name" :subtitle="t('edit_details')" edit="true" />

        <div class="fullwidth scroll">
          <ion-item class="fullwidth">
            <ion-input
              :label="t('typeform_id')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('typeform_id')"
              v-model="location.defaultTypeformID"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
          </ion-item>

          <ion-item class="fullwidth">
            <ion-input
              :label="t('capacity')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('capacity')"
              v-model="location.defaultCapacity"
              type="number"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
          </ion-item>

          <div class="fullwidth section" lines="none">
            <ion-label position="stacked">Room type</ion-label>
            <ion-radio-group v-model="location.defaultSpaceTypeID" @ionChange="markDirty()" class="fullwidth">
              <ion-item v-for="type in organisation?.spaceTypes" :key="type.id">
                <ion-radio color="secondary" mode="ios" :value="type.id">{{ type.name }}</ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>

          <div class="fullwidth section" lines="none">
            <ion-label position="stacked">Decision Tree</ion-label>
            <ion-radio-group v-model="location.defaultDecisionTreeID" @ionChange="markDirty()" class="fullwidth">
              <ion-item v-for="tree in organisation?.decisionTrees" :key="tree.id">
                <ion-radio color="secondary" mode="ios" :value="tree.id">{{ tree.title }}</ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>

          <div class="fullwidth section" lines="none">
            <ion-label position="stacked">Checklist</ion-label>
            <ion-radio-group v-model="location.defaultChecklistID" @ionChange="markDirty()" class="fullwidth">
              <ion-item v-for="checklist in organisation?.checklists" :key="checklist.id">
                <ion-radio color="secondary" mode="ios" :value="checklist.id">{{ checklist.name }}</ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>
        </div>

        <div class="grow"></div>
        <!-- <nav-footer :label="location.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const route = useRoute();
const router = useRouter();

const locationID = route.params.locationID;
const organisationID = route.params.organisationID;

const organisationStore = useOrganisationStore();
const { location, organisation } = storeToRefs(organisationStore);
const { t } = useI18n();

const dirty = ref(false);

let backup;

onIonViewWillEnter(() => {
  organisationStore.initialise(organisationID, locationID);
});

const save = async () => {
  organisationStore.saveRecord(`organisations/${organisationID}/locations`, locationID, location.value, ["floors"]);

  const alert = await alertController.create({
    cssClass: "alert",
    header: "Update existing records",
    message: "Do you want to update existing spaces in this building?",
    buttons: [
      {
        text: "Yes, overwrite all existing values",
        handler: () => {
          organisationStore.updateSpaces(location.value, true);
          router.back();
        },
      },
      {
        text: "Update spaces without existing values",
        handler: () => {
          organisationStore.updateSpaces(location.value, false);
          router.back();
        },
      },
      {
        text: "No, just use these defaults for future spaces",
        role: "destructive",
        handler: () => {
          router.back();
        },
      },
    ],
  });
  await alert.present();
};

const markDirty = () => {
  dirty.value = true;
};

const createBackup = () => {
  backup = location.value || {};
};

watch(location, createBackup);
createBackup();

const back = async () => {
  if (dirty.value) {
    const alert = await alertController.create({
      cssClass: "alert",
      header: "Are you sure?",
      message: "You have unsaved changes.",
      buttons: [
        {
          text: "Save & Close",
          handler: () => {
            save();
          },
        },
        { text: "Cancel" },
        {
          text: "Discard Changes",
          role: "destructive",
          handler: () => {
            location.value = backup;
            router.back();
          },
        },
      ],
    });
    await alert.present();
  } else {
    router.back();
  }
};

const go = (name) => {
  router.push({ name: name, params: { locationID } });
};
</script>

<style lang="scss" scoped>
.section {
  margin-top: 40px;
  padding: 0px 20px;

  ion-label {
    font-family: Title;
  }
}
</style>
