// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
ion-footer[data-v-f683b6fe] {
  display: flex;
  justify-content: center;
  background: var(--ion-color-dark);
  padding: 20px;
}
#articleContainer[data-v-f683b6fe] {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow: auto;
}
ion-item[data-v-f683b6fe] {
  width: 100%;
}
ion-item > div[data-v-f683b6fe] {
  width: 100%;
}
ion-accordion-group[data-v-f683b6fe] {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
}
ion-accordion > ion-list > ion-item[data-v-f683b6fe] {
  padding-left: 30px;
}
ion-accordion[data-v-f683b6fe] {
  margin-left: 0px;
}
ion-accordion ion-item[slot="header"][data-v-f683b6fe] {
  --color: var(--ion-color-secondary);
}
ion-accordion.accordion-expanding[data-v-f683b6fe],
ion-accordion.accordion-expanded[data-v-f683b6fe] {
  width: calc(100% - 16px);
  margin-left: 16px;
}
ion-accordion ion-icon[data-v-f683b6fe] {
  color: var(--ion-color-primary);
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
