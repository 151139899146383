<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="location">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="location.name" :subtitle="t('export_files')" edit="true" />

        <div class="fullwidth scroll p2">
          <ion-item>
            <div class="cl titleFont">{{ t("select_files_to_export") }}:</div>
          </ion-item>
          <ion-item lines="none"
            ><ion-checkbox color="primary" v-model="exportQR" label-placement="end">{{ t("export_qr_codes") }}</ion-checkbox></ion-item
          >
        </div>
        <div class="fullwidth p2">
          <ion-button expand="block" class="bright" @click="exportFiles">{{ t("start_export") }}</ion-button>
        </div>

        <div class="grow"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { onIonViewWillEnter } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { getQR } from "@/composables/qr";
import { toast } from "@/composables/utilities";
import { useI18n } from "vue-i18n";

const route = useRoute();
const router = useRouter();

const locationID = route.params.locationID;
const organisationID = route.params.organisationID;

const exportQR = ref(true);
const wait = ref(false);

const organisationStore = useOrganisationStore();
const { location } = storeToRefs(organisationStore);
const { t } = useI18n();

onIonViewWillEnter(() => {
  organisationStore.initialise(organisationID, locationID);
});

const exportFiles = async () => {
  wait.value = true;
  let filesAdded = false;
  const zip = new JSZip();

  if (exportQR.value) {
    for (const floorID in location.value.floors) {
      const floor = location.value.floors[floorID];
      for (const spaceID in floor.spaces) {
        const space = floor.spaces[spaceID];
        const qr = getQR(`${process.env.VUE_APP_DOMAIN}/qr/${space.shortcode}`);
        zip.folder(location.value.name).folder("QR Codes").folder(floor.name).file(`${space.name}.svg`, qr.download, { binary: false });
        filesAdded = true;
      }
    }
  }

  if (!filesAdded) {
    toast("No files found.");
    return;
  }
  const content = await zip.generateAsync({ type: "blob" });

  const zipName = `${location.value?.name} documents` || "Alpha Victor";
  saveAs(content, zipName);
  toast("Please check your downloads.");
  wait.value = false;
};

const back = async () => {
  router.back();
};
</script>
