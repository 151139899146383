<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical fullheight" slot="fixed">
        <view-header @back="back" :subtitle="userEmail" title="Delete Account" class="fullwidth" />

        <div style="max-width: 600px">
          <div slot="content" class="flex centered p2">
            <div>
              Are you sure you want to delete your account? You will be logged out immediately and all of your saved information will be
              deleted.
            </div>
          </div>
          <div class="flex p2">
            <ion-button expand="block" class="equal" color="success" @click="back">Oops, no!</ion-button>
            <ion-button expand="block" class="equal" color="danger" @click="confirmDelete">Delete account</ion-button>
          </div>
        </div>
        <div class="grow"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { toast } from "@/composables/utilities";
import { storeToRefs } from "pinia";
import { computed } from "@vue/runtime-core";

export default {
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const userEmail = computed(() => user.value.id);

    const back = () => {
      router.back();
    };

    const confirmDelete = async () => {
      try {
        await userStore.deleteAccount();
        userStore.logout();
        toast("Account deleted");
        router.push({ name: "Home" });
      } catch (error) {
        console.log(error);
      }
    };
    return { back, confirmDelete, userEmail };
  },
};
</script>

<style scoped></style>
