<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div slot="fixed" class="flex vertical fullwidth safe-bottom nogap bgd">
        <view-header @back="back" :title="description" />

        <iframe :src="ifURL" frameborder="0" class="fullheight fullwidth"></iframe>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import {
  share as shareIcon,
  closeCircle,
  arrowForwardCircle,
  arrowBackCircle,
  refreshCircle,
  addCircle,
  removeCircle,
  expand,
} from "ionicons/icons";
import { getDownloadURL, ref as fbRef } from "firebase/storage";
import { storage } from "@/composables/firebase";
import { storeToRefs } from "pinia";
import { useOrganisationStore } from "@/stores/organisation";
import { onIonViewDidEnter } from "@ionic/vue";
import { ref } from "vue";

export default {
  params: ["description"],
  setup() {
    const organisationStore = useOrganisationStore();
    const route = useRoute();
    const router = useRouter();
    const { space } = storeToRefs(organisationStore);
    const path = route.params.path;
    const description = route.params.description;
    const page = ref(1);
    const scale = ref(1);
    const rotation = ref(0);
    const pages = ref(1);
    const loaded = ref(false);
    const fit = ref(true);
    const pdfViewer = ref();
    let url;
    const ifURL = ref();

    const setupPDF = async () => {
      url = await getDownloadURL(fbRef(storage, path));
      ifURL.value = "lib/web/viewer.html?file=" + encodeURIComponent(url);
      loaded.value = true;
    };

    onIonViewDidEnter(() => {
      setTimeout(setupPDF, 2000);
    });

    const canShare = !!navigator.share;

    const back = () => {
      router.back();
    };

    const movePage = (move) => {
      page.value += move;
    };

    const share = async () => {
      await navigator.share({ title: "Title", url: url.value });
    };

    const rotate = () => {
      rotation.value += 90;
      if (rotation.value > 359) rotation.value = 0;
    };

    const zoomIn = () => {
      fit.value = false;
      scale.value = Math.min(3, scale.value + 0.25);
    };

    const zoomOut = () => {
      fit.value = false;
      scale.value = Math.max(0.25, scale.value - 0.25);
    };

    const fill = () => {
      fit.value = true;
      pdfViewer.value?.calculateScale();
    };

    return {
      space,
      description,
      path,
      back,
      share,
      movePage,
      rotate,
      zoomIn,
      zoomOut,
      rotation,
      scale,
      page,
      pages,
      canShare,
      shareIcon,
      closeCircle,
      arrowForwardCircle,
      arrowBackCircle,
      refreshCircle,
      addCircle,
      removeCircle,
      loaded,
      expand,
      fit,
      fill,
      pdfViewer,
      ifURL,
    };
  },
};
</script>
<style scoped>
.toolbarIcon {
  width: 32px;
  height: 32px;
  color: var(--ion-color-light);
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
