<template>
  <div class="flex" ref="container">
    <canvas ref="canvas" class="fullwidth" style="height: 40px"></canvas>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "@vue/runtime-core";
import { onIonViewWillEnter } from "@ionic/vue";
export default {
  props: ["text", "maxSize"],
  setup(props) {
    const canvas = ref();
    const container = ref();

    onMounted(() => {
      setTimeout(resize, 50);
      window.addEventListener("resize", resize);
    });

    onIonViewWillEnter(() => resize());

    watch(
      () => props.text,
      () => {
        if (props.text) resize();
      }
    );

    const resize = () => {
      if (!props.text) return;
      const text = props.text;
      const w = container.value?.offsetWidth;
      const h = container.value?.offsetHeight;
      if (!(w && h)) return;
      canvas.value.height = h;
      canvas.value.width = w;
      const ctx = canvas.value?.getContext("2d");
      if (!ctx) return;
      ctx.font = `${h}px Title`;
      const textWidth = ctx.measureText(text).width;
      const wRatio = textWidth / w;
      const fontSize = Math.min(props.maxSize, h / wRatio);
      ctx.font = `${fontSize}px Title`;
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";

      ctx.fillText(text, w / 2, h / 2 + 5);
    };

    return { canvas, container };
  },
};
</script>

<style></style>
