<template>
  <ion-page>
    <av-header />
    <ion-content class="p">
      <div class="flex vertical bgd" slot="fixed">
        <view-header @back="back" :title="organisation?.name" :subtitle="t('edit_locations')" edit="true" />
        <div class="ph2 fullwidth">
          <div class="action openStart" v-for="location in organisation?.locations" :key="location.id" @click="editLocation(location.id)">
            <div>
              <div class="titleFont">{{ location?.name }}</div>
              <div class="boldFont fs" style="margin-top: 3px">{{ location?.address?.postcode }}</div>
            </div>
            <div>&gt;</div>
          </div>
        </div>

        <div class="grow"></div>
        <div class="fullwidth">
          <ion-button @click="addLocation" expand="block" class="bright m2">{{ t("add_location") }}</ion-button>
        </div>
        <!-- <nav-footer :label="organisation.name" @back="cancel" /> -->
      </div>
    </ion-content></ion-page
  >
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const organisationStore = useOrganisationStore();
    const organisationData = organisationStore.data;
    const organisationID = route.params.organisationID;
    const { organisation } = storeToRefs(organisationStore);
    const { t } = useI18n();

    onMounted(() => {
      organisationStore.initialise(organisationID);
    });

    const addLocation = async () => {
      const newLocationID = await organisationStore.addLocation();
      editLocation(newLocationID);
    };

    const editLocation = (locationID) => {
      router.push({ name: "EditLocation", params: { organisationID: organisation.value.id, locationID } });
    };

    const back = () => {
      router.back();
    };

    return { organisationData, organisation, addLocation, editLocation, back, t };
  },
};
</script>

<style scoped>
.card {
  overflow: hidden;
  aspect-ratio: 1;
}
.card .background {
  position: absolute;
  left: 0px;
  top: 0px;
  object-fit: cover;
  filter: grayscale(1) opacity(0.3);
}

.card .title {
  color: var(--ion-color-secondary);
  opacity: 0.7;
}
</style>
