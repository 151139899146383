<template>
  <ion-page>
    <av-header />
    <ion-content v-if="space">
      <input type="file" @change="addPanorama()" ref="panoramaSelector" hidden />
      <div class="flex fullheight vertical bgd">
        <view-header @back="back" :title="space.name" :subtitle="t('edit_panorama')" />
        <div ref="pano" class="fullwidth fullheight"></div>
      </div>

      <div class="flex centered p2" style="position: absolute; bottom: 0px; width: 100%">
        <ion-button @click="triggerAddPanorama" expand="block" class="bright grow" v-if="space.pano?.path">{{
          t("replace_panorama")
        }}</ion-button>
        <ion-button @click="triggerAddPanorama" color="primary" expand="block" class="grow" v-else>{{ t("add_panorama") }}</ion-button>
        <ion-button @click="deletePanorama" color="danger" expand="block" class="grow" :disabled="!space?.pano?.path">{{
          t("delete_panorama")
        }}</ion-button>
        <ion-button @click="setInitialView" color="primary" expand="block" class="grow" :disabled="!space.pano?.path">Set start</ion-button>
      </div>
    </ion-content>

    <ion-modal :is-open="hotspotModalVisible" @didDismiss="closeModal" :breakpoints="[1, 0]" :initial-breakpoint="1">
      <ion-content>
        <ion-header class="nogrow bgd">
          <ion-toolbar>
            <ion-title><ion-input color="secondary" v-model="selectedHotspot.text" @ionInput="isDirty = true"></ion-input></ion-title>
            <ion-buttons slot="end">
              <ion-button color="secondary" @click="closeModal(selectedHotspot)">Done</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <div class="flex vertical bgd">
          <ion-segment color="secondary" v-model="selectedHotspot.hotspotType">
            <ion-segment-button value="device">{{ t("device") }}</ion-segment-button
            ><ion-segment-button value="annotation">{{ t("annotation") }}</ion-segment-button>
          </ion-segment>

          <div class="fullwidth scroll">
            <div v-if="selectedHotspot.hotspotType == 'device'" class="fullwidth">
              <ion-list style="width: 100%">
                <ion-radio-group v-model="selectedHotspot.deviceID" @ionChange="deviceChanged">
                  <ion-item v-for="device in space.devices" :key="device.id" lines="inset" class="pv" :disabled="isDeviceDisabled(device)">
                    <ion-radio :value="device.id" color="secondary"
                      >{{ manufacturers[models[device.modelID].manufacturerID]?.name }} {{ models[device.modelID]?.name }}</ion-radio
                    >
                  </ion-item>
                </ion-radio-group>
              </ion-list>
            </div>
          </div>

          <div class="grow"></div>

          <ion-item lines="none" class="nogrow">
            <ion-label>Hotspot visible</ion-label>
            <ion-toggle slot="start" v-model="selectedHotspot.visible" labelPlacement="start">Hotspot visible</ion-toggle>
          </ion-item>

          <ion-button @click="deleteHotspot(selectedHotspot)" color="danger" class="fullwidth nogrow mt2" expand="block">{{
            t("delete_hotspot")
          }}</ion-button>
        </div>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, onIonViewDidEnter } from "@ionic/vue";
import { chevronForward, locate } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storage } from "@/composables/firebase";
import { getDownloadURL, ref as fbRef } from "firebase/storage";
import "pannellum";
import "pannellum/build/pannellum.css";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;
    const organisationStore = useOrganisationStore();
    const { organisation, location, floor, space, models, manufacturers, assetTypes } = storeToRefs(organisationStore);
    const dirty = ref(false);
    let backup;
    const { t } = useI18n();
    const pano = ref();
    const panoramaSelector = ref();
    const hotspotModalVisible = ref(false);
    const selectedHotspot = ref();
    const rootElement = ref();
    let panoramaRendered = false;
    let click;

    let viewer;

    onMounted(() => {
      rootElement.value = document.getElementById("main-content");
    });

    watch(
      () => space.value?.pano,
      () => {
        if (space.value?.pano) setupPanorama();
      }
    );

    const setupPanorama = async () => {
      if (!space.value?.pano?.path || panoramaRendered) return;
      panoramaRendered = true;
      space.value.pano.url = await getDownloadURL(fbRef(storage, space.value.pano.path));
      const options = {
        type: "equirectangular",
        panorama: space.value.pano.url,
        autoLoad: true,
        autoRotate: -10,
        autoRotateStopDelay: 1,
        //autoRotateInactivityDelay: 5000,
        orientationOnByDefault: true,
        draggable: true,
        mouseZoom: true,
        doubleClickZoom: true,
        compass: false,
        // hotSpotDebug: true,
        // preview: this.preview,
        hfov: 90,
        yaw: 0,
        pitch: 0,
        minHfov: 30,
        maxHfov: 120,
      };
      if (!viewer) viewer = window.pannellum.viewer(pano.value, options);
      viewer.on("mousedown", startClick);
      viewer.on("mouseup", endClick);
      viewer.on("load", drawHotspots);
      if (space.value.pano.initialView?.pitch) viewer.setPitch(space.value.pano.initialView.pitch);
      if (space.value.pano.initialView?.yaw) viewer.setYaw(space.value.pano.initialView?.yaw);
      if (space.value.pano.initialView?.hfov) viewer.setHfov(space.value.pano.initialView?.hfov);
    };

    const drawHotspots = () => {
      space.value?.pano?.hotspots?.forEach((hotspot) => {
        drawHotspot(hotspot);
      });
    };

    const startClick = (e) => {
      click = {
        x: e.clientX,
        y: e.clientY,
      };
    };

    const endClick = (e) => {
      const movement = {
        x: Math.abs(click.x - e.clientX),
        y: Math.abs(click.y - e.clientY),
      };
      if (movement.x == 0 && movement.y == 0) addHotspot(e);
      click = null;
    };

    const drawHotspot = (hotspot) => {
      const newHotspot = {
        ...hotspot,
        clickHandlerFunc: hotspotClicked,
        clickHandlerArgs: { id: hotspot.id },
      };
      viewer.addHotSpot(newHotspot);
    };

    const addHotspot = (event) => {
      if (event.movementX > 0 || event.movementY > 0) return;
      const location = viewer.mouseEventToCoords(event);
      const newHotspot = organisationStore.addPanoramaHotspot(location);
      drawHotspot(newHotspot);
      markDirty();
    };

    const hotspotClicked = (event, args) => {
      selectedHotspot.value = space.value.pano.hotspots.find((h) => h.id == args.id);

      viewer.setPitch(selectedHotspot.value.pitch);
      viewer.setYaw(selectedHotspot.value.yaw);
      if (selectedHotspot.value) hotspotModalVisible.value = true;
    };

    const isDeviceDisabled = (device) => {
      return space.value.pano?.hotspots.map((h) => h.deviceID).includes(device.id);
    };

    const closeModal = () => {
      if (selectedHotspot.value.hotspotType != "device") selectedHotspot.value.deviceID = null;
      viewer.removeHotSpot(selectedHotspot.value.id);
      if (space.value.pano.hotspots.find((h) => h.id == selectedHotspot.value.id)) drawHotspot(selectedHotspot.value);
      hotspotModalVisible.value = false;
    };

    const deleteHotspot = () => {
      if (!space.value?.pano) return;
      const hotspotID = selectedHotspot.value.id;
      space.value.pano.hotspots = space.value.pano?.hotspots.filter((i) => i.id != hotspotID);
      markDirty();
      hotspotModalVisible.value = false;
    };

    watch(pano, () => {
      //panorama element ref ready
      if (pano.value) setupPanorama();
    });

    const deviceChanged = () => {
      const device = space.value.devices.find((d) => d.id == selectedHotspot.value.deviceID);
      const model = models.value?.[device?.modelID];
      const manufacturer = manufacturers.value?.[model?.manufacturerID];
      const assetType = assetTypes.value?.[model?.assetTypeID];
      if (manufacturer && model) selectedHotspot.value.modelDetails = `${manufacturer.name} ${model.name}`;
      selectedHotspot.value.text = assetType?.name;
      markDirty();
    };

    const addPanorama = async () => {
      const files = Array.from(event.target.files);
      if (files.length == 0) return;
      panoramaSelector.value.value = "";
      await organisationStore.addSpacePanorama(files[0]);
      setupPanorama();
      save();
    };

    const deletePanorama = async () => {
      await organisationStore.deleteSpacePanorama();
      panoramaRendered = false;
    };

    const setInitialView = (hotspot) => {
      space.value.pano.initialView = {
        pitch: viewer.getPitch(),
        yaw: viewer.getYaw(),
        hfov: viewer.getHfov(),
      };
      markDirty();
    };

    onIonViewDidEnter(async () => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
      createBackup();
      setupPanorama();
    });

    const save = async () => {
      organisationStore.saveRecord(
        `organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces`,
        space.value.id,
        space.value
      );
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const createBackup = () => {
      const hotspots = space.value?.pano?.hotspots;
      backup = hotspots ? [...hotspots] : [];
    };

    watch(() => space, createBackup);
    createBackup();

    const triggerAddPanorama = () => {
      panoramaSelector.value.click();
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
                router.back();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                space.value.pano.hotspots = backup;
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const go = (name) => {
      router.push({ name: name, params: { locationID } });
    };

    return {
      // variables
      organisation,
      location,
      floor,
      space,
      panoramaSelector,
      hotspotModalVisible,
      dirty,
      selectedHotspot,
      rootElement,
      models,
      manufacturers,
      assetTypes,
      pano,
      //methods
      go,
      markDirty,
      back,
      triggerAddPanorama,
      addPanorama,
      addHotspot,
      deletePanorama,
      setInitialView,
      closeModal,
      isDeviceDisabled,
      deleteHotspot,
      deviceChanged,
      // hotspotClicked,
      // setInitialView,
      t,
      // icons
      chevronForward,
      locate,
    };
  },
};
</script>

<style scoped>
#modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#modalContainer ion-item {
  width: 100%;
}
</style>
