<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="location">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :subtitle="location.name" :title="t('photos')" edit="true" />

        <div class="fullwidth scroll">
          <input type="file" @change="addPhoto" ref="photoSelector" multiple hidden />

          <ion-item lines="none" class="fullwidth"
            ><ion-toggle color="secondary" v-model="allowReorder">{{ t("reorder_photos") }}</ion-toggle>
          </ion-item>

          <ion-list class="fullwidth">
            <ion-reorder-group :disabled="!allowReorder" @ionItemReorder="reorderPhotos($event)">
              <ion-item v-for="photo in location.photos" :key="photo.id" lines="none">
                <ion-thumbnail slot="start" class="thumbnail">
                  <img :src="photo.url" :alt="photo.description" v-if="photo.url" />
                </ion-thumbnail>
                <ion-input
                  color="secondary"
                  v-model="photo.description"
                  :placeholder="t('description')"
                  @ionInput="markDirty()"
                ></ion-input>
                <ion-reorder slot="end"></ion-reorder>
                <ion-icon v-if="!allowReorder" :icon="trashOutline" slot="end" color="secondary" @click="deletePhoto(photo)" />
              </ion-item>
            </ion-reorder-group>
          </ion-list>
        </div>

        <div class="grow"></div>
        <div class="fullwidth">
          <ion-button expand="block" class="m2" color="secondary" @click="triggerAddPhoto">{{ t("add_photo") }}</ion-button>
        </div>
        <!-- <nav-footer :label="location.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController } from "@ionic/vue";
import { chevronForward, trashOutline } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;

    const organisationStore = useOrganisationStore();
    const { organisation, location } = storeToRefs(organisationStore);
    const { t } = useI18n();

    const allowReorder = ref(false);
    const dirty = ref(false);
    const photoSelector = ref();

    onMounted(() => {
      organisationStore.initialise(organisationID, locationID);
    });

    const save = async (goBack = true) => {
      organisationStore.saveRecord(`organisations/${organisationID}/locations`, locationID, location.value, ["floors"]);
      if (goBack) router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const deletePhoto = async (photo) => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "This photo and associated information will be deleted immediately.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              organisationStore.deleteLocationPhoto(photo);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ],
      });
      await alert.present();
    };

    const addPhoto = async () => {
      const files = Array.from(event.target.files);
      files.forEach(async (file) => {
        const newPhoto = await organisationStore.addLocationPhoto(file);
        photoSelector.value.value = "";
      });
    };

    const reorderPhotos = (e) => {
      const from = e.detail.from;
      const to = e.detail.to;
      location.value.photos = e.detail.complete(location.value.photos);
      for (const [i, item] of location.value.photos.entries()) {
        item.index = i;
      }
      save(false);
    };

    const go = (name) => {
      router.push({ name: name, params: { locationID } });
    };

    const triggerAddPhoto = () => {
      photoSelector.value.click();
    };

    return {
      // variables
      organisation,
      location,
      allowReorder,
      photoSelector,
      //methods
      go,
      markDirty,
      back,
      triggerAddPhoto,
      addPhoto,
      reorderPhotos,
      deletePhoto,
      t,
      // icons
      chevronForward,
      trashOutline,
    };
  },
};
</script>
