// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.main[data-v-5d8c8a7c] {
  background-color: var(--ion-color-primary);
  font-family: Bold;
  font-size: 0.8rem;
}
.title[data-v-5d8c8a7c] {
  font-family: Title;
  font-size: 2em;
  text-transform: uppercase;
  color: var(--ion-color-primary);
}
.subtitle[data-v-5d8c8a7c] {
  font-family: Title;
  font-size: 1.2em;
  text-transform: uppercase;
  color: var(--ion-color-secondary);
}
.editMode[data-v-5d8c8a7c] {
  color: var(--ion-color-tertiary);
}
.backButton[data-v-5d8c8a7c] {
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px red solid;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
