<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="location">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="location.name" :subtitle="t('floors')" edit="true" />

        <div class="ph pt fullwidth scroll">
          <ion-item lines="none" class="fullwidth"
            ><ion-toggle color="secondary" v-model="allowFloorsReorder">{{ t("reorder_floors") }}</ion-toggle>
          </ion-item>
          <ion-reorder-group :disabled="!allowFloorsReorder" @ionItemReorder="reorderFloors($event)" class="fullwidth">
            <ion-item class="fullwidth" v-for="floor in sortedFloors" :key="floor.id" @click="editFloor(floor.id)">
              <div class="title cl">{{ floor?.name }}</div>
              <ion-icon :icon="chevronForward" v-if="!allowFloorsReorder" color="primary" slot="end" size="small" />
              <ion-reorder v-else slot="end" />
            </ion-item>
          </ion-reorder-group>
        </div>

        <div class="grow"></div>
        <div class="fullwidth">
          <ion-button @click="addFloor" expand="block" class="bright m2">{{ t("add_floor") }}</ion-button>
        </div>
        <!-- <nav-footer :label="location.name" @back="cancel" /> -->
      </div>
    </ion-content></ion-page
  >
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { onIonViewWillEnter } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { sortObjectByProperty } from "@/composables/utilities";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const organisationStore = useOrganisationStore();
    const organisationID = route.params.organisationID;
    const locationID = route.params.locationID;
    const { organisation, location } = storeToRefs(organisationStore);
    const allowFloorsReorder = ref(false);
    const { t } = useI18n();

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID);
    });

    const addFloor = async () => {
      const newFloorID = await organisationStore.addRecord(`organisations/${organisationID}/locations/${locationID}/floors`, {
        name: "New Floor",
        index: sortedFloors.value.length,
      });
      editFloor(newFloorID);
    };

    const editFloor = (floorID) => {
      router.push({ name: "EditFloor", params: { organisationID, locationID, floorID } });
    };

    const back = () => {
      router.back();
    };

    const reorderFloors = (e) => {
      const result = e.detail.complete(sortedFloors.value);
      for (const [i, item] of result.entries()) {
        location.value.floors[item.id].index = i;
        organisationStore.saveRecord(`organisations/${organisationID}/locations/${locationID}/floors`, item.id, item);
      }
    };

    const sortedFloors = computed(() => {
      return sortObjectByProperty(location.value?.floors, "index");
    });

    return { organisation, location, allowFloorsReorder, sortedFloors, addFloor, editFloor, reorderFloors, back, t, chevronForward };
  },
};
</script>

<style></style>
