<template>
  <ion-page>
    <av-header />

    <ion-content :scroll-y="false" v-if="space">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="space?.name" :subtitle="$t('integrations')" edit="true" />
        <div class="fullwidth ph pt scroll">
          <div class="action" @click="go('EditSpaceUbiqisense')">
            <ion-label color="secondary" class="fullwidth keepOnTop">Ubiqisense</ion-label><ion-icon :icon="chevronForward" />
          </div>
          <div class="action" @click="go('EditSpacePronestor')">
            <ion-label color="secondary" class="fullwidth keepOnTop">Pronestor</ion-label><ion-icon :icon="chevronForward" />
          </div>
        </div>
        <div class="grow"></div>
        <!-- <nav-footer :label="organisation.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";

export default {
  name: "Description",
  setup() {
    const dirty = ref(false);
    const route = useRoute();
    const router = useRouter();
    let organisationID = route.params.organisationID;
    let locationID = route.params.locationID;
    let floorID = route.params.floorID;
    let spaceID = route.params.spaceID;
    const organisationStore = useOrganisationStore();
    const { space } = storeToRefs(organisationStore);

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
    });

    const go = (name) => {
      router.push({ name: name, params: { organisationID, locationID, floorID, spaceID } });
    };

    const back = () => {
      router.back();
    };
    return {
      // variables
      space,
      // methods
      go,
      back,
      // icons
      chevronForward,
    };
  },
};
</script>
