<template>
  <ion-page>
    <av-header />

    <ion-content :scroll-y="false" v-if="manufacturers && assetTypes && models">
      <div class="flex vertical nogap left">
        <view-header @back="goHome" :title="t('models')" />
        <div class="fullwidth p">
          <ion-select
            interface="action-sheet"
            mode="ios"
            :placeholder="t('select_manufacturer')"
            v-model="manufacturerID"
            :interface-options="popoverOptions"
          >
            <ion-select-option value="all">{{ t("all_manufacturers") }}</ion-select-option>
            <ion-select-option v-for="manufacturer in sortedManufacturers" :key="manufacturer.id" :value="manufacturer.id">{{
              manufacturer.name
            }}</ion-select-option>
          </ion-select>

          <ion-select
            interface="action-sheet"
            mode="ios"
            :placeholder="t('select_type')"
            v-model="assetTypeID"
            :interface-options="popoverOptions"
          >
            <ion-select-option value="all">{{ t("all_types") }}</ion-select-option>
            <ion-select-option v-for="assetType in sortedAssetTypes" :key="assetType.id" :value="assetType.id">{{
              assetType.name
            }}</ion-select-option>
          </ion-select>
        </div>
        <div class="fullwidth p scroll">
          <ion-list>
            <ion-item>
              <ion-input v-model="name" :placeholder="t('type_new_model_name_and_press')" v-on:keyup.enter="addRecord()" />
              <ion-icon :icon="returnDownBack" slot="end" color="secondary" class="o4" />
            </ion-item>

            <ion-item v-for="(item, i) in filteredModels" :key="i" @click="editModel(item)">
              <div class="flex vertical nogap pv2">
                <div class="flex">
                  <ion-label class="grow">{{ item.name }}</ion-label>
                  <ion-icon :icon="trashOutline" color="danger" @click="deleteRecord($event, item)" size="large" />
                </div>
                <div class="flex left">
                  <ion-note>{{ manufacturers[item.manufacturerID]?.name }}</ion-note>
                  <ion-note>{{ assetTypes[item.assetTypeID]?.name }}</ion-note>
                </div>
              </div>
            </ion-item>
          </ion-list>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, ref, onMounted } from "@vue/runtime-core";
import { chevronForward, trashOutline, returnDownBack } from "ionicons/icons";
import { useOrganisationStore } from "@/stores/organisation";
import { toast, sortObjectByProperty } from "@/composables/utilities";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default {
  name: "Description",
  setup() {
    const name = ref();
    const assetTypeID = ref("all");
    const manufacturerID = ref("all");
    const organisationStore = useOrganisationStore();
    const { manufacturers, assetTypes, models } = storeToRefs(organisationStore);
    const { t } = useI18n();
    const router = useRouter();

    const popoverOptions = {
      cssClass: "popover",
    };

    onMounted(() => {
      organisationStore.initialise();
    });

    const sortedManufacturers = computed(() => {
      return sortObjectByProperty(manufacturers.value, "name", true);
    });

    const sortedAssetTypes = computed(() => {
      return sortObjectByProperty(assetTypes.value, "name", true);
    });

    const filteredModels = computed(() => {
      let result = Object.keys(models.value).map((key) => models.value[key]);
      if (!result) return;
      if (manufacturerID.value != "all") result = result.filter((m) => m.manufacturerID == manufacturerID.value);
      if (assetTypeID.value != "all") result = result.filter((m) => m.assetTypeID == assetTypeID.value);
      return result;
    });

    const manufacturerCount = (assetTypeID) => filteredModels.value?.filter((m) => m.assetTypeID == assetTypeID).length;
    const assetTypeCount = (manufacturerID) => filteredModels.value?.filter((m) => m.manufacturerID == manufacturerID).length;

    const addRecord = () => {
      if (!(manufacturerID.value && assetTypeID.value)) {
        toast("Please select a manufacturer and asset type");
        return;
      }
      organisationStore.addRecord("models", {
        name: name.value,
        manufacturerID: manufacturerID.value,
        assetTypeID: assetTypeID.value,
      });
      name.value = null;
    };

    const deleteRecord = (event, item) => {
      event.cancelBubble = true;
      organisationStore.deleteRecord("models", item.id);
    };

    const editModel = (model) => {
      router.push({ name: "EditModel", params: { modelID: model.id } });
    };

    const goHome = () => {
      router.push({ name: "Home" });
    };

    return {
      // variables
      sortedManufacturers,
      sortedAssetTypes,
      manufacturers,
      assetTypes,
      models,
      name,
      manufacturerID,
      assetTypeID,
      filteredModels,
      popoverOptions,
      // methods
      goHome,
      addRecord,
      deleteRecord,
      manufacturerCount,
      assetTypeCount,
      editModel,
      t,
      // icons
      chevronForward,
      trashOutline,
      returnDownBack,
    };
  },
};
</script>
