import { getAnalytics, logEvent } from "firebase/analytics";
import mixpanel from "mixpanel-browser";

export const setScreen = (screenName) => {
  const analytics = getAnalytics();
  logEvent(analytics, "screen_view", {
    firebase_screen: screenName,
  });
};

export const logPropertySelection = (name) => {
  const analytics = getAnalytics();
  logEvent(analytics, "select_content", {
    content_type: "property",
    content_id: name,
  });
};

export const logScan = (shortcode, organisation, location, floor, space) => {
  // // eslint-disable-next-line
  // pendo.track("code_scanned", {
  //   shortcode: shortcode,
  //   organisation,
  //   location,
  //   floor,
  //   space,
  // });
  mixpanel.track("QR Code Scanned", {
    shortcode,
    organisation,
    location,
    floor,
    space,
  });
};
