<template>
  <ion-page>
    <ion-content scroll-y="false">
      <div class="background"></div>
      <div class="absolute fullheight fullwidth noScroll flex vertical p2 safe-bottom">
        <div class="fullwidth tc">
          <img src="/img/logos/logo_white.svg" class="headerLogo" />
        </div>
        <div class="fullwidth flex grow mv2 centered" style="height: 100px">
          <img src="/img/iphone14.png" alt="Alpha Victor on iPhone" class="cover fullheight" />
        </div>
        <div class="fullwidth tc">
          <ion-button @click="useWeb()" color="tertiary" class="boldFont mv2 fl">Continue to website &gt;&gt;</ion-button>
        </div>
        <div class="fs tc">
          Or, by downloading Alpha Victor you'll unlock access to favourites, location awareness, wifi access and more.
        </div>
        <div class="mt2 avoidMenubar">
          <a href="https://apps.apple.com/gb/app/alpha-victor/id1563385983">
            <img v-if="isPlatform('ios')" src="/img/appstores/app_store.svg" alt="Download on the App Store" style="height: 50px"
          /></a>
          <a href="https://play.google.com/store/apps/details?id=com.uncoded.av"
            ><img v-if="isPlatform('android')" src="/img/appstores/google-play-badge.png" alt="Get in on Google Play" style="height: 50px"
          /></a>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { isPlatform } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const userStore = useUserStore();
    const { firestoreUser } = storeToRefs(userStore);

    const useWeb = () => {
      switch (route.name) {
        case "DownloadSpace":
          router.push({ name: "Space", params: route.params });
          break;
        default:
          router.push({ name: "Home" });
      }
    };

    return { useWeb, isPlatform };
  },
};
</script>

<style scoped>
.headerLogo {
  height: 120px;
  margin-top: 3px;
}

.avoidMenubar {
  padding-bottom: 50px;
}

.link {
  padding-bottom: 3px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--ion-color-secondary);
}
</style>
