<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical fullheight bgd" slot="fixed">
        <view-header @back="back" :title="$t('settings')" subtitle="" class="fullwidth" />

        <div class="fullwidth p2" v-if="isPlatform('cordova')">
          <ion-item
            ><div class="uppercase fl boldFont">{{ $t("permissions") }}</div></ion-item
          >

          <ion-item>
            <ion-label color="secondary">{{ $t("location") }}</ion-label>
            <ion-button expand="block" color="success" v-if="locationStatus == Status.ok" disabled>{{ $t("granted") }}</ion-button>
            <ion-button
              expand="block"
              color="danger"
              v-if="locationStatus == Status.declined"
              @click="retryPermission(Permission.location)"
              >{{ $t("declined") }}</ion-button
            >
            <ion-button
              expand="block"
              color="warning"
              v-if="locationStatus == Status.notNow"
              @click="requestPermission(Permission.location)"
              >{{ $t("not_requested") }}</ion-button
            >
          </ion-item>

          <ion-item>
            <ion-label color="secondary">{{ $t("camera") }}</ion-label>
            <ion-button expand="block" color="success" v-if="cameraStatus == Status.ok" disabled>{{ $t("granted") }}</ion-button>
            <ion-button expand="block" color="danger" v-if="cameraStatus == Status.declined" @click="retryPermission(Permission.camera)">{{
              $t("declined")
            }}</ion-button>
            <ion-button expand="block" color="warning" v-if="cameraStatus == Status.notNow" @click="requestPermission(Permission.camera)">{{
              $t("not_requested")
            }}</ion-button>
          </ion-item>

          <ion-item v-if="isPlatform('android')">
            <ion-label color="secondary">{{ $t("bluetooth") }}</ion-label>
            <ion-button expand="block" color="success" v-if="bluetoothStatus == Status.ok" disabled>{{ $t("granted") }}</ion-button>
            <ion-button
              expand="block"
              color="danger"
              v-if="bluetoothStatus == Status.declined"
              @click="retryPermission(Permission.bluetooth)"
              >{{ $t("declined") }}</ion-button
            >
            <ion-button
              expand="block"
              color="warning"
              v-if="bluetoothStatus == Status.notNow"
              @click="requestPermission(Permission.bluetooth)"
              >{{ $t("not_requested") }}</ion-button
            >
          </ion-item>

          <ion-item>
            <ion-toggle v-model="userData.profile.disableBeacons" @ionChange="beaconsDisabledChanged" color="secondary">{{
              $t("disable_beacons")
            }}</ion-toggle>
          </ion-item>
        </div>

        <div class="fullwidth p2">
          <ion-item v-if="isDemonstrator">
            <ion-toggle v-model="userData.profile.enableTechnicianMode" @ionChange="technicianEnabledChanged" color="secondary"
              >Enable technician mode</ion-toggle
            >
          </ion-item>
        </div>

        <div class="fullwidth p2" v-if="!isPlatform('cordova') && !isDemonstrator">
          {{ $t("no_settings") }}
        </div>
        <div class="grow"></div>
        <!-- <nav-footer label="Back" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
import { alertController, isPlatform, onIonViewWillEnter } from "@ionic/vue";
import { useUserStore } from "@/stores/user";
import { computed, onMounted, ref } from "vue";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic";
import { useBeaconStore } from "@/stores/beacons";

export default {
  setup() {
    const dirty = ref(false);
    const router = useRouter();
    const userStore = useUserStore();
    const userData = userStore.data;

    const Permission = {
      location: "location",
      camera: "camera",
      bluetooth: "bluetooth",
    };
    const Status = {
      ok: "ok",
      declined: "declined",
      notNow: "notNow",
    };
    const locationStatus = ref();
    const cameraStatus = ref();
    const bluetoothStatus = ref();

    onIonViewWillEnter(async () => {
      if (isPlatform("cordova")) {
        const _locationStatus = await Diagnostic.getLocationAuthorizationStatus();
        if ([Diagnostic.permissionStatus.GRANTED, Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE].includes(_locationStatus)) {
          locationStatus.value = Status.ok;
        } else if (_locationStatus == Diagnostic.permissionStatus.DENIED_ALWAYS) {
          locationStatus.value = Status.declined;
        } else {
          locationStatus.value = Status.notNow;
        }

        const _cameraStatus = await Diagnostic.getCameraAuthorizationStatus();
        if ([Diagnostic.permissionStatus.GRANTED].includes(_cameraStatus)) {
          cameraStatus.value = Status.ok;
        } else if (_cameraStatus == Diagnostic.permissionStatus.DENIED_ALWAYS) {
          cameraStatus.value = Status.declined;
        } else {
          cameraStatus.value = Status.notNow;
        }
      }
    });

    onMounted(() => {
      if (isPlatform("cordova")) {
        Diagnostic.registerLocationStateChangeHandler((status) => {
          switch (status) {
            case Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
            case Diagnostic.permissionStatus.GRANTED: {
              locationStatus.value = Status.ok;
              break;
            }
            case Diagnostic.permissionStatus.DENIED_ALWAYS: {
              locationStatus.value = Status.declined;
              break;
            }
            default: {
              locationStatus.value = Status.notNow;
            }
          }
        });

        Diagnostic.registerBluetoothStateChangeHandler((status) => {
          switch (status) {
            case Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
            case Diagnostic.permissionStatus.GRANTED: {
              bluetoothStatus.value = Status.ok;
              break;
            }
            case Diagnostic.permissionStatus.DENIED_ALWAYS: {
              bluetoothStatus.value = Status.declined;
              break;
            }
            default: {
              bluetoothStatus.value = Status.notNow;
            }
          }
        });
      }
    });

    const retryPermission = async (permission) => {
      if (isPlatform("android")) {
        switch (permission) {
          case Permission.location: {
            Diagnostic.switchToLocationSettings();
            break;
          }
          case Permission.beacons: {
            Diagnostic.switchToBluetoothSettings();
            break;
          }
          case Permission.camera: {
            Diagnostic.switchToSettings();
          }
        }
      }
      if (isPlatform("ios")) {
        Diagnostic.switchToSettings();
      }
    };

    const requestPermission = async (permission) => {
      switch (permission) {
        case Permission.location: {
          const status = await Diagnostic.requestLocationAuthorization();
          switch (status) {
            case Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
            case Diagnostic.permissionStatus.GRANTED: {
              locationStatus.value = Status.ok;
              break;
            }
            case Diagnostic.permissionStatus.DENIED_ALWAYS: {
              locationStatus.value = Status.declined;
              break;
            }
            default: {
              locationStatus.value = Status.notNow;
            }
          }
          break;
        }

        case Permission.camera: {
          const status = await Diagnostic.requestCameraAuthorization();
          switch (status) {
            case Diagnostic.permissionStatus.GRANTED: {
              cameraStatus.value = Status.ok;
              break;
            }
            case Diagnostic.permissionStatus.DENIED_ALWAYS: {
              cameraStatus.value = Status.declined;
              break;
            }
            default: {
              cameraStatus.value = Status.notNow;
            }
          }
          break;
        }

        case Permission.beacons: {
          const status = await Diagnostic.requestBluetoothAuthorization();
          switch (status) {
            case Diagnostic.permissionStatus.GRANTED: {
              bluetoothStatus.value = Status.ok;
              break;
            }
            case Diagnostic.permissionStatus.DENIED_ALWAYS: {
              bluetoothStatus.value = Status.declined;
              break;
            }
            default: {
              bluetoothStatus.value = Status.notNow;
            }
          }
          break;
        }

        default: {
          console.log("No permission detected");
        }
      }
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const save = async () => {
      userStore.saveProfile();
      dirty.value = false;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: async () => {
                await save();
                router.push({ name: "Home" });
              },
            },
            {
              text: "Cancel",
            },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.push({ name: "Home" });
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const beaconsDisabledChanged = () => {
      if (userStore.data.disableBeacons) useBeaconStore.stop();
      userStore.saveProfile();
    };

    const isDemonstrator = computed(() => userData.user?.roles?.includes("demonstrator"));

    const technicianEnabledChanged = () => {
      userStore.toggleTechnician();
    };

    return {
      userData,
      dirty,
      locationStatus,
      cameraStatus,
      bluetoothStatus,
      Status,
      Permission,
      isDemonstrator,
      // disableBeacons,
      isPlatform,
      markDirty,
      back,
      save,
      requestPermission,
      retryPermission,
      beaconsDisabledChanged,
      technicianEnabledChanged,
    };
  },
};
</script>

<style scoped></style>
