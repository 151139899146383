import { collection, onSnapshot } from "firebase/firestore";
import { computed, reactive, ref, watch } from "vue";
import { db, rcValues } from "@/composables/firebase";
import { defineStore, storeToRefs } from "pinia";
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { isPlatform } from "@ionic/vue";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic";

export const useUbiStore = defineStore("ubiStore", () => {
  const organisationStore = useOrganisationStore();
  const { organisation } = storeToRefs(organisationStore);
  const data = reactive({
    ubiOrganisations: {},
  });

  const organisations = computed(() => data.ubiOrganisations);

  const getAuthKey = async () => {
    const client_id = organisation.value?.ubiClientID;
    const client_secret = organisation.value?.ubiClientSecret;
    if (!(client_id && client_secret)) return;
    const response = await fetch(`${process.env.VUE_APP_UBI_BASE_URL}/auth/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        client_id: client_id,
        client_secret: client_secret,
        grant_type: "client_credentials",
      }),
    });
    const data = await response.json();
    return data.access_token;
  };

  const getResponse = async (query) => {
    const method = "POST";
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    const auth_key = await getAuthKey();
    if (!auth_key) return;
    const url = `${process.env.VUE_APP_UBI_BASE_URL}/${query}`;
    const response = await fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer ${auth_key}` },
    });
    const data = await response.json();
    return data;
  };

  const getOrganisations = async () => {
    const account = await getResponse(`account/me`);
    if (!account) return;
    const organisations = account.memberships.map((m) => {
      return { id: m.organizationId, name: m.organization?.name };
    });
    data.ubiOrganisations = organisations;
  };

  const getLocations = async () => {
    const organisationID = organisation.value?.ubi_id;
    if (!organisationID) return;
    const ubiLocations = await getResponse(`organizations/${organisationID}/locations`);
    return ubiLocations.results.map((l) => {
      return { id: l.id, name: l.name };
    });
  };

  const init = async () => {
    getOrganisations();
  };

  init();

  return {
    organisations,
    getOrganisations,
    getLocations,
  };
});
