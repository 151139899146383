<template>
  <ion-page>
    <ion-content>
      <div class="flex vertical nogap" slot="fixed" v-if="organisation">
        <av-header />
        <div class="flex vertical fullwidth noScroll">
          <view-header @back="back" :title="$t('checklists')" class="fullwidth" />
          <div class="fullwidth">
            <Tree
              :value="nodes"
              :filter="true"
              v-model:expandedKeys="expandedKeys"
              selectionMode="single"
              v-on:node-select="selectNode"
              @filter="filterTree"
            >
            </Tree>
          </div>

          <div class="grow"></div>
        </div>
      </div>
      <Wait v-else />
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import Wait from "@/components/Wait.vue";
import Tree from "primevue/tree";
import { filterObject } from "@/composables/utilities";
import { formatFirestoreDate } from "@/composables/firebase";

const organisationStore = useOrganisationStore();
const { organisation } = storeToRefs(organisationStore);
const expandedKeys = ref({});
const router = useRouter();
const route = useRoute();
const organisationID = route.params.organisationID;
organisationStore.initialise(organisationID);

const selectNode = (e) => {
  switch (e.data?.type) {
    case "organisation":
      break;
    case "space":
      if (e.data?.params) router.push({ name: "SpaceChecklist", params: e.data.params });
      break;
    default:
      break;
  }
};

const filterTree = (e) => {
  for (const node of nodes.value) {
    expandNode(node);
  }
};

const expandNode = (node) => {
  expandedKeys.value[node.key] = true;
  if (node.children) {
    node.children.forEach((n) => expandNode(n));
  }
};

const nodes = computed(() => {
  if (!organisation.value?.loaded) return;

  const orgNode = {
    label: organisation.value?.name,
    icon: "pi pi-fw pi-globe",
    key: organisation.value.id,
    children: [],
    data: { type: "organisation" },
  };

  for (const locationID in organisation.value?.locations) {
    const location = organisation.value.locations[locationID];
    const locNode = {
      label: location.name,
      icon: "pi pi-fw pi-building",
      key: location.id,
      children: [],
    };
    orgNode.children.push(locNode);

    if (location.floors)
      for (const floorID in location.floors) {
        const floor = location.floors[floorID];
        const floorStatus = getFloorChecklistStatus(floor);
        const flrNode = {
          label: floor.name,
          // icon: floorStatus.complete ? "pi pi-fw pi-arrows-v complete" : "pi pi-fw pi-arrows-v incomplete",
          key: floor.id,
          children: [],
        };
        locNode.children.push(flrNode);

        if (floor.spaces)
          for (const spaceID in floor.spaces) {
            const space = floor.spaces[spaceID];
            const spaceStatus = getSpaceChecklistStatus(space);

            const spcNode = {
              label: space.name,
              key: space.id,
              children: [],
              // icon: space.checklistCompletedToday
              //   ? spaceStatus.issues
              //     ? "pi pi-check-square issues"
              //     : "pi pi-check-square ok"
              //   : "pi pi-stop",
            };

            const dueNode = {
              label: "Due",
              key: `${space.id}-due`,
              icon: "pi pi-clock",
              children: [],
            };

            for (const checklistID in space.dueChecklists) {
              const checklist = space.dueChecklists[checklistID];
              const checklistNode = {
                label: checklist.name,
                key: `${space.id}-${checklist.id}`,
                children: [],
                icon: "pi pi-list",
                data: { type: "space", params: { organisationID, locationID, floorID, spaceID, checklistID: checklist.id } },
              };
              dueNode.children.push(checklistNode);
            }

            const completedNode = {
              label: "Complete",
              key: `${space.id}-completed`,
              children: [],
              icon: "pi pi-check-circle",
            };

            for (const checklistID in space.completedChecklists) {
              const checklist = space.completedChecklists[checklistID];
              const checklistNode = {
                label: `${checklist.name} - ${formatFirestoreDate(checklist.submissionDate)}`,
                key: `${space.id}-${checklist.id}`,
                children: [],
                icon: "pi pi-home",
                styleClass: checklist.didFail ? "fail" : "pass",
                data: { type: "space", params: { organisationID, locationID, floorID, spaceID, checklistID: checklist.id } },
              };
              completedNode.children.push(checklistNode);
            }

            spcNode.children.push(dueNode);
            spcNode.children.push(completedNode);

            flrNode.children.push(spcNode);
          }
      }
  }
  return [orgNode];
});

const getFloorChecklistStatus = (floor) => {
  const checklistSpaces = filterObject(floor.spaces, (space) => space.checklistID != null);
  const completedSpaces = filterObject(floor.spaces, (space) => {
    return space.checklistID && space.checklistCompletedToday;
  });
  const spaceCount = Object.keys(checklistSpaces)?.length;
  const completeCount = Object.keys(completedSpaces)?.length;
  return { spaceCount, completeCount, complete: spaceCount == completeCount };
};

const getSpaceChecklistStatus = (space) => {
  const issues = filterObject(space.latestChecklist?.responses, (response) => response.pass != null && response.pass == false);
  if (!issues) return 0;
  return { issues: Object.keys(issues).length > 0 };
};

const back = () => {
  router.back();
};
</script>

<style lang="scss" scoped></style>

<style>
.pass {
  color: var(--ion-color-success);
}
.fail {
  color: var(--ion-color-danger);
}
</style>
