<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="space">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="space.name" :subtitle="t('edit_devices')" edit="true" />
        <input type="file" @change="addPhoto" ref="photoSelector" hidden />
        <div class="fullwidth ph pt scroll">
          <ion-accordion-group>
            <ion-accordion v-for="item in space.devices" :key="item.id" :toggleIcon="addCircle" toggleIconSlot="start">
              <ion-item slot="header">
                <div class="flex">
                  <ion-label>{{ models[item.modelID].name }}</ion-label
                  ><ion-label class="grow"
                    ><div class="ft flex vertical nogap">
                      <div class="flex right">{{ manufacturers[models[item.modelID].manufacturerID].name }}</div>
                      <div class="flex right">{{ assetTypes[models[item.modelID].assetTypeID].name }}</div>
                    </div></ion-label
                  >
                  <ion-icon :icon="trash" color="primary" size="large" class="pl" @click="deleteDevice(item)" />
                </div>
              </ion-item>

              <div slot="content">
                <ion-item lines="none" v-if="item.photo">
                  <img :src="item.photo.url" style="height: 200px" />
                </ion-item>
                <ion-item>
                  <ion-button class="bright" expand="block" @click="triggerSelectPhoto(item)" v-if="!item.photo">Add photo</ion-button>
                  <ion-button expand="block" @click="deletePhoto(item)" v-else>Delete photo</ion-button>
                </ion-item>
                <ion-item>
                  <ion-input
                    :label="t('serial_number')"
                    label-placement="stacked"
                    color="secondary"
                    :placeholder="t('serial_number')"
                    v-model="item.serialNumber"
                    @ionInput="markDirty()"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-input
                    label="MAC"
                    label-placement="stacked"
                    color="secondary"
                    placeholder="MAC"
                    v-model="item.macAddress"
                    @ionInput="markDirty()"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-input
                    :label="t('installer')"
                    label-placement="stacked"
                    color="secondary"
                    :placeholder="t('installer_name')"
                    v-model="item.installer"
                    @ionInput="markDirty()"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label
                    ><div class="cl">{{ t("install_date") }}</div></ion-label
                  ><ion-datetime-button :datetime="`installerDateTime-${item.id}`" :class="{ noValue: !item.installDate }" />
                  <ion-modal :keep-contents-mounted="true"
                    ><ion-datetime
                      :id="`installerDateTime-${item.id}`"
                      v-model="item.installDate"
                      presentation="date"
                      @ionChange="markDirty"
                  /></ion-modal>
                </ion-item>
                <ion-item>
                  <ion-label
                    ><div class="cl">{{ t("warranty_expiration_date") }}</div></ion-label
                  ><ion-datetime-button :datetime="`warrantyDateTime-${item.id}`" />
                  <ion-modal :keep-contents-mounted="true"
                    ><ion-datetime
                      :id="`warrantyDateTime-${item.id}`"
                      v-model="item.warrantyExpirationDate"
                      presentation="date"
                      max="2050"
                      @ionChange="markDirty"
                  /></ion-modal>
                </ion-item>
                <ion-item>
                  <ion-input
                    :label="t('description')"
                    label-placement="stacked"
                    color="secondary"
                    :placeholder="t('description')"
                    v-model="item.description"
                    @ionInput="markDirty()"
                  ></ion-input>
                </ion-item>
              </div> </ion-accordion
          ></ion-accordion-group>
        </div>

        <div class="grow"></div>
        <div class="fullwidth bgd">
          <ion-button id="open-modal" expand="block" class="bright m2" @click="showModal">{{ t("add_device") }}</ion-button>
        </div>

        <!-- <nav-footer :label="space.name" @back="back" /> -->
      </div>

      <ion-modal :is-open="modalVisible" :can-dismiss="true" @didDismiss="closeModal">
        <ion-header>
          <ion-toolbar>
            <ion-title>Add Device</ion-title>
            <ion-buttons slot="end">
              <ion-button color="success" @click="closeModal">Done</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-select interface="action-sheet" mode="ios" placeholder="Select Manufacturer" v-model="manufacturerID" style="--color: red">
            <ion-select-option value="all">All Manufacturers</ion-select-option>
            <ion-select-option v-for="manufacturer in sortedManufacturers" :key="manufacturer.id" :value="manufacturer.id">{{
              manufacturer.name
            }}</ion-select-option>
          </ion-select>

          <ion-select interface="action-sheet" mode="ios" placeholder="Select Type" v-model="assetTypeID">
            <ion-select-option value="all">All Types</ion-select-option>
            <ion-select-option v-for="assetType in sortedAssetTypes" :key="assetType.id" :value="assetType.id">{{
              assetType.name
            }}</ion-select-option>
          </ion-select>

          <ion-list>
            <ion-item v-for="(item, i) in filteredModels" :key="i" @click="addDevice(item)">
              <div class="flex vertical nogap pv2">
                <div class="flex">
                  <ion-label class="grow">{{ item.name }}</ion-label>
                </div>
                <div class="flex left">
                  <ion-note>{{ manufacturers[item.manufacturerID]?.name }}</ion-note>
                  <ion-note>{{ assetTypes[item.assetTypeID]?.name }}</ion-note>
                </div>
              </div>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { chevronForward, addCircle, trash } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { toast } from "@/composables/utilities";
import { sortObjectByProperty, objectToArray } from "@/composables/utilities";
import { useI18n } from "vue-i18n";

const route = useRoute();
const router = useRouter();

const locationID = route.params.locationID;
const organisationID = route.params.organisationID;
const floorID = route.params.floorID;
const spaceID = route.params.spaceID;

const assetTypeID = ref("all");
const manufacturerID = ref("all");
const photoSelector = ref();
let currentDevice;

const organisationStore = useOrganisationStore();
const { t } = useI18n();

const { organisation, location, floor, space, models, assetTypes, manufacturers, decisionTree } = storeToRefs(organisationStore);

const dirty = ref(false);

let backup;
const modalVisible = ref(false);

const sortedManufacturers = computed(() => {
  return sortObjectByProperty(manufacturers.value, "name", true);
});

const sortedAssetTypes = computed(() => {
  return sortObjectByProperty(assetTypes.value, "name", true);
});

const deleteDevice = (device) => {
  space.value.devices = space.value.devices.filter((d) => d != device);
  markDirty();
};

const destinations = computed(() => {
  return objectToArray(organisation.value.decisionTrees?.[space.value.decisionTreeID]?.destinations);
});

const filteredModels = computed(() => {
  let result = Object.keys(models.value).map((key) => models.value[key]);
  if (!result) return;
  if (manufacturerID.value != "all") {
    result = result.filter((m) => m.manufacturerID == manufacturerID.value);
  }
  if (assetTypeID.value != "all") result = result.filter((m) => m.assetTypeID == assetTypeID.value);
  return result;
});

const addDevice = (item) => {
  if (!space.value.devices) space.value.devices = [];
  space.value.devices.push({
    id: self.crypto.randomUUID(),
    modelID: item.id,
    installer: location.value.defaultInstallerName || null,
    installDate: location.value.defaultInstallDate || null,
    warrantyExpirationDate: location.value.defaultWarrantyExpirationDate || null,
  });
  toast(`Added ${assetTypes.value[item.assetTypeID]?.name}`);
  markDirty();
};

const triggerSelectPhoto = (device) => {
  currentDevice = device;
  photoSelector.value.click();
};

const addPhoto = async () => {
  const files = Array.from(event.target.files);
  if (files.length == 0) {
    currentDevice = null;
    photoSelector.value.value = "";
    return;
  }

  await organisationStore.addDevicePhoto(currentDevice, files[0]);
  photoSelector.value.value = "";
};

const deletePhoto = async (device) => {
  await organisationStore.deleteDevicePhoto(device);
};

onIonViewWillEnter(() => {
  organisationStore.initialise(organisationID, locationID, floorID, spaceID);
});

const save = async () => {
  organisationStore.saveRecord(
    `organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces`,
    space.value.id,
    space.value
  );
  router.back();
};

const markDirty = () => {
  dirty.value = true;
};

const createBackup = () => {
  if (space.value?.devices) {
    backup = [...space.value.devices];
  }
};

watch(space, createBackup);
onIonViewWillEnter(() => {
  createBackup();
});

const back = async () => {
  if (dirty.value) {
    const alert = await alertController.create({
      cssClass: "alert",
      header: "Are you sure?",
      message: "You have unsaved changes.",
      buttons: [
        {
          text: "Save & Close",
          handler: () => {
            save();
          },
        },
        { text: "Cancel" },
        {
          text: "Discard Changes",
          role: "destructive",
          handler: () => {
            space.value.devices = backup;
            router.back();
          },
        },
      ],
    });
    await alert.present();
  } else {
    router.back();
  }
};

const showModal = () => {
  modalVisible.value = true;
};

const closeModal = () => {
  modalVisible.value = false;
};

const go = (name) => {
  router.push({ name: name, params: { locationID } });
};
</script>

<style scoped>
ion-datetime-button::part(native) {
  background: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  font-size: 0.8em;
}

.noValue {
  opacity: 0.5;
}
</style>

<style>
.ion-accordion-toggle-icon {
  color: var(--ion-color-dark-contrast);
}
</style>
