<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd" slot="fixed">
        <view-header @back="back" :title="t('issue')" :subtitle="issue?.title || t('no_description')" class="fullwidth" />
        <div class="fullwidth ph2" v-if="issue">
          <ion-item lines="none" class="mb2">
            <ion-input v-model="issue.title" @ionInput="markDirty" color="secondary" :label="t('title')" label-placement="stacked" />
          </ion-item>

          <ion-segment v-model="issue.status" @ionChange="addStatusChange" class="fullwidth mb2">
            <ion-segment-button value="warning" :class="{ warning: issue.status == 'warning' }">{{ t("low_impact") }}</ion-segment-button>
            <ion-segment-button value="danger" :class="{ danger: issue.status == 'danger' }">{{ t("high_impact") }}</ion-segment-button>
            <ion-segment-button value="success" :class="{ closed: issue.status == 'success' }">{{ t("resolved") }}</ion-segment-button>
          </ion-segment>

          <div class="scroll">
            <ion-item v-for="(event, i) in events" :key="i">
              <div class="flex vertical pv">
                <div class="flex ft">
                  <div>{{ event.username }}</div>
                  <div>{{ getAge(event.date) }}</div>
                </div>
                <div class="fs fullwidth">{{ event.description }}</div>
              </div>
            </ion-item>
          </div>
          <div class="fullwidth mt">
            <ion-item class="mb" lines="none">
              <ion-label color="secondary" position="stacked">{{ t("new_comment") }}</ion-label>
              <ion-textarea v-model="newEvent" auto-grow :placeholder="t('enter_a_new_comment_or_status')" />
            </ion-item>
          </div>
        </div>

        <div class="grow"></div>
        <div class="fullwidth p2">
          <ion-button expand="block" color="secondary" @click="addEvent">{{ t("add_comment") }}</ion-button>
        </div>
        <!-- <nav-footer label="Back" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { computed, ref, watch } from "@vue/runtime-core";
import { ellipse, chatboxEllipses, create } from "ionicons/icons";
import { Timestamp } from "firebase/firestore";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const organisationStore = useOrganisationStore();
    const userStore = useUserStore();
    const { organisation } = storeToRefs(organisationStore);
    const { user, profile } = storeToRefs(userStore);
    const route = useRoute();
    const router = useRouter();
    const issueID = route.params.issueID;
    const organisationID = route.params.organisationID;
    const issue = computed(() => organisation.value?.issues?.[issueID]);
    const newEvent = ref();
    const dirty = ref(false);
    const { t } = useI18n();
    let backup;

    const Status = {
      warning: "Low Impact",
      danger: "High Impact",
      success: "Resolved",
    };

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID);
    });

    const createBackup = () => (backup = { ...(issue.value || []) });
    createBackup();
    watch(issue, createBackup);

    const isAdmin = computed(() => user.value?.roles?.includes("admin") || user.value?.roles?.includes("spaceAdmin"));

    const events = computed(() => issue.value?.events?.sort((e1, e2) => (e2.date > e1.date ? 1 : -1)) || []);

    const getAge = (timestamp) => {
      const now = new Date();
      const then = timestamp.toDate();
      const age = (now - then) / (1000 * 60);
      if (age < 60) return `${Math.round(age)} ${t("minutes_ago")}`;
      if (age < 60 * 60) return `${Math.round(age / 60)} ${t("hours_ago")}`;
      return `${Math.round(age / (60 * 24))} ${t("days_ago")}`;
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const addEvent = () => {
      markDirty();
      if (!issue.value.events) issue.value.events = [];
      issue.value.events.push({
        username: profile.value?.firstname ? `${profile.value?.firstname} ${profile.value?.lastname}` : user.value?.id,
        date: Timestamp.fromDate(new Date()),
        type: "update",
        description: newEvent.value,
      });
    };

    const addStatusChange = () => {
      markDirty();
      if (!issue.value.events) issue.value.events = [];
      issue.value.events.push({
        username: profile.value?.firstname ? `${profile.value?.firstname} ${profile.value?.lastname}` : user.value?.id,
        date: Timestamp.fromDate(new Date()),
        type: "status",
        description: `Status changed to ${Status[issue.value.status]}`,
      });
    };

    const save = async () => {
      organisationStore.saveRecord(`organisations/${organisationID}/issues`, issueID, issue.value);
      router.back();
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                organisation.value.issues[issueID] = backup;
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      organisation,
      issue,
      events,
      isAdmin,
      newEvent,
      back,
      getAge,
      addEvent,
      addStatusChange,
      markDirty,
      t,
      ellipse,
      chatboxEllipses,
      create,
    };
  },
};
</script>

<style scoped>
.bg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}

ion-segment {
  --background: var(--ion-color-dark);
}
</style>
