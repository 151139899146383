<template>
  <ion-page>
    <av-header @showInfo="infoVisible = true" />

    <ion-content :scroll-y="false" id="decisionTreeContent">
      <div id="pageContainer" class="bgd">
        <div id="container" ref="container">
          <canvas ref="canvas" id="canvas" :class="{ canvas: decisionTree?.loaded }"></canvas>
        </div>
        <div class="footer flex">
          <ion-button @click="save" :disabled="!dirty" expand="block" class="bright grow">{{ t("save") }}</ion-button>
          <ion-button @click="cancel" expand="block" color="warning">{{ dirty ? t("cancel") : t("close") }}</ion-button>
          <ion-icon :icon="informationCircle" @click="infoVisible = true" />
        </div>
      </div>
    </ion-content>

    <ion-modal :is-open="destinationVisible" @didDismiss="destinationVisible = false">
      <ion-header>
        <ion-toolbar class="flex centered vertical">
          <ion-title>{{ t("edit_destination") }}</ion-title>
          <ion-icon :icon="closeCircle" slot="end" size="large" color="primary" @click="destinationVisible = false" class="mr" />
        </ion-toolbar>
      </ion-header>

      <ion-content v-if="editDestination">
        <div class="scroll p grow" style="height: 85%">
          <ion-item class="fullwidth">
            <ion-input :label="t('title')" label-placement="stacked" v-model="editDestination.title" @ionInput="dirty = true"></ion-input>
          </ion-item>

          <ion-segment v-model="editDestination.type" @ionChange="dirty = true" class="fullwidth mb">
            <ion-segment-button value="Question">{{ t("question") }}</ion-segment-button>
            <ion-segment-button value="Article">{{ t("article") }}</ion-segment-button>
            <ion-segment-button value="Video">{{ t("video") }}</ion-segment-button>
            <ion-segment-button value="Phone">{{ t("phone") }}</ion-segment-button>
            <ion-segment-button value="Email">{{ t("email") }}</ion-segment-button>
            <ion-segment-button value="PDF">PDF</ion-segment-button>
          </ion-segment>

          <div v-if="editDestination.type == 'Article'" class="fullwidth">
            <div class="flex centered ph bds">
              <ion-icon :icon="search" class="fl cs" />
              <ion-input v-model="searchTerm" :placeholder="t('search_for_an_article')"></ion-input>
            </div>

            <ion-list class="fullwidth">
              <ion-radio-group v-model="editDestination.destinationID" @ionChange="articleSelected()">
                <ion-item @click="editArticle('new')">
                  <ion-icon :icon="create" color="secondary" slot="start" style="margin-left: 2px" />
                  <ion-label color="secondary">{{ t("add_new_article") }}</ion-label> </ion-item
                ><ion-item v-for="article in filteredArticles" :key="article.id">
                  <div>
                    <ion-label>
                      <div class="fr boldFont cs">{{ article.title }}</div>
                      <div class="fs o7 cs">{{ article.subtitle }}</div>
                    </ion-label>
                  </div>
                  <ion-radio slot="start" :value="article.id"></ion-radio>
                  <ion-icon slot="end" @click="editArticle(article.id, $event)" :icon="create" color="secondary" style="z-index: 100" />
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </div>

          <div v-if="editDestination?.type == 'Video'" class="fullwidth">
            <div class="flex centered ph bds">
              <ion-icon :icon="search" class="fl cs" />
              <ion-input v-model="searchTerm" :placeholder="t('search_for_a_video')"></ion-input>
            </div>
            <ion-list>
              <ion-radio-group v-model="editDestination.destinationID" @ionChange="videoSelected()">
                <ion-item v-for="video in filteredVideos" :key="video.id">
                  <ion-label>{{ video.title }}</ion-label>
                  <ion-radio slot="start" :value="video.id"></ion-radio>
                  <ion-icon @click="editVideo(video.id, $event)" :icon="create" color="primary" style="z-index: 100" />
                </ion-item>
                <ion-item @click="editVideo('new')">
                  <ion-icon :icon="create" color="primary" slot="start" style="margin-left: 2px" />
                  <ion-label color="secondary">{{ t("add_new_video") }}</ion-label>
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </div>

          <div v-if="editDestination?.type == 'Phone'" class="fullwidth">
            <ion-item v-if="editDestination?.type == 'Phone'">
              <ion-input
                :label="t('phone_number')"
                label-placement="stacked"
                v-model="editDestination.phone"
                @ionChange="dirty = true"
              ></ion-input>
            </ion-item>
          </div>

          <div v-if="editDestination?.type == 'Email'" class="fullwidth">
            <ion-item v-if="editDestination?.type == 'Email'">
              <ion-input
                :label="t('email_address')"
                label-placement="stacked"
                v-model="editDestination.email"
                @ionChange="dirty = true"
              ></ion-input>
            </ion-item>
          </div>

          <div v-if="editDestination.type == 'PDF'" class="fullwidth">
            <div class="flex vertical centered ph">
              <ion-icon size="large" :icon="documentOutline" v-if="editDestination.path" />
              <div v-if="editDestination.path">{{ editDestination.filename }}</div>
              <input type="file" accept="application/pdf" hidden @change="documentUploaded()" ref="documentUploader" />
              <ion-button @click="deleteDocument()" v-if="editDestination.path" color="danger">Delete</ion-button>
              <ion-button @click="documentUploader.click()" v-else>Upload</ion-button>
            </div>

            <!-- <ion-select
                interface="action-sheet"
                v-model="manufacturer"
                mode="ios"
                placeholder="Manufacturer"
                :interface-options="popoverOptions"
              >
                <ion-select-option v-for="manufacturer in sortedManufacturers" :key="manufacturer.id" :value="manufacturer.id">{{
                  manufacturer.name
                }}</ion-select-option>
              </ion-select>

              <ion-select interface="action-sheet" v-model="assetType" mode="ios" placeholder="Type" :interface-options="popoverOptions">
                <ion-select-option v-for="assetType in sortedAssetTypes" :key="assetType.id" :value="assetType.id">{{
                  assetType.name
                }}</ion-select-option>
              </ion-select>

              <ion-select interface="action-sheet" v-model="model" mode="ios" placeholder="Model" :interface-options="popoverOptions">
                <ion-select-option v-for="model in sortedModels" :key="model.id" :value="model">{{ model.name }}</ion-select-option>
              </ion-select>
            </div>

            <ion-list class="fullwidth">
              <ion-radio-group v-model="editDestination.destinationID" @ionChange="documentSelected()">
                <ion-item v-for="(document, i) in model?.documents" :key="i">
                  <ion-label>{{ document.description }}</ion-label>
                  <ion-radio slot="start" :value="document"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list> -->
          </div>
        </div>
        <div class="grow"></div>
      </ion-content>
    </ion-modal>

    <ion-modal :is-open="outcomeVisible" @didDismiss="outcomeVisible = false">
      <ion-header>
        <ion-toolbar class="flex centered vertical">
          <ion-title>{{ t("outcome") }}</ion-title>
          <ion-icon :icon="closeCircle" slot="end" size="large" @click="outcomeVisible = false" class="mr" />
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding" v-if="editOutcome">
        <ion-item>
          <ion-input :label="t('label')" label-placement="stacked" @ionInput="dirty = true" v-model="editOutcome.label"></ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            :label="t('index')"
            label-placement="stacked"
            @ionInput="dirty = true"
            type="number"
            v-model="editOutcome.index"
          ></ion-input>
        </ion-item>
      </ion-content>
    </ion-modal>

    <!-- <ion-modal :is-open="newDestinationVisible" @didDismiss="newDestinationVisible = false">
      <ion-header>
        <ion-toolbar class="flex centered vertical">
          <ion-title>{{ t("edit_destination") }}</ion-title>
          <ion-icon :icon="closeCircle" slot="end" size="large" @click="newDestinationVisible = false" class="mr" />
        </ion-toolbar>
      </ion-header>

      <ion-content v-if="editDestination">
        <div class="scroll p" style="height: 85%">
          <ion-item>
            <ion-label position="stacked"
              ><div class="cs">{{ t("outcome_label") }}</div>
            </ion-label>
            <ion-input v-model="editOutcome.label" @ionInput="dirty = true"></ion-input>
          </ion-item>
          <ion-item class="fullwidth">
            <ion-label position="stacked"
              ><div class="cs">{{ t("title") }}</div></ion-label
            >
            <ion-input v-model="editDestination.title" @ionInput="dirty = true"></ion-input>
          </ion-item>

          <ion-segment v-model="editDestination.type" @ionChange="dirty = true" class="fullwidth mb">
            <ion-segment-button value="Question">{{ t("question") }}</ion-segment-button>
            <ion-segment-button value="Article">{{ t("article") }}</ion-segment-button>
            <ion-segment-button value="Video">{{ t("video") }}</ion-segment-button>
            <ion-segment-button value="Phone">{{ t("phone") }}</ion-segment-button>
            <ion-segment-button value="Email">{{ t("email") }}</ion-segment-button>
          </ion-segment>

          <div v-if="editDestination.type == 'Article'" class="fullwidth">
            <div class="flex centered ph bds">
              <ion-icon :icon="search" class="fl cs" />
              <ion-input v-model="searchTerm" :placeholder="t('search_for_an_article')"></ion-input>
            </div>

            <ion-list class="fullwidth">
              <ion-radio-group v-model="editDestination.destinationID" @ionChange="articleSelected()">
                <ion-item @click="editArticle('new')">
                  <ion-icon :icon="create" color="secondary" slot="start" style="margin-left: 2px" />
                  <ion-label color="secondary">{{ t("add_new_article") }}</ion-label> </ion-item
                ><ion-item v-for="article in filteredArticles" :key="article.id">
                  <div>
                    <ion-label>
                      <div class="fr boldFont cs">{{ article.title }}</div>
                      <div class="fs o7">{{ article.subtitle }}</div>
                    </ion-label>
                  </div>
                  <ion-radio slot="start" :value="article.id"></ion-radio>
                  <ion-icon slot="end" @click="editArticle(article.id, $event)" :icon="create" color="secondary" style="z-index: 100" />
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </div>

          <div v-if="editDestination?.type == 'Video'" class="fullwidth scroll">
            <div class="flex centered ph bds">
              <ion-icon :icon="search" class="fl cs" />
              <ion-input v-model="searchTerm" :placeholder="t('search_for_a_video')"></ion-input>
            </div>
            <ion-list>
              <ion-radio-group v-model="editDestination.destinationID" @ionChange="videoSelected()">
                <ion-item @click="editVideo('new')">
                  <ion-icon :icon="create" color="secondary" slot="start" style="margin-left: 2px" />
                  <ion-label color="secondary">{{ t("add_new_video") }}</ion-label>
                </ion-item>
                <ion-item v-for="video in filteredVideos" :key="video.id">
                  <ion-label>{{ video.title }}</ion-label>
                  <ion-radio slot="start" :value="video.id"></ion-radio>
                  <ion-icon @click="editVideo(video.id, $event)" :icon="create" color="secondary" style="z-index: 100" />
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </div>

          <div v-if="editDestination?.type == 'Phone'" class="fullwidth scroll">
            <ion-item v-if="editDestination?.type == 'Phone'">
              <ion-label position="floating">{{ t("phone_number") }}</ion-label>
              <ion-input v-model="editDestination.phone" @ionChange="dirty = true"></ion-input>
            </ion-item>
          </div>
          <div v-if="editDestination?.type == 'Email'" class="fullwidth scroll">
            <ion-item v-if="editDestination?.type == 'Email'">
              <ion-label position="floating">{{ t("email_address") }}</ion-label>
              <ion-input v-model="editDestination.email" @ionChange="dirty = true"></ion-input>
            </ion-item>
          </div>
        </div>

        <div class="grow"></div>
      </ion-content>
    </ion-modal> -->

    <ion-modal :is-open="infoVisible" @didDismiss="infoVisible = false">
      <ion-header>
        <ion-toolbar class="flex centered vertical">
          <ion-title>{{ t("decision_tree") }}</ion-title>
          <ion-icon :icon="closeCircle" slot="end" @click="infoVisible = false" class="mr" size="large" />
        </ion-toolbar>
      </ion-header>
      <ion-content class="p" v-if="decisionTree">
        <ion-item>
          <ion-input :label="t('name')" label-placement="stacked" v-model="decisionTree.title" @ionInput="dirty = true"></ion-input>
        </ion-item>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
import { watch, ref, computed } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { getLines, toast } from "@/composables/utilities";
import { closeCircle, informationCircle, create, search, documentOutline } from "ionicons/icons";
import { isPlatform, menuController, onIonViewDidEnter, onIonViewWillEnter, useIonRouter } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { objectToArray, sortObjectByProperty } from "@/composables/utilities";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const destinationWidth = 200;
    const outcomeWidth = 150;
    const outcomeHeight = 20;
    const iconSize = 20;
    const smallIconSize = 15;
    const cornerRadius = 5;
    const padding = 10;
    const canvas = ref();
    const container = ref();
    const { t } = useI18n();

    let dragStart;
    let dragDestination;
    let newOutcome;
    let destinations = [];
    let outcomes = [];
    let c;
    let backup;

    const editDestination = ref();
    const editOutcome = ref();
    const deletedDestinationIDs = [];
    const deletedOutcomeIDs = [];

    const destinationVisible = ref(false);
    const newDestinationVisible = ref(false);
    const outcomeVisible = ref(false);
    const infoVisible = ref(false);
    const searchTerm = ref("");
    const dirty = ref(false);
    const documentUploader = ref();

    const deleteIcon = new Image();
    const addIcon = new Image();
    const editIcon = new Image();

    deleteIcon.src = "/img/ui/delete.svg";
    addIcon.src = "/img/ui/add.svg";
    editIcon.src = "/img/ui/edit.svg";

    const route = useRoute();
    const router = useRouter();
    const decisionTreeID = route.params.decisionTreeID;
    const organisationID = route.params.organisationID;

    const theme = computed(() => organisation.value?.theme);

    const popoverOptions = {
      cssClass: "popover",
    };

    const model = ref();
    const manufacturer = ref();
    const assetType = ref();

    const sortedModels = computed(() => {
      const arr = objectToArray(models.value);
      return arr
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
        .filter((item) => item.manufacturerID == manufacturer.value && item.assetTypeID == assetType.value);
    });

    const sortedManufacturers = computed(() => sortObjectByProperty(manufacturers.value, "name", true));
    const sortedAssetTypes = computed(() => sortObjectByProperty(assetTypes.value, "name", true));

    // const sortedModels = computed(() =>
    //   models.value?.sort((a, b) => {
    //     if (a.name < b.name) return -1;
    //     if (a.name > b.name) return 1;
    //     return 0;
    //   })
    // );
    // const sortedManufacturers = computed(() =>
    //   manufacturers.value?.sort((a, b) => {
    //     if (a.name < b.name) return -1;
    //     if (a.name > b.name) return 1;
    //     return 0;
    //   })
    // );
    // const sortedAssetTypes = computed(() =>
    //   assetTypes.value?.sort((a, b) => {
    //     if (a.name < b.name) return -1;
    //     if (a.name > b.name) return 1;
    //     return 0;
    //   })
    // );

    const organisationStore = useOrganisationStore();
    const { organisation, manufacturers, models, assetTypes } = storeToRefs(organisationStore);

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID);
    });

    const decisionTree = computed(() => organisation.value.decisionTrees[decisionTreeID]);

    onIonViewDidEnter(() => {
      onResize();
    });

    const filteredArticles = computed(() => {
      const articles = objectToArray(organisation.value.articles);
      return articles.filter((a) => a.title?.toLowerCase().indexOf(searchTerm.value.toLowerCase()) !== -1);
    });

    const filteredVideos = computed(() => {
      const videos = objectToArray(organisation.value.videos);
      return videos.filter((a) => a.title?.toLowerCase().indexOf(searchTerm.value.toLowerCase()) !== -1);
    });

    class Destination {
      constructor(id, title, type, x, y, phone, email, outcomeIDs, destinationID, filename, path) {
        this.id = id;
        this.title = title;
        this.type = type;
        this.x = x;
        this.y = y;
        this.phone = phone;
        this.email = email;
        this.outcomeIDs = outcomeIDs || [];
        this.hover = false;
        this.destinationID = destinationID;
        this.filename = filename;
        this.path = path;
      }

      get lines() {
        return getLines(c, this.title, destinationWidth - 2 * padding);
      }

      get lineHeight() {
        return 15;
      }

      get totalHeight() {
        return this.lines.length * this.lineHeight + 2 * padding;
      }

      get bottom() {
        return this.y + this.totalHeight;
      }

      get right() {
        return this.x + destinationWidth;
      }

      get center() {
        return {
          x: this.x + destinationWidth / 2,
          y: this.y + this.totalHeight / 2,
        };
      }

      get connectors() {
        const bottomCenter = {
          x: this.center.x,
          y: this.bottom,
        };
        const bottomLeft = {
          x: this.x,
          y: this.bottom,
        };
        const topCenter = {
          x: this.center.x,
          y: this.y,
        };
        const centerRight = {
          x: this.right,
          y: this.center.y,
        };
        const centerLeft = {
          x: this.x,
          y: this.center.y,
        };
        return {
          bottomCenter: bottomCenter,
          bottomLeft: bottomLeft,
          topCenter: topCenter,
          centerRight: centerRight,
          centerLeft: centerLeft,
        };
      }

      draw() {
        let color = "orange";
        let contrast = "black";

        // Only drawe object if it's within the bounds of the canvas
        if (this.x > canvas.value.width || this.x < -destinationWidth || this.y < -this.totalHeight || this.y > canvas.value.height) return;

        switch (this.type) {
          case "Question":
            color = theme.value?.primary?.colour || "green";
            contrast = theme.value?.primary?.contrast || "white";
            break;
          case "Video":
            color = theme.value?.primary?.colour || "white";
            contrast = theme.value?.primary?.contrast || "black";
            break;
          default:
            break;
        }

        drawBox(this.x, this.y, destinationWidth, this.lineHeight, this.lines, 2, true, color, color, contrast, this.lineHeight);

        if (this.hover) {
          c.drawImage(deleteIcon, this.x - iconSize / 3, this.y - iconSize / 3, iconSize, iconSize);
          c.drawImage(
            editIcon,
            this.connectors.bottomLeft.x - iconSize / 3,
            this.connectors.bottomLeft.y - (iconSize * 2) / 3,
            iconSize,
            iconSize
          );
        }

        if (this.hover && this.type == "Question")
          c.drawImage(
            addIcon,
            this.connectors.centerRight.x - iconSize / 2,
            this.connectors.centerRight.y - iconSize / 2,
            iconSize,
            iconSize
          );
      }

      checkSelected(x, y) {
        if (x > this.x - iconSize / 3 && x < this.x + (iconSize * 2) / 3 && y > this.y - iconSize / 3 && y < this.y + (iconSize * 2) / 3)
          return "delete";
        if (
          x > this.connectors.centerRight.x - iconSize / 2 &&
          x < this.connectors.centerRight.x + iconSize / 2 &&
          y > this.connectors.centerRight.y - iconSize / 2 &&
          y < this.connectors.centerRight.y + iconSize / 2
        )
          return "add";
        if (
          x > this.connectors.bottomLeft.x - iconSize / 3 &&
          x < this.connectors.bottomLeft.x + (iconSize * 2) / 3 &&
          y > this.connectors.bottomLeft.y - (iconSize * 2) / 3 &&
          y < this.connectors.bottomLeft.y + iconSize / 3
        )
          return "edit";
        if (x > this.x && x < this.right && y > this.y && y < this.bottom) return "click";
        return null;
      }

      static getByID(id) {
        return destinations.find((d) => d.id == id);
      }

      delete() {
        if (destinations.length == 1) {
          toast("At least one destination must exist");
          return;
        }

        const destinationIndex = destinations.findIndex((d) => d.id == this.id);
        destinations.splice(destinationIndex, 1);
        deletedDestinationIDs.push(this.id);

        const linkedOutcomes = outcomes.filter((o) => o.child.id == this.id || o.parent.id == this.id);
        linkedOutcomes.forEach((outcome) => outcome.delete());
        renderChart();
      }
    }

    class Outcome {
      constructor(id, parent, label, child, x, y, index) {
        this.id = id;
        this.parent = parent;
        this.child = child;
        this.label = label;
        this.x = x;
        this.y = y;
        this.index = index;
        this.hover = false;
      }

      get lines() {
        return getLines(c, this.label, outcomeWidth);
      }

      get lineHeight() {
        return 12;
      }

      get x1() {
        return this.parent?.connectors.bottomCenter.x;
      }

      get x2() {
        return this.child?.connectors.topCenter.x;
      }

      get y1() {
        return this.parent?.connectors.bottomCenter.y;
      }

      get y2() {
        return this.child?.connectors.topCenter.y;
      }

      get labelX() {
        return this.x1 + (this.x2 - this.x1 - outcomeWidth) / 2;
      }

      get labelY() {
        return this.y1 + (this.y2 - this.y1 - outcomeHeight) / 2;
      }

      drawConnector() {
        if (!this.parent && this.child) return;
        c.beginPath();
        // horizontal
        const x1 = this.parent.connectors.centerRight.x;
        const y1 = this.parent.connectors.centerRight.y;
        const x2 = this.x || this.child.connectors.centerLeft.x;
        const y2 = this.y || this.child.connectors.centerLeft.y;
        c.moveTo(x1, y1);
        c.bezierCurveTo(x2 - (x2 - x1) / 2, y1, x1 + (x2 - x1) / 2, y2, x2, y2);
        // vertical;
        // const x1 = this.parent?.connectors.bottomCenter.x;
        // const y1 = this.parent?.connectors.bottomCenter.y;
        // const x2 = this.x || this.child?.connectors.topCenter.x;
        // const y2 = this.y || this.child?.connectors.topCenter.y;
        // c.moveTo(x1, y1);
        // c.bezierCurveTo(x1, y2 - (y2 - y1) / 2, x2, y1 + (y2 - y1) / 2, x2, y2);

        c.lineWidth = 2;
        c.strokeStyle = "black";
        c.setLineDash([5, 2]);
        c.stroke();
        c.setLineDash([]);
      }

      drawLabel() {
        drawBox(
          this.labelX,
          this.labelY,
          outcomeWidth,
          this.lineHeight,
          this.lines,
          2,
          false,
          theme.value?.light?.color || "white",
          theme.value?.dark?.color || "black",
          theme.value?.light?.contrast || "#000000",
          this.lineHeight
        );
        // if (this.hover)
        //   c.drawImage(deleteIcon, this.labelX - smallIconSize / 3, this.labelY - smallIconSize / 3, smallIconSize, smallIconSize);
      }

      checkSelected(x, y) {
        if (
          x > this.labelX - iconSize / 3 &&
          x < this.labelX + (iconSize * 2) / 3 &&
          y > this.labelY - iconSize / 3 &&
          y < this.labelY + (iconSize * 2) / 3
        )
          return "delete";
        if (
          x > this.labelX &&
          x < this.labelX + destinationWidth &&
          y > this.labelY &&
          y < this.labelY + this.lines.length * this.lineHeight + 2 * padding
        )
          return "click";
        return null;
      }

      delete() {
        deletedOutcomeIDs.push(this.id);

        const linkedDestinations = destinations.filter((d) => d.outcomeIDs.includes(this.id));
        linkedDestinations.forEach((d) => {
          d.outcomeIDs.splice(
            d.outcomeIDs.findIndex((o) => o == this.id),
            1
          );
        });

        const outcomeIndex = outcomes.findIndex((o) => o.id == this.id);
        outcomes.splice(outcomeIndex, 1);

        renderChart();
      }
    }

    const initialiseData = async () => {
      console.log(decisionTree.value);
      backup = JSON.parse(JSON.stringify(decisionTree.value));
      console.log("BACKUP created", backup);
      // Create objects from data and push to array
      const mappedDestinations = objectToArray(decisionTree.value?.destinations);
      const mappedOutcomes = objectToArray(decisionTree.value?.outcomes);

      for (const destination of mappedDestinations) {
        const newDestination = new Destination(
          destination.id,
          destination.title,
          destination.type,
          destination.x,
          destination.y,
          destination.phone,
          destination.email,
          destination.outcomeIDs,
          destination.destinationID,
          destination.filename,
          destination.path
        );
        destinations.push(newDestination);
      }

      if (decisionTree.value.destinations.length == 0) {
        const newDestination = new Destination(crypto.randomUUID(), "First Question", "Question", 50, 50, [], null);
        destinations.push(newDestination);
        decisionTree.value.rootID = newDestination.id;
      }

      for (const outcome of mappedOutcomes) {
        const parent = Destination.getByID(outcome.parentID);
        const child = Destination.getByID(outcome.childID);
        const newOutcome = new Outcome(outcome.id, parent, outcome.label, child, null, null, outcome.index);
        outcomes.push(newOutcome);
      }

      renderChart();
    };

    const renderChart = () => {
      if (!c) return;
      c.clearRect(0, 0, canvas.value.width, canvas.value.height);

      c.fillStyle = "white";
      c.rect(0, 0, canvas.value.width, canvas.value.height);
      c.fill();

      outcomes.forEach((o) => {
        o.drawConnector();
      });

      outcomes.forEach((o) => {
        o.drawLabel();
      });

      destinations.forEach((d) => {
        d.draw();
      });

      if (newOutcome) {
        newOutcome.drawConnector();
      }
    };

    const onMouseDown = (e) => {
      const mobile = isPlatform("mobile");
      const event = mobile ? e.targetTouches[0] : e;
      const clientX = event.clientX;
      const clientY = event.clientY;
      const top = event.target.getBoundingClientRect().top;
      const left = event.target.getBoundingClientRect().left;
      const x = clientX - left;
      const y = clientY - top;
      const snap = 50;
      const snap_x = Math.round(x / snap) * snap;
      const snap_y = Math.round(y / snap) * snap;
      destinations.forEach((destination) => {
        if (destination.checkSelected(x, y) == "click") {
          dragDestination = destination;
        }
      });
      dragStart = { x: snap_x, y: snap_y };
    };

    const onMouseUp = () => {
      dragStart = null;
      if (!dragDestination) return;
      const snap = 50;
      dragDestination.x = Math.round(dragDestination.x / snap) * snap;
      dragDestination.y = Math.round(dragDestination.y / snap) * snap;
      renderChart();
      dragDestination = null;
    };

    const onClick = async (e) => {
      const top = e.target.getBoundingClientRect().top;
      const left = e.target.getBoundingClientRect().left;
      const x = e.clientX - left;
      const y = e.clientY - top;

      // Used to flag whether the event has been handled to prevent multiple actions
      let eventHandled = false;

      /* Iterate through all destinations to check if an event has occurred
      within the bounds of that object or its buttons */
      destinations.forEach(async (destination) => {
        const action = destination.checkSelected(x, y);

        // Destination - Delete button has been clicked
        if (action == "delete") {
          eventHandled = true;
          destination.delete();
          dirty.value = true;
          return;
        }

        // Destination - Add button has been clicked
        if (action == "add" && destination.type == "Question") {
          eventHandled = true;
          newOutcome = new Outcome(crypto.randomUUID(), destination, "Answer", null, x, y, 0);
          destination.outcomeIDs.push(newOutcome.id);

          dirty.value = true;
          return;
        }

        if (action == "edit") {
          eventHandled = true;
          editDestination.value = destination;
          // showMenu("destinationMenu");
          destinationVisible.value = true;
        }

        if (action == "click" && newOutcome) {
          eventHandled = true;

          // Destination - Existing destination has been clicked
          if (
            outcomes.some((o) => {
              return o.parent.id == newOutcome.parent.id && o.child.id == destination.id;
            })
          ) {
            newOutcome.parent.outcomeIDs.splice(
              newOutcome.parent.outcomeIDs.findIndex((o) => o.id == newOutcome.id),
              1
            );
            newOutcome = null;
            renderChart();
            return;
          }

          newOutcome.child = destination;
          newOutcome.x = null;
          newOutcome.y = null;
          outcomes.push(newOutcome);
          editOutcome.value = outcomes[outcomes.length - 1];
          outcomeVisible.value = true;
          // showMenu("outcomeMenu");
          newOutcome = null;
          dirty.value = true;
          renderChart();
          return;
        }

        if (action == "click") {
          console.log();
        }
      });

      if (eventHandled) return;

      /* Iterate through all destinations to check if an event has occurred
      within the bounds of that object or its buttons */
      outcomes.forEach((outcome) => {
        const action = outcome.checkSelected(x, y);

        // Outcome - delete button has been clicked
        if (action == "delete") {
          eventHandled = true;
          outcome.delete();
        }

        // Outcome - body has been clicked
        if (action == "click") {
          eventHandled = true;
          editOutcome.value = outcome;
          outcomeVisible.value = true;
          // showMenu("outcomeMenu");
        }
      });

      if (eventHandled) return;

      /* If click has occurred outside all destinations and outcomes, and there is a newOutcome active,
      create a new destination and make it the target of the new outcome */
      if (newOutcome) {
        // Create Destination object and push it to the destinations array
        const newDestination = new Destination(
          crypto.randomUUID(),
          "New question",
          "Question",
          x - destinationWidth / 2,
          y,
          null,
          null,
          []
        );
        destinations.push(newDestination);

        //Make the new destination the target of the active outcome
        newOutcome.x = null;
        newOutcome.y = null;
        newOutcome.child = newDestination;
        outcomes.push(newOutcome);
        newOutcome = null;
        renderChart();
        editDestination.value = destinations[destinations.length - 1];
        editOutcome.value = outcomes[outcomes.length - 1];
        destinationVisible.value = true;
        // showMenu("newDestinationMenu");
        dirty.value = true;
      }
    };

    const onDrag = (e) => {
      const mobile = isPlatform("mobile");
      const event = mobile ? e.targetTouches[0] : e;
      const clientX = event.clientX;
      const clientY = event.clientY;
      const top = event.target.getBoundingClientRect().top;
      const left = event.target.getBoundingClientRect().left;
      const x = clientX - left;
      const y = clientY - top;
      const snap = 50;
      const snap_x = Math.round(x / snap) * snap;
      const snap_y = Math.round(y / snap) * snap;

      //If user is already creating a new outcome, update the connector line
      if (newOutcome) {
        newOutcome.x = x;
        newOutcome.y = y;
        renderChart();
        return;
      }
      //If mouse has gone outside the canvas, stop dragging
      if (x < 0 || y < 0) dragDestination = null;

      //If user is dragging an object, move the object
      if (dragDestination) {
        dragDestination.x += x - dragStart.x;
        dragDestination.y += y - dragStart.y;
      } else {
        // Otherwise, iterate through destinations and see if mouse is interacting with any
        for (const destination of destinations) {
          destination.hover = destination.checkSelected(x, y);
          if (dragStart) {
            destination.x += x - dragStart.x;
            destination.y += y - dragStart.y;
          }
        }
        // Also iterate through outcomes and see if mouse is interacting with any
        for (const outcome of outcomes) {
          outcome.hover = outcome.checkSelected(x, y);
        }
      }

      // If drag is in progress, update start coordinates
      if (dragStart) dragStart = { x: x, y: y };

      renderChart();
    };

    const onResize = () => {
      if (canvas.value && container.value) {
        canvas.value.height = container.value.clientHeight - 20;
        canvas.value.width = container.value.clientWidth - 20;
        renderChart();
      }
    };

    const setShadow = (visible) => {
      c.shadowColor = "#000000";
      c.shadowBlur = visible ? 4 : 0;
      c.shadowOffsetX = visible ? 4 : 0;
      c.shadowOffsetY = visible ? 4 : 0;
    };

    watch([() => decisionTree, container, canvas], () => {
      if (!(decisionTree.value && !!container.value && !!canvas.value)) return;
      setTimeout(() => {
        canvas.value.width = container.value.clientWidth - 20;
        canvas.value.height = container.value.clientHeight - 20;
        c = canvas.value.getContext("2d");
        canvas.value.addEventListener("mousedown", onMouseDown);
        canvas.value.addEventListener("mouseup", onMouseUp);
        canvas.value.addEventListener("mousemove", onDrag);
        canvas.value.addEventListener("touchstart", onMouseDown);
        canvas.value.addEventListener("touchend", onMouseUp);
        canvas.value.addEventListener("touchmove", onDrag);
        canvas.value.addEventListener("click", onClick);
        window.addEventListener("focus", onResize);
        window.addEventListener("resize", onResize);
        initialiseData();
      }, 1);
    });

    const drawBox = (
      x,
      y,
      width,
      lineHeight,
      text,
      lineWidth = 2,
      shadow = false,
      fillStyle = "#ffffff",
      strokeStyle = "#000000",
      textStyle = "#000000",
      textSize = 10
    ) => {
      const height = text.length * lineHeight + 2 * padding;
      c.beginPath();
      c.moveTo(x + cornerRadius, y);
      c.lineTo(x + width - cornerRadius, y);
      c.arc(x + width - cornerRadius, y + cornerRadius, cornerRadius, -Math.PI / 2, 0, false);
      c.lineTo(x + width, y + height - cornerRadius);
      c.arc(x + width - cornerRadius, y + height - cornerRadius, cornerRadius, 0, Math.PI / 2, false);
      c.lineTo(x + cornerRadius, y + height);
      c.arc(x + cornerRadius, y + height - cornerRadius, cornerRadius, Math.PI / 2, Math.PI, false);
      c.lineTo(x, y + cornerRadius);
      c.arc(x + cornerRadius, y + cornerRadius, cornerRadius, Math.PI, (Math.PI * 3) / 2, false);
      c.closePath();
      c.strokeStyle = strokeStyle;
      c.lineWidth = lineWidth;
      c.stroke();

      c.fillStyle = fillStyle;
      c.shadowColor = "#000000";
      setShadow(shadow);
      c.fill();
      setShadow(false);
      c.shadowBlur = 0;
      c.shadowOffsetX = 0;
      c.shadowOffsetY = 0;
      c.textBaseline = "top";
      c.textAlign = "center";
      c.font = `${textSize - 3}px Regular`;
      c.fillStyle = textStyle;
      for (let i = 0; i < text.length; i++) {
        c.fillText(text[i], x + width / 2, y + padding + lineHeight * i, width - 2 * padding);
      }
    };

    const save = async () => {
      delete decisionTree.value.destinations;
      delete decisionTree.value.outcomes;

      const destinationData = destinations.map((d) => {
        return {
          id: d.id,
          title: d.title,
          type: d.type,
          phone: d.phone || "",
          email: d.email || "",
          path: d.path || "",
          filename: d.filename || "",
          x: d.x,
          y: d.y,
          destinationID: d.destinationID || null,
          outcomeIDs: d.outcomeIDs,
        };
      });

      const outcomeData = outcomes.map((o) => {
        return {
          id: o.id,
          label: o.label,
          parentID: o.parent?.id,
          childID: o.child?.id,
          index: o.index || 0,
        };
      });

      deletedDestinationIDs.forEach((id) => {
        organisationStore.deleteRecord(`organisations/${organisationID}/decisionTrees/${decisionTreeID}/destinations`, id);
      });

      deletedOutcomeIDs.forEach((id) => {
        organisationStore.deleteRecord(`organisations/${organisationID}/decisionTrees/${decisionTreeID}/outcomes`, id);
      });

      destinationData.forEach((d) => {
        organisationStore.saveRecord(`organisations/${organisationID}/decisionTrees/${decisionTreeID}/destinations`, d.id, d);
      });

      outcomeData.forEach((o) => {
        organisationStore.saveRecord(`organisations/${organisationID}/decisionTrees/${decisionTreeID}/outcomes`, o.id, o);
      });

      organisationStore.saveRecord(`organisations/${organisationID}/decisionTrees`, decisionTreeID, {
        rootID: decisionTree.value.rootID,
        title: decisionTree.value.title,
      });

      dirty.value = false;
    };

    const cancel = async () => {
      organisation.value.decisionTrees[decisionTree.value.id] = JSON.parse(JSON.stringify(backup));
      router.back();
    };

    const editArticle = async (_articleID) => {
      let articleID = _articleID;
      if (articleID == "new") {
        articleID = await organisationStore.addRecord(`organisations/${organisationID}/articles`, {
          title: "Article",
          subtitle: "",
          html: "",
        });
        editDestination.value.destinationID = articleID;
        articleSelected();
      }
      destinationVisible.value = false;
      // newDestinationVisible.value = false;
      outcomeVisible.value = false;
      router.push({
        name: "EditOrganisationArticle",
        params: { organisationID: organisationID, articleID: articleID },
      });
    };

    const editVideo = async (_videoID) => {
      let videoID = _videoID;
      if (videoID == "new") {
        videoID = await organisationStore.addRecord(`organisations/${organisationID}/videos`, { title: "A Video" });
        editDestination.value.destinationID = videoID;
        videoSelected();
      }
      destinationVisible.value = false;
      // newDestinationVisible.value = false;
      outcomeVisible.value = false;

      router.push({
        name: "EditOrganisationVideo",
        params: { organisationID: organisationID, videoID: videoID },
      });
    };

    const showMenu = (menu) => {
      menuController.enable(true, menu);
      menuController.open(menu);
    };

    const closeMenu = (menu) => {
      menuController.enable(false, menu);
    };

    const articleSelected = () => {
      dirty.value = true;
      editDestination.value.title = organisation.value.articles[editDestination.value.destinationID].title;
    };

    const documentSelected = () => {
      dirty.value = true;
      editDestination.value.title = editDestination.value?.destinationID?.description;
    };

    const videoSelected = () => {
      dirty.value = true;
      editDestination.value.title = organisation.value.videos[editDestination.value.destinationID].title;
    };

    const debug = (o) => {
      console.log(o);
    };

    const documentUploaded = async () => {
      const file = documentUploader.value.files[0];
      editDestination.value.title = file.name;
      const newDocument = await organisationStore.addTreeDocument(file);
      editDestination.value.path = newDocument.path.fullPath;
      editDestination.value.filename = newDocument.path.name;
      dirty.value = true;
      documentUploader.value.value = "";
    };

    const deleteDocument = async () => {
      try {
        decisionTree.value.documents = decisionTree.value.documents?.filter((d) => d.path != editDestination.value.path);
        await organisationStore.deleteFile(editDestination.value.path);
        editDestination.value.filename = "";
        editDestination.value.path = "";
        await save();
      } catch (error) {
        console.log(error);
      }
    };

    return {
      debug,
      canvas,
      container,
      filteredArticles,
      filteredVideos,
      dirty,
      decisionTree,
      searchTerm,
      manufacturers,
      models,
      assetTypes,
      popoverOptions,
      model,
      manufacturer,
      assetType,
      sortedAssetTypes,
      sortedManufacturers,
      sortedModels,
      documentUploader,
      save,
      cancel,
      editArticle,
      editVideo,
      articleSelected,
      documentSelected,
      documentUploaded,
      videoSelected,
      editDestination,
      editOutcome,
      closeMenu,
      showMenu,
      deleteDocument,
      t,
      outcomeVisible,
      // newDestinationVisible,
      destinationVisible,
      infoVisible,
      closeCircle,
      informationCircle,
      create,
      search,
      documentOutline,
    };
  },
};
</script>

<style scoped>
.scroll {
  height: 100%;
}
.canvas {
  background: var(--ion-color-light);
  border: 1px solid var(--ion-color-dark-contrast);

  margin: 10px;
}

#pageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#container {
  flex-grow: 1;
  overflow: hidden;
}

ion-footer {
  display: flex;
  justify-content: center;
  padding: 20px;
}
</style>
