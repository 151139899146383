<template>
  <ion-page>
    <av-header />

    <ion-content :scroll-y="false" v-if="space">
      <div class="flex vertical bgd">
        <view-header @back="back" :title="space.name" :subtitle="$t('qr_code')" edit="true" />
        <div v-html="qr?.display" class="contain tc p2 m2 grow" @click="downloadQR" />
        <canvas ref="canvas" width="100" height="100" hidden></canvas>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getQR } from "@/composables/qr";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";
import Jimp from "jimp";
import { Canvg } from "canvg";
import floydSteinberg from "floyd-steinberg";
import bmpJS from "bmp-js";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export default {
  name: "QR",
  setup() {
    const organisationID = useRoute().params.organisationID;
    const locationID = useRoute().params.locationID;
    const floorID = useRoute().params.floorID;
    const spaceID = useRoute().params.spaceID;
    const canvas = ref();
    let bmp;
    let link;

    const organisationStore = useOrganisationStore();
    const { organisation, location, floor, space } = storeToRefs(organisationStore);

    const downloadLink = computed(
      () => "data:image/svg+xml;charset=utf-8," + encodeURIComponent(isProduction ? qr.value.download : qr.value.downloadDev)
    );
    const isProduction = process.env.VUE_APP_MODE == "production";
    const qr = computed(() => getQR(`${process.env.VUE_APP_DOMAIN}/qr/${space.value?.shortcode}`));

    const router = useRouter();

    const downloadQR = () => {
      link.href = downloadLink.value;
      link.download = `${space.value?.name}.svg`;
      link.click();
    };

    onMounted(() => {
      link = document.createElement("a");
    });

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
    });

    const back = () => {
      router.back();
    };

    const getBMP = async (width, height) => {
      canvas.value.height = height;
      canvas.value.width = width;
      const ctx = canvas.value?.getContext("2d");

      const cvsBarcode = new OffscreenCanvas(width / 4, width / 4);
      const ctxBarcode = cvsBarcode.getContext("2d");
      const svgBarcode = Canvg.fromString(ctxBarcode, qr.value?.display);
      await svgBarcode.start();

      const cvsComposite = new OffscreenCanvas(width, height);
      const ctxComposite = cvsComposite.getContext("2d");
      ctxComposite.drawImage(cvsBarcode, 20, 50);

      const blbComposite = await cvsComposite.convertToBlob();
      const pngComposite = URL.createObjectURL(blbComposite);

      ctx.drawImage(cvsComposite, 0, 0);

      const image = await Jimp.read(pngComposite);
      await image.background(0xffffffff);

      image.bitmap = await floydSteinberg(image.bitmap);
      const buffer = await image.getBufferAsync(Jimp.MIME_BMP);

      const rawData = {
        data: buffer,
        width: 300,
        height: 300,
        bitPP: 1,
      };
      const bmpData = bmpJS.encode(rawData);
      exportFiles(bmpData.data, width, height);

      link.href = pngComposite;
      link.download = `${space.value?.name}_eink`;
      link.click();
    };

    const exportFiles = async (bmp, w, h) => {
      const zip = new JSZip();
      zip.file(`${w}x${h}.bmp`, bmp, { binary: true });

      const content = await zip.generateAsync({ type: "blob" });

      const zipName = "EInk Files";
      saveAs(content, zipName);
    };

    const downloadEInk = async () => {
      //await getBMP(800, 480, "large");
      //await getBMP(250, 122, "small");
      await getBMP(400, 300, "medium");
    };

    return {
      // variables
      downloadLink,
      organisation,
      space,
      qr,
      canvas,
      // methods
      downloadQR,
      downloadEInk,
      back,
    };
  },
};
</script>

<style scoped>
.test svg {
  width: 50px;
}
</style>
