// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bg[data-v-2dfbc3bf] {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}
.bg img[data-v-2dfbc3bf] {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}
.card[data-v-2dfbc3bf] {
  background-color: var(--ion-color-light);
}
.information[data-v-2dfbc3bf] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.1);
}
.information.documents[data-v-2dfbc3bf] {
  font-family: Title;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
