<template>
  <ion-page>
    <av-header />

    <ion-content :scroll-y="false" v-if="organisation">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="organisation.name" :subtitle="t('edit_decision_trees')" edit="true" />
        <ion-list class="fullwidth p" ref="list">
          <ion-item-sliding v-for="tree in organisation?.decisionTrees" :key="tree.id">
            <ion-item>
              <ion-label color="secondary" @click="editTree(tree.id)">{{ tree.title }}</ion-label>
            </ion-item>
            <ion-item-options>
              <ion-item-option @click="clone(tree, $event)"><ion-icon :icon="copyOutline" color="warning" /></ion-item-option>
              <ion-item-option @click="deleteTree(tree, $event)"><ion-icon :icon="trashOutline" color="danger" /> </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </ion-list>

        <div class="grow"></div>
        <div class="fullwidth ph2 mb2">
          <ion-button @click="addTree" class="bright mb2" expand="block">{{ t("add_decision_tree") }}</ion-button>
        </div>
        <!-- <nav-footer :label="organisation.name" @back="cancel" /> -->
      </div>
    </ion-content></ion-page
  >
</template>

<script setup>
import { useOrganisationStore } from "@/stores/organisation";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import { trashOutline, copyOutline } from "ionicons/icons";
import { ref } from "vue";

const router = useRouter();
const route = useRoute();
const organisationStore = useOrganisationStore();
const organisationData = organisationStore.data;
const organisationID = route.params.organisationID;
const { organisation } = storeToRefs(organisationStore);
const list = ref();
const { t } = useI18n();

onIonViewWillEnter(() => {
  organisationStore.initialise(organisationID);
});

const addTree = async () => {
  const newTreeID = await organisationStore.addRecord(`organisations/${organisationID}/decisionTrees`, {
    title: "New Decision Tree",
  });
  const rootID = await organisationStore.addRecord(`organisations/${organisationID}/decisionTrees/${newTreeID}/destinations`, {
    title: "How can I help?",
    type: "Question",
    x: 50,
    y: 50,
    outcomeIDs: [],
  });
  await organisationStore.saveRecord(`organisations/${organisationID}/decisionTrees`, newTreeID, {
    title: "New Decision Tree",
    rootID,
  });
  editTree(newTreeID);
};

const editTree = (decisionTreeID) => {
  router.push({ name: "EditOrganisationDecisionTree", params: { organisationID: organisation.value.id, decisionTreeID } });
};

const deleteTree = (tree) => {
  organisationStore.deleteTree(organisationID, tree.id);
  list.value.$el.closeSlidingItems();
};

const clone = (tree) => {
  organisationStore.cloneTree(tree, organisationID);
  list.value.$el.closeSlidingItems();
};

const back = () => {
  router.back();
};
</script>

<style></style>
