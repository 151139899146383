import { toastController } from "@ionic/vue";

export const routeTo = (router, type, ids) => {
  const path = `/${type}/view/${ids.join("/")}`;
  router.push(path);
};

export const routeToEdit = (router, type, ids) => {
  const path = `/${type}/edit/${ids.join("/")}`;
  router.push(path);
};

export const routeToDelete = (router, type, ids) => {
  const path = `/${type}/delete/${ids.join("/")}`;
  router.push(path);
};

export const precacheImage = (url) => {
  if (!url) return;
  try {
    const img = new Image();
    img.src = url;
  } catch (error) {
    console.log("Could not precache image");
  }
};

export const boundValue = (value, min, max) => {
  return Math.max(min, Math.min(max, value));
};

export const reIndex = (array) => {
  //First sort in existing index order
  if (array && array.length > 0) {
    array.sort((a, b) => (a.index > b.index ? 1 : -1));
    //Then re-base from 0
    array.forEach((item, index) => {
      item.index = index;
    });
  }
};

export const formatBandwidth = (bandwidth) => {
  return bandwidth < 1000 ? `${bandwidth}MB` : `${bandwidth / 1000}GB`;
};

export const scrollTo = (page) => {
  page.scrollIntoView({
    block: "start",
    behavior: "smooth",
  });
};

export const toast = async (message, duration = 2000, icon) => {
  const toast = await toastController.create({
    message: message,
    duration: duration,
    cssClass: "toast",
    position: "top",
    icon: icon,
  });
  toast.present();
};

export const getAddress = (from, multiline = true) => {
  const addressComponents = [];
  const components = ["address1", "address2", "address3", "town", "county", "postcode"];

  components.forEach((component) => {
    if (component in from && from[component] != "") {
      addressComponents.push(from[component]);
    }
  });
  return addressComponents.join(multiline ? "<br />" : ", ");
};

export const formatDate = (date, style = "long") => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  switch (style) {
    case "short":
      return `${date.getDate()} ${shortMonths[date.getMonth()]} ${date.getFullYear()}`;
    default:
      return `${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  }
};

export const haversine = (lat1, lng1, lat2, lng2) => {
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lng2 - lng1) * Math.PI) / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres
  return d;
};

export const delay = (t) => {
  return new Promise((resolve) => setTimeout(resolve, t));
};

export const isEmailValid = (email) => {
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
    email?.toLowerCase()
  );
};

export const filterObject = (obj, predicate) => {
  if (!obj) return;
  return Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});
};

export const sortObjectByProperty = (obj, sortProperty, isString = false) => {
  const compareStrings = (_a, _b) => {
    const a = _a.toLowerCase();
    const b = _b.toLowerCase();
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  };

  return (
    obj &&
    Object.keys(obj)
      .map((key) => obj[key])
      .sort((a, b) => (isString ? compareStrings(a[sortProperty], b[sortProperty]) : a[sortProperty] - b[sortProperty]))
  );
};

export function getLines(ctx, text, maxWidth) {
  const workingText = text || "";
  const words = workingText.split(" ");
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    var word = words[i];
    var width = ctx.measureText(currentLine + " " + word).width;
    if (width < maxWidth) {
      currentLine += " " + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines;
}

export const objectToArray = (obj) => {
  if (!obj) return [];
  const keys = Object.keys(obj);
  const mapped = keys.map((k) => obj[k]);
  return mapped;
};

export const arrayToObject = (arr, keyField) => {
  const obj = {};
  for (const entry of arr) {
    obj[entry[keyField]] = entry;
  }
  return obj;
};

export const sortObject = (object, byField) => {
  if (!object) return {};
  const sortedArray = Object.entries(object).sort((a, b) => {
    if (a[1][byField].toLowerCase() > b[1][byField].toLowerCase()) return 1;
    if (a[1][byField].toLowerCase() < b[1][byField].toLowerCase()) return -1;
    return 0;
  });

  const obj = {};
  for (const entry of sortedArray) {
    obj[entry[0]] = entry[1];
  }
  return obj;
};

export const pSBC = (p, c0, c1, l) => {
  let r,
    g,
    b,
    P,
    f,
    t,
    h,
    m = Math.round,
    a = typeof c1 == "string";
  if (typeof p != "number" || p < -1 || p > 1 || typeof c0 != "string" || (c0[0] != "r" && c0[0] != "#") || (c1 && !a)) return null;
  (h = c0.length > 9),
    (h = a ? (c1.length > 9 ? true : c1 == "c" ? !h : false) : h),
    (f = pSBC.pSBCr(c0)),
    (P = p < 0),
    (t = c1 && c1 != "c" ? pSBC.pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }),
    (p = P ? p * -1 : p),
    (P = 1 - p);
  if (!f || !t) return null;
  if (l) (r = m(P * f.r + p * t.r)), (g = m(P * f.g + p * t.g)), (b = m(P * f.b + p * t.b));
  else
    (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
      (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
      (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
  (a = f.a), (t = t.a), (f = a >= 0 || t >= 0), (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
  if (h) return r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "");
  else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2);
};

pSBC.pSBCr = (d) => {
  const i = parseInt;
  let n = d.length,
    x = {};
  if (n > 9) {
    const [r, g, b, a] = (d = d.split(","));
    n = d.length;
    if (n < 3 || n > 4) return null;
    (x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4))), (x.g = i(g)), (x.b = i(b)), (x.a = a ? parseFloat(a) : -1);
  } else {
    if (n == 8 || n == 6 || n < 4) return null;
    if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
    d = i(d.slice(1), 16);
    if (n == 9 || n == 5)
      (x.r = (d >> 24) & 255), (x.g = (d >> 16) & 255), (x.b = (d >> 8) & 255), (x.a = Math.round((d & 255) / 0.255) / 1000);
    else (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
  }
  return x;
};
