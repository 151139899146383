<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd" slot="fixed" v-if="resolvedSpace">
        <view-header @back="back" :title="$t('room_equipment')" :subtitle="resolvedSpace?.name" class="fullwidth" />
        <div class="scroll pv fullwidth bgd">
          <div v-for="device in resolvedSpace?.devices" :key="device.id" class="flex ph2 pv bottomBorder" @click="showDevice(device)">
            <div>
              <div class="itemTitle">{{ getAssetType(device)?.name }}</div>
              <div class="boldFont fs o8">{{ getManufacturer(device)?.name }}</div>
            </div>
            <div>&gt;</div>
          </div>
        </div>
        <div class="grow"></div>
        <!-- <nav-footer label="Back" @back="goBack" /> -->
      </div>
      <wait v-else />
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";
import { computed } from "vue";

export default {
  setup() {
    const organisationStore = useOrganisationStore();
    const { space, models, assetTypes, manufacturers, anonymous } = storeToRefs(organisationStore);
    const route = useRoute();
    const router = useRouter();
    const spaceID = route.params.spaceID;
    const floorID = route.params.floorID;
    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
    });

    const resolvedSpace = computed(() => space.value || anonymous.value.space);
    const getModel = (device) => models.value && models.value[device.modelID];
    const getManufacturer = (hotspot) => manufacturers.value && manufacturers.value[getModel(hotspot)?.manufacturerID];
    const getAssetType = (hotspot) => assetTypes.value && assetTypes.value[getModel(hotspot)?.assetTypeID];

    const back = () => {
      router.back();
    };

    const showDevice = (device) => {
      router.push({ name: "Device", params: { organisationID, locationID, floorID, spaceID, deviceID: device.id } });
    };

    return {
      resolvedSpace,
      anonymous,
      back,
      getModel,
      getManufacturer,
      getAssetType,
      showDevice,
    };
  },
};
</script>

<style scoped>
.bg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}

.tight {
  line-height: 0.9em;
}

.footer {
  font-family: Title;
  color: var(--ion-color-secondary);
  font-size: 1.6em;
  text-transform: uppercase;
  background: var(--ion-color-light-tint);
}

.smallButton {
  height: 1.5rem;
  font-size: 0.7rem;
}

.errorCount {
  margin: -20px 0px;
}

.bottomBorder {
  border: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.1);
}

.itemTitle {
  font-family: Title;
  color: var(--ion-color-light);
  padding-bottom: 3px;
}
</style>
