<template>
  <div class="fullwidth footerBg">
    <div class="footer flex centered safe-bottom" @click="goBack">
      <span class="ft"><ion-icon :icon="caretBack" /></span>
      <div>{{ label }}</div>
    </div>
  </div>
</template>

<script>
import { caretBack } from "ionicons/icons";
export default {
  emits: ["back"],
  props: ["label"],
  setup(props, { emit }) {
    const goBack = () => {
      emit("back");
    };
    return { goBack, caretBack };
  },
};
</script>
