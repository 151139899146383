<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd" slot="fixed">
        <view-header @back="back" class="fullwidth" :title="location?.contact?.sos_label_active || 'Contacting SOS'" />
        <div class="scroll p fullwidth flex vertical left">
          <ion-input v-model="data.room" class="bdl" label="Room" label-placement="stacked"> </ion-input>
          <ion-input v-model="data.name" class="bdl" placeholder="Required" label="Name" label-placement="stacked" />
          <ion-input v-model="data.email" class="bdl" label="Email" label-placement="stacked" />
          <ion-input v-model="data.phone" class="bdl" label="Phone" label-placement="stacked" />

          <ion-textarea
            class="bdl"
            v-model="data.message"
            rows="20"
            :auto-grow="true"
            label="Your message"
            label-placement="stacked"
            placeholder="Please enter details of the issue you're experiencing."
          ></ion-textarea>
        </div>
        <div class="grow"></div>
        <div class="fullwidth">
          <ion-button class="m2 bright" expand="block" @click="sendEmail()" :disabled="!data.name">Send Email</ion-button>
        </div>
        <!-- <nav-footer label="Back" @back="goBack" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";
import { reactive, watch } from "vue";
import { toast } from "@/composables/utilities";
import { runFunction } from "@/composables/firebase";
import { IonInput } from "@ionic/vue";

export default {
  props: ["title"],
  components: { IonInput },
  setup() {
    const organisationStore = useOrganisationStore();
    const userStore = useUserStore();
    const { profile } = storeToRefs(userStore);

    const { floor, location, organisation, space } = storeToRefs(organisationStore);
    const route = useRoute();
    const router = useRouter();
    const spaceID = route.params.spaceID;
    const floorID = route.params.floorID;
    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;

    const data = reactive({
      name: "",
      email: "",
      phone: "",
      message: "",
    });

    onIonViewWillEnter(async () => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
      data.room = space.value?.name;
      data.name = `${profile.value?.firstname || ""} ${profile.value?.lastname || ""}`;
      data.email = `${profile.value?.id || ""}`;
      data.phone = `${profile.value?.phone || ""}`;
      if (data.name == " ") data.name = "";
    });

    watch(space, () => {
      data.room = space.value?.name;
    });
    watch(profile, () => {
      data.name = `${profile.value?.firstname || ""} ${profile.value?.lastname || ""}`;
      data.email = `${profile.value?.id || ""}`;
      data.phone = `${profile.value?.phone || ""}`;
    });

    const back = () => {
      router.back();
    };

    const sendEmail = async () => {
      if (data.name == " ") data.name = "";
      data.subject = `Alpha Victor issue report`;
      data.space = space.value;
      data.floor = floor.value;
      data.location = location.value;
      data.organisation = organisation.value;
      runFunction("sendEmail", data);
      toast("Your email has been sent, thank you.");
      back();
    };

    return {
      data,
      space,
      back,
      sendEmail,
      location,
    };
  },
};
</script>

<style scoped>
ion-input,
ion-textarea {
  margin: 5px 0px;
  --padding-start: 10px;
}
</style>
