// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.tight[data-v-042dae5f] {
  line-height: 0.9em;
}
.footer[data-v-042dae5f] {
  font-family: Title;
  color: var(--ion-color-secondary);
  font-size: 1.6em;
  text-transform: uppercase;
  background: var(--ion-color-light-tint);
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
