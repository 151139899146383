<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd" slot="fixed" v-if="article">
        <view-header @back="back" :title="article.title" size="small" :subtitle="article.subtitle" class="fullwidth" />
        <div class="scroll p2 fullwidth flex vertical left autoheight cs">
          <span v-html="article.html"></span>
        </div>
        <div class="grow"></div>
      </div>
      <wait v-else />
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";
import mixpanel from "mixpanel-browser";
import { useUserStore } from "@/stores/user";

export default {
  setup() {
    const organisationStore = useOrganisationStore();
    const { organisation, location, floor, space, decisionTree, anonymous } = storeToRefs(organisationStore);

    const route = useRoute();
    const router = useRouter();
    const articleID = route.params.articleID;
    const organisationID = route.params.organisationID;
    const locationID = location.value?.id;
    const floorID = floor.value?.id;
    const spaceID = space.value?.id;

    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    const resolvedOrganisation = computed(() => organisation.value || anonymous.value.organisation);
    const article = computed(() => {
      return resolvedOrganisation.value?.articles?.[articleID];
    });

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID);
    });

    const back = () => {
      router.back();
    };

    const callSOS = async () => {
      mixpanel.track("DT Goal Reached", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        type: "SOS",
      });
      if (location.value.contact.sosUseEmail) {
        placeCall(location.value.contact?.sos);
      } else {
        sendEmail(location.value.contact?.sos_email);
      }
    };

    const placeCall = (phoneNumber) => {
      mixpanel.track("Phone Call Placed", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        number: phoneNumber,
      });
      mixpanel.track("DT Goal Reached", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        type: "Phone Call",
      });
      window.open(`tel:${phoneNumber}`, "_blank");
    };

    const sendEmail = (emailAddress) => {
      mixpanel.track("Email Sent", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        email: emailAddress,
      });
      mixpanel.track("DT Goal Reached", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        type: "Email",
      });
      router.push({ name: "Email", params: { organisationID, locationID, floorID, spaceID } });
    };

    return {
      article,
      location,
      back,
      callSOS,
      user,
    };
  },
};
</script>

<style scoped>
.footer {
  font-family: Title;
  color: var(--ion-color-secondary);
  font-size: 1.6em;
  text-transform: uppercase;
  background: var(--ion-color-light-tint);
}
</style>
