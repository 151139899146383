<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="space">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="space.name" :subtitle="t('edit_photos')" edit="true" />

        <div class="fullwidth ph pt scroll">
          <input type="file" @change="addPhoto" ref="photoSelector" multiple hidden />

          <ion-item lines="none" class="fullwidth"
            ><ion-toggle color="secondary" v-model="allowReorder">{{ t("reorder_photos") }}</ion-toggle>
          </ion-item>

          <ion-list class="fullwidth">
            <ion-reorder-group :disabled="!allowReorder" @ionItemReorder="reorderPhotos($event)">
              <ion-item v-for="photo in space.photos" :key="photo.id" lines="none">
                <ion-thumbnail slot="start" class="thumbnail">
                  <img :src="photo.url" :alt="photo.description" v-if="photo.url" />
                </ion-thumbnail>
                <ion-input color="secondary" v-model="photo.description" placeholder="Description" @ionInput="markDirty()"></ion-input>
                <ion-reorder slot="end"></ion-reorder>
                <ion-icon v-if="!allowReorder" :icon="trashOutline" slot="end" color="secondary" @click="deletePhoto(photo)" />
              </ion-item>
            </ion-reorder-group>
          </ion-list>
        </div>

        <div class="grow"></div>
        <div class="fullwidth">
          <ion-button expand="block" class="bright m2" @click="triggerAddPhoto">{{ t("add_photo") }}</ion-button>
        </div>
        <!-- <nav-footer :label="space.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { chevronForward, trashOutline } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;

    const organisationStore = useOrganisationStore();
    const { organisation, location, floor, space } = storeToRefs(organisationStore);
    const { t } = useI18n();

    const allowReorder = ref(false);
    const dirty = ref(false);
    const photoSelector = ref();

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
    });

    const save = async () => {
      organisationStore.saveRecord(
        `organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces`,
        space.value.id,
        space.value
      );
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
                router.back();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const deletePhoto = async (photo) => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "This photo and associated information will be deleted immediately.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              organisationStore.deleteSpacePhoto(photo);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ],
      });
      await alert.present();
    };

    const addPhoto = async () => {
      const files = Array.from(event.target.files);
      files.forEach(async (file) => {
        const newPhoto = await organisationStore.addSpacePhoto(file);
        photoSelector.value.value = "";
      });
    };

    const reorderPhotos = (e) => {
      const from = e.detail.from;
      const to = e.detail.to;
      space.value.photos = e.detail.complete(space.value.photos);
      for (const [i, item] of space.value.photos.entries()) {
        item.index = i;
      }
      save(false);
    };

    const go = (name) => {
      router.push({ name: name, params: { locationID } });
    };

    const triggerAddPhoto = () => {
      photoSelector.value.click();
    };

    return {
      // variables
      organisation,
      location,
      floor,
      space,
      allowReorder,
      photoSelector,
      //methods
      go,
      markDirty,
      back,
      addPhoto,
      reorderPhotos,
      deletePhoto,
      triggerAddPhoto,
      t,
      // icons
      chevronForward,
      trashOutline,
    };
  },
};
</script>
