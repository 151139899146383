<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd" slot="fixed">
        <view-header @back="back" title="Login" subtitle="Use Password" class="fullwidth" />

        <div style="max-width: min(90vw, 500px)" class="fullwidth">
          <ion-item lines="full">
            <ion-input color="secondary" v-model="email" type="email" label="Email" label-placement="floating"></ion-input>
            <ion-note color="warning">{{ emailFeedback }}</ion-note>
          </ion-item>

          <ion-item lines="full">
            <ion-input
              color="secondary"
              type="password"
              v-model="password"
              v-on:keyup.enter="login()"
              label="Password"
              label-placement="floating"
            ></ion-input>
            <ion-note color="warning">{{ passwordFeedback }}</ion-note>
          </ion-item>

          <div class="tc">
            <ion-button expand="block" class="bright mt2" @click="login()" id="login-button" :disabled="!canSubmit">Login</ion-button>
            <div class="fs cl mt2" @click="loginWithMagicLink()"><span class="cm">or</span> get magic link</div>
          </div>
        </div>

        <div class="fullwidth footerBg">
          <div class="footer flex safe-bottom">
            <ion-button expand="block" color="secondary" class="grow" router-direction="root" router-link="/register">
              <ion-label class="fs">Register</ion-label>
            </ion-button>
            <ion-button expand="block" color="secondary" class="grow" router-direction="root" router-link="/password-reminder"
              ><ion-label class="fs">Forgotten Password</ion-label></ion-button
            >
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { isPlatform } from "@ionic/vue";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Keyboard } from "@capacitor/keyboard";
import { isEmailValid } from "@/composables/utilities";
import { useUserStore } from "@/stores/user";

export default {
  setup() {
    const userStore = useUserStore();
    const userData = userStore.data;

    const router = useRouter();
    const route = useRoute();
    const email = ref(route.query?.email);
    const password = ref();
    const emailFeedback = ref();
    const passwordFeedback = ref();
    const isProduction = process.env.VUE_APP_MODE == "production";

    const canSubmit = computed(() => {
      return isEmailValid(email.value) && password.value?.length > 5;
    });

    const login = async () => {
      if (isPlatform("cordova")) Keyboard.hide();
      userStore
        .login(email.value, password.value)
        .then(() => {
          if (!userData.firebaseUser?.emailVerified) {
            router.push({ name: "VerifyEmail" });
          } else {
            router.push({ name: "Home" });
          }
          email.value = "";
          password.value = "";
        })
        .catch((errors) => {
          emailFeedback.value = errors.email;
          passwordFeedback.value = errors.password;
        });
    };

    const loginWithMagicLink = () => {
      router.push({
        name: "Login",
        query: { email: email.value },
      });
    };

    const back = () => {
      router.back();
    };

    return {
      email,
      password,
      emailFeedback,
      passwordFeedback,
      isProduction,
      canSubmit,
      back,
      login,
      loginWithMagicLink,
    };
  },
};
</script>

<style scoped>
#loginContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.formItems {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

#loginContainer ion-item,
#loginContainer ion-button {
  width: 100%;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: env(safe-area-inset-bottom);
}
.footerContainer ion-button {
  flex: 1 0 1px;
  font-size: 0.7em;
}
</style>
