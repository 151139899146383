<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical nogap bgd" slot="fixed">
        <view-header @back="back" title="Give Feedback" :subtitle="space?.name" class="fullwidth" />
        <div ref="typeform" id="typeform" class="grow fullwidth"></div>
        <!-- <nav-footer label="Back" @back="goBack" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";
import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";
import { ref, watch } from "vue";
import mixpanel from "mixpanel-browser";

export default {
  setup() {
    const organisationStore = useOrganisationStore();
    const userStore = useUserStore();
    const { floor, location, organisation, space } = storeToRefs(organisationStore);
    const { profile } = storeToRefs(userStore);
    const route = useRoute();
    const router = useRouter();
    const spaceID = route.params.spaceID;
    const floorID = route.params.floorID;
    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const typeform = ref();

    onIonViewWillEnter(async () => {
      await organisationStore.initialise(organisationID, locationID, floorID, spaceID);
      mixpanel.track("Feedback Started", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
      });
    });

    const setupForm = () => {
      if (!(space.value?.typeformID && typeform.value)) return;
      createWidget(space.value.typeformID, {
        container: typeform.value,
        inlineOnMobile: true,
        hidden: {
          firstname: profile.value?.firstname,
          lastname: profile.value?.lastname,
          room: space.value?.name,
        },
      });
    };

    watch([space, typeform], setupForm);
    setupForm();

    const back = () => {
      router.back();
    };

    return {
      space,
      floor,
      location,
      organisation,
      typeform,
      back,
    };
  },
};
</script>

<style scoped>
.tight {
  line-height: 0.9em;
}

.footer {
  font-family: Title;
  color: var(--ion-color-secondary);
  font-size: 1.6em;
  text-transform: uppercase;
  background: var(--ion-color-light-tint);
}
</style>
