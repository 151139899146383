// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  font-family: "Inter var", sans-serif;
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  font-variation-settings: normal;
  --font-family: "Inter var", sans-serif;
  --font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  --text-color: #ffffff;
  --text-color-secondary: #a1a1aa;
  --primary-color: #000000;
  --primary-color-text: #ffffff;
  --surface-0: #18181b;
  --surface-50: #27272a;
  --surface-100: #3f3f46;
  --surface-200: #52525b;
  --surface-300: #71717a;
  --surface-400: #a1a1aa;
  --surface-500: #d4d4d8;
  --surface-600: #e4e4e7;
  --surface-700: #f4f4f5;
  --surface-800: #fafafa;
  --surface-900: #ffffff;
  --gray-0: #ffffff;
  --gray-50: #fafafa;
  --gray-100: #f4f4f5;
  --gray-200: #e4e4e7;
  --gray-300: #d4d4d8;
  --gray-400: #a1a1aa;
  --gray-500: #71717a;
  --gray-600: #52525b;
  --gray-700: #3f3f46;
  --gray-800: #27272a;
  --gray-900: #18181b;
  --content-padding: 1.125rem;
  --inline-spacing: 0.5rem;
  --border-radius: 6px;
  --surface-ground: #09090b;
  --surface-section: #18181b;
  --surface-card: #18181b;
  --surface-overlay: #18181b;
  --surface-border: #27272a;
  --surface-hover: rgba(255, 255, 255, 0.03);
  --focus-ring: none;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: rgba(250, 250, 250, 0.16);
  --highlight-text-color: rgba(255, 255, 255, 0.87);
  --p-anchor-gutter: 2px;
  color-scheme: dark;
}

:root {
  --p-focus-ring-color: var(--primary-color);
}

@font-face {
  font-family: "Akkurat";
  font-style: normal;
  font-named-instance: "Regular";
  src: "./fonts/Akkurat-Regular.ttf";
}
@font-face {
  font-family: "Akkurat-Mono";
  font-style: normal;
  font-named-instance: "Regular";
  src: "./fonts/AkkuratMono-Regular.ttf";
}
@font-face {
  font-family: "Akkurat-Light";
  font-style: normal;
  font-named-instance: "Regular";
  src: "./fonts/Akkurat-Light.ttf";
}
:root {
  --blue-50:#f5f9ff;
  --blue-100:#d0e1fd;
  --blue-200:#abc9fb;
  --blue-300:#85b2f9;
  --blue-400:#609af8;
  --blue-500:#3b82f6;
  --blue-600:#326fd1;
  --blue-700:#295bac;
  --blue-800:#204887;
  --blue-900:#183462;
  --green-50:#f4fcf7;
  --green-100:#caf1d8;
  --green-200:#a0e6ba;
  --green-300:#76db9b;
  --green-400:#4cd07d;
  --green-500:#22c55e;
  --green-600:#1da750;
  --green-700:#188a42;
  --green-800:#136c34;
  --green-900:#0e4f26;
  --yellow-50:#fefbf3;
  --yellow-100:#faedc4;
  --yellow-200:#f6de95;
  --yellow-300:#f2d066;
  --yellow-400:#eec137;
  --yellow-500:#eab308;
  --yellow-600:#c79807;
  --yellow-700:#a47d06;
  --yellow-800:#816204;
  --yellow-900:#5e4803;
  --cyan-50:#f3fbfd;
  --cyan-100:#c3edf5;
  --cyan-200:#94e0ed;
  --cyan-300:#65d2e4;
  --cyan-400:#35c4dc;
  --cyan-500:#06b6d4;
  --cyan-600:#059bb4;
  --cyan-700:#047f94;
  --cyan-800:#036475;
  --cyan-900:#024955;
  --pink-50:#fef6fa;
  --pink-100:#fad3e7;
  --pink-200:#f7b0d3;
  --pink-300:#f38ec0;
  --pink-400:#f06bac;
  --pink-500:#ec4899;
  --pink-600:#c93d82;
  --pink-700:#a5326b;
  --pink-800:#822854;
  --pink-900:#5e1d3d;
  --indigo-50:#f7f7fe;
  --indigo-100:#dadafc;
  --indigo-200:#bcbdf9;
  --indigo-300:#9ea0f6;
  --indigo-400:#8183f4;
  --indigo-500:#6366f1;
  --indigo-600:#5457cd;
  --indigo-700:#4547a9;
  --indigo-800:#363885;
  --indigo-900:#282960;
  --teal-50:#f3fbfb;
  --teal-100:#c7eeea;
  --teal-200:#9ae0d9;
  --teal-300:#6dd3c8;
  --teal-400:#41c5b7;
  --teal-500:#14b8a6;
  --teal-600:#119c8d;
  --teal-700:#0e8174;
  --teal-800:#0b655b;
  --teal-900:#084a42;
  --orange-50:#fff8f3;
  --orange-100:#feddc7;
  --orange-200:#fcc39b;
  --orange-300:#fba86f;
  --orange-400:#fa8e42;
  --orange-500:#f97316;
  --orange-600:#d46213;
  --orange-700:#ae510f;
  --orange-800:#893f0c;
  --orange-900:#642e09;
  --bluegray-50:#f7f8f9;
  --bluegray-100:#dadee3;
  --bluegray-200:#bcc3cd;
  --bluegray-300:#9fa9b7;
  --bluegray-400:#818ea1;
  --bluegray-500:#64748b;
  --bluegray-600:#556376;
  --bluegray-700:#465161;
  --bluegray-800:#37404c;
  --bluegray-900:#282e38;
  --purple-50:#fbf7ff;
  --purple-100:#ead6fd;
  --purple-200:#dab6fc;
  --purple-300:#c996fa;
  --purple-400:#b975f9;
  --purple-500:#a855f7;
  --purple-600:#8f48d2;
  --purple-700:#763cad;
  --purple-800:#5c2f88;
  --purple-900:#432263;
  --red-50:#fff5f5;
  --red-100:#ffd0ce;
  --red-200:#ffaca7;
  --red-300:#ff8780;
  --red-400:#ff6259;
  --red-500:#ff3d32;
  --red-600:#d9342b;
  --red-700:#b32b23;
  --red-800:#8c221c;
  --red-900:#661814;
  --primary-50:#f2f2f2;
  --primary-100:#c2c2c2;
  --primary-200:#919191;
  --primary-300:#616161;
  --primary-400:#303030;
  --primary-500:black;
  --primary-600:black;
  --primary-700:black;
  --primary-800:black;
  --primary-900:black;
}

.p-editor-container .p-editor-toolbar {
  background: #18181b;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #3f3f46;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #a1a1aa;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #a1a1aa;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #a1a1aa;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #ffffff;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #ffffff;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #ffffff;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ffffff;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ffffff;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ffffff;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #18181b;
  border: 1px solid #3f3f46;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0.25rem 0.25rem;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #ffffff;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.03);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.5rem 0.75rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #3f3f46;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #09090b;
  color: #ffffff;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #ffffff;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #ffffff;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #ffffff;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #000000;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #000000;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #000000;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #000000;
}

@layer primevue {
  .p-component, .p-component * {
    box-sizing: border-box;
  }
  .p-hidden-space {
    visibility: hidden;
  }
  .p-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
  }
  .p-disabled, .p-disabled * {
    cursor: default;
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-component-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .p-unselectable-text {
    -webkit-user-select: none;
            user-select: none;
  }
  .p-sr-only {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal;
  }
  .p-link {
    text-align: left;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-link:disabled {
    cursor: default;
  }
  /* Non vue overlay animations */
  .p-connected-overlay {
    opacity: 0;
    transform: scaleY(0.8);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  .p-connected-overlay-visible {
    opacity: 1;
    transform: scaleY(1);
  }
  .p-connected-overlay-hidden {
    opacity: 0;
    transform: scaleY(1);
    transition: opacity 0.1s linear;
  }
  /* Vue based overlay animations */
  .p-connected-overlay-enter-from {
    opacity: 0;
    transform: scaleY(0.8);
  }
  .p-connected-overlay-leave-to {
    opacity: 0;
  }
  .p-connected-overlay-enter-active {
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  .p-connected-overlay-leave-active {
    transition: opacity 0.1s linear;
  }
  /* Toggleable Content */
  .p-toggleable-content-enter-from,
  .p-toggleable-content-leave-to {
    max-height: 0;
  }
  .p-toggleable-content-enter-to,
  .p-toggleable-content-leave-from {
    max-height: 1000px;
  }
  .p-toggleable-content-leave-active {
    overflow: hidden;
    transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
  }
  .p-toggleable-content-enter-active {
    overflow: hidden;
    transition: max-height 1s ease-in-out;
  }
  * {
    box-sizing: border-box;
  }
  .p-component {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    font-weight: normal;
  }
  .p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    transition-duration: 0.2s;
  }
  .p-disabled, .p-component:disabled {
    opacity: 0.6;
  }
  .p-error {
    color: #fca5a5;
  }
  .p-text-secondary {
    color: #a1a1aa;
  }
  .pi {
    font-size: 1rem;
  }
  .p-icon {
    width: 1rem;
    height: 1rem;
  }
  .p-link {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    border-radius: 6px;
    outline-color: transparent;
  }
  .p-link:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
  }
  .p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
  }
  @keyframes p-component-overlay-enter-animation {
    from {
      background-color: transparent;
    }
    to {
      background-color: var(--maskbg);
    }
  }
  @keyframes p-component-overlay-leave-animation {
    from {
      background-color: var(--maskbg);
    }
    to {
      background-color: transparent;
    }
  }
  .p-autocomplete {
    display: inline-flex;
  }
  .p-autocomplete-loader {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
  }
  .p-autocomplete-dd .p-autocomplete-input {
    flex: 1 1 auto;
    width: 1%;
  }
  .p-autocomplete-dd .p-autocomplete-input,
  .p-autocomplete-dd .p-autocomplete-multiple-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .p-autocomplete-dd .p-autocomplete-dropdown {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0px;
  }
  .p-autocomplete .p-autocomplete-panel {
    min-width: 100%;
  }
  .p-autocomplete-panel {
    position: absolute;
    overflow: auto;
    top: 0;
    left: 0;
  }
  .p-autocomplete-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .p-autocomplete-item {
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
  }
  .p-autocomplete-multiple-container {
    margin: 0;
    padding: 0;
    list-style-type: none;
    cursor: text;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .p-autocomplete-token {
    cursor: default;
    display: inline-flex;
    align-items: center;
    flex: 0 0 auto;
  }
  .p-autocomplete-token-icon {
    cursor: pointer;
  }
  .p-autocomplete-input-token {
    flex: 1 1 auto;
    display: inline-flex;
  }
  .p-autocomplete-input-token input {
    border: 0 none;
    outline: 0 none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
  }
  .p-fluid .p-autocomplete {
    display: flex;
  }
  .p-fluid .p-autocomplete-dd .p-autocomplete-input {
    width: 1%;
  }
  .p-autocomplete .p-autocomplete-loader {
    right: 0.75rem;
  }
  .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 3.25rem;
  }
  .p-autocomplete:not(.p-disabled):hover .p-autocomplete-multiple-container {
    border-color: #52525b;
  }
  .p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-autocomplete .p-autocomplete-multiple-container {
    padding: 0.25rem 0.75rem;
    gap: 0.5rem;
    outline-color: transparent;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.25rem 0;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0.25rem 0.75rem;
    background: #3f3f46;
    color: #ffffff;
    border-radius: 16px;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: 0.5rem;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token.p-focus {
    background: #3f3f46;
    color: #ffffff;
  }
  .p-autocomplete.p-invalid.p-component > .p-inputtext {
    border-color: #fca5a5;
  }
  .p-autocomplete-panel {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-autocomplete-panel .p-autocomplete-items {
    padding: 0.25rem 0.25rem;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    margin: 2px 0;
    padding: 0.5rem 0.75rem;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:first-child {
    margin-top: 0;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:last-child {
    margin-bottom: 0;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight.p-focus {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    margin: 0;
    padding: 0.5rem 0.75rem;
    color: #71717a;
    background: #18181b;
    font-weight: 600;
  }
  .p-calendar {
    display: inline-flex;
    max-width: 100%;
  }
  .p-calendar .p-inputtext {
    flex: 1 1 auto;
    width: 1%;
  }
  .p-calendar-w-btn .p-inputtext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .p-calendar-w-btn .p-datepicker-trigger {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-calendar .p-datepicker-trigger-icon {
    cursor: pointer;
  }
  /* Fluid */
  .p-fluid .p-calendar {
    display: flex;
  }
  .p-fluid .p-calendar .p-inputtext {
    width: 1%;
  }
  /* Datepicker */
  .p-calendar .p-datepicker {
    min-width: 100%;
  }
  .p-datepicker {
    width: auto;
  }
  .p-datepicker-inline {
    display: inline-block;
    overflow-x: auto;
  }
  /* Header */
  .p-datepicker-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .p-datepicker-header .p-datepicker-title {
    margin: 0 auto;
  }
  .p-datepicker-prev,
  .p-datepicker-next {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  /* Multiple Month DatePicker */
  .p-datepicker-multiple-month .p-datepicker-group-container {
    display: flex;
  }
  .p-datepicker-multiple-month .p-datepicker-group-container .p-datepicker-group {
    flex: 1 1 auto;
  }
  /* DatePicker Table */
  .p-datepicker table {
    width: 100%;
    border-collapse: collapse;
  }
  .p-datepicker td > span {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
  }
  /* Month Picker */
  .p-monthpicker-month {
    width: 33.3%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  /* Year Picker */
  .p-yearpicker-year {
    width: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  /*  Button Bar */
  .p-datepicker-buttonbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* Time Picker */
  .p-timepicker {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p-timepicker button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .p-timepicker > div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  /* Touch UI */
  .p-datepicker-touch-ui,
  .p-calendar .p-datepicker-touch-ui {
    min-width: 80vw;
  }
  .p-calendar.p-invalid.p-component > .p-inputtext {
    border-color: #fca5a5;
  }
  .p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-datepicker {
    padding: 0.75rem;
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
  }
  .p-datepicker:not(.p-datepicker-inline) {
    background: #18181b;
    border: 1px solid #3f3f46;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: transparent;
  }
  .p-datepicker .p-datepicker-header {
    padding: 0 0 0.5rem 0;
    color: #ffffff;
    background: transparent;
    font-weight: 500;
    margin: 0 0 0 0;
    border-bottom: 1px solid #3f3f46;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev,
  .p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
  .p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 1.75rem;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    font-weight: 500;
    padding: 0.25rem;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: #000000;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
  }
  .p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0 0 0;
  }
  .p-datepicker table th {
    padding: 0.25rem;
  }
  .p-datepicker table th > span {
    width: 2rem;
    height: 2rem;
  }
  .p-datepicker table td {
    padding: 0.25rem;
  }
  .p-datepicker table td > span {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border: 1px solid transparent;
    outline-color: transparent;
  }
  .p-datepicker table td > span.p-highlight {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-datepicker table td > span:focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-datepicker table td.p-datepicker-today > span {
    background: #3f3f46;
    color: #ffffff;
    border-color: transparent;
  }
  .p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-datepicker .p-datepicker-buttonbar {
    padding: 0.5rem 0 0 0;
    border-top: 1px solid #3f3f46;
  }
  .p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
  }
  .p-datepicker .p-timepicker {
    border-top: 1px solid #3f3f46;
    padding: 0;
  }
  .p-datepicker .p-timepicker button {
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-datepicker .p-timepicker button:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-datepicker .p-timepicker button:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-datepicker .p-timepicker button:last-child {
    margin-top: 0.2em;
  }
  .p-datepicker .p-timepicker span {
    font-size: 1rem;
  }
  .p-datepicker .p-timepicker > div {
    padding: 0 0.5rem;
  }
  .p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
  }
  .p-datepicker .p-monthpicker {
    margin: 0.5rem 0 0 0;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.25rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-datepicker .p-yearpicker {
    margin: 0.5rem 0 0 0;
  }
  .p-datepicker .p-yearpicker .p-yearpicker-year {
    padding: 0.25rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
  }
  .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid #3f3f46;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
    border-left: 0 none;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
  }
  .p-datepicker.p-datepicker-mobile table th,
  .p-datepicker.p-datepicker-mobile table td {
    padding: 0.25rem;
  }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled) {
    outline-color: transparent;
  }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.03);
  }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled) {
    outline-color: transparent;
  }
  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.03);
  }
  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled) {
    outline-color: transparent;
  }
  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.03);
  }
  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-cascadeselect {
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-cascadeselect-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  .p-cascadeselect-label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1 auto;
    width: 1%;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .p-cascadeselect-label-empty {
    overflow: hidden;
    visibility: hidden;
  }
  .p-cascadeselect .p-cascadeselect-panel {
    min-width: 100%;
  }
  .p-cascadeselect-item {
    cursor: pointer;
    font-weight: normal;
    white-space: nowrap;
  }
  .p-cascadeselect-item-content {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  .p-cascadeselect-group-icon {
    margin-left: auto;
  }
  .p-cascadeselect-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
    min-width: 100%;
  }
  .p-fluid .p-cascadeselect {
    display: flex;
  }
  .p-fluid .p-cascadeselect .p-cascadeselect-label {
    width: 1%;
  }
  .p-cascadeselect-sublist {
    position: absolute;
    min-width: 100%;
    z-index: 1;
    display: none;
  }
  .p-cascadeselect-item-active {
    overflow: visible;
  }
  .p-cascadeselect-item-active > .p-cascadeselect-sublist {
    display: block;
    left: 100%;
    top: 0;
  }
  .p-cascadeselect-enter-from,
  .p-cascadeselect-leave-active {
    opacity: 0;
  }
  .p-cascadeselect-enter-active {
    transition: opacity 150ms;
  }
  .p-cascadeselect {
    background: #09090b;
    border: 1px solid #3f3f46;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
    outline-color: transparent;
  }
  .p-cascadeselect:not(.p-disabled):hover {
    border-color: #52525b;
  }
  .p-cascadeselect:not(.p-disabled).p-focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-cascadeselect.p-variant-filled {
    background-color: #27272a;
  }
  .p-cascadeselect.p-variant-filled:enabled:hover {
    background-color: #27272a;
  }
  .p-cascadeselect.p-variant-filled:enabled:focus {
    background-color: #27272a;
  }
  .p-cascadeselect .p-cascadeselect-label {
    background: transparent;
    border: 0 none;
    padding: 0.5rem 0.75rem;
  }
  .p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #a1a1aa;
  }
  .p-cascadeselect .p-cascadeselect-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-cascadeselect .p-cascadeselect-trigger {
    background: transparent;
    color: #a1a1aa;
    width: 2.5rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-cascadeselect.p-invalid.p-component {
    border-color: #fca5a5;
  }
  .p-cascadeselect-panel {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-cascadeselect-panel .p-cascadeselect-items {
    padding: 0.25rem 0.25rem;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    margin: 2px 0;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:first-child {
    margin-top: 0;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:last-child {
    margin-bottom: 0;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight.p-focus {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
    padding: 0.5rem 0.75rem;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
    font-size: 0.875rem;
  }
  .p-checkbox {
    position: relative;
    display: inline-flex;
    -webkit-user-select: none;
            user-select: none;
    vertical-align: bottom;
  }
  .p-checkbox-input {
    cursor: pointer;
  }
  .p-checkbox-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p-checkbox {
    width: 1.25rem;
    height: 1.25rem;
  }
  .p-checkbox .p-checkbox-input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: 1;
    outline: 0 none;
    border: 1px solid #3f3f46;
    border-radius: 6px;
  }
  .p-checkbox .p-checkbox-box {
    border: 1px solid #3f3f46;
    background: #09090b;
    width: 1.25rem;
    height: 1.25rem;
    color: #ffffff;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 0.875rem;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-checkbox.p-highlight .p-checkbox-box {
    border-color: #000000;
    background: #000000;
  }
  .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
    border-color: #52525b;
  }
  .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
    border-color: #111111;
    background: #111111;
    color: #ffffff;
  }
  .p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible) .p-checkbox-box {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-checkbox.p-invalid > .p-checkbox-box {
    border-color: #fca5a5;
  }
  .p-checkbox.p-variant-filled .p-checkbox-box {
    background-color: #27272a;
  }
  .p-checkbox.p-variant-filled.p-highlight .p-checkbox-box {
    background: #000000;
  }
  .p-checkbox.p-variant-filled:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
    background-color: #27272a;
  }
  .p-checkbox.p-variant-filled:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
    background: #111111;
  }
  .p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #27272a;
  }
  .p-input-filled .p-checkbox.p-highlight .p-checkbox-box {
    background: #000000;
  }
  .p-input-filled .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
    background-color: #27272a;
  }
  .p-input-filled .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
    background: #111111;
  }
  .p-chips {
    display: inline-flex;
  }
  .p-chips-multiple-container {
    margin: 0;
    padding: 0;
    list-style-type: none;
    cursor: text;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .p-chips-token {
    cursor: default;
    display: inline-flex;
    align-items: center;
    flex: 0 0 auto;
  }
  .p-chips-input-token {
    flex: 1 1 auto;
    display: inline-flex;
  }
  .p-chips-token-icon {
    cursor: pointer;
  }
  .p-chips-input-token input {
    border: 0 none;
    outline: 0 none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
  }
  .p-fluid .p-chips {
    display: flex;
  }
  .p-chips:not(.p-disabled):hover .p-chips-multiple-container {
    border-color: #52525b;
  }
  .p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-chips .p-chips-multiple-container {
    padding: 0.25rem 0.75rem;
    outline-color: transparent;
  }
  .p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: #3f3f46;
    color: #ffffff;
    border-radius: 16px;
  }
  .p-chips .p-chips-multiple-container .p-chips-token.p-focus {
    background: #3f3f46;
    color: #ffffff;
  }
  .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: 0.5rem;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0.25rem 0;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
  .p-chips.p-invalid.p-component > .p-inputtext {
    border-color: #fca5a5;
  }
  .p-colorpicker-panel .p-colorpicker-color {
    background: linear-gradient(to top, #000 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
  }
  .p-colorpicker-panel .p-colorpicker-hue {
    background: linear-gradient(0deg, red 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red);
  }
  .p-colorpicker-preview {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-colorpicker-panel {
    background: #18181b;
    border: 1px solid #3f3f46;
  }
  .p-colorpicker-panel .p-colorpicker-color-handle,
  .p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #ffffff;
  }
  .p-colorpicker-overlay-panel {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-dropdown {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-dropdown-clear-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
  }
  .p-dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  .p-dropdown-label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1 auto;
    width: 1%;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .p-dropdown-label-empty {
    overflow: hidden;
    opacity: 0;
  }
  input.p-dropdown-label {
    cursor: default;
  }
  .p-dropdown .p-dropdown-panel {
    min-width: 100%;
  }
  .p-dropdown-panel {
    position: absolute;
    top: 0;
    left: 0;
  }
  .p-dropdown-items-wrapper {
    overflow: auto;
  }
  .p-dropdown-item {
    cursor: pointer;
    font-weight: normal;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .p-dropdown-item-group {
    cursor: auto;
  }
  .p-dropdown-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .p-dropdown-filter {
    width: 100%;
  }
  .p-dropdown-filter-container {
    position: relative;
  }
  .p-dropdown-filter-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
  }
  .p-fluid .p-dropdown {
    display: flex;
  }
  .p-fluid .p-dropdown .p-dropdown-label {
    width: 1%;
  }
  .p-dropdown {
    background: #09090b;
    border: 1px solid #3f3f46;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
    outline-color: transparent;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: #52525b;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-dropdown.p-variant-filled {
    background: #27272a;
  }
  .p-dropdown.p-variant-filled:not(.p-disabled):hover {
    background-color: #27272a;
  }
  .p-dropdown.p-variant-filled:not(.p-disabled).p-focus {
    background-color: #27272a;
  }
  .p-dropdown.p-variant-filled:not(.p-disabled).p-focus .p-inputtext {
    background-color: transparent;
  }
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem;
  }
  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
  }
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #a1a1aa;
  }
  .p-dropdown .p-dropdown-label:focus, .p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #a1a1aa;
    width: 2.5rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-dropdown .p-dropdown-clear-icon {
    color: #a1a1aa;
    right: 2.5rem;
  }
  .p-dropdown.p-invalid.p-component {
    border-color: #fca5a5;
  }
  .p-dropdown-panel {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-dropdown-panel .p-dropdown-header {
    padding: 0.5rem 0.5rem 0 0.5rem;
    border-bottom: 0 none;
    color: #ffffff;
    background: #18181b;
    margin: 0 0 0 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.75rem;
    margin-right: -1.75rem;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.75rem;
    color: #a1a1aa;
  }
  .p-dropdown-panel .p-dropdown-items {
    padding: 0.25rem 0.25rem;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 2px 0;
    padding: 0.5rem 0.75rem;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
    margin-top: 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:last-child {
    margin-bottom: 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item .p-dropdown-check-icon {
    position: relative;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.5rem 0.75rem;
    color: #71717a;
    background: #18181b;
    font-weight: 600;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.5rem 0.75rem;
    color: #ffffff;
    background: transparent;
  }
  .p-float-label {
    display: block;
    position: relative;
  }
  .p-float-label label {
    position: absolute;
    pointer-events: none;
    top: 50%;
    margin-top: -0.5rem;
    transition-property: all;
    transition-timing-function: ease;
    line-height: 1;
  }
  .p-float-label:has(textarea) label {
    top: 1rem;
  }
  .p-float-label:has(input:focus) label,
  .p-float-label:has(input.p-filled) label,
  .p-float-label:has(input:-webkit-autofill) label,
  .p-float-label:has(textarea:focus) label,
  .p-float-label:has(textarea.p-filled) label,
  .p-float-label:has(.p-inputwrapper-focus) label,
  .p-float-label:has(.p-inputwrapper-filled) label {
    top: -0.75rem;
    font-size: 12px;
  }
  .p-float-label .p-placeholder,
  .p-float-label input::placeholder,
  .p-float-label .p-inputtext::placeholder {
    opacity: 0;
    transition-property: all;
    transition-timing-function: ease;
  }
  .p-float-label .p-focus .p-placeholder,
  .p-float-label input:focus::placeholder,
  .p-float-label .p-inputtext:focus::placeholder {
    opacity: 1;
    transition-property: all;
    transition-timing-function: ease;
  }
  .p-icon-field {
    position: relative;
  }
  .p-icon-field > .p-input-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
  }
  .p-inputotp {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .p-inputotp-input {
    text-align: center;
    width: 2.5rem;
  }
  .p-inputgroup {
    display: flex;
    align-items: stretch;
    width: 100%;
  }
  .p-inputgroup-addon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-inputgroup .p-float-label {
    display: flex;
    align-items: stretch;
    width: 100%;
  }
  .p-inputgroup .p-inputtext,
  .p-fluid .p-inputgroup .p-inputtext,
  .p-inputgroup .p-inputwrapper,
  .p-fluid .p-inputgroup .p-input {
    flex: 1 1 auto;
    width: 1%;
  }
  .p-inputgroup-addon {
    background: #18181b;
    color: #a1a1aa;
    border-top: 1px solid #3f3f46;
    border-left: 1px solid #3f3f46;
    border-bottom: 1px solid #3f3f46;
    padding: 0.5rem 0.75rem;
    min-width: 2.5rem;
  }
  .p-inputgroup-addon:last-child {
    border-right: 1px solid #3f3f46;
  }
  .p-inputgroup > .p-component,
  .p-inputgroup > .p-inputwrapper > .p-inputtext,
  .p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
  }
  .p-inputgroup > .p-component + .p-inputgroup-addon,
  .p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
  .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none;
  }
  .p-inputgroup > .p-component:focus,
  .p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
  .p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1;
  }
  .p-inputgroup > .p-component:focus ~ label,
  .p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
  .p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1;
  }
  .p-inputgroup-addon:first-child,
  .p-inputgroup button:first-child,
  .p-inputgroup input:first-child,
  .p-inputgroup > .p-inputwrapper:first-child,
  .p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-inputgroup-addon:last-child,
  .p-inputgroup button:last-child,
  .p-inputgroup input:last-child,
  .p-inputgroup > .p-inputwrapper:last-child,
  .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-fluid .p-inputgroup .p-button {
    width: auto;
  }
  .p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.5rem;
  }
  .p-fluid .p-icon-field-left,
  .p-fluid .p-icon-field-right {
    width: 100%;
  }
  .p-icon-field-left > .p-input-icon:first-of-type {
    left: 0.75rem;
    color: #a1a1aa;
  }
  .p-icon-field-right > .p-input-icon:last-of-type {
    right: 0.75rem;
    color: #a1a1aa;
  }
  .p-inputnumber {
    display: inline-flex;
  }
  .p-inputnumber-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
  }
  .p-inputnumber-buttons-stacked .p-button.p-inputnumber-button .p-button-label,
  .p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button .p-button-label {
    display: none;
  }
  .p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0;
  }
  .p-inputnumber-buttons-stacked .p-inputnumber-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0;
  }
  .p-inputnumber-buttons-stacked .p-inputnumber-button-group {
    display: flex;
    flex-direction: column;
  }
  .p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-button.p-inputnumber-button {
    flex: 1 1 auto;
  }
  .p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up {
    order: 3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-inputnumber-buttons-horizontal .p-inputnumber-input {
    order: 2;
    border-radius: 0;
  }
  .p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down {
    order: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .p-inputnumber-buttons-vertical {
    flex-direction: column;
  }
  .p-inputnumber-buttons-vertical .p-button.p-inputnumber-button-up {
    order: 1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
  }
  .p-inputnumber-buttons-vertical .p-inputnumber-input {
    order: 2;
    border-radius: 0;
    text-align: center;
  }
  .p-inputnumber-buttons-vertical .p-button.p-inputnumber-button-down {
    order: 3;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
  }
  .p-inputnumber-input {
    flex: 1 1 auto;
  }
  .p-fluid .p-inputnumber {
    width: 100%;
  }
  .p-fluid .p-inputnumber .p-inputnumber-input {
    width: 1%;
  }
  .p-fluid .p-inputnumber-buttons-vertical .p-inputnumber-input {
    width: 100%;
  }
  .p-inputnumber.p-invalid.p-component > .p-inputtext {
    border-color: #fca5a5;
  }
  .p-inputnumber.p-variant-filled > .p-inputnumber-input {
    background-color: #27272a;
  }
  .p-inputnumber.p-variant-filled > .p-inputnumber-input:enabled:hover {
    background-color: #27272a;
  }
  .p-inputnumber.p-variant-filled > .p-inputnumber-input:enabled:focus {
    background-color: #27272a;
  }
  .p-inputswitch {
    display: inline-block;
  }
  .p-inputswitch-input {
    cursor: pointer;
  }
  .p-inputswitch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
  }
  .p-inputswitch-slider:before {
    position: absolute;
    content: "";
    top: 50%;
  }
  .p-inputswitch {
    width: 2.5rem;
    height: 1.5rem;
  }
  .p-inputswitch .p-inputswitch-input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: 1;
    outline: 0 none;
    border-radius: 30px;
  }
  .p-inputswitch .p-inputswitch-slider {
    background: #27272a;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 30px;
    outline-color: transparent;
  }
  .p-inputswitch .p-inputswitch-slider:before {
    background: #a1a1aa;
    width: 1rem;
    height: 1rem;
    left: 0.25rem;
    margin-top: -0.5rem;
    border-radius: 50%;
    transition-duration: 0.2s;
  }
  .p-inputswitch.p-highlight .p-inputswitch-slider {
    background: #000000;
  }
  .p-inputswitch.p-highlight .p-inputswitch-slider:before {
    background: #ffffff;
    transform: translateX(1rem);
  }
  .p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:hover) .p-inputswitch-slider {
    background: #27272a;
  }
  .p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:hover).p-highlight .p-inputswitch-slider {
    background: #111111;
  }
  .p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:focus-visible) .p-inputswitch-slider {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-inputswitch.p-invalid > .p-inputswitch-slider {
    border-color: #fca5a5;
  }
  .p-fluid .p-inputtext {
    width: 100%;
  }
  .p-inputtext {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #ffffff;
    background: #09090b;
    padding: 0.5rem 0.75rem;
    border: 1px solid #3f3f46;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border-radius: 6px;
    outline-color: transparent;
  }
  .p-inputtext:enabled:hover {
    border-color: #52525b;
  }
  .p-inputtext:enabled:focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-inputtext.p-invalid.p-component {
    border-color: #fca5a5;
  }
  .p-inputtext.p-variant-filled {
    background-color: #27272a;
  }
  .p-inputtext.p-variant-filled:enabled:hover {
    background-color: #27272a;
  }
  .p-inputtext.p-variant-filled:enabled:focus {
    background-color: #27272a;
  }
  .p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.65625rem;
  }
  .p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.625rem 0.9375rem;
  }
  .p-float-label > label {
    left: 0.75rem;
    color: #a1a1aa;
    transition-duration: 0.2s;
  }
  .p-float-label > .p-invalid + label {
    color: #fca5a5;
  }
  .p-icon-field-left > .p-inputtext {
    padding-left: 2.5rem;
  }
  .p-icon-field-left.p-float-label > label {
    left: 2.5rem;
  }
  .p-icon-field-right > .p-inputtext {
    padding-right: 2.5rem;
  }
  ::-webkit-input-placeholder {
    color: #a1a1aa;
  }
  :-moz-placeholder {
    color: #a1a1aa;
  }
  ::-moz-placeholder {
    color: #a1a1aa;
  }
  :-ms-input-placeholder {
    color: #a1a1aa;
  }
  .p-input-filled .p-inputtext {
    background-color: #27272a;
  }
  .p-input-filled .p-inputtext:enabled:hover {
    background-color: #27272a;
  }
  .p-input-filled .p-inputtext:enabled:focus {
    background-color: #27272a;
  }
  .p-inputtext-sm .p-inputtext {
    font-size: 0.875rem;
    padding: 0.4375rem 0.65625rem;
  }
  .p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: 0.625rem 0.9375rem;
  }
  .p-knob-range {
    fill: none;
    transition: stroke 0.1s ease-in;
  }
  .p-knob-value {
    animation-name: dash-frame;
    animation-fill-mode: forwards;
    fill: none;
  }
  .p-knob-text {
    font-size: 1.3rem;
    text-align: center;
  }
  @keyframes dash-frame {
    100% {
      stroke-dashoffset: 0;
    }
  }
  .p-listbox-list-wrapper {
    overflow: auto;
  }
  .p-listbox-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .p-listbox-item {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .p-listbox-item-group {
    cursor: auto;
  }
  .p-listbox-filter-container {
    position: relative;
  }
  .p-listbox-filter-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
  }
  .p-listbox-filter {
    width: 100%;
  }
  .p-listbox {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-listbox .p-listbox-header {
    padding: 0.5rem 0.5rem 0 0.5rem;
    border-bottom: 0 none;
    color: #ffffff;
    background: #18181b;
    margin: 0 0 0 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.75rem;
  }
  .p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: 0.75rem;
    color: #a1a1aa;
  }
  .p-listbox .p-listbox-list {
    padding: 0.25rem 0.25rem;
    outline: 0 none;
  }
  .p-listbox .p-listbox-list .p-listbox-item {
    margin: 2px 0;
    padding: 0.5rem 0.75rem;
    border: 0 none;
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-listbox .p-listbox-list .p-listbox-item:first-child {
    margin-top: 0;
  }
  .p-listbox .p-listbox-list .p-listbox-item:last-child {
    margin-bottom: 0;
  }
  .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-listbox .p-listbox-list .p-listbox-item-group {
    margin: 0;
    padding: 0.5rem 0.75rem;
    color: #71717a;
    background: #18181b;
    font-weight: 600;
  }
  .p-listbox .p-listbox-list .p-listbox-empty-message {
    padding: 0.5rem 0.75rem;
    color: #ffffff;
    background: transparent;
  }
  .p-listbox:not(.p-disabled) .p-listbox-item.p-highlight.p-focus {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover.p-focus {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-listbox.p-focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-listbox.p-invalid {
    border-color: #fca5a5;
  }
  .p-multiselect {
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-multiselect-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  .p-multiselect-label-container {
    overflow: hidden;
    flex: 1 1 auto;
    cursor: pointer;
  }
  .p-multiselect-label {
    display: block;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .p-multiselect-label-empty {
    overflow: hidden;
    visibility: hidden;
  }
  .p-multiselect-token {
    cursor: default;
    display: inline-flex;
    align-items: center;
    flex: 0 0 auto;
  }
  .p-multiselect-token-icon {
    cursor: pointer;
  }
  .p-multiselect .p-multiselect-panel {
    min-width: 100%;
  }
  .p-multiselect-items-wrapper {
    overflow: auto;
  }
  .p-multiselect-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .p-multiselect-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: normal;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
  }
  .p-multiselect-item-group {
    cursor: auto;
  }
  .p-multiselect-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .p-multiselect-filter-container {
    position: relative;
    flex: 1 1 auto;
  }
  .p-multiselect-filter-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
  }
  .p-multiselect-filter-container .p-inputtext {
    width: 100%;
  }
  .p-multiselect-close {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    margin-left: auto;
  }
  .p-fluid .p-multiselect {
    display: flex;
  }
  .p-multiselect {
    background: #09090b;
    border: 1px solid #3f3f46;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
    outline-color: transparent;
  }
  .p-multiselect:not(.p-disabled):hover {
    border-color: #52525b;
  }
  .p-multiselect:not(.p-disabled).p-focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-multiselect.p-variant-filled {
    background: #27272a;
  }
  .p-multiselect.p-variant-filled:not(.p-disabled):hover {
    background-color: #27272a;
  }
  .p-multiselect.p-variant-filled:not(.p-disabled).p-focus {
    background-color: #27272a;
  }
  .p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.75rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-multiselect .p-multiselect-label.p-placeholder {
    color: #a1a1aa;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: #3f3f46;
    color: #ffffff;
    border-radius: 16px;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: 0.5rem;
  }
  .p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: #a1a1aa;
    width: 2.5rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-multiselect.p-invalid.p-component {
    border-color: #fca5a5;
  }
  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.25rem 0.75rem;
  }
  .p-multiselect-panel {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-multiselect-panel .p-multiselect-header {
    padding: 0.5rem 0.5rem 0 0.5rem;
    border-bottom: 0 none;
    color: #ffffff;
    background: #18181b;
    margin: 0 0 0 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.75rem;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    right: 0.75rem;
    color: #a1a1aa;
  }
  .p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-left: 0.5rem;
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-multiselect-panel .p-multiselect-items {
    padding: 0.25rem 0.25rem;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 2px 0;
    padding: 0.5rem 0.75rem;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:first-child {
    margin-top: 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:last-child {
    margin-bottom: 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: 0.5rem 0.75rem;
    color: #71717a;
    background: #18181b;
    font-weight: 600;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: 0.5rem 0.75rem;
    color: #ffffff;
    background: transparent;
  }
  .p-password {
    display: inline-flex;
  }
  .p-password .p-password-panel {
    min-width: 100%;
  }
  .p-password-meter {
    height: 10px;
  }
  .p-password-strength {
    height: 100%;
    width: 0;
    transition: width 1s ease-in-out;
  }
  .p-fluid .p-password {
    display: flex;
  }
  .p-password-input::-ms-reveal,
  .p-password-input::-ms-clear {
    display: none;
  }
  .p-password.p-invalid.p-component > .p-inputtext {
    border-color: #fca5a5;
  }
  .p-password-panel {
    padding: 0 1.125rem 1.125rem 1.125rem;
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  .p-password-panel .p-password-meter {
    margin-bottom: 0.5rem;
    background: #3f3f46;
  }
  .p-password-panel .p-password-meter .p-password-strength.weak {
    background: #f87171;
  }
  .p-password-panel .p-password-meter .p-password-strength.medium {
    background: #fbbf24;
  }
  .p-password-panel .p-password-meter .p-password-strength.strong {
    background: #4ade80;
  }
  .p-radiobutton {
    position: relative;
    display: inline-flex;
    -webkit-user-select: none;
            user-select: none;
    vertical-align: bottom;
  }
  .p-radiobutton-input {
    cursor: pointer;
  }
  .p-radiobutton-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p-radiobutton-icon {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateZ(0) scale(0.1);
    border-radius: 50%;
    visibility: hidden;
  }
  .p-radiobutton.p-highlight .p-radiobutton-icon {
    transform: translateZ(0) scale(1, 1);
    visibility: visible;
  }
  .p-radiobutton {
    width: 1.25rem;
    height: 1.25rem;
  }
  .p-radiobutton .p-radiobutton-input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: 1;
    outline: 0 none;
    border: 1px solid #3f3f46;
    border-radius: 50%;
  }
  .p-radiobutton .p-radiobutton-box {
    border: 1px solid #3f3f46;
    background: #09090b;
    width: 1.25rem;
    height: 1.25rem;
    color: #ffffff;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 0.75rem;
    height: 0.75rem;
    transition-duration: 0.2s;
    background-color: #000000;
  }
  .p-radiobutton.p-highlight .p-radiobutton-box {
    border-color: #000000;
    background: #ffffff;
  }
  .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
    border-color: #52525b;
  }
  .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover).p-highlight .p-radiobutton-box {
    border-color: #111111;
    background: #ffffff;
  }
  .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover).p-highlight .p-radiobutton-box .p-radiobutton-icon {
    background-color: #111111;
  }
  .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:focus-visible) .p-radiobutton-box {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-radiobutton.p-invalid > .p-radiobutton-box {
    border-color: #fca5a5;
  }
  .p-radiobutton.p-variant-filled .p-radiobutton-box {
    background-color: #27272a;
  }
  .p-radiobutton.p-variant-filled.p-highlight .p-radiobutton-box {
    background: #ffffff;
  }
  .p-radiobutton.p-variant-filled:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
    background-color: #27272a;
  }
  .p-radiobutton.p-variant-filled:not(.p-disabled):has(.p-radiobutton-input:hover).p-highlight .p-radiobutton-box {
    background: #ffffff;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: #27272a;
  }
  .p-input-filled .p-radiobutton.p-highlight .p-radiobutton-box {
    background: #ffffff;
  }
  .p-input-filled .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
    background-color: #27272a;
  }
  .p-input-filled .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover).p-highlight .p-radiobutton-box {
    background: #ffffff;
  }
  .p-rating {
    position: relative;
    display: flex;
    align-items: center;
  }
  .p-rating-item {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  .p-rating.p-readonly .p-rating-item {
    cursor: default;
  }
  .p-rating {
    gap: 0.5rem;
  }
  .p-rating .p-rating-item {
    outline-color: transparent;
    border-radius: 50%;
  }
  .p-rating .p-rating-item .p-rating-icon {
    color: #a1a1aa;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    font-size: 1rem;
  }
  .p-rating .p-rating-item .p-rating-icon.p-icon {
    width: 1rem;
    height: 1rem;
  }
  .p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
    color: #f87171;
  }
  .p-rating .p-rating-item.p-focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
    color: #000000;
  }
  .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
    color: #000000;
  }
  .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon.p-rating-cancel {
    color: #ef4444;
  }
  .p-selectbutton .p-button {
    background: #09090b;
    border: 1px solid #09090b;
    color: #a1a1aa;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-selectbutton .p-button .p-button-icon-left,
  .p-selectbutton .p-button .p-button-icon-right {
    color: #a1a1aa;
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #09090b;
    border-color: #09090b;
    color: #ffffff;
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight {
    background: #09090b;
    border-color: #09090b;
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight:hover {
    background: #09090b;
    border-color: #09090b;
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
  }
  .p-selectbutton.p-invalid > .p-button {
    border-color: #fca5a5;
  }
  .p-slider {
    position: relative;
  }
  .p-slider .p-slider-handle {
    cursor: grab;
    touch-action: none;
    display: block;
  }
  .p-slider-range {
    display: block;
  }
  .p-slider-horizontal .p-slider-range {
    top: 0;
    left: 0;
    height: 100%;
  }
  .p-slider-horizontal .p-slider-handle {
    top: 50%;
  }
  .p-slider-vertical {
    height: 100px;
  }
  .p-slider-vertical .p-slider-handle {
    left: 50%;
  }
  .p-slider-vertical .p-slider-range {
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .p-slider {
    background: #3f3f46;
    border: 0 none;
    border-radius: 6px;
  }
  .p-slider.p-slider-horizontal {
    height: 3px;
  }
  .p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -10px;
    margin-left: -10px;
  }
  .p-slider.p-slider-vertical {
    width: 3px;
  }
  .p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -10px;
    margin-bottom: -10px;
  }
  .p-slider .p-slider-handle {
    height: 20px;
    width: 20px;
    background: #3f3f46;
    border: 0 none;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-slider .p-slider-handle:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-slider .p-slider-range {
    background: #000000;
    border-radius: 6px;
  }
  .p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #3f3f46;
    border-color: transparent;
  }
  .p-inputtextarea-resizable {
    overflow: hidden;
    resize: none;
  }
  .p-fluid .p-inputtextarea {
    width: 100%;
  }
  .p-treeselect {
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-treeselect-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  .p-treeselect-label-container {
    overflow: hidden;
    flex: 1 1 auto;
    cursor: pointer;
  }
  .p-treeselect-label {
    display: block;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .p-treeselect-label-empty {
    overflow: hidden;
    visibility: hidden;
  }
  .p-treeselect-token {
    cursor: default;
    display: inline-flex;
    align-items: center;
    flex: 0 0 auto;
  }
  .p-treeselect .p-treeselect-panel {
    min-width: 100%;
  }
  .p-treeselect-items-wrapper {
    overflow: auto;
  }
  .p-fluid .p-treeselect {
    display: flex;
  }
  .p-treeselect {
    background: #09090b;
    border: 1px solid #3f3f46;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
    outline-color: transparent;
  }
  .p-treeselect:not(.p-disabled):hover {
    border-color: #52525b;
  }
  .p-treeselect:not(.p-disabled).p-focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-treeselect.p-variant-filled {
    background: #27272a;
  }
  .p-treeselect.p-variant-filled:not(.p-disabled):hover {
    background-color: #27272a;
  }
  .p-treeselect.p-variant-filled:not(.p-disabled).p-focus {
    background-color: #27272a;
  }
  .p-treeselect .p-treeselect-label {
    padding: 0.5rem 0.75rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-treeselect .p-treeselect-label.p-placeholder {
    color: #a1a1aa;
  }
  .p-treeselect.p-treeselect-chip .p-treeselect-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: #3f3f46;
    color: #ffffff;
    border-radius: 16px;
  }
  .p-treeselect .p-treeselect-trigger {
    background: transparent;
    color: #a1a1aa;
    width: 2.5rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-treeselect.p-invalid.p-component {
    border-color: #fca5a5;
  }
  .p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: 0.25rem 0.75rem;
  }
  .p-treeselect-panel {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0 none;
  }
  .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    padding: 0.5rem 0.75rem;
    color: #ffffff;
    background: transparent;
  }
  .p-input-filled .p-treeselect {
    background: #27272a;
  }
  .p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: #27272a;
  }
  .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
    background-color: #27272a;
  }
  .p-togglebutton {
    position: relative;
    display: inline-flex;
    -webkit-user-select: none;
            user-select: none;
    vertical-align: bottom;
  }
  .p-togglebutton-input {
    cursor: pointer;
  }
  .p-togglebutton .p-button {
    flex: 1 1 auto;
  }
  .p-togglebutton .p-togglebutton-input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: 1;
    outline: 0 none;
    border: 1px solid #09090b;
    border-radius: 6px;
  }
  .p-togglebutton .p-button {
    background: #09090b;
    border: 1px solid #09090b;
    color: #a1a1aa;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-togglebutton .p-button .p-button-icon-left,
  .p-togglebutton .p-button .p-button-icon-right {
    color: #a1a1aa;
  }
  .p-togglebutton.p-highlight .p-button {
    background: #09090b;
    border-color: #09090b;
    color: #ffffff;
  }
  .p-togglebutton.p-highlight .p-button .p-button-icon-left,
  .p-togglebutton.p-highlight .p-button .p-button-icon-right {
    color: #ffffff;
  }
  .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover):not(.p-highlight) .p-button {
    background: #09090b;
    border-color: #09090b;
    color: #ffffff;
  }
  .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover):not(.p-highlight) .p-button .p-button-icon-left,
  .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover):not(.p-highlight) .p-button .p-button-icon-right {
    color: #ffffff;
  }
  .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover).p-highlight .p-button {
    background: #09090b;
    border-color: #09090b;
    color: #ffffff;
  }
  .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover).p-highlight .p-button .p-button-icon-left,
  .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover).p-highlight .p-button .p-button-icon-right {
    color: #ffffff;
  }
  .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:focus-visible) .p-button {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-togglebutton.p-invalid > .p-button {
    border-color: #fca5a5;
  }
  .p-button {
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
  }
  .p-button-label {
    flex: 1 1 auto;
  }
  .p-button-icon-right {
    order: 1;
  }
  .p-button:disabled {
    cursor: default;
  }
  .p-button-icon-only {
    justify-content: center;
  }
  .p-button-icon-only .p-button-label {
    visibility: hidden;
    width: 0;
    flex: 0 0 auto;
  }
  .p-button-vertical {
    flex-direction: column;
  }
  .p-button-icon-bottom {
    order: 2;
  }
  .p-button-group .p-button {
    margin: 0;
  }
  .p-button-group .p-button:not(:last-child), .p-button-group .p-button:not(:last-child):hover {
    border-right: 0 none;
  }
  .p-button-group .p-button:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
  }
  .p-button-group .p-button:first-of-type:not(:only-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .p-button-group .p-button:last-of-type:not(:only-of-type) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-button-group .p-button:focus {
    position: relative;
    z-index: 1;
  }
  .p-button {
    color: #ffffff;
    background: #000000;
    border: 1px solid #000000;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
    outline-color: transparent;
  }
  .p-button:not(:disabled):hover {
    background: #111111;
    color: #ffffff;
    border-color: #111111;
  }
  .p-button:not(:disabled):active {
    background: #222222;
    color: #ffffff;
    border-color: #222222;
  }
  .p-button.p-button-outlined {
    background-color: transparent;
    color: #000000;
    border: 1px solid;
  }
  .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(0, 0, 0, 0.04);
    color: #000000;
    border: 1px solid;
  }
  .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(0, 0, 0, 0.16);
    color: #000000;
    border: 1px solid;
  }
  .p-button.p-button-outlined.p-button-plain {
    color: #a1a1aa;
    border-color: #a1a1aa;
  }
  .p-button.p-button-outlined.p-button-plain:not(:disabled):hover {
    background: rgba(255, 255, 255, 0.03);
    color: #a1a1aa;
  }
  .p-button.p-button-outlined.p-button-plain:not(:disabled):active {
    background: rgba(255, 255, 255, 0.16);
    color: #a1a1aa;
  }
  .p-button.p-button-text {
    background-color: transparent;
    color: #000000;
    border-color: transparent;
  }
  .p-button.p-button-text:not(:disabled):hover {
    background: rgba(0, 0, 0, 0.04);
    color: #000000;
    border-color: transparent;
  }
  .p-button.p-button-text:not(:disabled):active {
    background: rgba(0, 0, 0, 0.16);
    color: #000000;
    border-color: transparent;
  }
  .p-button.p-button-text.p-button-plain {
    color: #a1a1aa;
  }
  .p-button.p-button-text.p-button-plain:not(:disabled):hover {
    background: rgba(255, 255, 255, 0.03);
    color: #a1a1aa;
  }
  .p-button.p-button-text.p-button-plain:not(:disabled):active {
    background: rgba(255, 255, 255, 0.16);
    color: #a1a1aa;
  }
  .p-button:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-button .p-button-label {
    transition-duration: 0.2s;
  }
  .p-button .p-button-icon-left {
    margin-right: 0.5rem;
  }
  .p-button .p-button-icon-right {
    margin-left: 0.5rem;
  }
  .p-button .p-button-icon-bottom {
    margin-top: 0.5rem;
  }
  .p-button .p-button-icon-top {
    margin-bottom: 0.5rem;
  }
  .p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: #000000;
    background-color: #ffffff;
  }
  .p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .p-button.p-button-rounded {
    border-radius: 2rem;
  }
  .p-button.p-button-icon-only {
    width: 2.5rem;
    padding: 0.5rem 0;
  }
  .p-button.p-button-icon-only .p-button-icon-left,
  .p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
  }
  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.5rem;
  }
  .p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
  }
  .p-button.p-button-sm .p-button-icon {
    font-size: 0.875rem;
  }
  .p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.625rem 1.25rem;
  }
  .p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem;
  }
  .p-button.p-button-loading-label-only .p-button-label {
    margin-left: 0.5rem;
  }
  .p-button.p-button-loading-label-only .p-button-loading-icon {
    margin-right: 0;
  }
  .p-fluid .p-button {
    width: 100%;
  }
  .p-fluid .p-button-icon-only {
    width: 2.5rem;
  }
  .p-fluid .p-button-group {
    display: flex;
  }
  .p-fluid .p-button-group .p-button {
    flex: 1;
  }
  .p-button.p-button-secondary, .p-button-group.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
    color: #d4d4d8;
    background: #27272a;
    border: 1px solid #27272a;
  }
  .p-button.p-button-secondary:not(:disabled):hover, .p-button-group.p-button-secondary > .p-button:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):hover {
    background: #3f3f46;
    color: #d4d4d8;
    border-color: #3f3f46;
  }
  .p-button.p-button-secondary:not(:disabled):focus, .p-button-group.p-button-secondary > .p-button:not(:disabled):focus, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):focus {
    box-shadow: none;
  }
  .p-button.p-button-secondary:not(:disabled):active, .p-button-group.p-button-secondary > .p-button:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):active {
    background: #52525b;
    color: #d4d4d8;
    border-color: #52525b;
  }
  .p-button.p-button-secondary.p-button-outlined, .p-button-group.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    background-color: transparent;
    color: #27272a;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-secondary > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(39, 39, 42, 0.04);
    color: #27272a;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-outlined:not(:disabled):active, .p-button-group.p-button-secondary > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(39, 39, 42, 0.16);
    color: #27272a;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-text, .p-button-group.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
    background-color: transparent;
    color: #27272a;
    border-color: transparent;
  }
  .p-button.p-button-secondary.p-button-text:not(:disabled):hover, .p-button-group.p-button-secondary > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(39, 39, 42, 0.04);
    border-color: transparent;
    color: #27272a;
  }
  .p-button.p-button-secondary.p-button-text:not(:disabled):active, .p-button-group.p-button-secondary > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):active {
    background: rgba(39, 39, 42, 0.16);
    border-color: transparent;
    color: #27272a;
  }
  .p-button.p-button-info, .p-button-group.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
    color: #082f49;
    background: #38bdf8;
    border: 1px solid #38bdf8;
  }
  .p-button.p-button-info:not(:disabled):hover, .p-button-group.p-button-info > .p-button:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button:not(:disabled):hover {
    background: #7dd3fc;
    color: #082f49;
    border-color: #7dd3fc;
  }
  .p-button.p-button-info:not(:disabled):focus, .p-button-group.p-button-info > .p-button:not(:disabled):focus, .p-splitbutton.p-button-info > .p-button:not(:disabled):focus {
    box-shadow: none;
  }
  .p-button.p-button-info:not(:disabled):active, .p-button-group.p-button-info > .p-button:not(:disabled):active, .p-splitbutton.p-button-info > .p-button:not(:disabled):active {
    background: #bae6fd;
    color: #082f49;
    border-color: #bae6fd;
  }
  .p-button.p-button-info.p-button-outlined, .p-button-group.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: #38bdf8;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-info > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(56, 189, 248, 0.04);
    color: #38bdf8;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-outlined:not(:disabled):active, .p-button-group.p-button-info > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(56, 189, 248, 0.16);
    color: #38bdf8;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-text, .p-button-group.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
    background-color: transparent;
    color: #38bdf8;
    border-color: transparent;
  }
  .p-button.p-button-info.p-button-text:not(:disabled):hover, .p-button-group.p-button-info > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(56, 189, 248, 0.04);
    border-color: transparent;
    color: #38bdf8;
  }
  .p-button.p-button-info.p-button-text:not(:disabled):active, .p-button-group.p-button-info > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):active {
    background: rgba(56, 189, 248, 0.16);
    border-color: transparent;
    color: #38bdf8;
  }
  .p-button.p-button-success, .p-button-group.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
    color: #052e16;
    background: #4ade80;
    border: 1px solid #4ade80;
  }
  .p-button.p-button-success:not(:disabled):hover, .p-button-group.p-button-success > .p-button:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button:not(:disabled):hover {
    background: #86efac;
    color: #052e16;
    border-color: #86efac;
  }
  .p-button.p-button-success:not(:disabled):focus, .p-button-group.p-button-success > .p-button:not(:disabled):focus, .p-splitbutton.p-button-success > .p-button:not(:disabled):focus {
    box-shadow: none;
  }
  .p-button.p-button-success:not(:disabled):active, .p-button-group.p-button-success > .p-button:not(:disabled):active, .p-splitbutton.p-button-success > .p-button:not(:disabled):active {
    background: #bbf7d0;
    color: #052e16;
    border-color: #bbf7d0;
  }
  .p-button.p-button-success.p-button-outlined, .p-button-group.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
    background-color: transparent;
    color: #4ade80;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-success > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(74, 222, 128, 0.04);
    color: #4ade80;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-outlined:not(:disabled):active, .p-button-group.p-button-success > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(74, 222, 128, 0.16);
    color: #4ade80;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-text, .p-button-group.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
    background-color: transparent;
    color: #4ade80;
    border-color: transparent;
  }
  .p-button.p-button-success.p-button-text:not(:disabled):hover, .p-button-group.p-button-success > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(74, 222, 128, 0.04);
    border-color: transparent;
    color: #4ade80;
  }
  .p-button.p-button-success.p-button-text:not(:disabled):active, .p-button-group.p-button-success > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):active {
    background: rgba(74, 222, 128, 0.16);
    border-color: transparent;
    color: #4ade80;
  }
  .p-button.p-button-warning, .p-button-group.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
    color: #431407;
    background: #fb923c;
    border: 1px solid #fb923c;
  }
  .p-button.p-button-warning:not(:disabled):hover, .p-button-group.p-button-warning > .p-button:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button:not(:disabled):hover {
    background: #fdba74;
    color: #431407;
    border-color: #fdba74;
  }
  .p-button.p-button-warning:not(:disabled):focus, .p-button-group.p-button-warning > .p-button:not(:disabled):focus, .p-splitbutton.p-button-warning > .p-button:not(:disabled):focus {
    box-shadow: none;
  }
  .p-button.p-button-warning:not(:disabled):active, .p-button-group.p-button-warning > .p-button:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button:not(:disabled):active {
    background: #fed7aa;
    color: #431407;
    border-color: #fed7aa;
  }
  .p-button.p-button-warning.p-button-outlined, .p-button-group.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    background-color: transparent;
    color: #fb923c;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-warning > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(251, 146, 60, 0.04);
    color: #fb923c;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-outlined:not(:disabled):active, .p-button-group.p-button-warning > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(251, 146, 60, 0.16);
    color: #fb923c;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-text, .p-button-group.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
    background-color: transparent;
    color: #fb923c;
    border-color: transparent;
  }
  .p-button.p-button-warning.p-button-text:not(:disabled):hover, .p-button-group.p-button-warning > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(251, 146, 60, 0.04);
    border-color: transparent;
    color: #fb923c;
  }
  .p-button.p-button-warning.p-button-text:not(:disabled):active, .p-button-group.p-button-warning > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):active {
    background: rgba(251, 146, 60, 0.16);
    border-color: transparent;
    color: #fb923c;
  }
  .p-button.p-button-help, .p-button-group.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
    color: #3b0764;
    background: #c084fc;
    border: 1px solid #c084fc;
  }
  .p-button.p-button-help:not(:disabled):hover, .p-button-group.p-button-help > .p-button:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button:not(:disabled):hover {
    background: #d8b4fe;
    color: #3b0764;
    border-color: #d8b4fe;
  }
  .p-button.p-button-help:not(:disabled):focus, .p-button-group.p-button-help > .p-button:not(:disabled):focus, .p-splitbutton.p-button-help > .p-button:not(:disabled):focus {
    box-shadow: none;
  }
  .p-button.p-button-help:not(:disabled):active, .p-button-group.p-button-help > .p-button:not(:disabled):active, .p-splitbutton.p-button-help > .p-button:not(:disabled):active {
    background: #e9d5ff;
    color: #3b0764;
    border-color: #e9d5ff;
  }
  .p-button.p-button-help.p-button-outlined, .p-button-group.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: #c084fc;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-help > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(192, 132, 252, 0.04);
    color: #c084fc;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-outlined:not(:disabled):active, .p-button-group.p-button-help > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(192, 132, 252, 0.16);
    color: #c084fc;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-text, .p-button-group.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
    background-color: transparent;
    color: #c084fc;
    border-color: transparent;
  }
  .p-button.p-button-help.p-button-text:not(:disabled):hover, .p-button-group.p-button-help > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(192, 132, 252, 0.04);
    border-color: transparent;
    color: #c084fc;
  }
  .p-button.p-button-help.p-button-text:not(:disabled):active, .p-button-group.p-button-help > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):active {
    background: rgba(192, 132, 252, 0.16);
    border-color: transparent;
    color: #c084fc;
  }
  .p-button.p-button-danger, .p-button-group.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
    color: #450a0a;
    background: #f87171;
    border: 1px solid #f87171;
  }
  .p-button.p-button-danger:not(:disabled):hover, .p-button-group.p-button-danger > .p-button:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button:not(:disabled):hover {
    background: #fca5a5;
    color: #450a0a;
    border-color: #fca5a5;
  }
  .p-button.p-button-danger:not(:disabled):focus, .p-button-group.p-button-danger > .p-button:not(:disabled):focus, .p-splitbutton.p-button-danger > .p-button:not(:disabled):focus {
    box-shadow: none;
  }
  .p-button.p-button-danger:not(:disabled):active, .p-button-group.p-button-danger > .p-button:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button:not(:disabled):active {
    background: #fecaca;
    color: #450a0a;
    border-color: #fecaca;
  }
  .p-button.p-button-danger.p-button-outlined, .p-button-group.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: #f87171;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-danger > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(248, 113, 113, 0.04);
    color: #f87171;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-outlined:not(:disabled):active, .p-button-group.p-button-danger > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(248, 113, 113, 0.16);
    color: #f87171;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-text, .p-button-group.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
    background-color: transparent;
    color: #f87171;
    border-color: transparent;
  }
  .p-button.p-button-danger.p-button-text:not(:disabled):hover, .p-button-group.p-button-danger > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(248, 113, 113, 0.04);
    border-color: transparent;
    color: #f87171;
  }
  .p-button.p-button-danger.p-button-text:not(:disabled):active, .p-button-group.p-button-danger > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):active {
    background: rgba(248, 113, 113, 0.16);
    border-color: transparent;
    color: #f87171;
  }
  .p-button.p-button-contrast, .p-button-group.p-button-contrast > .p-button, .p-splitbutton.p-button-contrast > .p-button {
    color: #09090b;
    background: #ffffff;
    border: 1px solid #ffffff;
  }
  .p-button.p-button-contrast:not(:disabled):hover, .p-button-group.p-button-contrast > .p-button:not(:disabled):hover, .p-splitbutton.p-button-contrast > .p-button:not(:disabled):hover {
    background: #f4f4f5;
    color: #09090b;
    border-color: #f4f4f5;
  }
  .p-button.p-button-contrast:not(:disabled):focus, .p-button-group.p-button-contrast > .p-button:not(:disabled):focus, .p-splitbutton.p-button-contrast > .p-button:not(:disabled):focus {
    box-shadow: none;
  }
  .p-button.p-button-contrast:not(:disabled):active, .p-button-group.p-button-contrast > .p-button:not(:disabled):active, .p-splitbutton.p-button-contrast > .p-button:not(:disabled):active {
    background: #e4e4e7;
    color: #09090b;
    border-color: #e4e4e7;
  }
  .p-button.p-button-contrast.p-button-outlined, .p-button-group.p-button-contrast > .p-button.p-button-outlined, .p-splitbutton.p-button-contrast > .p-button.p-button-outlined {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid;
  }
  .p-button.p-button-contrast.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-contrast > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-contrast > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(255, 255, 255, 0.04);
    color: #ffffff;
    border: 1px solid;
  }
  .p-button.p-button-contrast.p-button-outlined:not(:disabled):active, .p-button-group.p-button-contrast > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-contrast > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(255, 255, 255, 0.16);
    color: #ffffff;
    border: 1px solid;
  }
  .p-button.p-button-contrast.p-button-text, .p-button-group.p-button-contrast > .p-button.p-button-text, .p-splitbutton.p-button-contrast > .p-button.p-button-text {
    background-color: transparent;
    color: #ffffff;
    border-color: transparent;
  }
  .p-button.p-button-contrast.p-button-text:not(:disabled):hover, .p-button-group.p-button-contrast > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-contrast > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(255, 255, 255, 0.04);
    border-color: transparent;
    color: #ffffff;
  }
  .p-button.p-button-contrast.p-button-text:not(:disabled):active, .p-button-group.p-button-contrast > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-contrast > .p-button.p-button-text:not(:disabled):active {
    background: rgba(255, 255, 255, 0.16);
    border-color: transparent;
    color: #ffffff;
  }
  .p-button.p-button-link {
    color: #000000;
    background: transparent;
    border: transparent;
  }
  .p-button.p-button-link:not(:disabled):hover {
    background: transparent;
    color: #000000;
    border-color: transparent;
  }
  .p-button.p-button-link:not(:disabled):hover .p-button-label {
    text-decoration: underline;
  }
  .p-button.p-button-link:not(:disabled):focus {
    background: transparent;
    box-shadow: none;
    border-color: transparent;
  }
  .p-button.p-button-link:not(:disabled):active {
    background: transparent;
    color: #000000;
    border-color: transparent;
  }
  .p-speeddial {
    position: absolute;
    display: flex;
  }
  .p-speeddial-button {
    z-index: 1;
  }
  .p-speeddial-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: top 0s linear 0.2s;
    pointer-events: none;
    z-index: 2;
  }
  .p-speeddial-item {
    transform: scale(0);
    opacity: 0;
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.8s;
    will-change: transform;
  }
  .p-speeddial-action {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
  }
  .p-speeddial-circle .p-speeddial-item,
  .p-speeddial-semi-circle .p-speeddial-item,
  .p-speeddial-quarter-circle .p-speeddial-item {
    position: absolute;
  }
  .p-speeddial-rotate {
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    will-change: transform;
  }
  .p-speeddial-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 250ms cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .p-speeddial-mask-visible {
    pointer-events: none;
    opacity: 1;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .p-speeddial-opened .p-speeddial-list {
    pointer-events: auto;
  }
  .p-speeddial-opened .p-speeddial-item {
    transform: scale(1);
    opacity: 1;
  }
  .p-speeddial-opened .p-speeddial-rotate {
    transform: rotate(45deg);
  }
  .p-speeddial-button.p-button.p-button-icon-only {
    width: 2.5rem;
    height: 2.5rem;
  }
  .p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1rem;
  }
  .p-speeddial-button.p-button.p-button-icon-only .p-icon {
    width: 1rem;
    height: 1rem;
  }
  .p-speeddial-list {
    outline: 0 none;
  }
  .p-speeddial-item.p-focus > .p-speeddial-action {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-speeddial-action {
    width: 2.5rem;
    height: 2.5rem;
    background: #3f3f46;
    color: #a1a1aa;
    outline-color: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-speeddial-action:hover {
    background: #3f3f46;
    color: #ffffff;
  }
  .p-speeddial-direction-up .p-speeddial-item {
    margin: 0.25rem 0;
  }
  .p-speeddial-direction-up .p-speeddial-item:first-child {
    margin-bottom: 0.5rem;
  }
  .p-speeddial-direction-down .p-speeddial-item {
    margin: 0.25rem 0;
  }
  .p-speeddial-direction-down .p-speeddial-item:first-child {
    margin-top: 0.5rem;
  }
  .p-speeddial-direction-left .p-speeddial-item {
    margin: 0 0.25rem;
  }
  .p-speeddial-direction-left .p-speeddial-item:first-child {
    margin-right: 0.5rem;
  }
  .p-speeddial-direction-right .p-speeddial-item {
    margin: 0 0.25rem;
  }
  .p-speeddial-direction-right .p-speeddial-item:first-child {
    margin-left: 0.5rem;
  }
  .p-speeddial-circle .p-speeddial-item,
  .p-speeddial-semi-circle .p-speeddial-item,
  .p-speeddial-quarter-circle .p-speeddial-item {
    margin: 0;
  }
  .p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child,
  .p-speeddial-semi-circle .p-speeddial-item:first-child,
  .p-speeddial-semi-circle .p-speeddial-item:last-child,
  .p-speeddial-quarter-circle .p-speeddial-item:first-child,
  .p-speeddial-quarter-circle .p-speeddial-item:last-child {
    margin: 0;
  }
  .p-speeddial-mask {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 6px;
  }
  .p-splitbutton {
    display: inline-flex;
    position: relative;
  }
  .p-splitbutton .p-splitbutton-defaultbutton,
  .p-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton.p-button,
  .p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button,
  .p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button-outlined.p-button:hover {
    flex: 1 1 auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 none;
  }
  .p-splitbutton-menubutton,
  .p-splitbutton.p-button-rounded > .p-splitbutton-menubutton.p-button,
  .p-splitbutton.p-button-outlined > .p-splitbutton-menubutton.p-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-splitbutton .p-menu {
    min-width: 100%;
  }
  .p-fluid .p-splitbutton {
    display: flex;
  }
  .p-splitbutton {
    border-radius: 6px;
  }
  .p-splitbutton.p-button-rounded {
    border-radius: 2rem;
  }
  .p-splitbutton.p-button-rounded > .p-button {
    border-radius: 2rem;
  }
  .p-splitbutton.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .p-carousel {
    display: flex;
    flex-direction: column;
  }
  .p-carousel-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .p-carousel-prev,
  .p-carousel-next {
    align-self: center;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  .p-carousel-container {
    display: flex;
    flex-direction: row;
  }
  .p-carousel-items-content {
    overflow: hidden;
    width: 100%;
  }
  .p-carousel-items-container {
    display: flex;
    flex-direction: row;
  }
  .p-carousel-indicators {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  .p-carousel-indicator > button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* Vertical */
  .p-carousel-vertical .p-carousel-container {
    flex-direction: column;
  }
  .p-carousel-vertical .p-carousel-items-container {
    flex-direction: column;
    height: 100%;
  }
  /* Keyboard Support */
  .p-items-hidden .p-carousel-item {
    visibility: hidden;
  }
  .p-items-hidden .p-carousel-item.p-carousel-item-active {
    visibility: visible;
  }
  .p-carousel .p-carousel-content .p-carousel-prev,
  .p-carousel .p-carousel-content .p-carousel-next {
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
    margin: 0.5rem;
  }
  .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
  .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-carousel .p-carousel-content .p-carousel-prev:focus-visible,
  .p-carousel .p-carousel-content .p-carousel-next:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-carousel .p-carousel-indicators {
    padding: 1rem;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #3f3f46;
    width: 2rem;
    height: 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #3f3f46;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-datatable {
    position: relative;
  }
  .p-datatable-table {
    border-spacing: 0px;
    width: 100%;
  }
  .p-datatable .p-sortable-column {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-datatable .p-sortable-column .p-column-title,
  .p-datatable .p-sortable-column .p-sortable-column-icon,
  .p-datatable .p-sortable-column .p-sortable-column-badge {
    vertical-align: middle;
  }
  .p-datatable .p-sortable-column .p-sortable-column-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .p-datatable-hoverable-rows .p-selectable-row {
    cursor: pointer;
  }
  /* Scrollable */
  .p-datatable-scrollable > .p-datatable-wrapper {
    position: relative;
  }
  .p-datatable-scrollable-table > .p-datatable-thead {
    top: 0;
    z-index: 1;
  }
  .p-datatable-scrollable-table > .p-datatable-frozen-tbody {
    position: sticky;
    z-index: 1;
  }
  .p-datatable-scrollable-table > .p-datatable-tfoot {
    bottom: 0;
    z-index: 1;
  }
  .p-datatable-scrollable .p-frozen-column {
    position: sticky;
    background: inherit;
  }
  .p-datatable-scrollable th.p-frozen-column {
    z-index: 1;
  }
  .p-datatable-flex-scrollable {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .p-datatable-flex-scrollable > .p-datatable-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }
  .p-datatable-scrollable-table > .p-datatable-tbody > .p-rowgroup-header {
    position: sticky;
    z-index: 1;
  }
  /* Resizable */
  .p-datatable-resizable-table > .p-datatable-thead > tr > th,
  .p-datatable-resizable-table > .p-datatable-tfoot > tr > td,
  .p-datatable-resizable-table > .p-datatable-tbody > tr > td {
    overflow: hidden;
    white-space: nowrap;
  }
  .p-datatable-resizable-table > .p-datatable-thead > tr > th.p-resizable-column:not(.p-frozen-column) {
    background-clip: padding-box;
    position: relative;
  }
  .p-datatable-resizable-table-fit > .p-datatable-thead > tr > th.p-resizable-column:last-child .p-column-resizer {
    display: none;
  }
  .p-datatable .p-column-resizer {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    width: 0.5rem;
    height: 100%;
    padding: 0px;
    cursor: col-resize;
    border: 1px solid transparent;
  }
  .p-datatable .p-column-header-content {
    display: flex;
    align-items: center;
  }
  .p-datatable .p-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
  }
  .p-datatable .p-row-editor-init,
  .p-datatable .p-row-editor-save,
  .p-datatable .p-row-editor-cancel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  /* Expand */
  .p-datatable .p-row-toggler {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  /* Reorder */
  .p-datatable-reorder-indicator-up,
  .p-datatable-reorder-indicator-down {
    position: absolute;
    display: none;
  }
  .p-reorderable-column,
  .p-datatable-reorderablerow-handle {
    cursor: move;
  }
  /* Loader */
  .p-datatable .p-datatable-loading-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  /* Filter */
  .p-column-filter-row {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .p-column-filter-menu {
    display: inline-flex;
    margin-left: auto;
  }
  .p-column-filter-row .p-column-filter-element {
    flex: 1 1 auto;
    width: 1%;
  }
  .p-column-filter-menu-button,
  .p-column-filter-clear-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    position: relative;
  }
  .p-column-filter-row-items {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .p-column-filter-row-item {
    cursor: pointer;
  }
  .p-column-filter-add-button,
  .p-column-filter-remove-button {
    justify-content: center;
  }
  .p-column-filter-add-button .p-button-label,
  .p-column-filter-remove-button .p-button-label {
    flex-grow: 0;
  }
  .p-column-filter-buttonbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
    width: auto;
  }
  /* Responsive */
  .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
    display: none;
  }
  /* VirtualScroller */
  .p-datatable-virtualscroller-spacer {
    display: flex;
  }
  .p-datatable .p-virtualscroller .p-virtualscroller-loading {
    transform: none !important;
    min-height: 0;
    position: sticky;
    top: 0;
    left: 0;
  }
  .p-datatable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-datatable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-datatable .p-datatable-header {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #27272a;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1rem;
    font-weight: 600;
  }
  .p-datatable .p-datatable-footer {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #27272a;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1rem;
    font-weight: 600;
  }
  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0.75rem 1rem;
    border: 1px solid #27272a;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #ffffff;
    background: #18181b;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 0.75rem 1rem;
    border: 1px solid #27272a;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #ffffff;
    background: #18181b;
  }
  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #a1a1aa;
    margin-left: 0.5rem;
  }
  .p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1rem;
    min-width: 1rem;
    line-height: 1rem;
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
    margin-left: 0.5rem;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #ffffff;
  }
  .p-datatable .p-sortable-column.p-highlight {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-datatable .p-sortable-column.p-highlight:hover {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-datatable .p-sortable-column:focus-visible {
    box-shadow: 0 none;
    outline: 0 none;
  }
  .p-datatable .p-datatable-tbody > tr {
    background: #18181b;
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #27272a;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1rem;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: 0.5rem;
  }
  .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr:focus-visible {
    outline: 0.15rem solid var(--p-focus-ring-color);
    outline-offset: -0.15rem;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight-contextmenu {
    outline: 0.15rem solid var(--p-focus-ring-color);
    outline-offset: -0.15rem;
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 rgba(250, 250, 250, 0.16);
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 rgba(250, 250, 250, 0.16);
  }
  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-datatable .p-column-resizer-helper {
    background: #000000;
  }
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-thead,
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-tfoot {
    background-color: #18181b;
  }
  .p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
  }
  .p-datatable .p-datatable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
    border-width: 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px 0 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
    border-width: 1px 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
    border-width: 0 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    border-width: 0 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
    border-width: 0 0 0 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 0 1px 0 1px;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background: #1f1f22;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.375rem 0.5rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.375rem 0.5rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.375rem 0.5rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: 0.375rem 0.5rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.375rem 0.5rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-header {
    padding: 0.9375rem 1.25rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 0.9375rem 1.25rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 0.9375rem 1.25rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 0.9375rem 1.25rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 0.9375rem 1.25rem;
  }
  .p-dataview .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-dataview .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-dataview .p-dataview-header {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #27272a;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1rem;
    font-weight: 600;
  }
  .p-dataview .p-dataview-content {
    background: #18181b;
    color: #ffffff;
    border: 0 none;
    padding: 0;
  }
  .p-dataview .p-dataview-footer {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #27272a;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-column-filter-row .p-column-filter-menu-button,
  .p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
  }
  .p-column-filter-menu-button {
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-column-filter-menu-button:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-column-filter-menu-button:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-column-filter-clear-button {
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-column-filter-clear-button:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-column-filter-clear-button:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-column-filter-overlay {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    min-width: 12.5rem;
  }
  .p-column-filter-overlay .p-column-filter-row-items {
    padding: 0.25rem 0.25rem;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 2px 0;
    padding: 0.5rem 0.75rem;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:first-child {
    margin-top: 0;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:last-child {
    margin-bottom: 0;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: 0 none;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid #3f3f46;
    margin: 2px 0;
  }
  .p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0.5rem 0.5rem 0 0.5rem;
    border-bottom: 0 none;
    color: #ffffff;
    background: #18181b;
    margin: 0 0 0 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 0 1.125rem 1.125rem 1.125rem;
    border-bottom: 1px solid #3f3f46;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
  }
  .p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0 1.125rem 1.125rem 1.125rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 0 1.125rem 1.125rem 1.125rem;
  }
  .p-orderlist {
    display: flex;
  }
  .p-orderlist-controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .p-orderlist-list-container {
    flex: 1 1 auto;
  }
  .p-orderlist-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    min-height: 12rem;
    max-height: 24rem;
  }
  .p-orderlist-item {
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .p-orderlist.p-state-disabled .p-orderlist-item,
  .p-orderlist.p-state-disabled .p-button {
    cursor: default;
  }
  .p-orderlist.p-state-disabled .p-orderlist-list {
    overflow: hidden;
  }
  .p-orderlist .p-orderlist-controls {
    padding: 0 1.125rem 1.125rem 1.125rem;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: 0.5rem;
  }
  .p-orderlist .p-orderlist-list-container {
    background: #18181b;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-orderlist .p-orderlist-list-container.p-focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-orderlist .p-orderlist-header {
    color: #ffffff;
    padding: 1.125rem;
    font-weight: 600;
  }
  .p-orderlist .p-orderlist-list {
    color: #ffffff;
    padding: 0.25rem 0.25rem;
    outline: 0 none;
  }
  .p-orderlist .p-orderlist-list:not(:first-child) {
    border-top: 1px solid #3f3f46;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.5rem 0.75rem;
    margin: 2px 0;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: transform 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item:first-child {
    margin-top: 0;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item:last-child {
    margin-bottom: 0;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover.p-focus {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-focus {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight.p-focus {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
    background: #1f1f22;
  }
  .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
    background: rgba(255, 255, 255, 0.03);
  }
  .p-organizationchart-table {
    border-spacing: 0;
    border-collapse: separate;
    margin: 0 auto;
  }
  .p-organizationchart-table > tbody > tr > td {
    text-align: center;
    vertical-align: top;
    padding: 0 0.75rem;
  }
  .p-organizationchart-node-content {
    display: inline-block;
    position: relative;
  }
  .p-organizationchart-node-content .p-node-toggler {
    position: absolute;
    bottom: -0.75rem;
    margin-left: -0.75rem;
    z-index: 2;
    left: 50%;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    text-decoration: none;
  }
  .p-organizationchart-node-content .p-node-toggler .p-node-toggler-icon {
    position: relative;
    top: 0.25rem;
  }
  .p-organizationchart-line-down {
    margin: 0 auto;
    height: 20px;
    width: 1px;
  }
  .p-organizationchart-line-right {
    border-radius: 0px;
  }
  .p-organizationchart-line-left {
    border-radius: 0;
  }
  .p-organizationchart-selectable-node {
    cursor: pointer;
  }
  .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: rgba(186, 186, 186, 0.16);
  }
  .p-organizationchart .p-organizationchart-line-down {
    background: #3f3f46;
  }
  .p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #3f3f46;
    border-color: #3f3f46;
  }
  .p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #3f3f46;
    border-color: #3f3f46;
  }
  .p-organizationchart .p-organizationchart-node-content {
    border: 1px solid #3f3f46;
    background: #18181b;
    color: #ffffff;
    padding: 0 1.125rem 1.125rem 1.125rem;
  }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;
    outline-color: transparent;
  }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-paginator-default {
    display: flex;
  }
  .p-paginator {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .p-paginator-left-content {
    margin-right: auto;
  }
  .p-paginator-right-content {
    margin-left: auto;
  }
  .p-paginator-page,
  .p-paginator-next,
  .p-paginator-last,
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-current {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    -webkit-user-select: none;
            user-select: none;
    overflow: hidden;
    position: relative;
  }
  .p-paginator-element:focus {
    z-index: 1;
    position: relative;
  }
  .p-paginator {
    background: #18181b;
    color: #ffffff;
    border: solid #3f3f46;
    border-width: 0;
    padding: 0.5rem 1rem;
    border-radius: 6px;
  }
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: #a1a1aa;
    min-width: 2.5rem;
    height: 2.5rem;
    margin: 0.143rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 50%;
  }
  .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.03);
    border-color: transparent;
    color: #ffffff;
  }
  .p-paginator .p-paginator-first {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  .p-paginator .p-paginator-last {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  .p-paginator .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 2.5rem;
  }
  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
  }
  .p-paginator .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 2.5rem;
  }
  .p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: #a1a1aa;
    min-width: 2.5rem;
    height: 2.5rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
  }
  .p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #a1a1aa;
    min-width: 2.5rem;
    height: 2.5rem;
    margin: 0.143rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 50%;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: rgba(250, 250, 250, 0.16);
    border-color: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.03);
    border-color: transparent;
    color: #ffffff;
  }
  .p-picklist {
    display: flex;
  }
  .p-picklist-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .p-picklist-list-wrapper {
    flex: 1 1 50%;
  }
  .p-picklist-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    min-height: 12rem;
    max-height: 24rem;
  }
  .p-picklist-item {
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .p-picklist-item.p-picklist-flip-enter-active.p-picklist-flip-enter-to,
  .p-picklist-item.p-picklist-flip-leave-active.p-picklist-flip-leave-to {
    transition: none;
  }
  .p-picklist .p-picklist-buttons {
    padding: 0 1.125rem 1.125rem 1.125rem;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
  }
  .p-picklist .p-picklist-list-wrapper {
    background: #18181b;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-picklist .p-picklist-list-wrapper.p-focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-picklist .p-picklist-header {
    color: #ffffff;
    padding: 1.125rem;
    font-weight: 600;
  }
  .p-picklist .p-picklist-list {
    color: #ffffff;
    padding: 0.25rem 0.25rem;
    outline: 0 none;
  }
  .p-picklist .p-picklist-list:not(:first-child) {
    border-top: 1px solid #3f3f46;
  }
  .p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.5rem 0.75rem;
    margin: 2px 0;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: transform 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-picklist .p-picklist-list .p-picklist-item:first-child {
    margin-top: 0;
  }
  .p-picklist .p-picklist-list .p-picklist-item:last-child {
    margin-bottom: 0;
  }
  .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover.p-focus {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-focus {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-highlight.p-focus {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
    background: #1f1f22;
  }
  .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover {
    background: rgba(255, 255, 255, 0.03);
  }
  .p-timeline {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  .p-timeline-left .p-timeline-event-opposite {
    text-align: right;
  }
  .p-timeline-left .p-timeline-event-content {
    text-align: left;
  }
  .p-timeline-right .p-timeline-event {
    flex-direction: row-reverse;
  }
  .p-timeline-right .p-timeline-event-opposite {
    text-align: left;
  }
  .p-timeline-right .p-timeline-event-content {
    text-align: right;
  }
  .p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(even) {
    flex-direction: row-reverse;
  }
  .p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(odd) .p-timeline-event-opposite {
    text-align: right;
  }
  .p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(odd) .p-timeline-event-content {
    text-align: left;
  }
  .p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(even) .p-timeline-event-opposite {
    text-align: left;
  }
  .p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(even) .p-timeline-event-content {
    text-align: right;
  }
  .p-timeline-event {
    display: flex;
    position: relative;
    min-height: 70px;
  }
  .p-timeline-event:last-child {
    min-height: 0;
  }
  .p-timeline-event-opposite {
    flex: 1;
    padding: 0 1rem;
  }
  .p-timeline-event-content {
    flex: 1;
    padding: 0 1rem;
  }
  .p-timeline-event-separator {
    flex: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .p-timeline-event-marker {
    display: flex;
    align-self: baseline;
  }
  .p-timeline-event-connector {
    flex-grow: 1;
  }
  .p-timeline-horizontal {
    flex-direction: row;
  }
  .p-timeline-horizontal .p-timeline-event {
    flex-direction: column;
    flex: 1;
  }
  .p-timeline-horizontal .p-timeline-event:last-child {
    flex: 0;
  }
  .p-timeline-horizontal .p-timeline-event-separator {
    flex-direction: row;
  }
  .p-timeline-horizontal .p-timeline-event-connector {
    width: 100%;
  }
  .p-timeline-bottom .p-timeline-event {
    flex-direction: column-reverse;
  }
  .p-timeline-horizontal.p-timeline-alternate .p-timeline-event:nth-child(even) {
    flex-direction: column-reverse;
  }
  .p-timeline .p-timeline-event-marker {
    border: 2px solid #3f3f46;
    border-radius: 50%;
    width: 1.125rem;
    height: 1.125rem;
    background-color: #ffffff;
  }
  .p-timeline .p-timeline-event-connector {
    background-color: #3f3f46;
  }
  .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
  .p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
  }
  .p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
  }
  .p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
  .p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0;
  }
  .p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px;
  }
  .p-tree-container {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow: auto;
  }
  .p-treenode-children {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .p-tree-wrapper {
    overflow: auto;
  }
  .p-treenode-selectable {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-tree-toggler {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
  }
  .p-treenode-leaf > .p-treenode-content .p-tree-toggler {
    visibility: hidden;
  }
  .p-treenode-content {
    display: flex;
    align-items: center;
  }
  .p-tree-filter {
    width: 100%;
  }
  .p-tree-filter-container {
    position: relative;
    display: block;
    width: 100%;
  }
  .p-tree-filter-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
  }
  .p-tree-loading {
    position: relative;
    min-height: 4rem;
  }
  .p-tree .p-tree-loading-overlay {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-tree-flex-scrollable {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
  }
  .p-tree-flex-scrollable .p-tree-wrapper {
    flex: 1;
  }
  .p-tree {
    border: 1px solid #3f3f46;
    background: #18181b;
    color: #ffffff;
    padding: 0 1.125rem 1.125rem 1.125rem;
    border-radius: 6px;
  }
  .p-tree .p-tree-container .p-treenode {
    padding: 0 0;
    outline: 0 none;
  }
  .p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: 0 none;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    padding: 0.25rem 0.5rem;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    margin-right: 0.5rem;
    color: #a1a1aa;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-indeterminate .p-checkbox-icon {
    color: #ffffff;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-tree .p-tree-filter-container {
    margin-bottom: 0.5rem;
  }
  .p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.75rem;
  }
  .p-tree .p-tree-filter-container .p-tree-filter-icon {
    right: 0.75rem;
    color: #a1a1aa;
  }
  .p-tree .p-treenode-children {
    padding: 0 0 0 1rem;
  }
  .p-tree .p-tree-loading-icon {
    font-size: 2rem;
  }
  .p-tree .p-tree-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-treetable {
    position: relative;
  }
  .p-treetable table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }
  .p-treetable .p-sortable-column {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-treetable-responsive-scroll > .p-treetable-wrapper {
    overflow-x: auto;
  }
  .p-treetable-responsive-scroll > .p-treetable-wrapper > table,
  .p-treetable-auto-layout > .p-treetable-wrapper > table {
    table-layout: auto;
  }
  .p-treetable-hoverable-rows .p-treetable-tbody > tr {
    cursor: pointer;
  }
  .p-treetable-toggler {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    position: relative;
  }
  .p-treetable-toggler + .p-checkbox {
    vertical-align: middle;
  }
  .p-treetable-toggler + .p-checkbox + span {
    vertical-align: middle;
  }
  /* Resizable */
  .p-treetable-resizable > .p-treetable-wrapper {
    overflow-x: auto;
  }
  .p-treetable-resizable .p-treetable-thead > tr > th,
  .p-treetable-resizable .p-treetable-tfoot > tr > td,
  .p-treetable-resizable .p-treetable-tbody > tr > td {
    overflow: hidden;
  }
  .p-treetable-resizable .p-resizable-column:not(.p-frozen-column) {
    background-clip: padding-box;
    position: relative;
  }
  .p-treetable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
    display: none;
  }
  .p-treetable .p-column-resizer {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    width: 0.5rem;
    height: 100%;
    padding: 0px;
    cursor: col-resize;
    border: 1px solid transparent;
  }
  .p-treetable .p-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
  }
  .p-treetable .p-treetable-loading-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  /* Scrollable */
  .p-treetable-scrollable .p-treetable-wrapper {
    position: relative;
    overflow: auto;
  }
  .p-treetable-scrollable .p-treetable-table {
    display: block;
  }
  .p-treetable-scrollable .p-treetable-thead,
  .p-treetable-scrollable .p-treetable-tbody,
  .p-treetable-scrollable .p-treetable-tfoot {
    display: block;
  }
  .p-treetable-scrollable .p-treetable-thead > tr,
  .p-treetable-scrollable .p-treetable-tbody > tr,
  .p-treetable-scrollable .p-treetable-tfoot > tr {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  .p-treetable-scrollable .p-treetable-thead > tr > th,
  .p-treetable-scrollable .p-treetable-tbody > tr > td,
  .p-treetable-scrollable .p-treetable-tfoot > tr > td {
    display: flex;
    flex: 1 1 0;
    align-items: center;
  }
  .p-treetable-scrollable .p-treetable-thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .p-treetable-scrollable .p-treetable-tfoot {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
  .p-treetable-scrollable .p-frozen-column {
    position: sticky;
    background: inherit;
  }
  .p-treetable-scrollable th.p-frozen-column {
    z-index: 1;
  }
  .p-treetable-scrollable-both .p-treetable-thead > tr > th,
  .p-treetable-scrollable-both .p-treetable-tbody > tr > td,
  .p-treetable-scrollable-both .p-treetable-tfoot > tr > td,
  .p-treetable-scrollable-horizontal .p-treetable-thead > tr > th .p-treetable-scrollable-horizontal .p-treetable-tbody > tr > td,
  .p-treetable-scrollable-horizontal .p-treetable-tfoot > tr > td {
    flex: 0 0 auto;
  }
  .p-treetable-flex-scrollable {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .p-treetable-flex-scrollable .p-treetable-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }
  .p-treetable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-treetable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-treetable .p-treetable-header {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #27272a;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1rem;
    font-weight: 600;
  }
  .p-treetable .p-treetable-footer {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #27272a;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1rem;
    font-weight: 600;
  }
  .p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    padding: 0.75rem 1rem;
    border: 1px solid #27272a;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #ffffff;
    background: #18181b;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    padding: 0.75rem 1rem;
    border: 1px solid #27272a;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #ffffff;
    background: #18181b;
  }
  .p-treetable .p-sortable-column {
    outline-color: var(--p-focus-ring-color);
  }
  .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #a1a1aa;
    margin-left: 0.5rem;
  }
  .p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1rem;
    min-width: 1rem;
    line-height: 1rem;
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
    margin-left: 0.5rem;
  }
  .p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #ffffff;
  }
  .p-treetable .p-sortable-column.p-highlight {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-treetable .p-treetable-tbody > tr {
    background: #18181b;
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 1px solid #27272a;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
    margin-right: 0.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox.p-indeterminate .p-checkbox-icon {
    color: #ffffff;
  }
  .p-treetable .p-treetable-tbody > tr:focus-visible {
    outline: 0.15rem solid var(--p-focus-ring-color);
    outline-offset: -0.15rem;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
    color: #ffffff;
  }
  .p-treetable .p-column-resizer-helper {
    background: #000000;
  }
  .p-treetable .p-treetable-scrollable-header,
  .p-treetable .p-treetable-scrollable-footer {
    background: #18181b;
  }
  .p-treetable .p-treetable-loading-icon {
    font-size: 2rem;
  }
  .p-treetable .p-treetable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px 0 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
    border-width: 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
    border-width: 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px;
  }
  .p-treetable.p-treetable-sm .p-treetable-header {
    padding: 0.65625rem 0.875rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
    padding: 0.375rem 0.5rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
    padding: 0.375rem 0.5rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
    padding: 0.375rem 0.5rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-footer {
    padding: 0.375rem 0.5rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-header {
    padding: 0.9375rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
    padding: 0.9375rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
    padding: 0.9375rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
    padding: 0.9375rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 0.9375rem 1.25rem;
  }
  .p-accordion-header-action {
    cursor: pointer;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
    position: relative;
    text-decoration: none;
  }
  .p-accordion-header-action:focus {
    z-index: 1;
  }
  .p-accordion-header-text {
    line-height: 1;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1.125rem 1.125rem 1.125rem 1.125rem;
    border: 0 none;
    color: #a1a1aa;
    background: #18181b;
    font-weight: 600;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: 0.5rem;
  }
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -2px;
    box-shadow: inset none;
  }
  .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #18181b;
    border-color: #18181b;
    color: #ffffff;
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #18181b;
    border-color: #18181b;
    color: #ffffff;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-color: #18181b;
    background: #18181b;
    color: #ffffff;
  }
  .p-accordion .p-accordion-content {
    padding: 0 1.125rem 1.125rem 1.125rem;
    border: 0 none;
    background: #18181b;
    color: #ffffff;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-accordion .p-accordion-tab {
    margin-bottom: 0;
  }
  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    border-radius: 0;
  }
  .p-accordion .p-accordion-tab .p-accordion-content {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
    border-top: 0 none;
  }
  .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-top: 0 none;
  }
  .p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-accordion .p-accordion-tab:last-child .p-accordion-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-card {
    background: #18181b;
    color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  .p-card .p-card-body {
    padding: 1.5rem;
  }
  .p-card .p-card-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .p-card .p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: #a1a1aa;
  }
  .p-card .p-card-content {
    padding: 0 0 0 0;
  }
  .p-card .p-card-footer {
    padding: 0 0 0 0;
  }
  .p-fieldset-legend > a,
  .p-fieldset-legend > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-fieldset-toggleable .p-fieldset-legend a {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    overflow: hidden;
    position: relative;
    text-decoration: none;
  }
  .p-fieldset-legend-text {
    line-height: 1;
  }
  .p-fieldset {
    border: 1px solid #3f3f46;
    background: #18181b;
    color: #ffffff;
    border-radius: 6px;
  }
  .p-fieldset .p-fieldset-legend {
    padding: 1.125rem;
    border: 1px solid #3f3f46;
    color: #ffffff;
    background: #18181b;
    font-weight: 600;
    border-radius: 6px;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 1.125rem;
    color: #ffffff;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: 0.5rem;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:hover {
    color: #ffffff;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: rgba(255, 255, 255, 0.03);
    border-color: #3f3f46;
    color: #ffffff;
  }
  .p-fieldset .p-fieldset-content {
    padding: 0 1.125rem 1.125rem 1.125rem;
  }
  .p-divider-horizontal {
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
  }
  .p-divider-horizontal:before {
    position: absolute;
    display: block;
    top: 50%;
    left: 0;
    width: 100%;
    content: "";
  }
  .p-divider-content {
    z-index: 1;
  }
  .p-divider-vertical {
    min-height: 100%;
    margin: 0 1rem;
    display: flex;
    position: relative;
    justify-content: center;
  }
  .p-divider-vertical:before {
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    height: 100%;
    content: "";
  }
  .p-divider.p-divider-solid.p-divider-horizontal:before {
    border-top-style: solid;
  }
  .p-divider.p-divider-solid.p-divider-vertical:before {
    border-left-style: solid;
  }
  .p-divider.p-divider-dashed.p-divider-horizontal:before {
    border-top-style: dashed;
  }
  .p-divider.p-divider-dashed.p-divider-vertical:before {
    border-left-style: dashed;
  }
  .p-divider.p-divider-dotted.p-divider-horizontal:before {
    border-top-style: dotted;
  }
  .p-divider.p-divider-dotted.p-divider-vertical:before {
    border-left-style: dotted;
  }
  .p-divider .p-divider-content {
    background-color: #18181b;
  }
  .p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem;
  }
  .p-divider.p-divider-horizontal:before {
    border-top: 1px solid #3f3f46;
  }
  .p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 0.5rem;
  }
  .p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0;
  }
  .p-divider.p-divider-vertical:before {
    border-left: 1px solid #3f3f46;
  }
  .p-divider.p-divider-vertical .p-divider-content {
    padding: 0.5rem 0;
  }
  .p-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .p-panel-title {
    line-height: 1;
  }
  .p-panel-header-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    position: relative;
  }
  .p-panel .p-panel-header {
    border: 1px solid #3f3f46;
    padding: 1.125rem;
    background: #18181b;
    color: #ffffff;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-panel .p-panel-header .p-panel-title {
    font-weight: 600;
  }
  .p-panel .p-panel-header .p-panel-header-icon {
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-panel .p-panel-header .p-panel-header-icon:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.75rem 1.125rem;
  }
  .p-panel .p-panel-content {
    padding: 0 1.125rem 1.125rem 1.125rem;
    border: 1px solid #3f3f46;
    background: #18181b;
    color: #ffffff;
    border-top: 0 none;
  }
  .p-panel .p-panel-content:last-child {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-panel .p-panel-footer {
    padding: 0 1.125rem 1.125rem 1.125rem;
    border: 1px solid #3f3f46;
    background: #18181b;
    color: #ffffff;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top: 0 none;
  }
  .p-scrollpanel-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    float: left;
  }
  .p-scrollpanel-content {
    height: calc(100% + 18px);
    width: calc(100% + 18px);
    padding: 0 18px 18px 0;
    position: relative;
    overflow: auto;
    box-sizing: border-box;
    scrollbar-width: none;
  }
  .p-scrollpanel-content::-webkit-scrollbar {
    display: none;
  }
  .p-scrollpanel-bar {
    position: relative;
    background: #c1c1c1;
    border-radius: 3px;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.25s linear;
  }
  .p-scrollpanel-bar-y {
    width: 9px;
    top: 0;
  }
  .p-scrollpanel-bar-x {
    height: 9px;
    bottom: 0;
  }
  .p-scrollpanel-hidden {
    visibility: hidden;
  }
  .p-scrollpanel:hover .p-scrollpanel-bar,
  .p-scrollpanel:active .p-scrollpanel-bar {
    opacity: 1;
  }
  .p-scrollpanel-grabbed {
    -webkit-user-select: none;
            user-select: none;
  }
  .p-scrollpanel .p-scrollpanel-bar {
    background: #3f3f46;
    border: 0 none;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-scrollpanel .p-scrollpanel-bar:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-splitter {
    display: flex;
    flex-wrap: nowrap;
  }
  .p-splitter-vertical {
    flex-direction: column;
  }
  .p-splitter-gutter {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: col-resize;
  }
  .p-splitter-horizontal.p-splitter-resizing {
    cursor: col-resize;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-splitter-horizontal > .p-splitter-gutter > .p-splitter-gutter-handle {
    height: 24px;
    width: 100%;
  }
  .p-splitter-horizontal > .p-splitter-gutter {
    cursor: col-resize;
  }
  .p-splitter-vertical.p-splitter-resizing {
    cursor: row-resize;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-splitter-vertical > .p-splitter-gutter {
    cursor: row-resize;
  }
  .p-splitter-vertical > .p-splitter-gutter > .p-splitter-gutter-handle {
    width: 24px;
    height: 100%;
  }
  .p-splitter-panel {
    flex-grow: 1;
    overflow: hidden;
  }
  .p-splitter-panel-nested {
    display: flex;
  }
  .p-splitter-panel .p-splitter {
    flex-grow: 1;
    border: 0 none;
  }
  .p-splitter {
    border: 1px solid #3f3f46;
    background: #18181b;
    border-radius: 6px;
    color: #ffffff;
  }
  .p-splitter .p-splitter-gutter {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    background: #3f3f46;
  }
  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #3f3f46;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-splitter .p-splitter-gutter-resizing {
    background: #3f3f46;
  }
  .p-stepper .p-stepper-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-x: auto;
  }
  .p-stepper-vertical .p-stepper-nav {
    flex-direction: column;
  }
  .p-stepper-header {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }
  .p-stepper-header:last-of-type {
    flex: initial;
  }
  .p-stepper-header .p-stepper-action {
    border: 0 none;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .p-stepper-header .p-stepper-action:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-stepper.p-stepper-readonly .p-stepper-header {
    cursor: auto;
  }
  .p-stepper-header.p-highlight .p-stepper-action {
    cursor: default;
  }
  .p-stepper-title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .p-stepper-number {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-stepper-separator {
    flex: 1 1 0;
  }
  .p-stepper .p-stepper-nav {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .p-stepper .p-stepper-header {
    padding: 0.5rem;
  }
  .p-stepper .p-stepper-header .p-stepper-action {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
    background: #18181b;
    outline-color: transparent;
  }
  .p-stepper .p-stepper-header .p-stepper-action .p-stepper-number {
    color: #a1a1aa;
    border: 1px solid #3f3f46;
    border-width: 2px;
    background: #18181b;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-stepper .p-stepper-header .p-stepper-action .p-stepper-title {
    margin-left: 0.5rem;
    color: #ffffff;
    font-weight: 500;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-stepper .p-stepper-header .p-stepper-action:not(.p-disabled):focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-stepper .p-stepper-header.p-highlight .p-stepper-title {
    color: #ffffff;
  }
  .p-stepper .p-stepper-header:not(.p-disabled):focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
    background-color: #000000;
  }
  .p-stepper .p-stepper-panels {
    background: #18181b;
    padding: 0.875rem 1.125rem 1.125rem 1.125rem;
    color: #ffffff;
  }
  .p-stepper .p-stepper-separator {
    background-color: #3f3f46;
    width: 100%;
    height: 2px;
    margin-inline-start: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-stepper.p-stepper-vertical {
    display: flex;
    flex-direction: column;
  }
  .p-stepper.p-stepper-vertical .p-stepper-toggleable-content {
    display: flex;
    flex: 1 1 auto;
    background: #18181b;
    color: #ffffff;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel {
    display: flex;
    flex-direction: column;
    flex: initial;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel.p-stepper-panel-active {
    flex: 1 1 auto;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-header {
    flex: initial;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-content {
    width: 100%;
    padding-left: 1rem;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-separator {
    flex: 0 0 auto;
    width: 2px;
    height: auto;
    margin-inline-start: calc(1.75rem + 2px);
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel:has(~ .p-stepper-panel-active) .p-stepper-separator {
    background-color: #000000;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel:last-of-type .p-stepper-content {
    padding-left: 3rem;
  }
  .p-tabview-nav-container {
    position: relative;
  }
  .p-tabview-scrollable .p-tabview-nav-container {
    overflow: hidden;
  }
  .p-tabview-nav-content {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
    overscroll-behavior: contain auto;
  }
  .p-tabview-nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    flex: 1 1 auto;
  }
  .p-tabview-header-action {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    overflow: hidden;
  }
  .p-tabview-ink-bar {
    display: none;
    z-index: 1;
  }
  .p-tabview-header-action:focus {
    z-index: 1;
  }
  .p-tabview-title {
    line-height: 1;
    white-space: nowrap;
  }
  .p-tabview-nav-btn {
    position: absolute;
    top: 0;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-tabview-nav-prev {
    left: 0;
  }
  .p-tabview-nav-next {
    right: 0;
  }
  .p-tabview-nav-content::-webkit-scrollbar {
    display: none;
  }
  .p-tabview .p-tabview-nav {
    background: #18181b;
    border: 1px solid #3f3f46;
    border-width: 0 0 1px 0;
  }
  .p-tabview .p-tabview-nav li {
    margin-right: 0;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #3f3f46;
    border-width: 0 0 1px 0;
    border-color: transparent transparent #3f3f46 transparent;
    background: #18181b;
    color: #a1a1aa;
    padding: 1rem 1.125rem;
    font-weight: 600;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    margin: 0 0 -1px 0;
    outline-color: transparent;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -2px;
    box-shadow: inset none;
  }
  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: #18181b;
    border-color: #3f3f46;
    color: #ffffff;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #18181b;
    border-color: #3f3f46;
    color: #000000;
  }
  .p-tabview .p-tabview-nav-btn.p-link {
    background: #18181b;
    color: #000000;
    width: 2.5rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
    outline-color: transparent;
  }
  .p-tabview .p-tabview-nav-btn.p-link:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -2px;
    box-shadow: inset none;
  }
  .p-tabview .p-tabview-panels {
    background: #18181b;
    padding: 0.875rem 1.125rem 1.125rem 1.125rem;
    border: 0 none;
    color: #ffffff;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .p-toolbar-group-start,
  .p-toolbar-group-center,
  .p-toolbar-group-end {
    display: flex;
    align-items: center;
  }
  .p-toolbar-group-left,
  .p-toolbar-group-right {
    display: flex;
    align-items: center;
  }
  .p-toolbar {
    background: #18181b;
    border: 1px solid #3f3f46;
    padding: 1.125rem;
    border-radius: 6px;
    gap: 0.5rem;
  }
  .p-toolbar .p-toolbar-separator {
    margin: 0 0.5rem;
  }
  .p-confirm-popup {
    position: absolute;
    margin-top: 10px;
    top: 0;
    left: 0;
  }
  .p-confirm-popup-flipped {
    margin-top: -10px;
    margin-bottom: 10px;
  }
  /* Animation */
  .p-confirm-popup-enter-from {
    opacity: 0;
    transform: scaleY(0.8);
  }
  .p-confirm-popup-leave-to {
    opacity: 0;
  }
  .p-confirm-popup-enter-active {
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  .p-confirm-popup-leave-active {
    transition: opacity 0.1s linear;
  }
  .p-confirm-popup:after,
  .p-confirm-popup:before {
    bottom: 100%;
    left: calc(var(--overlayArrowLeft, 0) + 1.25rem);
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .p-confirm-popup:after {
    border-width: 8px;
    margin-left: -8px;
  }
  .p-confirm-popup:before {
    border-width: 10px;
    margin-left: -10px;
  }
  .p-confirm-popup-flipped:after,
  .p-confirm-popup-flipped:before {
    bottom: auto;
    top: 100%;
  }
  .p-confirm-popup.p-confirm-popup-flipped:after {
    border-bottom-color: transparent;
  }
  .p-confirm-popup.p-confirm-popup-flipped:before {
    border-bottom-color: transparent;
  }
  .p-confirm-popup .p-confirm-popup-content {
    display: flex;
    align-items: center;
  }
  .p-confirm-popup {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  }
  .p-confirm-popup .p-confirm-popup-content {
    padding: 1rem;
  }
  .p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0 1rem 1rem 1rem;
  }
  .p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
  }
  .p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0;
  }
  .p-confirm-popup:after {
    border-style: solid;
    border-color: rgba(24, 24, 27, 0);
    border-bottom-color: #18181b;
  }
  .p-confirm-popup:before {
    border-style: solid;
    border-color: rgba(63, 63, 70, 0);
    border-bottom-color: #3c3c43;
  }
  .p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: #18181b;
  }
  .p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: #3f3f46;
  }
  .p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
  }
  .p-confirm-popup .p-confirm-popup-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
  }
  .p-dialog-mask.p-component-overlay {
    pointer-events: auto;
  }
  .p-dialog {
    max-height: 90%;
    transform: scale(1);
  }
  .p-dialog-content {
    overflow-y: auto;
  }
  .p-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
  }
  .p-dialog-footer {
    flex-shrink: 0;
  }
  .p-dialog .p-dialog-header-icons {
    display: flex;
    align-items: center;
  }
  .p-dialog .p-dialog-header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  /* Fluid */
  .p-fluid .p-dialog-footer .p-button {
    width: auto;
  }
  /* Animation */
  /* Center */
  .p-dialog-enter-active {
    transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
  }
  .p-dialog-leave-active {
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .p-dialog-enter-from,
  .p-dialog-leave-to {
    opacity: 0;
    transform: scale(0.7);
  }
  /* Top, Bottom, Left, Right, Top* and Bottom* */
  .p-dialog-top .p-dialog,
  .p-dialog-bottom .p-dialog,
  .p-dialog-left .p-dialog,
  .p-dialog-right .p-dialog,
  .p-dialog-topleft .p-dialog,
  .p-dialog-topright .p-dialog,
  .p-dialog-bottomleft .p-dialog,
  .p-dialog-bottomright .p-dialog {
    margin: 0.75rem;
    transform: translate3d(0px, 0px, 0px);
  }
  .p-dialog-top .p-dialog-enter-active,
  .p-dialog-top .p-dialog-leave-active,
  .p-dialog-bottom .p-dialog-enter-active,
  .p-dialog-bottom .p-dialog-leave-active,
  .p-dialog-left .p-dialog-enter-active,
  .p-dialog-left .p-dialog-leave-active,
  .p-dialog-right .p-dialog-enter-active,
  .p-dialog-right .p-dialog-leave-active,
  .p-dialog-topleft .p-dialog-enter-active,
  .p-dialog-topleft .p-dialog-leave-active,
  .p-dialog-topright .p-dialog-enter-active,
  .p-dialog-topright .p-dialog-leave-active,
  .p-dialog-bottomleft .p-dialog-enter-active,
  .p-dialog-bottomleft .p-dialog-leave-active,
  .p-dialog-bottomright .p-dialog-enter-active,
  .p-dialog-bottomright .p-dialog-leave-active {
    transition: all 0.3s ease-out;
  }
  .p-dialog-top .p-dialog-enter-from,
  .p-dialog-top .p-dialog-leave-to {
    transform: translate3d(0px, -100%, 0px);
  }
  .p-dialog-bottom .p-dialog-enter-from,
  .p-dialog-bottom .p-dialog-leave-to {
    transform: translate3d(0px, 100%, 0px);
  }
  .p-dialog-left .p-dialog-enter-from,
  .p-dialog-left .p-dialog-leave-to,
  .p-dialog-topleft .p-dialog-enter-from,
  .p-dialog-topleft .p-dialog-leave-to,
  .p-dialog-bottomleft .p-dialog-enter-from,
  .p-dialog-bottomleft .p-dialog-leave-to {
    transform: translate3d(-100%, 0px, 0px);
  }
  .p-dialog-right .p-dialog-enter-from,
  .p-dialog-right .p-dialog-leave-to,
  .p-dialog-topright .p-dialog-enter-from,
  .p-dialog-topright .p-dialog-leave-to,
  .p-dialog-bottomright .p-dialog-enter-from,
  .p-dialog-bottomright .p-dialog-leave-to {
    transform: translate3d(100%, 0px, 0px);
  }
  /* Maximize */
  .p-dialog-maximized {
    width: 100vw !important;
    height: 100vh !important;
    top: 0px !important;
    left: 0px !important;
    max-height: 100%;
    height: 100%;
  }
  .p-dialog-maximized .p-dialog-content {
    flex-grow: 1;
  }
  .p-confirm-dialog .p-dialog-content {
    display: flex;
    align-items: center;
  }
  .p-dialog {
    border-radius: 6px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
    border: 1px solid #3f3f46;
  }
  .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #18181b;
    color: #ffffff;
    padding: 1.5rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 1.25rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
    margin-right: 0.5rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
  }
  .p-dialog .p-dialog-content {
    background: #18181b;
    color: #ffffff;
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
  .p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #18181b;
    color: #ffffff;
    padding: 0 1.5rem 1.5rem 1.5rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-message:not(:first-child) {
    margin-left: 1rem;
  }
  .p-overlaypanel {
    margin-top: 10px;
  }
  .p-overlaypanel-flipped {
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .p-overlaypanel-close {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  /* Animation */
  .p-overlaypanel-enter-from {
    opacity: 0;
    transform: scaleY(0.8);
  }
  .p-overlaypanel-leave-to {
    opacity: 0;
  }
  .p-overlaypanel-enter-active {
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  .p-overlaypanel-leave-active {
    transition: opacity 0.1s linear;
  }
  .p-overlaypanel:after,
  .p-overlaypanel:before {
    bottom: 100%;
    left: calc(var(--overlayArrowLeft, 0) + 1.25rem);
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .p-overlaypanel:after {
    border-width: 8px;
    margin-left: -8px;
  }
  .p-overlaypanel:before {
    border-width: 10px;
    margin-left: -10px;
  }
  .p-overlaypanel-flipped:after,
  .p-overlaypanel-flipped:before {
    bottom: auto;
    top: 100%;
  }
  .p-overlaypanel.p-overlaypanel-flipped:after {
    border-bottom-color: transparent;
  }
  .p-overlaypanel.p-overlaypanel-flipped:before {
    border-bottom-color: transparent;
  }
  .p-overlaypanel {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  }
  .p-overlaypanel .p-overlaypanel-content {
    padding: 0 1.125rem 1.125rem 1.125rem;
  }
  .p-overlaypanel .p-overlaypanel-close {
    background: #000000;
    color: #ffffff;
    width: 1.75rem;
    height: 1.75rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 50%;
    position: absolute;
    top: -0.875rem;
    right: -0.875rem;
  }
  .p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #111111;
    color: #ffffff;
  }
  .p-overlaypanel:after {
    border-style: solid;
    border-color: rgba(24, 24, 27, 0);
    border-bottom-color: #18181b;
  }
  .p-overlaypanel:before {
    border-style: solid;
    border-color: rgba(63, 63, 70, 0);
    border-bottom-color: #3c3c43;
  }
  .p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #18181b;
  }
  .p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #3f3f46;
  }
  .p-sidebar-mask {
    display: none;
    pointer-events: none;
    background-color: transparent;
    transition-property: background-color;
  }
  .p-sidebar-mask.p-component-overlay {
    pointer-events: auto;
  }
  .p-sidebar-visible {
    display: flex;
  }
  .p-sidebar {
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    transform: translate3d(0px, 0px, 0px);
    position: relative;
    transition: transform 0.3s;
  }
  .p-sidebar-content {
    overflow-y: auto;
    flex-grow: 1;
  }
  .p-sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
  }
  .p-sidebar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  .p-sidebar-full .p-sidebar {
    transition: none;
    transform: none;
    width: 100vw !important;
    height: 100vh !important;
    max-height: 100%;
    top: 0px !important;
    left: 0px !important;
  }
  /* Animation */
  /* Center */
  .p-sidebar-left .p-sidebar-enter-from,
  .p-sidebar-left .p-sidebar-leave-to {
    transform: translateX(-100%);
  }
  .p-sidebar-right .p-sidebar-enter-from,
  .p-sidebar-right .p-sidebar-leave-to {
    transform: translateX(100%);
  }
  .p-sidebar-top .p-sidebar-enter-from,
  .p-sidebar-top .p-sidebar-leave-to {
    transform: translateY(-100%);
  }
  .p-sidebar-bottom .p-sidebar-enter-from,
  .p-sidebar-bottom .p-sidebar-leave-to {
    transform: translateY(100%);
  }
  .p-sidebar-full .p-sidebar-enter-from,
  .p-sidebar-full .p-sidebar-leave-to {
    opacity: 0;
  }
  .p-sidebar-full .p-sidebar-enter-active,
  .p-sidebar-full .p-sidebar-leave-active {
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  /* Size */
  .p-sidebar-left .p-sidebar {
    width: 20rem;
    height: 100%;
  }
  .p-sidebar-right .p-sidebar {
    width: 20rem;
    height: 100%;
  }
  .p-sidebar-top .p-sidebar {
    height: 10rem;
    width: 100%;
  }
  .p-sidebar-bottom .p-sidebar {
    height: 10rem;
    width: 100%;
  }
  .p-sidebar-left .p-sidebar-sm,
  .p-sidebar-right .p-sidebar-sm {
    width: 20rem;
  }
  .p-sidebar-left .p-sidebar-md,
  .p-sidebar-right .p-sidebar-md {
    width: 40rem;
  }
  .p-sidebar-left .p-sidebar-lg,
  .p-sidebar-right .p-sidebar-lg {
    width: 60rem;
  }
  .p-sidebar-top .p-sidebar-sm,
  .p-sidebar-bottom .p-sidebar-sm {
    height: 10rem;
  }
  .p-sidebar-top .p-sidebar-md,
  .p-sidebar-bottom .p-sidebar-md {
    height: 20rem;
  }
  .p-sidebar-top .p-sidebar-lg,
  .p-sidebar-bottom .p-sidebar-lg {
    height: 30rem;
  }
  .p-sidebar-left .p-sidebar-content,
  .p-sidebar-right .p-sidebar-content,
  .p-sidebar-top .p-sidebar-content,
  .p-sidebar-bottom .p-sidebar-content {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 64em) {
    .p-sidebar-left .p-sidebar-lg,
    .p-sidebar-left .p-sidebar-md,
    .p-sidebar-right .p-sidebar-lg,
    .p-sidebar-right .p-sidebar-md {
      width: 20rem;
    }
  }
  .p-sidebar {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  }
  .p-sidebar .p-sidebar-header {
    padding: 1.125rem;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-header-content {
    font-weight: 600;
    font-size: 1.25rem;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close,
  .p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:focus-visible,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-top: 0;
  }
  .p-sidebar .p-sidebar-content {
    padding: 0 1.125rem 1.125rem 1.125rem;
  }
  .p-tooltip {
    position: absolute;
    display: none;
    padding: 0.25em 0.5rem;
    max-width: 12.5rem;
  }
  .p-tooltip.p-tooltip-right,
  .p-tooltip.p-tooltip-left {
    padding: 0 0.25rem;
  }
  .p-tooltip.p-tooltip-top,
  .p-tooltip.p-tooltip-bottom {
    padding: 0.25em 0;
  }
  .p-tooltip .p-tooltip-text {
    white-space: pre-line;
    word-break: break-word;
  }
  .p-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    scale: 2;
  }
  .p-tooltip-right .p-tooltip-arrow {
    margin-top: -0.25rem;
    border-width: 0.25em 0.25em 0.25em 0;
  }
  .p-tooltip-left .p-tooltip-arrow {
    margin-top: -0.25rem;
    border-width: 0.25em 0 0.25em 0.25rem;
  }
  .p-tooltip.p-tooltip-top {
    padding: 0.25em 0;
  }
  .p-tooltip-top .p-tooltip-arrow {
    margin-left: -0.25rem;
    border-width: 0.25em 0.25em 0;
  }
  .p-tooltip-bottom .p-tooltip-arrow {
    margin-left: -0.25rem;
    border-width: 0 0.25em 0.25rem;
  }
  .p-tooltip .p-tooltip-text {
    background: #3f3f46;
    color: #ffffff;
    padding: 0.5rem 0.75rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  .p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #3f3f46;
  }
  .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #3f3f46;
  }
  .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #3f3f46;
  }
  .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #3f3f46;
  }
  .p-fileupload-content {
    position: relative;
  }
  .p-fileupload-content .p-progressbar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .p-button.p-fileupload-choose {
    position: relative;
    overflow: hidden;
  }
  .p-fileupload-buttonbar {
    display: flex;
    flex-wrap: wrap;
  }
  .p-fileupload > input[type=file],
  .p-fileupload-basic input[type=file] {
    display: none;
  }
  .p-fluid .p-fileupload .p-button {
    width: auto;
  }
  .p-fileupload-file {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .p-fileupload-file-thumbnail {
    flex-shrink: 0;
  }
  .p-fileupload-file-actions {
    margin-left: auto;
  }
  .p-fileupload .p-fileupload-buttonbar {
    background: #18181b;
    padding: 1.125rem;
    border: 1px solid #3f3f46;
    color: #ffffff;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    gap: 0.5rem;
  }
  .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-fileupload .p-fileupload-content {
    background: #18181b;
    padding: 0 1.125rem 1.125rem 1.125rem;
    border: 1px solid #3f3f46;
    color: #ffffff;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-fileupload .p-fileupload-content.p-fileupload-highlight {
    border: 1px dashed #000000;
    background-color: rgba(250, 250, 250, 0.16);
  }
  .p-fileupload .p-fileupload-file {
    padding: 1rem;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .p-fileupload .p-fileupload-file:last-child {
    margin-bottom: 0;
  }
  .p-fileupload .p-fileupload-file-name {
    margin-bottom: 0.5rem;
  }
  .p-fileupload .p-fileupload-file-size {
    margin-right: 0.5rem;
  }
  .p-fileupload .p-progressbar {
    height: 0.25rem;
  }
  .p-fileupload .p-fileupload-row > div {
    padding: 0.75rem 1rem;
  }
  .p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
  }
  .p-fileupload-choose:not(.p-disabled):hover {
    background: #111111;
    color: #ffffff;
    border-color: #111111;
  }
  .p-fileupload-choose:not(.p-disabled):active {
    background: #222222;
    color: #ffffff;
    border-color: #222222;
  }
  .p-breadcrumb {
    overflow-x: auto;
  }
  .p-breadcrumb .p-breadcrumb-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .p-breadcrumb .p-menuitem-text {
    line-height: 1;
  }
  .p-breadcrumb .p-menuitem-link {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .p-breadcrumb .p-menuitem-separator {
    display: flex;
    align-items: center;
  }
  .p-breadcrumb::-webkit-scrollbar {
    display: none;
  }
  .p-breadcrumb {
    background: #18181b;
    border: 0 none;
    border-radius: 6px;
    padding: 1rem;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
    outline-color: transparent;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
  }
  .p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
    margin: 0 0.5rem 0 0.5rem;
    color: #71717a;
  }
  .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
    color: #ffffff;
  }
  .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-icon {
    color: #71717a;
  }
  .p-contextmenu ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .p-contextmenu .p-submenu-list {
    position: absolute;
    min-width: 100%;
    z-index: 1;
  }
  .p-contextmenu .p-menuitem-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    overflow: hidden;
    position: relative;
  }
  .p-contextmenu .p-menuitem-text {
    line-height: 1;
  }
  .p-contextmenu .p-menuitem {
    position: relative;
  }
  .p-contextmenu .p-menuitem-link .p-submenu-icon {
    margin-left: auto;
  }
  .p-contextmenu-enter-from,
  .p-contextmenu-leave-active {
    opacity: 0;
  }
  .p-contextmenu-enter-active {
    transition: opacity 250ms;
  }
  .p-contextmenu {
    padding: 0.25rem 0.25rem;
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    min-width: 12.5rem;
  }
  .p-contextmenu .p-contextmenu-root-list {
    outline: 0 none;
  }
  .p-contextmenu .p-submenu-list {
    padding: 0.25rem 0.25rem;
    background: #18181b;
    border: 1px solid #3f3f46;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  .p-contextmenu .p-menuitem {
    margin: 2px 0;
  }
  .p-contextmenu .p-menuitem:first-child {
    margin-top: 0;
  }
  .p-contextmenu .p-menuitem:last-child {
    margin-bottom: 0;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content {
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #ffffff;
    padding: 0.5rem 0.75rem;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }
  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-contextmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-contextmenu .p-menuitem-separator {
    border-top: 1px solid #3f3f46;
    margin: 2px 0;
  }
  .p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-contextmenu .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-dock {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }
  .p-dock-list-container {
    display: flex;
    pointer-events: auto;
  }
  .p-dock-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-dock-item {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
  }
  .p-dock-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: default;
  }
  .p-dock-item-second-prev,
  .p-dock-item-second-next {
    transform: scale(1.2);
  }
  .p-dock-item-prev,
  .p-dock-item-next {
    transform: scale(1.4);
  }
  .p-dock-item-current {
    transform: scale(1.6);
    z-index: 1;
  }
  /* Position */
  /* top */
  .p-dock-top {
    left: 0;
    top: 0;
    width: 100%;
  }
  .p-dock-top .p-dock-item {
    transform-origin: center top;
  }
  /* bottom */
  .p-dock-bottom {
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .p-dock-bottom .p-dock-item {
    transform-origin: center bottom;
  }
  /* right */
  .p-dock-right {
    right: 0;
    top: 0;
    height: 100%;
  }
  .p-dock-right .p-dock-item {
    transform-origin: center right;
  }
  .p-dock-right .p-dock-list {
    flex-direction: column;
  }
  /* left */
  .p-dock-left {
    left: 0;
    top: 0;
    height: 100%;
  }
  .p-dock-left .p-dock-item {
    transform-origin: center left;
  }
  .p-dock-left .p-dock-list {
    flex-direction: column;
  }
  .p-dock .p-dock-list-container {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
  }
  .p-dock .p-dock-list-container .p-dock-list {
    outline: 0 none;
  }
  .p-dock .p-dock-item {
    padding: 0.5rem;
    border-radius: 6px;
  }
  .p-dock .p-dock-item.p-focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: 0 none;
  }
  .p-dock .p-dock-link {
    width: 3rem;
    height: 3rem;
  }
  .p-dock.p-dock-top .p-dock-item-second-prev,
  .p-dock.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-bottom .p-dock-item-second-prev,
  .p-dock.p-dock-bottom .p-dock-item-second-next {
    margin: 0 0.9rem;
  }
  .p-dock.p-dock-top .p-dock-item-prev,
  .p-dock.p-dock-top .p-dock-item-next, .p-dock.p-dock-bottom .p-dock-item-prev,
  .p-dock.p-dock-bottom .p-dock-item-next {
    margin: 0 1.3rem;
  }
  .p-dock.p-dock-top .p-dock-item-current, .p-dock.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem;
  }
  .p-dock.p-dock-left .p-dock-item-second-prev,
  .p-dock.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-right .p-dock-item-second-prev,
  .p-dock.p-dock-right .p-dock-item-second-next {
    margin: 0.9rem 0;
  }
  .p-dock.p-dock-left .p-dock-item-prev,
  .p-dock.p-dock-left .p-dock-item-next, .p-dock.p-dock-right .p-dock-item-prev,
  .p-dock.p-dock-right .p-dock-item-next {
    margin: 1.3rem 0;
  }
  .p-dock.p-dock-left .p-dock-item-current, .p-dock.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0;
  }
  .p-dock.p-dock-mobile.p-dock-top .p-dock-list-container, .p-dock.p-dock-mobile.p-dock-bottom .p-dock-list-container {
    overflow-x: auto;
    width: 100%;
  }
  .p-dock.p-dock-mobile.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-mobile.p-dock-bottom .p-dock-list-container .p-dock-list {
    margin: 0 auto;
  }
  .p-dock.p-dock-mobile.p-dock-left .p-dock-list-container, .p-dock.p-dock-mobile.p-dock-right .p-dock-list-container {
    overflow-y: auto;
    height: 100%;
  }
  .p-dock.p-dock-mobile.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-mobile.p-dock-right .p-dock-list-container .p-dock-list {
    margin: auto 0;
  }
  .p-dock.p-dock-mobile .p-dock-list .p-dock-item {
    transform: none;
    margin: 0;
  }
  .p-megamenu {
    display: flex;
    position: relative;
  }
  .p-megamenu-root-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .p-megamenu .p-menuitem-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    overflow: hidden;
    position: relative;
  }
  .p-megamenu .p-menuitem-text {
    line-height: 1;
  }
  .p-megamenu-panel {
    display: none;
    width: auto;
    z-index: 1;
    left: 0;
    min-width: 100%;
  }
  .p-megamenu-panel:not(.p-megamenu-mobile) {
    position: absolute;
  }
  .p-megamenu-root-list > .p-menuitem-active > .p-megamenu-panel {
    display: block;
  }
  .p-megamenu-submenu {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .p-megamenu-button {
    display: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  /* Horizontal */
  .p-megamenu-horizontal {
    align-items: center;
  }
  .p-megamenu-horizontal .p-megamenu-root-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .p-megamenu-horizontal .p-megamenu-end {
    margin-left: auto;
    align-self: center;
  }
  /* Vertical */
  .p-megamenu-vertical {
    flex-direction: column;
  }
  .p-megamenu-vertical:not(.p-megamenu-mobile) {
    display: inline-flex;
  }
  .p-megamenu-vertical .p-megamenu-root-list {
    flex-direction: column;
  }
  .p-megamenu-vertical:not(.p-megamenu-mobile) .p-megamenu-root-list > .p-menuitem-active > .p-megamenu-panel {
    left: 100%;
    top: 0;
  }
  .p-megamenu-vertical .p-megamenu-root-list > .p-menuitem > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
  }
  .p-megamenu-grid {
    display: flex;
  }
  .p-megamenu-col-2,
  .p-megamenu-col-3,
  .p-megamenu-col-4,
  .p-megamenu-col-6,
  .p-megamenu-col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .p-megamenu-col-2 {
    width: 16.6667%;
  }
  .p-megamenu-col-3 {
    width: 25%;
  }
  .p-megamenu-col-4 {
    width: 33.3333%;
  }
  .p-megamenu-col-6 {
    width: 50%;
  }
  .p-megamenu-col-12 {
    width: 100%;
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-button {
    display: flex;
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list {
    position: absolute;
    display: none;
    width: 100%;
  }
  .p-megamenu.p-megamenu-mobile .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-grid {
    flex-wrap: wrap;
    overflow: auto;
    max-height: 90%;
  }
  .p-megamenu {
    padding: 0.5rem 0.5rem;
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
  }
  .p-megamenu .p-megamenu-root-list {
    outline: 0 none;
  }
  .p-megamenu .p-menuitem {
    margin: 2px 0;
  }
  .p-megamenu .p-menuitem:first-child {
    margin-top: 0;
  }
  .p-megamenu .p-menuitem:last-child {
    margin-bottom: 0;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content {
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #ffffff;
    padding: 0.5rem 0.75rem;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }
  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-megamenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-megamenu .p-megamenu-panel {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  .p-megamenu .p-submenu-header {
    margin: 0;
    padding: 0.5rem 0.75rem;
    color: #71717a;
    background: #18181b;
    font-weight: 600;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-megamenu .p-submenu-list {
    padding: 0.25rem 0.25rem;
    min-width: 12.5rem;
  }
  .p-megamenu .p-submenu-list .p-menuitem-separator {
    border-top: 1px solid #3f3f46;
    margin: 2px 0;
  }
  .p-megamenu.p-megamenu-vertical {
    min-width: 12.5rem;
    padding: 0.25rem 0.25rem;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content {
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    padding: 0.5rem 0.75rem;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
    margin-left: 0.5rem;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-megamenu.p-megamenu-mobile.p-megamenu-vertical {
    width: 100%;
    padding: 0.5rem 0.5rem;
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-button {
    width: 1.75rem;
    height: 1.75rem;
    color: #71717a;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-button:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-button:focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list {
    padding: 0.25rem 0.25rem;
    background: #18181b;
    border: 1px solid #3f3f46;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-menuitem-separator {
    border-top: 1px solid #3f3f46;
    margin: 2px 0;
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
    padding-left: 1.5rem;
  }
  .p-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .p-menu .p-menuitem-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    overflow: hidden;
    position: relative;
  }
  .p-menu .p-menuitem-text {
    line-height: 1;
  }
  .p-menu {
    padding: 0.25rem 0.25rem;
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    min-width: 12.5rem;
  }
  .p-menu .p-menuitem {
    margin: 2px 0;
  }
  .p-menu .p-menuitem:first-child {
    margin-top: 0;
  }
  .p-menu .p-menuitem:last-child {
    margin-bottom: 0;
  }
  .p-menu .p-menuitem > .p-menuitem-content {
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #ffffff;
    padding: 0.5rem 0.75rem;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }
  .p-menu .p-menuitem.p-highlight > .p-menuitem-content {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-menu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-menu.p-menu-overlay {
    background: #18181b;
    border: 1px solid #3f3f46;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-menu .p-submenu-header {
    margin: 0;
    padding: 0.5rem 0.75rem;
    color: #71717a;
    background: #18181b;
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .p-menu .p-menuitem-separator {
    border-top: 1px solid #3f3f46;
    margin: 2px 0;
  }
  .p-menubar {
    display: flex;
    align-items: center;
  }
  .p-menubar ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .p-menubar .p-menuitem-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    overflow: hidden;
    position: relative;
  }
  .p-menubar .p-menuitem-text {
    line-height: 1;
  }
  .p-menubar .p-menuitem {
    position: relative;
  }
  .p-menubar-root-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .p-menubar-root-list > li ul {
    display: none;
    z-index: 1;
  }
  .p-menubar-root-list > .p-menuitem-active > .p-submenu-list {
    display: block;
  }
  .p-menubar .p-submenu-list {
    display: none;
    position: absolute;
    z-index: 1;
  }
  .p-menubar .p-submenu-list > .p-menuitem-active > .p-submenu-list {
    display: block;
    left: 100%;
    top: 0;
  }
  .p-menubar .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    margin-left: auto;
  }
  .p-menubar .p-menubar-end {
    margin-left: auto;
    align-self: center;
  }
  .p-menubar-button {
    display: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  .p-menubar.p-menubar-mobile {
    position: relative;
  }
  .p-menubar.p-menubar-mobile .p-menubar-button {
    display: flex;
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list {
    position: absolute;
    display: none;
    width: 100%;
  }
  .p-menubar.p-menubar-mobile .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  .p-menubar {
    padding: 0.5rem 0.5rem;
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
  }
  .p-menubar .p-menubar-root-list {
    outline: 0 none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content {
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    padding: 0.5rem 0.75rem;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
    margin-left: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-menubar .p-menuitem {
    margin: 2px 0;
  }
  .p-menubar .p-menuitem:first-child {
    margin-top: 0;
  }
  .p-menubar .p-menuitem:last-child {
    margin-bottom: 0;
  }
  .p-menubar .p-menuitem > .p-menuitem-content {
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #ffffff;
    padding: 0.5rem 0.75rem;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }
  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-menubar .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-menubar .p-submenu-list {
    padding: 0.25rem 0.25rem;
    background: #18181b;
    border: 1px solid #3f3f46;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    min-width: 12.5rem;
    border-radius: 6px;
  }
  .p-menubar .p-submenu-list .p-menuitem-separator {
    border-top: 1px solid #3f3f46;
    margin: 2px 0;
  }
  .p-menubar .p-submenu-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar.p-menubar-mobile .p-menubar-button {
    width: 1.75rem;
    height: 1.75rem;
    color: #71717a;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-menubar.p-menubar-mobile .p-menubar-button:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-menubar.p-menubar-mobile .p-menubar-button:focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list {
    padding: 0.25rem 0.25rem;
    background: #18181b;
    border: 1px solid #3f3f46;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem-separator {
    border-top: 1px solid #3f3f46;
    margin: 2px 0;
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
    padding-left: 1.5rem;
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
    padding-left: 2.5rem;
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
    padding-left: 3.5rem;
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
    padding-left: 4.5rem;
  }
  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
    padding-left: 5.5rem;
  }
  .p-panelmenu .p-panelmenu-header-action {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    position: relative;
    text-decoration: none;
  }
  .p-panelmenu .p-panelmenu-header-action:focus {
    z-index: 1;
  }
  .p-panelmenu .p-submenu-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .p-panelmenu .p-menuitem-link {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    overflow: hidden;
  }
  .p-panelmenu .p-menuitem-text {
    line-height: 1;
  }
  .p-panelmenu .p-panelmenu-header {
    outline: 0 none;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
    border: 0 none;
    color: #a1a1aa;
    background: #18181b;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
    color: #a1a1aa;
    padding: 1.125rem 1.125rem 1.125rem 1.125rem;
    font-weight: 600;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -2px;
    box-shadow: inset none;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
    background: #18181b;
    border-color: #18181b;
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
    background: #18181b;
    border-color: #18181b;
    color: #ffffff;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
    border-color: #18181b;
    background: #18181b;
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-content {
    padding: 0.25rem 0.25rem;
    border: 0 none;
    background: #18181b;
    color: #ffffff;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-panelmenu .p-panelmenu-content .p-panelmenu-root-list {
    outline: 0 none;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem {
    margin: 2px 0;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:first-child {
    margin-top: 0;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:last-child {
    margin-bottom: 0;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content {
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #ffffff;
    padding: 0.5rem 0.75rem;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem-separator {
    border-top: 1px solid #3f3f46;
    margin: 2px 0;
  }
  .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-list) {
    padding: 0 0 0 1rem;
  }
  .p-panelmenu .p-panelmenu-panel {
    margin-bottom: 0;
  }
  .p-panelmenu .p-panelmenu-panel .p-panelmenu-header .p-panelmenu-header-content {
    border-radius: 0;
  }
  .p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
    border-radius: 0;
  }
  .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header .p-panelmenu-header-content {
    border-top: 0 none;
  }
  .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
    border-top: 0 none;
  }
  .p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header .p-panelmenu-header-content {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) .p-panelmenu-header-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-steps {
    position: relative;
  }
  .p-steps .p-steps-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
  }
  .p-steps-item {
    position: relative;
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    overflow: hidden;
  }
  .p-steps-item .p-menuitem-link {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    text-decoration: none;
    cursor: pointer;
  }
  .p-steps.p-steps-readonly .p-steps-item {
    cursor: auto;
  }
  .p-steps-item.p-steps-current .p-menuitem-link {
    cursor: default;
  }
  .p-steps-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .p-steps-number {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-steps-title {
    display: block;
  }
  .p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
    background: #18181b;
    outline-color: transparent;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #a1a1aa;
    border: 1px solid #3f3f46;
    background: #18181b;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: #ffffff;
  }
  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 500;
    color: #ffffff;
  }
  .p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #3f3f46;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
  }
  .p-tabmenu {
    overflow-x: auto;
  }
  .p-tabmenu-nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    flex-wrap: nowrap;
  }
  .p-tabmenu-nav a {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    text-decoration: none;
    overflow: hidden;
  }
  .p-tabmenu-nav a:focus {
    z-index: 1;
  }
  .p-tabmenu-nav .p-menuitem-text {
    line-height: 1;
  }
  .p-tabmenu-ink-bar {
    display: none;
    z-index: 1;
  }
  .p-tabmenu::-webkit-scrollbar {
    display: none;
  }
  .p-tabmenu .p-tabmenu-nav {
    background: #18181b;
    border: 1px solid #3f3f46;
    border-width: 0 0 1px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: solid #3f3f46;
    border-width: 0 0 1px 0;
    border-color: transparent transparent #3f3f46 transparent;
    background: #18181b;
    color: #a1a1aa;
    padding: 1rem 1.125rem;
    font-weight: 600;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    margin: 0 0 -1px 0;
    outline-color: transparent;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -2px;
    box-shadow: inset none;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: #18181b;
    border-color: #3f3f46;
    color: #ffffff;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #18181b;
    border-color: #3f3f46;
    color: #000000;
  }
  .p-tieredmenu ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .p-tieredmenu .p-submenu-list {
    position: absolute;
    min-width: 100%;
    z-index: 1;
    display: none;
  }
  .p-tieredmenu .p-menuitem-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    overflow: hidden;
    position: relative;
  }
  .p-tieredmenu .p-menuitem-text {
    line-height: 1;
  }
  .p-tieredmenu .p-menuitem {
    position: relative;
  }
  .p-tieredmenu .p-menuitem-link .p-submenu-icon {
    margin-left: auto;
  }
  .p-tieredmenu .p-menuitem-active > .p-submenu-list {
    display: block;
    left: 100%;
    top: 0;
  }
  .p-tieredmenu-enter-from,
  .p-tieredmenu-leave-active {
    opacity: 0;
  }
  .p-tieredmenu-enter-active {
    transition: opacity 250ms;
  }
  .p-tieredmenu {
    padding: 0.25rem 0.25rem;
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border-radius: 6px;
    min-width: 12.5rem;
  }
  .p-tieredmenu.p-tieredmenu-overlay {
    background: #18181b;
    border: 1px solid #3f3f46;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-tieredmenu .p-tieredmenu-root-list {
    outline: 0 none;
  }
  .p-tieredmenu .p-submenu-list {
    padding: 0.25rem 0.25rem;
    background: #18181b;
    border: 1px solid #3f3f46;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  .p-tieredmenu .p-menuitem {
    margin: 2px 0;
  }
  .p-tieredmenu .p-menuitem:first-child {
    margin-top: 0;
  }
  .p-tieredmenu .p-menuitem:last-child {
    margin-bottom: 0;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content {
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #ffffff;
    padding: 0.5rem 0.75rem;
    -webkit-user-select: none;
            user-select: none;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }
  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(250, 250, 250, 0.16);
  }
  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-tieredmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: rgba(0, 0, 0, 0.24);
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #ffffff;
  }
  .p-tieredmenu .p-menuitem-separator {
    border-top: 1px solid #3f3f46;
    margin: 2px 0;
  }
  .p-tieredmenu .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-tieredmenu .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-inline-message {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
  }
  .p-inline-message-icon {
    flex-shrink: 0;
  }
  .p-inline-message-icon-only .p-inline-message-text {
    visibility: hidden;
    width: 0;
  }
  .p-fluid .p-inline-message {
    display: flex;
  }
  .p-inline-message {
    padding: 0.5rem 0.75rem;
    margin: 0;
    border-radius: 6px;
  }
  .p-inline-message.p-inline-message-info {
    background: rgba(59, 130, 246, 0.16);
    border: solid rgba(29, 78, 216, 0.36);
    border-width: 1px;
    color: #3b82f6;
  }
  .p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #3b82f6;
  }
  .p-inline-message.p-inline-message-success {
    background: rgba(34, 197, 94, 0.16);
    border: solid rgba(21, 128, 61, 0.36);
    border-width: 1px;
    color: #22c55e;
  }
  .p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #22c55e;
  }
  .p-inline-message.p-inline-message-warn {
    background: rgba(234, 179, 8, 0.16);
    border: solid rgba(161, 98, 7, 0.36);
    border-width: 1px;
    color: #eab308;
  }
  .p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #eab308;
  }
  .p-inline-message.p-inline-message-error {
    background: rgba(239, 68, 68, 0.16);
    border: solid rgba(185, 28, 28, 0.36);
    border-width: 1px;
    color: #ef4444;
  }
  .p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #ef4444;
  }
  .p-inline-message.p-inline-message-secondary {
    background: #27272a;
    border: solid #3f3f46;
    border-width: 1px;
    color: #d4d4d8;
  }
  .p-inline-message.p-inline-message-secondary .p-inline-message-icon {
    color: #d4d4d8;
  }
  .p-inline-message.p-inline-message-contrast {
    background: #ffffff;
    border: solid #f4f4f5;
    border-width: 1px;
    color: #09090b;
  }
  .p-inline-message.p-inline-message-contrast .p-inline-message-icon {
    color: #09090b;
  }
  .p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  .p-inline-message .p-inline-message-text {
    font-size: 1rem;
  }
  .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
  }
  .p-message-wrapper {
    display: flex;
    align-items: center;
  }
  .p-message-icon {
    flex-shrink: 0;
  }
  .p-message-close {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  .p-message-close.p-link {
    margin-left: auto;
    overflow: hidden;
    position: relative;
  }
  .p-message-enter-from {
    opacity: 0;
  }
  .p-message-enter-active {
    transition: opacity 0.3s;
  }
  .p-message.p-message-leave-from {
    max-height: 1000px;
  }
  .p-message.p-message-leave-to {
    max-height: 0;
    opacity: 0;
    margin: 0;
  }
  .p-message-leave-active {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1), opacity 0.3s, margin 0.15s;
  }
  .p-message-leave-active .p-message-close {
    display: none;
  }
  .p-message {
    margin: 1rem 0;
    border-radius: 6px;
  }
  .p-message .p-message-wrapper {
    padding: 0.5rem 0.75rem;
  }
  .p-message .p-message-close {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-message .p-message-close:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  .p-message .p-message-close:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-message.p-message-info {
    background: rgba(59, 130, 246, 0.16);
    border: solid rgba(29, 78, 216, 0.36);
    border-width: 1px;
    color: #3b82f6;
  }
  .p-message.p-message-info .p-message-icon {
    color: #3b82f6;
  }
  .p-message.p-message-info .p-message-close {
    color: #3b82f6;
  }
  .p-message.p-message-success {
    background: rgba(34, 197, 94, 0.16);
    border: solid rgba(21, 128, 61, 0.36);
    border-width: 1px;
    color: #22c55e;
  }
  .p-message.p-message-success .p-message-icon {
    color: #22c55e;
  }
  .p-message.p-message-success .p-message-close {
    color: #22c55e;
  }
  .p-message.p-message-warn {
    background: rgba(234, 179, 8, 0.16);
    border: solid rgba(161, 98, 7, 0.36);
    border-width: 1px;
    color: #eab308;
  }
  .p-message.p-message-warn .p-message-icon {
    color: #eab308;
  }
  .p-message.p-message-warn .p-message-close {
    color: #eab308;
  }
  .p-message.p-message-error {
    background: rgba(239, 68, 68, 0.16);
    border: solid rgba(185, 28, 28, 0.36);
    border-width: 1px;
    color: #ef4444;
  }
  .p-message.p-message-error .p-message-icon {
    color: #ef4444;
  }
  .p-message.p-message-error .p-message-close {
    color: #ef4444;
  }
  .p-message.p-message-secondary {
    background: #27272a;
    border: solid #3f3f46;
    border-width: 1px;
    color: #d4d4d8;
  }
  .p-message.p-message-secondary .p-message-icon {
    color: #d4d4d8;
  }
  .p-message.p-message-secondary .p-message-close {
    color: #d4d4d8;
  }
  .p-message.p-message-contrast {
    background: #ffffff;
    border: solid #f4f4f5;
    border-width: 1px;
    color: #09090b;
  }
  .p-message.p-message-contrast .p-message-icon {
    color: #09090b;
  }
  .p-message.p-message-contrast .p-message-close {
    color: #09090b;
  }
  .p-message .p-message-text {
    font-size: 1rem;
    font-weight: 500;
  }
  .p-message .p-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  .p-message .p-icon:not(.p-message-close-icon) {
    width: 1rem;
    height: 1rem;
  }
  .p-toast {
    width: 25rem;
    white-space: pre-line;
    word-break: break-word;
  }
  .p-toast-message-icon {
    flex-shrink: 0;
  }
  .p-toast-message-content {
    display: flex;
    align-items: flex-start;
  }
  .p-toast-message-text {
    flex: 1 1 auto;
  }
  .p-toast-top-center {
    transform: translateX(-50%);
  }
  .p-toast-bottom-center {
    transform: translateX(-50%);
  }
  .p-toast-center {
    min-width: 20vw;
    transform: translate(-50%, -50%);
  }
  .p-toast-icon-close {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  .p-toast-icon-close.p-link {
    cursor: pointer;
  }
  /* Animations */
  .p-toast-message-enter-from {
    opacity: 0;
    transform: translateY(50%);
  }
  .p-toast-message-leave-from {
    max-height: 1000px;
  }
  .p-toast .p-toast-message.p-toast-message-leave-to {
    max-height: 0;
    opacity: 0;
    margin-bottom: 0;
    overflow: hidden;
  }
  .p-toast-message-enter-active {
    transition: transform 0.3s, opacity 0.3s;
  }
  .p-toast-message-leave-active {
    transition: max-height 0.45s cubic-bezier(0, 1, 0, 1), opacity 0.3s, margin-bottom 0.3s;
  }
  .p-toast {
    opacity: 1;
  }
  .p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  .p-toast .p-toast-message .p-toast-message-content {
    padding: 0.75rem;
    border-width: 1px;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 0.5rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 1.125rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
    width: 1.125rem;
    height: 1.125rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 500;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: 0.5rem 0 0 0;
  }
  .p-toast .p-toast-message .p-toast-icon-close {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-toast .p-toast-message .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  .p-toast .p-toast-message .p-toast-icon-close:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-toast .p-toast-message.p-toast-message-info {
    background: rgba(59, 130, 246, 0.16);
    border: solid rgba(29, 78, 216, 0.36);
    border-width: 1px;
    color: #3b82f6;
  }
  .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #3b82f6;
  }
  .p-toast .p-toast-message.p-toast-message-success {
    background: rgba(34, 197, 94, 0.16);
    border: solid rgba(21, 128, 61, 0.36);
    border-width: 1px;
    color: #22c55e;
  }
  .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #22c55e;
  }
  .p-toast .p-toast-message.p-toast-message-warn {
    background: rgba(234, 179, 8, 0.16);
    border: solid rgba(161, 98, 7, 0.36);
    border-width: 1px;
    color: #eab308;
  }
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #eab308;
  }
  .p-toast .p-toast-message.p-toast-message-error {
    background: rgba(239, 68, 68, 0.16);
    border: solid rgba(185, 28, 28, 0.36);
    border-width: 1px;
    color: #ef4444;
  }
  .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #ef4444;
  }
  .p-toast .p-toast-message.p-toast-message-secondary {
    background: #27272a;
    border: solid #3f3f46;
    border-width: 1px;
    color: #d4d4d8;
  }
  .p-toast .p-toast-message.p-toast-message-secondary .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-secondary .p-toast-icon-close {
    color: #d4d4d8;
  }
  .p-toast .p-toast-message.p-toast-message-contrast {
    background: #ffffff;
    border: solid #f4f4f5;
    border-width: 1px;
    color: #09090b;
  }
  .p-toast .p-toast-message.p-toast-message-contrast .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-contrast .p-toast-icon-close {
    color: #09090b;
  }
  .p-galleria-content {
    display: flex;
    flex-direction: column;
  }
  .p-galleria-item-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .p-galleria-item-container {
    position: relative;
    display: flex;
    height: 100%;
  }
  .p-galleria-item-nav {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .p-galleria-item-prev {
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-galleria-item-next {
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .p-galleria-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .p-galleria-item-nav-onhover .p-galleria-item-nav {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  .p-galleria-item-nav-onhover .p-galleria-item-wrapper:hover .p-galleria-item-nav {
    pointer-events: all;
    opacity: 1;
  }
  .p-galleria-item-nav-onhover .p-galleria-item-wrapper:hover .p-galleria-item-nav.p-disabled {
    pointer-events: none;
  }
  .p-galleria-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  /* Thumbnails */
  .p-galleria-thumbnail-wrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-shrink: 0;
  }
  .p-galleria-thumbnail-prev,
  .p-galleria-thumbnail-next {
    align-self: center;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  .p-galleria-thumbnail-prev span,
  .p-galleria-thumbnail-next span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p-galleria-thumbnail-container {
    display: flex;
    flex-direction: row;
  }
  .p-galleria-thumbnail-items-container {
    overflow: hidden;
    width: 100%;
  }
  .p-galleria-thumbnail-items {
    display: flex;
  }
  .p-galleria-thumbnail-item {
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.5;
  }
  .p-galleria-thumbnail-item:hover {
    opacity: 1;
    transition: opacity 0.3s;
  }
  .p-galleria-thumbnail-item-current {
    opacity: 1;
  }
  /* Positions */
  /* Thumbnails */
  .p-galleria-thumbnails-left .p-galleria-content,
  .p-galleria-thumbnails-right .p-galleria-content {
    flex-direction: row;
  }
  .p-galleria-thumbnails-left .p-galleria-item-wrapper,
  .p-galleria-thumbnails-right .p-galleria-item-wrapper {
    flex-direction: row;
  }
  .p-galleria-thumbnails-left .p-galleria-item-wrapper,
  .p-galleria-thumbnails-top .p-galleria-item-wrapper {
    order: 2;
  }
  .p-galleria-thumbnails-left .p-galleria-thumbnail-wrapper,
  .p-galleria-thumbnails-top .p-galleria-thumbnail-wrapper {
    order: 1;
  }
  .p-galleria-thumbnails-left .p-galleria-thumbnail-container,
  .p-galleria-thumbnails-right .p-galleria-thumbnail-container {
    flex-direction: column;
    flex-grow: 1;
  }
  .p-galleria-thumbnails-left .p-galleria-thumbnail-items,
  .p-galleria-thumbnails-right .p-galleria-thumbnail-items {
    flex-direction: column;
    height: 100%;
  }
  /* Indicators */
  .p-galleria-indicators {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-galleria-indicator > button {
    display: inline-flex;
    align-items: center;
  }
  .p-galleria-indicators-left .p-galleria-item-wrapper,
  .p-galleria-indicators-right .p-galleria-item-wrapper {
    flex-direction: row;
    align-items: center;
  }
  .p-galleria-indicators-left .p-galleria-item-container,
  .p-galleria-indicators-top .p-galleria-item-container {
    order: 2;
  }
  .p-galleria-indicators-left .p-galleria-indicators,
  .p-galleria-indicators-top .p-galleria-indicators {
    order: 1;
  }
  .p-galleria-indicators-left .p-galleria-indicators,
  .p-galleria-indicators-right .p-galleria-indicators {
    flex-direction: column;
  }
  .p-galleria-indicator-onitem .p-galleria-indicators {
    position: absolute;
    display: flex;
    z-index: 1;
  }
  .p-galleria-indicator-onitem.p-galleria-indicators-top .p-galleria-indicators {
    top: 0;
    left: 0;
    width: 100%;
    align-items: flex-start;
  }
  .p-galleria-indicator-onitem.p-galleria-indicators-right .p-galleria-indicators {
    right: 0;
    top: 0;
    height: 100%;
    align-items: flex-end;
  }
  .p-galleria-indicator-onitem.p-galleria-indicators-bottom .p-galleria-indicators {
    bottom: 0;
    left: 0;
    width: 100%;
    align-items: flex-end;
  }
  .p-galleria-indicator-onitem.p-galleria-indicators-left .p-galleria-indicators {
    left: 0;
    top: 0;
    height: 100%;
    align-items: flex-start;
  }
  /* FullScreen */
  .p-galleria-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-galleria-close {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .p-galleria-mask .p-galleria-item-nav {
    position: fixed;
    top: 50%;
    margin-top: -0.5rem;
  }
  /* Animation */
  .p-galleria-enter-active {
    transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
  }
  .p-galleria-leave-active {
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .p-galleria-enter-from,
  .p-galleria-leave-to {
    opacity: 0;
    transform: scale(0.7);
  }
  .p-galleria-enter-active .p-galleria-item-nav {
    opacity: 0;
  }
  /* Keyboard Support */
  .p-items-hidden .p-galleria-thumbnail-item {
    visibility: hidden;
  }
  .p-items-hidden .p-galleria-thumbnail-item.p-galleria-thumbnail-item-active {
    visibility: visible;
  }
  .p-galleria .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: #a1a1aa;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem;
  }
  .p-galleria .p-galleria-close .p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-galleria .p-galleria-close:hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-galleria .p-galleria-item-nav {
    background: transparent;
    color: #a1a1aa;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
    margin: 0 0.5rem;
  }
  .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
  .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
  }
  .p-galleria .p-galleria-item-nav .p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    padding: 1rem;
  }
  .p-galleria .p-galleria-indicators {
    padding: 1rem;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #3f3f46;
    width: 1rem;
    height: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #3f3f46;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: 0.5rem;
  }
  .p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: 0.5rem;
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(255, 255, 255, 0.4);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
    background: rgba(255, 255, 255, 0.6);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: 0.5rem;
    background-color: transparent;
    color: #a1a1aa;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
    outline-color: transparent;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-galleria-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
  }
  .p-image-mask {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-image-preview-container {
    position: relative;
    display: inline-block;
    line-height: 0;
  }
  .p-image-preview-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
    border: none;
    padding: 0;
  }
  .p-image-preview-container:hover > .p-image-preview-indicator {
    opacity: 1;
    cursor: pointer;
  }
  .p-image-preview-container > img {
    cursor: pointer;
  }
  .p-image-toolbar {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    z-index: 1;
  }
  .p-image-action.p-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p-image-action.p-disabled {
    pointer-events: auto;
  }
  .p-image-preview {
    transition: transform 0.15s;
    max-width: 100vw;
    max-height: 100vh;
  }
  .p-image-preview-enter-active {
    transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
  }
  .p-image-preview-leave-active {
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .p-image-preview-enter-from,
  .p-image-preview-leave-to {
    opacity: 0;
    transform: scale(0.7);
  }
  .p-image-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
  }
  .p-image-preview-indicator {
    background-color: transparent;
    color: #a1a1aa;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-image-preview-indicator .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-image-preview-container:hover > .p-image-preview-indicator {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .p-image-toolbar {
    padding: 1rem;
  }
  .p-image-action.p-link {
    color: #a1a1aa;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    margin-right: 0.5rem;
  }
  .p-image-action.p-link:last-child {
    margin-right: 0;
  }
  .p-image-action.p-link:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.03);
  }
  .p-image-action.p-link i {
    font-size: 1.5rem;
  }
  .p-image-action.p-link .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }
  .p-avatar.p-avatar-image {
    background-color: transparent;
  }
  .p-avatar.p-avatar-circle {
    border-radius: 50%;
  }
  .p-avatar-circle img {
    border-radius: 50%;
  }
  .p-avatar .p-avatar-icon {
    font-size: 1rem;
  }
  .p-avatar img {
    width: 100%;
    height: 100%;
  }
  .p-avatar-group .p-avatar + .p-avatar {
    margin-left: -1rem;
  }
  .p-avatar-group {
    display: flex;
    align-items: center;
  }
  .p-avatar {
    background-color: #3f3f46;
    border-radius: 6px;
  }
  .p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }
  .p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem;
  }
  .p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }
  .p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem;
  }
  .p-avatar-group .p-avatar {
    border: 2px solid #18181b;
  }
  .p-badge {
    display: inline-block;
    border-radius: 10px;
    text-align: center;
    padding: 0 0.5rem;
  }
  .p-overlay-badge {
    position: relative;
  }
  .p-overlay-badge .p-badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
    margin: 0;
  }
  .p-badge.p-badge-dot {
    width: 0.5rem;
    min-width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    padding: 0;
  }
  .p-badge-no-gutter {
    padding: 0;
    border-radius: 50%;
  }
  .p-badge {
    background: #000000;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
  .p-badge.p-badge-secondary {
    background-color: #27272a;
    color: #d4d4d8;
  }
  .p-badge.p-badge-success {
    background-color: #4ade80;
    color: #052e16;
  }
  .p-badge.p-badge-info {
    background-color: #38bdf8;
    color: #082f49;
  }
  .p-badge.p-badge-warning {
    background-color: #fb923c;
    color: #431407;
  }
  .p-badge.p-badge-danger {
    background-color: #f87171;
    color: #450a0a;
  }
  .p-badge.p-badge-secondary {
    background-color: #27272a;
    color: #d4d4d8;
  }
  .p-badge.p-badge-contrast {
    background-color: #ffffff;
    color: #09090b;
  }
  .p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
  }
  .p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
  }
  .p-blockui-container {
    position: relative;
  }
  .p-blockui.p-component-overlay {
    position: absolute;
  }
  .p-blockui-document.p-component-overlay {
    position: fixed;
  }
  .p-blockui {
    border-radius: 6px;
  }
  .p-chip {
    display: inline-flex;
    align-items: center;
  }
  .p-chip-text {
    line-height: 1.5;
  }
  .p-chip-icon.pi {
    line-height: 1.5;
  }
  .p-chip-remove-icon {
    line-height: 1.5;
    cursor: pointer;
  }
  .p-chip img {
    border-radius: 50%;
  }
  .p-chip {
    background-color: #3f3f46;
    color: #ffffff;
    border-radius: 16px;
    padding: 0 0.75rem;
  }
  .p-chip .p-chip-text {
    line-height: 1.5;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .p-chip .p-chip-icon {
    margin-right: 0.5rem;
  }
  .p-chip img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.75rem;
    margin-right: 0.5rem;
  }
  .p-chip .p-chip-remove-icon {
    margin-left: 0.5rem;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-chip .p-chip-remove-icon:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-chip .p-chip-remove-icon:focus {
    outline: 0 none;
  }
  .p-inplace .p-inplace-display {
    display: inline;
    cursor: pointer;
  }
  .p-inplace .p-inplace-content {
    display: inline;
  }
  .p-fluid .p-inplace.p-inplace-closable .p-inplace-content {
    display: flex;
  }
  .p-fluid .p-inplace.p-inplace-closable .p-inplace-content > .p-inputtext {
    flex: 1 1 auto;
    width: 1%;
  }
  .p-inplace .p-inplace-display {
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }
  .p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-inplace .p-inplace-display:focus {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-metergroup {
    display: flex;
  }
  .p-metergroup-meters {
    display: flex;
  }
  .p-metergroup-vertical .p-metergroup-meters {
    flex-direction: column;
  }
  .p-metergroup-labels {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .p-metergroup-vertical .p-metergroup-labels {
    align-items: start;
  }
  .p-metergroup-labels-vertical {
    flex-direction: column;
  }
  .p-metergroup-label {
    display: inline-flex;
    align-items: center;
  }
  .p-metergroup-label-marker {
    display: inline-flex;
  }
  .p-metergroup {
    gap: 1rem;
  }
  .p-metergroup .p-metergroup-meters {
    background: #3f3f46;
    border-radius: 6px;
  }
  .p-metergroup .p-metergroup-meter {
    border: 0 none;
    background: #000000;
  }
  .p-metergroup .p-metergroup-labels .p-metergroup-label {
    gap: 0.5rem;
  }
  .p-metergroup .p-metergroup-labels .p-metergroup-label-marker {
    background: #000000;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }
  .p-metergroup .p-metergroup-labels .p-metergroup-label-icon {
    width: 1rem;
    height: 1rem;
  }
  .p-metergroup .p-metergroup-labels.p-metergroup-labels-vertical {
    gap: 0.5rem;
  }
  .p-metergroup .p-metergroup-labels.p-metergroup-labels-horizontal {
    gap: 1rem;
  }
  .p-metergroup.p-metergroup-horizontal {
    flex-direction: column;
  }
  .p-metergroup.p-metergroup-horizontal .p-metergroup-meters {
    height: 0.5rem;
  }
  .p-metergroup.p-metergroup-horizontal .p-metergroup-meter:first-of-type {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-metergroup.p-metergroup-horizontal .p-metergroup-meter:last-of-type {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-metergroup.p-metergroup-vertical {
    flex-direction: row;
  }
  .p-metergroup.p-metergroup-vertical .p-metergroup-meters {
    width: 0.5rem;
    height: 100%;
  }
  .p-metergroup.p-metergroup-vertical .p-metergroup-meter:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .p-metergroup.p-metergroup-vertical .p-metergroup-meter:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-progressbar {
    position: relative;
    overflow: hidden;
  }
  .p-progressbar-determinate .p-progressbar-value {
    height: 100%;
    width: 0%;
    position: absolute;
    display: none;
    border: 0 none;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .p-progressbar-determinate .p-progressbar-label {
    display: inline-flex;
  }
  .p-progressbar-determinate .p-progressbar-value-animate {
    transition: width 1s ease-in-out;
  }
  .p-progressbar-indeterminate .p-progressbar-value::before {
    content: "";
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: p-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }
  .p-progressbar-indeterminate .p-progressbar-value::after {
    content: "";
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: p-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
  }
  @keyframes p-progressbar-indeterminate-anim {
    0% {
      left: -35%;
      right: 100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
  }
  @keyframes p-progressbar-indeterminate-anim-short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
  }
  .p-progressbar {
    border: 0 none;
    height: 1.25rem;
    background: #3f3f46;
    border-radius: 6px;
  }
  .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #000000;
  }
  .p-progressbar .p-progressbar-label {
    color: #ffffff;
    line-height: 1.25rem;
  }
  .p-progress-spinner {
    position: relative;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    display: inline-block;
  }
  .p-progress-spinner::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  .p-progress-spinner-svg {
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .p-progress-spinner-svg {
    animation: p-progress-spinner-rotate 2s linear infinite;
  }
  .p-progress-spinner-circle {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: 0;
    stroke: #ef4444;
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite, p-progress-spinner-color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
  @keyframes p-progress-spinner-rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes p-progress-spinner-dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  @keyframes p-progress-spinner-color {
    100%, 0% {
      stroke: #ef4444;
    }
    40% {
      stroke: #3b82f6;
    }
    66% {
      stroke: #22c55e;
    }
    80%, 90% {
      stroke: #eab308;
    }
  }
  .p-ripple {
    overflow: hidden;
    position: relative;
  }
  .p-ink {
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    transform: scale(0);
    pointer-events: none;
  }
  .p-ink-active {
    animation: ripple 0.4s linear;
  }
  .p-ripple-disabled .p-ink {
    display: none;
  }
  @keyframes ripple {
    100% {
      opacity: 0;
      transform: scale(2.5);
    }
  }
  .p-scrolltop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-scrolltop-sticky {
    position: sticky;
  }
  .p-scrolltop-sticky.p-link {
    margin-left: auto;
  }
  .p-scrolltop-enter-from {
    opacity: 0;
  }
  .p-scrolltop-enter-active {
    transition: opacity 0.15s;
  }
  .p-scrolltop.p-scrolltop-leave-to {
    opacity: 0;
  }
  .p-scrolltop-leave-active {
    transition: opacity 0.15s;
  }
  .p-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-scrolltop.p-link {
    background: #3f3f46;
  }
  .p-scrolltop.p-link:hover {
    background: #3f3f46;
  }
  .p-scrolltop .p-scrolltop-icon {
    font-size: 1.5rem;
    color: #ffffff;
  }
  .p-scrolltop .p-scrolltop-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-skeleton {
    overflow: hidden;
  }
  .p-skeleton::after {
    content: "";
    animation: p-skeleton-animation 1.2s infinite;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
  }
  .p-skeleton.p-skeleton-circle {
    border-radius: 50%;
  }
  .p-skeleton-none::after {
    animation: none;
  }
  @keyframes p-skeleton-animation {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
  .p-skeleton {
    background-color: rgba(255, 255, 255, 0.06);
    border-radius: 6px;
  }
  .p-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0));
  }
  .p-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .p-tag-icon,
  .p-tag-value,
  .p-tag-icon.pi {
    line-height: 1.5;
  }
  .p-tag.p-tag-rounded {
    border-radius: 10rem;
  }
  .p-tag {
    background: #000000;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 6px;
  }
  .p-tag.p-tag-success {
    background-color: #4ade80;
    color: #052e16;
  }
  .p-tag.p-tag-info {
    background-color: #38bdf8;
    color: #082f49;
  }
  .p-tag.p-tag-warning {
    background-color: #fb923c;
    color: #431407;
  }
  .p-tag.p-tag-danger {
    background-color: #f87171;
    color: #450a0a;
  }
  .p-tag.p-tag-secondary {
    background-color: #27272a;
    color: #d4d4d8;
  }
  .p-tag.p-tag-contrast {
    background-color: #ffffff;
    color: #09090b;
  }
  .p-tag .p-tag-icon {
    font-size: 0.75rem;
  }
  .p-tag .p-tag-icon:not(:last-child) {
    margin-right: 0.25rem;
  }
  .p-tag .p-tag-icon.p-icon {
    width: 0.75rem;
    height: 0.75rem;
  }
  .p-terminal {
    height: 18rem;
    overflow: auto;
  }
  .p-terminal-prompt-container {
    display: flex;
    align-items: center;
  }
  .p-terminal-input {
    flex: 1 1 auto;
    border: 0 none;
    background-color: transparent;
    color: inherit;
    padding: 0;
    outline: 0 none;
  }
  .p-terminal-input::-ms-clear {
    display: none;
  }
  .p-terminal {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    padding: 0 1.125rem 1.125rem 1.125rem;
  }
  .p-terminal .p-terminal-input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
  }
}
@layer primevue {
  .p-accordion .p-accordion-header .p-accordion-header-link {
    border-radius: 6px !important;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link:hover {
    border-bottom-color: #18181b;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    transform: rotate(90deg);
  }
  .p-accordion .p-accordion-header.p-highlight .p-accordion-toggle-icon {
    transform: rotate(-180deg);
  }
  .p-accordion .p-accordion-tab {
    border-bottom: 1px solid #27272a;
  }
  .p-accordion .p-accordion-tab:last-child {
    border-bottom: 0 none;
  }
  .p-autocomplete .p-autocomplete-multiple-container {
    padding: 0.25rem 0.25rem;
    gap: 0.25rem;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    border-radius: 4px;
    margin: 0;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: 0.375rem;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    margin-left: 0.5rem;
  }
  .p-autocomplete .p-autocomplete-multiple-container:has(.p-autocomplete-token) .p-autocomplete-input-token {
    margin-left: 0.5rem;
  }
  .p-autocomplete.p-disabled {
    opacity: 1;
  }
  .p-button-label {
    font-weight: 500;
  }
  .p-button.p-button-success:enabled:focus-visible {
    outline-color: #4ade80;
  }
  .p-button.p-button-info:enabled:focus-visible {
    outline-color: #38bdf8;
  }
  .p-button.p-button-warning:enabled:focus-visible {
    outline-color: #fb923c;
  }
  .p-button.p-button-help:enabled:focus-visible {
    outline-color: #c084fc;
  }
  .p-button.p-button-danger:enabled:focus-visible {
    outline-color: #f87171;
  }
  .p-button.p-button-contrast:enabled:focus-visible {
    outline-color: #ffffff;
  }
  .p-button.p-button-outlined {
    border-color: rgba(0, 0, 0, 0.44);
  }
  .p-button.p-button-outlined:not(:disabled):hover, .p-button.p-button-outlined:not(:disabled):active {
    border-color: rgba(0, 0, 0, 0.44);
  }
  .p-button.p-button-outlined.p-button-secondary {
    border-color: rgba(82, 82, 91, 0.48);
    color: #a1a1aa;
  }
  .p-button.p-button-outlined.p-button-secondary:not(:disabled):hover {
    color: #a1a1aa;
    background-color: rgba(255, 255, 255, 0.04);
    border-color: rgba(82, 82, 91, 0.48);
  }
  .p-button.p-button-outlined.p-button-secondary:not(:disabled):active {
    color: #a1a1aa;
    background-color: rgba(255, 255, 255, 0.16);
    border-color: rgba(82, 82, 91, 0.48);
  }
  .p-button.p-button-outlined.p-button-success {
    border-color: rgba(21, 128, 61, 0.44);
  }
  .p-button.p-button-outlined.p-button-success:not(:disabled):hover, .p-button.p-button-outlined.p-button-success:not(:disabled):active {
    border-color: rgba(21, 128, 61, 0.44);
  }
  .p-button.p-button-outlined.p-button-info {
    border-color: rgba(29, 78, 216, 0.44);
  }
  .p-button.p-button-outlined.p-button-info:not(:disabled):hover, .p-button.p-button-outlined.p-button-info:not(:disabled):active {
    border-color: rgba(29, 78, 216, 0.44);
  }
  .p-button.p-button-outlined.p-button-warning {
    border-color: rgba(161, 98, 7, 0.44);
  }
  .p-button.p-button-outlined.p-button-warning:not(:disabled):hover, .p-button.p-button-outlined.p-button-warning:not(:disabled):active {
    border-color: rgba(161, 98, 7, 0.44);
  }
  .p-button.p-button-outlined.p-button-help {
    border-color: rgba(109, 40, 217, 0.44);
  }
  .p-button.p-button-outlined.p-button-help:not(:disabled):hover, .p-button.p-button-outlined.p-button-help:not(:disabled):active {
    border-color: rgba(109, 40, 217, 0.44);
  }
  .p-button.p-button-outlined.p-button-danger {
    border-color: rgba(185, 28, 28, 0.44);
  }
  .p-button.p-button-outlined.p-button-danger:not(:disabled):hover, .p-button.p-button-outlined.p-button-danger:not(:disabled):active {
    border-color: rgba(185, 28, 28, 0.44);
  }
  .p-button.p-button-outlined.p-button-contrast {
    border-color: rgba(255, 255, 255, 0.44);
  }
  .p-button.p-button-outlined.p-button-contrast:not(:disabled):hover, .p-button.p-button-outlined.p-button-contrast:not(:disabled):active {
    border-color: rgba(255, 255, 255, 0.44);
  }
  .p-button.p-button-secondary.p-button-text {
    color: #a1a1aa;
  }
  .p-button.p-button-secondary.p-button-text:not(:disabled):hover {
    background-color: rgba(255, 255, 255, 0.04);
    color: #a1a1aa;
  }
  .p-button.p-button-secondary.p-button-text:not(:disabled):active {
    background-color: rgba(255, 255, 255, 0.16);
    color: #a1a1aa;
  }
  .p-datepicker-buttonbar .p-button {
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
  }
  .p-datepicker .p-datepicker-group-container + .p-timepicker {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
  }
  .p-datepicker table th {
    font-weight: 500;
  }
  .p-card {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
  }
  .p-card .p-card-caption {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .p-card .p-card-caption .p-card-title,
  .p-card .p-card-caption .p-card-subtitle {
    margin-bottom: 0;
  }
  .p-card .p-card-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #000000;
  }
  .p-cascadeselect {
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 1px 2px 0 rgba(18, 18, 23, 0.05);
  }
  .p-cascadeselect .p-cascadeselect-label {
    box-shadow: none;
  }
  .p-cascadeselect.p-disabled {
    opacity: 1;
    background-color: #3f3f46;
  }
  .p-cascadeselect.p-disabled .p-cascadeselect-label {
    background-color: #3f3f46;
    color: #a1a1aa;
  }
  div.p-cascadeselect-panel {
    border: 0 none;
    box-shadow: none;
  }
  .p-checkbox .p-checkbox-box {
    border-radius: 4px;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 1px 2px 0 rgba(18, 18, 23, 0.05);
  }
  .p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible) .p-checkbox-box {
    outline-width: 1px;
    outline-offset: 2px;
  }
  .p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible).p-highlight .p-checkbox-box {
    border-color: #000000;
  }
  .p-checkbox.p-disabled {
    opacity: 1;
  }
  .p-checkbox.p-disabled .p-checkbox-box {
    background-color: #3f3f46;
    border: 1px solid #3f3f46;
  }
  .p-checkbox.p-disabled .p-checkbox-box .p-checkbox-icon {
    color: #a1a1aa;
  }
  .p-chips .p-chips-multiple-container {
    padding: 0.25rem 0.25rem;
    gap: 0.25rem;
  }
  .p-chips .p-chips-multiple-container .p-chips-token {
    border-radius: 4px;
    margin: 0;
  }
  .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: 0.375rem;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token {
    margin-left: 0.5rem;
  }
  .p-chips .p-chips-multiple-container:has(.p-chips-token) .p-chips-input-token {
    margin-left: 0.5rem;
  }
  .p-chips.p-disabled .p-chips-multiple-container {
    opacity: 1;
    background-color: #3f3f46;
  }
  .p-chip {
    border-radius: 16px;
    padding: 0.25rem 0.75rem;
  }
  .p-chip .p-chip-text {
    margin-top: 0;
    margin-bottom: 0;
  }
  .p-chip .p-chip-remove-icon {
    margin-left: 0.375rem;
  }
  .p-chip:has(.p-chip-remove-icon) {
    padding-right: 0.5rem;
  }
  .p-chip img {
    margin-left: -0.5rem;
  }
  .p-colorpicker-preview {
    padding: 0;
  }
  .p-colorpicker-preview:enabled:focus {
    outline-offset: 2px;
  }
  .p-colorpicker-preview.p-inputtext.p-disabled {
    opacity: 0.6;
  }
  .p-confirm-popup {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 #000000;
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 #000000;
  }
  .p-datatable .p-datatable-tbody > tr:has(+ .p-highlight) > td {
    border-bottom-color: rgba(225, 225, 225, 0.16);
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight > td {
    border-bottom-color: rgba(225, 225, 225, 0.16);
  }
  .p-dataview-layout-options.p-selectbutton .p-button svg {
    position: relative;
  }
  .p-dialog {
    border-radius: 12px;
    background-color: #18181b;
  }
  .p-dialog.p-dialog-maximized {
    border-radius: 0;
  }
  .p-dialog .p-dialog-header {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .p-dialog .p-dialog-footer {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .p-dropdown {
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 1px 2px 0 rgba(18, 18, 23, 0.05);
  }
  .p-dropdown .p-dropdown-label {
    box-shadow: none;
  }
  .p-dropdown.p-disabled {
    opacity: 1;
    background-color: #3f3f46;
  }
  .p-dropdown.p-disabled .p-dropdown-label {
    color: #a1a1aa;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item .p-dropdown-check-icon {
    margin-left: -0.375rem;
    margin-right: 0.375rem;
  }
  .p-treetable .p-treetable-tbody > tr:has(+ .p-highlight) > td {
    border-bottom-color: rgba(225, 225, 225, 0.16);
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight > td {
    border-bottom-color: rgba(225, 225, 225, 0.16);
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight > td .p-treetable-toggler:hover {
    background-color: rgba(250, 250, 250, 0.16);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-fieldset {
    padding: 0 1.125rem 1.125rem 1.125rem;
    margin: 0;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 0.5rem 0.75rem;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-legend-text {
    padding: 0;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    color: #a1a1aa;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background-color: rgba(255, 255, 255, 0.03);
  }
  .p-fieldset .p-fieldset-legend {
    border: 0 none;
    padding: 0;
    margin-bottom: 0.375rem;
  }
  .p-fieldset .p-fieldset-legend span {
    padding: 0.5rem 0.75rem;
  }
  .p-fieldset .p-fieldset-content {
    padding: 0;
  }
  .p-column-filter-overlay-menu {
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraints {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 0 0 0 0;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-bottom: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint:last-child .p-column-filter-remove-button {
    margin-bottom: 0;
  }
  .p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0;
  }
  .p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 0;
  }
  .p-fileupload .p-fileupload-content {
    border-top: 0 none;
    padding-top: 1.125rem;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: #000000;
  }
  .p-inline-message-text {
    font-weight: 500;
  }
  .p-inline-message {
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }
  .p-inline-message.p-inline-message-info {
    box-shadow: 0px 4px 8px 0px rgba(59, 130, 246, 0.04);
  }
  .p-inline-message.p-inline-message-success {
    box-shadow: 0px 4px 8px 0px rgba(34, 197, 94, 0.04);
  }
  .p-inline-message.p-inline-message-warn {
    box-shadow: 0px 4px 8px 0px rgba(234, 179, 8, 0.04);
  }
  .p-inline-message.p-inline-message-error {
    box-shadow: 0px 4px 8px 0px rgba(239, 68, 68, 0.04);
  }
  .p-inline-message.p-inline-message-secondary {
    box-shadow: 0px 4px 8px 0px rgba(74, 85, 103, 0.04);
  }
  .p-inline-message.p-inline-message-contrast {
    box-shadow: 0px 4px 8px 0px rgba(2, 6, 23, 0.04);
  }
  .p-inputgroup-addon {
    padding: 0.5rem;
  }
  .p-inputnumber.p-inputnumber-buttons-stacked {
    position: relative;
  }
  .p-inputnumber.p-inputnumber-buttons-stacked .p-inputnumber-input {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .p-inputnumber.p-inputnumber-buttons-stacked .p-inputnumber-button-group {
    position: absolute;
    top: 1px;
    right: 1px;
    height: calc(100% - 2px);
  }
  .p-inputnumber.p-inputnumber-buttons-stacked .p-inputnumber-button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: transparent;
    border: 0 none;
    color: #a1a1aa;
  }
  .p-inputnumber.p-inputnumber-buttons-stacked .p-inputnumber-button:hover {
    background-color: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-inputnumber.p-inputnumber-buttons-stacked .p-inputnumber-button:active {
    background-color: rgba(255, 255, 255, 0.08);
    color: #ffffff;
  }
  .p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button {
    background-color: transparent;
    border: 1px solid #3f3f46;
    color: #a1a1aa;
  }
  .p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button:hover {
    background-color: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button:active {
    background-color: rgba(255, 255, 255, 0.08);
    color: #ffffff;
  }
  .p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button.p-inputnumber-button-up {
    border-left: 0 none;
  }
  .p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button.p-inputnumber-button-down {
    border-right: 0 none;
  }
  .p-inputnumber.p-inputnumber-buttons-vertical .p-inputnumber-button {
    background-color: transparent;
    border: 1px solid #3f3f46;
    color: #a1a1aa;
  }
  .p-inputnumber.p-inputnumber-buttons-vertical .p-inputnumber-button:hover {
    background-color: rgba(255, 255, 255, 0.03);
    color: #ffffff;
  }
  .p-inputnumber.p-inputnumber-buttons-vertical .p-inputnumber-button:active {
    background-color: rgba(255, 255, 255, 0.08);
    color: #ffffff;
  }
  .p-inputnumber.p-inputnumber-buttons-vertical .p-inputnumber-button.p-inputnumber-button-up {
    border-bottom: 0 none;
  }
  .p-inputnumber.p-inputnumber-buttons-vertical .p-inputnumber-button.p-inputnumber-button-down {
    border-top: 0 none;
  }
  .p-inputswitch .p-inputswitch-slider {
    border: 0 none;
  }
  .p-inputswitch.p-highlight p-inputswitch-slider:before {
    left: 1.25rem;
    transform: none;
  }
  .p-inputswitch.p-invalid > .p-inputswitch-slider {
    background: #fca5a5;
  }
  .p-inputswitch.p-invalid > .p-inputswitch-slider:before {
    background: #18181b;
  }
  .p-inputtext {
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 1px 2px 0 rgba(18, 18, 23, 0.05);
  }
  .p-inputtext:disabled {
    opacity: 1;
    background-color: #3f3f46;
    color: #a1a1aa;
  }
  .p-knob svg {
    border-radius: 6px;
    outline-color: transparent;
    transition: outline-color 0.2s;
  }
  .p-knob svg:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-listbox {
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 1px 2px 0 rgba(18, 18, 23, 0.05);
  }
  .p-listbox.p-disabled {
    opacity: 1;
    background-color: #3f3f46;
  }
  .p-listbox.p-disabled .p-listbox-list .p-listbox-item {
    color: #a1a1aa;
  }
  .p-message {
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }
  .p-message.p-message-info {
    box-shadow: 0px 4px 8px 0px rgba(59, 130, 246, 0.04);
  }
  .p-message.p-message-info .p-message-close:focus-visible {
    outline-color: #3b82f6;
  }
  .p-message.p-message-info .p-message-close:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  .p-message.p-message-success {
    box-shadow: 0px 4px 8px 0px rgba(34, 197, 94, 0.04);
  }
  .p-message.p-message-success .p-message-close:focus-visible {
    outline-color: #22c55e;
  }
  .p-message.p-message-success .p-message-close:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  .p-message.p-message-warn {
    box-shadow: 0px 4px 8px 0px rgba(234, 179, 8, 0.04);
  }
  .p-message.p-message-warn .p-message-close:focus-visible {
    outline-color: #eab308;
  }
  .p-message.p-message-warn .p-message-close:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  .p-message.p-message-error {
    box-shadow: 0px 4px 8px 0px rgba(239, 68, 68, 0.04);
  }
  .p-message.p-message-error .p-message-close:focus-visible {
    outline-color: #ef4444;
  }
  .p-message.p-message-error .p-message-close:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  .p-message.p-message-secondary {
    box-shadow: 0px 4px 8px 0px rgba(74, 85, 103, 0.04);
  }
  .p-message.p-message-secondary .p-message-close:focus-visible {
    outline-color: #d4d4d8;
  }
  .p-message.p-message-secondary .p-message-close:hover {
    background: #3f3f46;
  }
  .p-message.p-message-contrast {
    box-shadow: 0px 4px 8px 0px rgba(2, 6, 23, 0.04);
  }
  .p-message.p-message-contrast .p-message-close:focus-visible {
    outline-color: #09090b;
  }
  .p-message.p-message-contrast .p-message-close:hover {
    background: #f4f4f5;
  }
  .p-multiselect-panel .p-multiselect-header {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .p-multiselect {
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 1px 2px 0 rgba(18, 18, 23, 0.05);
  }
  .p-multiselect.p-disabled {
    opacity: 1;
    background-color: #3f3f46;
  }
  .p-multiselect.p-disabled .p-multiselect-label {
    color: #a1a1aa;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    border-radius: 4px;
    margin-right: 0.25rem;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: 0.375rem;
  }
  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.25rem 0.25rem;
  }
  .p-panelmenu .p-panelmenu-header {
    border-radius: 4px;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content {
    outline: 0 none;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content .p-panelmenu-header-action {
    background: rgba(255, 255, 255, 0.08);
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-text {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
    border-radius: 4px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
    color: #ffffff;
    padding: 0.5rem 0.75rem;
    font-weight: 600;
    border-radius: 4px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-text {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon {
    color: #71717a;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
    color: #71717a;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action:hover {
    background: rgba(255, 255, 255, 0.03);
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action:hover .p-menuitem-text {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action:hover .p-submenu-icon {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action:hover .p-menuitem-icon {
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-text {
    /*line-height: 1.5;*/
  }
  .p-panelmenu .p-panelmenu-content {
    border-radius: 6px;
    padding: 0 0 0 1rem;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:first-child {
    margin-top: 2px;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:last-child {
    margin-top: 2px;
  }
  .p-panelmenu .p-panelmenu-panel {
    padding: 0.25rem 0.25rem;
    overflow: hidden;
    margin-bottom: 0.75rem;
    border: 1px solid #3f3f46;
    border-radius: 6px;
  }
  .p-password-panel {
    background-color: #18181b;
    border: 1px solid #3f3f46;
    padding: 0.75rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  .p-password-panel .p-password-meter {
    margin-bottom: 0.75rem;
    background: #3f3f46;
    border-radius: 6px;
  }
  .p-password-panel .p-password-meter .p-password-strength {
    border-radius: 6px;
  }
  .p-password-panel .p-password-meter .p-password-strength.weak {
    background: #f87171;
  }
  .p-password-panel .p-password-meter .p-password-strength.medium {
    background: #fbbf24;
  }
  .p-password-panel .p-password-meter .p-password-strength.strong {
    background: #4ade80;
  }
  .p-orderlist-controls .p-button {
    transition: opacity 0.2s, outline-color 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    color: #d4d4d8;
    background: #27272a;
    border: 1px solid #27272a;
  }
  .p-orderlist-controls .p-button:not(:disabled):hover {
    background: #3f3f46;
    color: #d4d4d8;
    border-color: #3f3f46;
  }
  .p-orderlist-controls .p-button:not(:disabled):focus {
    box-shadow: none;
  }
  .p-orderlist-controls .p-button:not(:disabled):active {
    background: #52525b;
    color: #d4d4d8;
    border-color: #52525b;
  }
  .p-orderlist .p-orderlist-header {
    background: #18181b;
    color: #ffffff;
    border: 0 none;
    border-bottom: 0 none;
    padding: 0.75rem 1rem 0.5rem 1rem;
    font-weight: 600;
    color: #a1a1aa;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-orderlist .p-orderlist-list {
    border: 1px solid #3f3f46;
    border: 0 none;
    background: #18181b;
    color: #ffffff;
    padding: 0.25rem 0.25rem;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item {
    border-radius: 6px;
  }
  .p-orderlist .p-orderlist-list:not(:first-child) {
    border: 0 none;
  }
  .p-organizationchart .p-organizationchart-node-content {
    padding: 0.75rem 1rem;
    border-radius: 6px;
  }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border: 1px solid #3f3f46;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler .p-node-toggler-icon {
    position: static;
  }
  .p-organizationchart .p-organizationchart-node-content:has(.p-node-toggler) {
    padding: 0.75rem 1rem 1.25rem 1rem;
  }
  .p-organizationchart .p-organizationchart-lines :nth-child(1 of .p-organizationchart-line-left) {
    border-right: 0 none;
  }
  .p-organizationchart .p-organizationchart-lines :nth-last-child(1 of .p-organizationchart-line-left) {
    border-top-right-radius: 6px;
  }
  .p-organizationchart .p-organizationchart-lines :nth-child(1 of .p-organizationchart-line-right) {
    border-left: 1px solid #3f3f46;
    border-top-left-radius: 6px;
  }
  .p-overlaypanel {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  .p-overlaypanel .p-overlaypanel-content {
    padding: 0.75rem;
  }
  .p-overlaypanel .p-overlaypanel-close {
    width: 1.75rem;
    height: 1.75rem;
    color: #a1a1aa;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }
  .p-overlaypanel .p-overlaypanel-close:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.03);
  }
  .p-overlaypanel .p-overlaypanel-close:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-panel {
    border: 1px solid #3f3f46;
    border-radius: 6px;
    background-color: #18181b;
  }
  .p-panel .p-panel-header,
  .p-panel .p-panel-content,
  .p-panel .p-panel-footer {
    background: transparent;
    border: 0 none;
  }
  .p-panel:has(.p-panel-footer) .p-panel-content {
    padding-bottom: 0.875rem;
  }
  .p-picklist-buttons .p-button {
    transition: opacity 0.2s, outline-color 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    color: #d4d4d8;
    background: #27272a;
    border: 1px solid #27272a;
  }
  .p-picklist-buttons .p-button:not(:disabled):hover {
    background: #3f3f46;
    color: #d4d4d8;
    border-color: #3f3f46;
  }
  .p-picklist-buttons .p-button:not(:disabled):focus {
    box-shadow: none;
  }
  .p-picklist-buttons .p-button:not(:disabled):active {
    background: #52525b;
    color: #d4d4d8;
    border-color: #52525b;
  }
  .p-picklist .p-picklist-header {
    background: #18181b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    border: 0 none;
    padding: 0.75rem 1rem 0.5rem 1rem;
    font-weight: 600;
    color: #a1a1aa;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-picklist .p-picklist-list {
    border: 1px solid #3f3f46;
    border: 0 none;
    background: #18181b;
    color: #ffffff;
    padding: 0.25rem 0.25rem;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-picklist .p-picklist-list .p-picklist-item {
    border-radius: 6px;
  }
  .p-picklist .p-picklist-list:not(:first-child) {
    border: 0 none;
  }
  .p-progressbar-label {
    font-size: 0.75rem;
    font-weight: 600;
  }
  .p-radiobutton .p-radiobutton-box {
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 1px 2px 0 rgba(18, 18, 23, 0.05);
  }
  .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:focus-visible) .p-radiobutton-box {
    outline-width: 1px;
    outline-offset: 2px;
  }
  .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:focus-visible).p-highlight .p-radiobutton-box {
    border-color: #000000;
  }
  .p-radiobutton.p-disabled {
    opacity: 1;
  }
  .p-radiobutton.p-disabled .p-radiobutton-box {
    border: 1px solid #3f3f46;
    background-color: #3f3f46;
  }
  .p-radiobutton.p-disabled .p-radiobutton-box .p-radiobutton-icon {
    background-color: #a1a1aa;
  }
  .p-rating {
    gap: 0.25rem;
  }
  .p-selectbutton .p-button .pi,
  .p-selectbutton .p-button .p-button-label {
    position: relative;
    transition: none;
  }
  .p-selectbutton .p-button::before {
    content: "";
    background-color: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    position: absolute;
    left: 0.25rem;
    top: 0.25rem;
    width: calc(100% - 0.5rem);
    height: calc(100% - 0.5rem);
    border-radius: 4px;
  }
  .p-selectbutton .p-button.p-highlight::before {
    background: #27272a;
    border-color: #27272a;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
  }
  .p-selectbutton .p-button:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: -1px;
    box-shadow: none;
    border-color: #52525b;
  }
  .p-selectbutton .p-button.p-disabled {
    opacity: 1;
    color: #71717a;
  }
  .p-selectbutton.p-invalid {
    box-shadow: 0 0 0 1px #fca5a5;
    border-radius: 6px;
  }
  .p-selectbutton.p-invalid > .p-button {
    border: 1px solid #09090b;
  }
  .p-selectbutton.p-disabled {
    opacity: 1;
  }
  .p-selectbutton.p-disabled .p-button {
    color: #71717a;
  }
  .p-slider .p-slider-handle {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .p-slider .p-slider-handle::before {
    content: "";
    width: 16px;
    height: 16px;
    display: block;
    background-color: #09090b;
    border-radius: 50%;
    box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
  }
  .p-slider .p-slider-handle:focus-visible {
    outline: 0 none;
    box-shadow: 0 0 0 2px #09090b, 0 0 0 4px var(--p-focus-ring-color), 0 1px 2px 0 rgb(0, 0, 0);
  }
  .p-speeddial-item.p-focus > .p-speeddial-action {
    outline-color: gray;
  }
  .p-speeddial-button.p-speeddial-rotate {
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-splitter-gutter-handle {
    border-radius: 6px;
  }
  .p-stepper .p-stepper-header .p-stepper-number {
    box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
  }
  .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
    background: #18181b;
    color: #000000;
  }
  .p-stepper .p-stepper-header.p-highlight .p-stepper-title {
    color: #000000;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    border-width: 2px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    font-weight: 500;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-steps .p-steps-item .p-steps-number {
    position: relative;
    font-weight: 500;
  }
  .p-steps .p-steps-item .p-steps-number::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
  }
  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #18181b;
    color: #000000;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-title {
    color: #000000;
  }
  .p-steps .p-steps-item.p-disabled {
    opacity: 1;
  }
  .p-steps .p-steps-item:before {
    border-top-width: 2px;
    margin-top: calc(-1rem + 1px);
  }
  .p-steps .p-steps-item:first-child::before {
    width: calc(50% + 1rem);
    transform: translateX(100%);
  }
  .p-steps .p-steps-item:last-child::before {
    width: 50%;
  }
  .p-tabmenu .p-tabmenu-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: -1px;
    height: 1px;
    background-color: #000000;
    transition: 250ms cubic-bezier(0.35, 0, 0.25, 1);
  }
  .p-tabmenu .p-tabmenu-nav {
    position: relative;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-tabview .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 1px;
    background-color: #000000;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
  }
  .p-tabview .p-tabview-nav {
    position: relative;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-tabview .p-tabview-nav-btn.p-link {
    color: #a1a1aa;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 10px 50px rgba(24, 24, 27, 0.5);
  }
  .p-tabview .p-tabview-nav-btn.p-link:hover {
    color: #ffffff;
  }
  .p-tag {
    background: color-mix(in srgb, var(--primary-500), transparent 80%);
    color: var(--primary-300);
  }
  .p-tag.p-tag-success {
    background: rgba(34, 197, 94, 0.16);
    color: #22c55e;
  }
  .p-tag.p-tag-info {
    background: rgba(59, 130, 246, 0.16);
    color: #3b82f6;
  }
  .p-tag.p-tag-warning {
    background: rgba(234, 179, 8, 0.16);
    color: #eab308;
  }
  .p-tag.p-tag-danger {
    background: rgba(239, 68, 68, 0.16);
    color: #ef4444;
  }
  .p-terminal {
    background: #09090b;
    color: #ffffff;
    border: 1px solid #3f3f46;
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
  }
  .p-terminal .p-terminal-prompt {
    margin-right: 0.25rem;
  }
  .p-terminal .p-terminal-response {
    margin: 2px 0;
  }
  .p-timeline .p-timeline-event-marker {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .p-timeline .p-timeline-event-marker::before {
    content: " ";
    border-radius: 50%;
    width: 0.375rem;
    height: 0.375rem;
    background-color: #000000;
  }
  .p-timeline .p-timeline-event-marker::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
  }
  .p-toast .p-toast-message {
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    font-size: 0.875rem;
  }
  .p-toast .p-toast-message .p-toast-icon-close {
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -25%;
    right: -25%;
    position: relative;
  }
  .p-toast .p-toast-message.p-toast-message-info {
    box-shadow: 0px 4px 8px 0px rgba(59, 130, 246, 0.04);
  }
  .p-toast .p-toast-message.p-toast-message-info .p-toast-detail {
    color: #ffffff;
  }
  .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    outline-color: #3b82f6;
  }
  .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.075);
  }
  .p-toast .p-toast-message.p-toast-message-success {
    box-shadow: 0px 4px 8px 0px rgba(34, 197, 94, 0.04);
  }
  .p-toast .p-toast-message.p-toast-message-success .p-toast-detail {
    color: #ffffff;
  }
  .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    outline-color: #22c55e;
  }
  .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.075);
  }
  .p-toast .p-toast-message.p-toast-message-warn {
    box-shadow: 0px 4px 8px 0px rgba(234, 179, 8, 0.04);
  }
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-detail {
    color: #ffffff;
  }
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    outline-color: #eab308;
  }
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.075);
  }
  .p-toast .p-toast-message.p-toast-message-error {
    box-shadow: 0px 4px 8px 0px rgba(239, 68, 68, 0.04);
  }
  .p-toast .p-toast-message.p-toast-message-error .p-toast-detail {
    color: #ffffff;
  }
  .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    outline-color: #ef4444;
  }
  .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  .p-toast .p-toast-message.p-toast-message-secondary {
    box-shadow: 0px 4px 8px 0px rgba(74, 85, 103, 0.04);
  }
  .p-toast .p-toast-message.p-toast-message-secondary .p-toast-icon-close {
    outline-color: #ef4444;
  }
  .p-toast .p-toast-message.p-toast-message-secondary .p-toast-icon-close:hover {
    background: #3f3f46;
  }
  .p-toast .p-toast-message.p-toast-message-contrast {
    box-shadow: 0px 4px 8px 0px rgba(2, 6, 23, 0.04);
  }
  .p-toast .p-toast-message.p-toast-message-contrast .p-toast-icon-close {
    outline-color: #ef4444;
  }
  .p-toast .p-toast-message.p-toast-message-contrast .p-toast-icon-close:hover {
    background: #f4f4f5;
  }
  .p-togglebutton .p-button .p-button-label,
  .p-togglebutton .p-button .p-button-icon {
    position: relative;
    transition: none;
  }
  .p-togglebutton .p-button::before {
    content: "";
    background-color: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    position: absolute;
    left: 0.25rem;
    top: 0.25rem;
    width: calc(100% - 0.5rem);
    height: calc(100% - 0.5rem);
    border-radius: 4px;
  }
  .p-togglebutton.p-highlight .p-button::before {
    background: #27272a;
    border-color: #27272a;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
  }
  .p-togglebutton.p-disabled {
    opacity: 1;
  }
  .p-togglebutton.p-disabled .p-button {
    color: #71717a;
  }
  .p-togglebutton.p-disabled .p-button .p-button-icon {
    color: #71717a;
  }
  .p-toolbar {
    padding: 0.75rem;
  }
  .p-tree {
    border: 0 none;
    padding: 1rem;
  }
  .p-tree .p-tree-container .p-treenode {
    margin: 2px 0;
  }
  .p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
    outline-offset: -2px;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover {
    background-color: rgba(250, 250, 250, 0.16);
  }
  .p-tree .p-tree-container > .p-treenode:first-child {
    margin-top: 0;
  }
  .p-tree .p-tree-container > .p-treenode:last-child {
    margin-bottom: 0;
  }
  .p-treeselect-panel .p-tree {
    padding: 0.25rem 0.25rem;
  }
  .p-treeselect {
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 1px 2px 0 rgba(18, 18, 23, 0.05);
  }
  .p-treeselect.p-disabled {
    opacity: 1;
    background-color: #3f3f46;
  }
  .p-treeselect.p-disabled .p-treeselect-label {
    color: #a1a1aa;
  }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
