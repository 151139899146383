<template>
  <ion-page>
    <av-header />

    <ion-content>
      <div class="flex vertical bgd" slot="fixed">
        <view-header @back="back" title="Register" subtitle="For an Alpha Victor account" class="fullwidth" />

        <div style="max-width: min(90vw, 500px)" class="fullwidth">
          <ion-item>
            <ion-input color="secondary" v-model="email" type="email" label="Email" label-placement="floating"></ion-input>
            <ion-note color="warning">{{ emailFeedback }}</ion-note>
          </ion-item>

          <ion-item>
            <ion-input
              color="secondary"
              type="password"
              v-model="password"
              v-on:keyup.enter="register()"
              label="Password"
              label-placement="floating"
            ></ion-input>
            <ion-note color="warning">{{ passwordFeedback }}</ion-note>
          </ion-item>
          <ion-button expand="block" class="bright mt2" @click="register()" :disabled="!canSubmit">Register</ion-button>
        </div>
        <div class="fullwidth footerBg">
          <div class="footer flex safe-bottom">
            <ion-button expand="block" color="secondary" class="grow" router-direction="root" router-link="/login">
              <ion-label class="fs">Existing User?</ion-label>
            </ion-button>
            <ion-button expand="block" color="secondary" class="grow" router-direction="root" router-link="/password-reminder">
              <ion-label class="fs">Forgotten Password</ion-label>
            </ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { isEmailValid } from "@/composables/utilities";
import { useUserStore } from "@/stores/user";
import mixpanel from "mixpanel-browser";

export default {
  setup() {
    const router = useRouter();
    const email = ref();
    const password = ref();
    const emailFeedback = ref();
    const passwordFeedback = ref();
    const userStore = useUserStore();

    const canSubmit = computed(() => {
      return isEmailValid(email.value) && password.value?.length > 7;
    });

    const isProduction = process.env.VUE_APP_MODE == "production";

    // Uncomment following line to hide registration
    // if (isProduction) router.push({ name: "Login" });

    const register = async () => {
      if (password.value?.length < 8) return;
      try {
        mixpanel.track("User Registered", { email: email.value });
        await userStore.createUser(email.value, password.value);
        router.push({ name: "VerifyEmail" });
      } catch (errors) {
        emailFeedback.value = errors.email;
        passwordFeedback.value = errors.password;
      }
    };

    const back = () => {
      router.push({ name: "Home" });
    };

    return {
      email,
      password,
      emailFeedback,
      passwordFeedback,
      canSubmit,
      register,
      back,
    };
  },
};
</script>

<style scoped>
#loginContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.formItems {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#loginContainer ion-item,
#loginContainer ion-button {
  width: 100%;
  max-width: 500px;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: env(safe-area-inset-bottom);
}
.footerContainer ion-button {
  flex: 1 0 1px;
  font-size: 0.7em;
}
</style>
