<template>
  <ion-page>
    <av-header />
    <ion-content>
      <iframe :src="source" class="fullwidth fullheight" frameBorder="0" slot="fixed" />
    </ion-content>
  </ion-page>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  setup() {
    const source = computed(() => `${process.env.VUE_APP_DOMAIN}/static/privacy.html`);

    return { source };
  },
};
</script>
