<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="organisation">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="organisation.name" :subtitle="t('ubiqisense_configuration')" edit="true" />

        <div class="fullwidth p scroll">
          <ion-item>
            <ion-input
              :label="t('client_id')"
              label-placement="stacked"
              color="secondary"
              type="password"
              v-model="organisation.ubiClientID"
              :placeholder="t('ubiqisense_client_id')"
              @ionInput="markDirty"
              @ionBlur="updateOrganisations"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              :label="t('client_secret')"
              label-placement="stacked"
              color="secondary"
              type="password"
              v-model="organisation.ubiClientSecret"
              :placeholder="t('ubiqisense_client_secret')"
              @ionInput="markDirty"
              @ionBlur="updateOrganisations"
            ></ion-input>
          </ion-item>
          <ion-item lines="none" class="mt2" v-if="organisations">
            <ion-list class="fullwidth p">
              <ion-label color="secondary" class="uppercase boldFont">{{ t("select_organisation") }}</ion-label>
              <ion-radio-group v-model="organisation.ubi_id" @ionChange="markDirty">
                <ion-item v-for="org in organisations" :key="org.id">
                  <ion-label color="secondary">{{ org.name }}</ion-label>
                  <ion-radio color="secondary" mode="ios" :value="org.id" slot="end"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </ion-item>
        </div>

        <div class="grow"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { useUbiStore } from "@/stores/ubi";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default {
  name: "Description",
  setup() {
    const dirty = ref(false);
    const organisationID = useRoute().params.organisationID;
    const organisationStore = useOrganisationStore();
    const ubiStore = useUbiStore();
    const { organisation } = storeToRefs(organisationStore);
    const { organisations } = storeToRefs(ubiStore);
    const { t } = useI18n();

    let backup;

    onIonViewWillEnter(() => organisationStore.initialise(organisationID));
    const createBackup = () => (backup = { ...organisation });
    createBackup();
    watch(organisation, createBackup);

    const router = useRouter();

    const save = async () => {
      organisationStore.saveRecord("organisations", organisationID, organisation.value, [
        "decisionTrees",
        "locations",
        "articles",
        "videos",
        "issues",
        "loaded",
        "imageLoaded",
      ]);
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                organisation.value = backup;
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const updateOrganisations = () => {
      if (!(organisation.value.ubiClientID && organisation.value.ubiClientSecret)) return;
      ubiStore.getOrganisations();
    };

    return {
      // variables
      organisation,
      organisations,
      // methods
      markDirty,
      back,
      updateOrganisations,
      t,
      // icons
      chevronForward,
    };
  },
};
</script>
