// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.footer[data-v-261385a2] {
  font-family: Title;
  color: var(--ion-color-secondary);
  font-size: 1.6em;
  text-transform: uppercase;
  background: var(--ion-color-light-tint);
}
#stepContainer[data-v-261385a2] {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  text-align: left;
}
#player[data-v-261385a2] {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.chapterTitle[data-v-261385a2] {
  margin: 0px 0px 5px;
  font-family: Title;
  font-size: 1.1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.chapterSubtitle[data-v-261385a2] {
  margin-top: -5px;
  font-size: 0.5em;
  font-family: Regular;
}
.html[data-v-261385a2] {
  font-size: 0.8rem;
  display: flex;
  align-content: flex-start;
  padding: 0px;
  margin: 0px;
}
.html[data-v-261385a2] p {
  margin: 0px 0px 10px 0px;
}
.html[data-v-261385a2] {
  margin-bottom: 10px;
}
#controls[data-v-261385a2] {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8em;
  margin-bottom: 20px;
}
.activeChapter[data-v-261385a2] {
  opacity: 1;
}
.inactiveChapter[data-v-261385a2] {
  opacity: 0.5;
}
.playerControls[data-v-261385a2] {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.7em;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
