<template>
  <ion-page>
    <ion-content>
      <div class="flex vertical nogap" slot="fixed" v-if="organisation?.loaded && space">
        <av-header />
        <div class="flex vertical fullwidth noScroll">
          <!-- Feature Image  -->
          <div class="featureImage" @click="showLookAround">
            <div class="featureBackground"><img :src="space.featurePhotoURL" /></div>
            <div class="featureOverlay"></div>
            <div class="featureContent">
              <div class="featureTop">
                <div class="featureLeft" @click="goBack($event)">&lt;&lt; back</div>
                <div class="featureRight">
                  <ion-icon v-if="isLoggedIn" :icon="isFavourite ? heart : heartOutline" @click="toggleFavourite($event)" />
                </div>
              </div>
              <div class="featureBottom">
                <div class="featureLeft">
                  <div class="featureOccupancy">
                    <ion-icon :icon="peopleOutline" color="secondary" />
                    <div>&gt;&gt;{{ space.capacity }}</div>
                  </div>
                  <div class="featureName">
                    {{ space.name }} <span class="ft">{{ spaceType }}</span>
                  </div>

                  <div class="featureLocation">
                    <ion-icon :icon="locationOutline" color="secondary" />
                    <div>{{ floor.name }}</div>
                  </div>
                </div>
                <div class="featureRight">
                  <div class="360image">
                    <!-- <i class="pi pi-window-maximize" style="font-size: 2em" v-if="space.pano?.path" /> -->
                    <!-- <img src="/icons/360.svg" v-if="space.pano?.path" /> -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24" v-if="space.pano?.path">
                      <path
                        fill="currentColor"
                        d="M18 16h-.58l-.81-.81A7.07 7.07 0 0 0 18 11c0-3.87-3.13-7-7-7c-1.5 0-3 .5-4.21 1.4c-3.09 2.32-3.72 6.71-1.4 9.8c2.32 3.09 6.71 3.72 9.8 1.4l.81.81V18l5 5l2-2zm-7 0c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5M3 6L1 8V1h7L6 3H3zm18-5v7l-2-2V3h-3l-2-2zM6 19l2 2H1v-7l2 2v3z"
                      />
                    </svg>
                    <img src="/icons/gallery.svg" v-else />
                  </div>
                  <div class="featureOccupancy" v-if="space.currentOccupancy != null">
                    <ion-icon :icon="ellipse" :color="space.currentOccupancy == 0 ? 'success' : 'danger'" />
                    <div v-if="space.currentOccupancy == 0" class="featureVacant">space.vacant</div>
                    <div v-else class="featureOccupied">
                      {{ space.currentOccupancy }}.{{ space.currentOccupancy > 1 ? $t("people") : $t("person") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="features">
            <div v-for="feature in spaceFeatures" :key="feature.id">{{ feature.name }}</div>
          </div>

          <div class="wifiDetails" v-if="location.wifi?.name" @click="connectToWifi">
            <div class="ssid">
              <div class="label">Wifi.network</div>
              <div>{{ location.wifi.name }}</div>
            </div>
            <div class="password">
              <div class="label">Wifi.password</div>
              <div>{{ location.wifi.password }}</div>
            </div>
          </div>

          <!-- Actions  -->

          <div class="fullwidth">
            <div class="action" v-if="allowEdit && isTech" @click="editSpace">
              <div>{{ t("edit_space") }}</div>
              <div class="forward">&gt;</div>
            </div>

            <div class="action" @click="showHowTo" v-if="space.decisionTreeID">
              <div>{{ t("user_guides") }}</div>
              <div class="forward">&gt;</div>
            </div>

            <div class="action" @click="showInThisSpace" v-if="isTech">
              <div>{{ t("room_equipment") }}</div>
              <div class="forward">&gt;</div>
            </div>

            <div class="action" @click="showDocuments" v-if="(space.documents?.length > 0 || space.modelDocuments?.length > 0) && isTech">
              <div>{{ t("documents") }}</div>
              <div class="forward">&gt;</div>
            </div>

            <div class="action" @click="showIssues">
              <div class="flex autowidth">
                <div>{{ t("known_issues") }}</div>
                <div class="counter" :class="{ danger: spaceIssues?.length > 0 }">
                  {{ spaceIssues?.length || 0 }}
                </div>
              </div>
              <div class="forward">&gt;</div>
            </div>

            <div v-if="isSupport">
              <div class="action" @click="showChecklist(checklist.id)" v-for="checklist in space.dueChecklists" :key="checklist.id">
                <div class="flex autowidth">
                  {{ space.dueChecklists[checklist.id].name }}
                </div>
                <div class="forward">&gt;</div>
              </div>
            </div>

            <div v-if="isSupport">
              <div class="action" @click="showChecklist(checklist.id)" v-for="checklist in lastCompletedChecklists" :key="checklist.id">
                <div class="flex autowidth">
                  {{ checklist.name }}
                </div>

                <div class="forward flex autowidth">
                  <div :class="checklist.didFail ? 'fail' : 'success'">
                    {{ checklist.didFail ? "Failed " : "Passed " }}{{ formatFirestoreDate(checklist.submissionDate) }}
                  </div>
                  &gt;
                </div>
              </div>
            </div>
          </div>
          <div class="grow"></div>

          <div class="fullwidth footerBg">
            <div class="footer flex safe-bottom">
              <ion-button
                expand="block"
                color="secondary"
                class="grow"
                @click="reportIssue"
                v-if="isLoggedIn || location.allowAnonymousReporting"
                >{{ t("report_issue") }}</ion-button
              >
              <ion-button expand="block" color="tertiary" class="grow" @click="giveFeedback" v-if="space.typeformID">{{
                t("give_feedback")
              }}</ion-button>
            </div>
          </div>
        </div>
      </div>
      <wait v-else>Loading</wait>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useOrganisationStore } from "@/stores/organisation";
import { useBeaconStore } from "@/stores/beacons";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  checkmark,
  closeCircle,
  checkmarkCircle,
  scan,
  create,
  copy,
  heartCircle,
  heart,
  heartOutline,
  heartDislikeCircle,
  peopleCircle,
  peopleOutline,
  locationOutline,
  ellipse,
  images,
} from "ionicons/icons";
import { isPlatform, onIonViewWillEnter, onIonViewWillLeave } from "@ionic/vue";
import { Wifi } from "@uncoded-limited/connect";
import { toast, objectToArray } from "@/composables/utilities";
import { Clipboard } from "@capacitor/clipboard";
import Wait from "@/components/Wait.vue";
import { useI18n } from "vue-i18n";
import mixpanel from "mixpanel-browser";
import { filterObject } from "../../composables/utilities";
import { formatFirestoreDate } from "@/composables/firebase";

const organisationStore = useOrganisationStore();
const userStore = useUserStore();
const { user, profile, firebaseUser } = storeToRefs(userStore);
const route = useRoute();
const router = useRouter();
const spaceID = route.params.spaceID;
const floorID = route.params.floorID;
const locationID = route.params.locationID;
const organisationID = route.params.organisationID;
const { space, floor, location, organisation, issues } = storeToRefs(organisationStore);
const isLoggedIn = computed(() => !firebaseUser.value?.isAnonymous);
const { t } = useI18n();
let cancelUbiScan;

const spaceType = computed(() => organisation.value?.spaceTypes?.find((t) => t.id == space.value?.spaceTypeID)?.name || "");

const spaceFeatures = computed(() => organisation.value?.spaceFeatures?.filter((f) => space.value?.featureIDs?.includes(f.id)));

const allowEdit = computed(() => user.value?.roles?.includes("spaceAdmin") || user.value?.roles?.includes("admin"));

const spaceIssues = computed(() =>
  objectToArray(issues.value).filter(
    (i) => i.locationID == locationID && i.floorID == floorID && i.spaceID == spaceID && i.status != "success"
  )
);

const lastCompletedChecklists = computed(() => {
  const result = {};
  if (space.value?.checklistIDs) {
    for (const checklistID of space.value?.checklistIDs) {
      const dueChecklistIDs = [];
      for (const dueChecklistID in space.value.dueChecklists) {
        dueChecklistIDs.push(space.value.dueChecklists[dueChecklistID].checklistID);
      }

      const completedChecklists = filterObject(
        space.value.completedChecklists,
        (c) => c.checklistID == checklistID && !dueChecklistIDs.includes(c.checklistID)
      );

      if (completedChecklists) {
        const completedChecklistIDs = Object.keys(completedChecklists);
        if (completedChecklistIDs.length > 0) {
          result[completedChecklistIDs[0]] = completedChecklists[completedChecklistIDs[0]];
        }
      }
    }
  }
  return result;
});

const waitForDetails = () => {
  const checkDetails = (resolve) => {
    if (space.value?.featurePhotoURL && organisation.value && floor.value && location.value) {
      resolve();
    } else {
      setTimeout(checkDetails.bind(this, resolve), 50);
    }
  };
  return new Promise(checkDetails);
};

const saveRecentSpace = async () => {
  if (!profile.value) return;
  if (isLoggedIn.value) {
    if (!profile.value.recentSpaces) profile.value.recentSpaces = [];
    const recentSpace = profile.value?.recentSpaces?.find(
      (s) => s.spaceID == spaceID && s.floorID == floorID && s.locationID == locationID && s.organisationID == organisationID
    );
    if (!recentSpace) {
      const data = {
        date: new Date(),
        organisationID,
        locationID,
        floorID,
        spaceID,
        organisationName: organisation.value?.name || "",
        locationName: location.value?.name || "",
        floorName: floor.value?.name || "",
        spaceName: space.value?.name || "",
        photoPath: space.value?.featurePhotoPath || "",
        photoURL: space.value.featurePhotoURL || "",
      };
      profile.value.recentSpaces.push(data);
      profile.value.lastViewDate = new Date();
    } else {
      recentSpace.date = new Date();
    }
    userStore.saveProfile();
  }
};

onIonViewWillEnter(async () => {
  await organisationStore.initialise(organisationID, locationID, floorID, spaceID);
  mixpanel.track("Space Viewed", {
    organisation: organisation.value?.name,
    location: location.value?.name,
    floor: floor.value?.name,
    space: space.value?.name,
  });
  // if (isPlatform("cordova")) {
  //   const beacon = beacons.value?.find((b) => b.spaceID == spaceID);
  //   if (beacon) beaconStore.monitorSpaceForExit(beacon.major, beacon.minor);
  // }
  await waitForDetails();

  setTimeout(() => {
    organisationStore.updateSpaceOccupancy();
  }, 500);
  cancelUbiScan = setInterval(() => {
    if (space.value?.ubi_id) organisationStore.updateSpaceOccupancy();
  }, 5000);
  saveRecentSpace();
});

onIonViewWillLeave(() => {
  if (cancelUbiScan) clearInterval(cancelUbiScan);
});

const issueCount = computed(() => (space.value?.issues && space.value.issues.length) || 0);

const isFavourite = computed(() => {
  const favourites = profile.value?.favouriteSpaces || [];
  return favourites.find(
    (f) => f.organisationID == organisationID && f.locationID == locationID && f.floorID == floorID && f.spaceID == spaceID
  );
});

const toggleFavourite = (event) => {
  if (event) event.cancelBubble = true;
  if (isFavourite.value) {
    profile.value.favouriteSpaces = profile.value.favouriteSpaces.filter(
      (f) => !(f.organisationID == organisationID && f.locationID == locationID && f.floorID == floorID && f.spaceID == spaceID)
    );
  } else {
    if (!profile.value.favouriteSpaces) profile.value.favouriteSpaces = [];
    profile.value.favouriteSpaces.push({
      organisationID,
      locationID,
      floorID,
      spaceID,
      // organisationName: organisation.value.name,
      // locationName: location.value.name,
      // floorName: floor.value.name,
      // spaceName: space.value.name,
      // photoPath: space.value.featurePhotoPath,
      // photoURL: space.value.featurePhotoURL,
      favouriteAdded: new Date(),
    });
  }
  userStore.saveProfile();
};

const showHowTo = () => {
  router.push({
    name: "SpaceHowTo",
    params: { organisationID, locationID, floorID, spaceID, decisionTreeID: space.value.decisionTreeID },
  });
};

const showChecklist = (id) => {
  router.push({
    name: "SpaceChecklist",
    params: { organisationID, locationID, floorID, spaceID, checklistID: id },
  });
};

const connectToWifi = async () => {
  if (!isPlatform("cordova")) return;
  const outcome = await Wifi.connect({ ssid: location.value?.wifi?.name, password: location.value?.wifi?.password });
  toast(outcome.result);
};

const showInThisSpace = () => {
  router.push({ name: "SpaceInThisSpace", params: { organisationID, locationID, floorID, spaceID } });
};

const isTech = computed(() => user.value?.roles?.includes("technician"));
const isSupport = computed(() => user.value?.roles?.includes("support"));

const showLookAround = () => {
  if (space.value?.pano?.path) {
    router.push({ name: "SpaceLookAround", params: { organisationID, locationID, floorID, spaceID } });
  } else {
    showGallery();
  }
};
const showGallery = () => {
  router.push({ name: "SpaceGallery", params: { organisationID, locationID, floorID, spaceID } });
};

const showIssues = () => {
  router.push({ name: "SpaceIssues", params: { organisationID, locationID, floorID, spaceID } });
};
const showDocuments = () => {
  router.push({ name: "SpaceDocuments", params: { organisationID, locationID, floorID, spaceID } });
};

const goBack = (event) => {
  event.cancelBubble = true;
  router.back();
};

const giveFeedback = () => {
  router.push({ name: "SpaceGiveFeedback", params: { organisationID, locationID, floorID } });
};

const reportIssue = () => {
  router.push({ name: "SpaceReportIssue", params: { organisationID, locationID, floorID } });
};

const editSpace = () => {
  router.push({ name: "EditSpace", params: { organisationID, locationID, floorID } });
};

const copyToClipboard = async (name, value) => {
  await Clipboard.write({ string: value });
  toast(`${name} copied to clipboard`, 2000, copy);
};
</script>

<style lang="scss" scoped>
.success {
  color: var(--ion-color-success);
}

.fail {
  color: var(--ion-color-danger);
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  padding-top: 2px;
  margin-left: 5px;
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}

.counter.warning {
  background-color: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
}

.counter.danger {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

.forward {
  margin-right: 0.3rem;
}

.wifiDetails {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  border: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.5);
  border-radius: 5px;
  font-family: Title;
  font-size: 1.1em;
  .ssid,
  .password {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 10px 20px;
    padding: 5px 0px;
    .label {
      font-family: Bold;
      font-size: 0.5em;
      font-weight: 300;
      margin-bottom: 3px;
    }
  }

  .ssid {
    border-right: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.5);
  }
}

.features {
  display: flex;
  margin: 5px 20px;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 20px;

  div {
    border: 1px solid var(--ion-color-light);
    padding: 5px 10px;
    background: var(--ion-color-dark);
    color: var(--ion-color-dark-contrast);

    border-radius: 8px;
    font-family: Bold;
    font-size: 12px;
  }
}

.featureImage {
  height: 50%;
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  .featureBackground {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .featureOverlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 1));
  }

  .featureContent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Bold;
    .featureTop {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .featureLeft {
        color: var(--ion-color-light);
        font-size: 0.8em;
        display: flex;
        flex-direction: column;
      }
      .featureRight {
        color: var(--ion-color-light);
        font-size: 1.4em;
        display: flex;
        flex-direction: column;
      }
    }
    .featureBottom {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      .featureLeft {
        color: var(--ion-color-light);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .featureOccupancy {
          display: flex;
          gap: 5px;
          align-items: center;
          margin-bottom: 10px;
          font-size: 1.2em;
          div {
            font-size: 0.6em;
            color: var(--ion-color-light);
          }
        }
        .featureName {
          color: var(--ion-color-light);
          font-family: Title;
          font-size: 1.5em;
        }
        .featureLocation {
          display: flex;
          align-items: center;
          gap: 5px;
          div {
            padding-top: 3px;
            font-size: 0.7em;
            color: var(--ion-color-light);
          }
        }
      }
      .featureRight {
        color: var(--ion-color-light);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        .featureOccupancy {
          display: flex;
          gap: 5px;
          align-items: center;
          font-size: 0.8em;
          .featureOccupied {
            color: var(--ion-color-danger);
          }
          .featureVacant {
            color: var(--ion-color-success);
          }
        }
      }
    }
  }
}
</style>
