<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="space">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="space.name" :subtitle="t('edit_documents')" edit="true" />

        <div class="fullwidth ph pt scroll">
          <input type="file" @change="addDocument" ref="documentSelector" multiple hidden accept=".pdf" />

          <ion-list class="fullwidth">
            <ion-item v-for="document in space.documents" :key="document.id">
              <ion-icon :icon="documentOutline" color="secondary" slot="start" />
              <div class="flex vertical nogap left">
                <ion-input color="secondary" v-model="document.description" placeholder="Description" @ionInput="markDirty()"></ion-input>
                <ion-note class="boldFont fs fullwidth mb" @click="selectDocument(document)" color="secondary">{{
                  getTypeName(document.documentTypeID)
                }}</ion-note>
              </div>
              <ion-icon :icon="trashOutline" slot="end" color="secondary" @click="deleteDocument(document)" />
            </ion-item>
          </ion-list>
        </div>

        <div class="grow"></div>
        <div class="fullwidth">
          <ion-button expand="block" class="bright m2" @click="triggerAddDocument">{{ t("add_document") }}</ion-button>
        </div>

        <!-- <nav-footer :label="space.name" @back="back" /> -->
      </div>
    </ion-content>

    <ion-modal :is-open="!!selectedDocument" :can-dismiss="true" @didDismiss="selectedDocument = null" :presenting-element="rootElement">
      <ion-header>
        <ion-toolbar>
          <ion-title>Select Document Type</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list>
          <ion-item v-for="item in organisation.documentTypes" :key="item.id" @click="setDocumentType(item)">
            <ion-label>{{ item.name }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { chevronForward, trashOutline, documentOutline } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const rootElement = ref();
    const selectedDocument = ref();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;

    const organisationStore = useOrganisationStore();
    const { organisation, location, floor, space } = storeToRefs(organisationStore);
    const { t } = useI18n();

    const dirty = ref(false);
    const documentSelector = ref();

    onMounted(() => {
      rootElement.value = document.getElementById("main-content");
    });

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
    });

    const save = async () => {
      organisationStore.saveRecord(
        `organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces`,
        space.value.id,
        space.value
      );
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const selectDocument = (document) => {
      selectedDocument.value = document;
    };

    const setDocumentType = (type) => {
      selectedDocument.value.documentTypeID = type.id;
      save();
      selectedDocument.value = null;
      markDirty();
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
                router.back();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const deleteDocument = async (document) => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "This photo and associated information will be deleted immediately.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              organisationStore.deleteSpaceDocument(document);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ],
      });
      await alert.present();
    };

    const addDocument = async () => {
      const files = Array.from(event.target.files);
      files.forEach(async (file) => {
        const newDocument = await organisationStore.addSpaceDocument(file);
        documentSelector.value.value = "";
      });
    };

    const getTypeName = (typeID) => organisation.value.documentTypes?.find((t) => t.id == typeID)?.name || "Select document type";

    const go = (name) => {
      router.push({ name: name, params: { locationID } });
    };

    const triggerAddDocument = () => {
      documentSelector.value.click();
    };

    return {
      // variables
      organisation,
      location,
      floor,
      space,
      documentSelector,
      rootElement,
      selectedDocument,
      //methods
      go,
      markDirty,
      back,
      addDocument,
      deleteDocument,
      triggerAddDocument,
      selectDocument,
      setDocumentType,
      getTypeName,
      t,
      // icons
      chevronForward,
      trashOutline,
      documentOutline,
    };
  },
};
</script>
