<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div v-if="anonymous?.space" class="fullheight">
        <div class="bg"><img :src="anonymous?.space?.featurePhotoURL" /></div>
        <div class="flex vertical" slot="fixed">
          <view-header @back="back" title="Issues" :subtitle="anonymous?.space?.name" class="fullwidth" />
          <div class="boldFont uppercase mv ph2 flex">
            <div class="cx">Open issues</div>
          </div>
          <ion-accordion-group class="fullwidth ph">
            <ion-accordion v-for="issue in anonymous?.issues" :key="issue.id">
              <div class="action" slot="header">
                <div class="flex vertical top nogap">
                  <div>{{ issue.title }}</div>
                  <div class="ft">{{ getAge(issue.created) }}</div>
                </div>

                <div class="flex autowidth">
                  <ion-icon :icon="create" @click="editIssue(issue.id, $event)" v-if="isAdmin" />
                  <ion-icon :icon="chatboxEllipses" v-if="issue.updates?.length > 0" /><ion-icon
                    :icon="ellipse"
                    :color="issue.status || 'success'"
                  />
                </div>
              </div>
              <div slot="content" class="p fs" v-if="issue.events?.length > 0">
                <ion-list>
                  <ion-item v-for="(update, i) in issue.events" :key="i" :lines="i == issue.events.length - 1 ? 'none' : 'inset'">
                    <div class="flex vertical">
                      <div class="ft boldFont flex mt">
                        <div>{{ update.username }}</div>
                        <div>{{ getAge(update.date) }}</div>
                      </div>

                      <div class="fullwidth" style="padding-bottom: 10px">{{ update.description }}</div>
                    </div>
                  </ion-item>
                </ion-list>
              </div>
              <div slot="content" class="p ft" v-else>
                <ion-item lines="none">No updates</ion-item>
              </div>
            </ion-accordion>
          </ion-accordion-group>

          <div class="grow"></div>
          <!-- <nav-footer label="Back" @back="goBack" /> -->
        </div>
      </div>
      <wait v-else />
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";
import { computed } from "@vue/runtime-core";
import { ellipse, chatboxEllipses, create, addCircle } from "ionicons/icons";

export default {
  setup() {
    const organisationStore = useOrganisationStore();
    const userStore = useUserStore();
    const { anonymous } = storeToRefs(organisationStore);
    const { user } = storeToRefs(userStore);
    const route = useRoute();
    const router = useRouter();
    const spaceID = route.params.spaceID;
    const floorID = route.params.floorID;
    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
    });

    const isAdmin = computed(() => user.value?.roles?.includes("admin") || user.value?.roles?.includes("spaceAdmin"));

    const back = () => {
      router.back();
    };

    const getAge = (timestamp) => {
      const now = new Date();
      const then = timestamp.toDate();
      const age = (now - then) / (1000 * 60);
      if (age < 60) return `${Math.round(age)} minutes ago`;
      if (age < 60 * 60) return `${Math.round(age / 60)} hours ago`;
      return `${Math.round(age / (60 * 24))} days ago`;
    };

    const editIssue = (issueID, event) => {
      if (event) event.cancelBubble = true;
      router.push({ name: "SpaceIssue", params: { organisationID, issueID } });
    };

    const addIssue = async () => {
      const issueID = await organisationStore.addIssue();
      editIssue(issueID);
    };

    return {
      anonymous,
      isAdmin,
      back,
      getAge,
      editIssue,
      addIssue,
      ellipse,
      chatboxEllipses,
      create,
      addCircle,
    };
  },
};
</script>

<style scoped>
.bg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}

.tight {
  line-height: 0.9em;
}

.footer {
  font-family: Title;
  color: var(--ion-color-secondary);
  font-size: 1.6em;
  text-transform: uppercase;
  background: var(--ion-color-light-tint);
}

.smallButton {
  height: 1.5rem;
  font-size: 0.7rem;
}

.errorCount {
  margin: -20px 0px;
}

.bordered {
  flex-direction: column;
  border: 1px dotted var(--ion-color-secondary);
}

.actionCell {
  flex-direction: column;
}

.forward {
  margin-right: 0.3rem;
}
</style>
