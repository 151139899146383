<template>
  <ion-page>
    <av-header />

    <ion-content :scroll-y="false" v-if="organisation">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="organisation.name" :subtitle="t('edit_space_types')" edit="true" />
        <div class="fullwidth p scroll">
          <ion-list>
            <ion-item>
              <ion-input
                color="secondary"
                v-model="newTypeName"
                :placeholder="t('enter_new_type_and_press')"
                v-on:keyup.enter="addType()"
              />
              <ion-icon color="secondary" :icon="returnDownBack" @click="addType()" />
            </ion-item>
            <ion-item v-for="(type, i) in organisation.spaceTypes" :key="i">
              <ion-input color="secondary" v-model="type.name" @ionChange="markDirty" />
              <ion-icon color="secondary" slot="end" :icon="trashOutline" @click="deleteType(type)" />
            </ion-item>
          </ion-list>
        </div>
        <div class="grow"></div>
        <!-- <nav-footer :label="organisation.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward, trashOutline, returnDownBack } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default {
  name: "Description",
  setup() {
    const newTypeName = ref();
    const router = useRouter();
    const dirty = ref(false);
    const organisationID = useRoute().params.organisationID;
    const organisationStore = useOrganisationStore();
    const { organisation } = storeToRefs(organisationStore);
    const { t } = useI18n();
    let backup;

    onIonViewWillEnter(() => organisationStore.initialise(organisationID));
    const createBackup = () => (backup = { ...(organisation.value?.spaceTypes || []) });
    createBackup();
    watch(organisation, createBackup);

    const save = async () => {
      organisationStore.saveRecord("organisations", organisationID, organisation.value, [
        "decisionTrees",
        "locations",
        "articles",
        "videos",
        "issues",
        "loaded",
        "imageLoaded",
      ]);
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const addType = () => {
      if (!organisation.value.spaceTypes) organisation.value.spaceTypes = [];
      organisation.value.spaceTypes.push({ name: newTypeName.value, id: self.crypto.randomUUID() });
      markDirty();
      newTypeName.value = null;
    };

    const deleteType = (type) => {
      organisationStore.deleteOrganisationRecord("spaceTypes", type);
      markDirty();
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                organisation.value.spaceTypes = backup;
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      organisation,
      newTypeName,
      // methods
      markDirty,
      back,
      addType,
      deleteType,
      t,
      // icons
      chevronForward,
      trashOutline,
      returnDownBack,
    };
  },
};
</script>
