// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("backgrounds/fibre.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("backgrounds/av-logo-black.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/theme/fonts/Akkurat/Akkurat-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/theme/fonts/Akkurat/Akkurat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("@/theme/fonts/Akkurat Mono/AkkuratMono-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --default-padding: 10px;
  --default-margin: 10px;
  --default-radius: 10px;
}

div {
  color: var(--ion-color-dark-contrast);
}

.flex {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.flex.vertical {
  height: 100%;
  flex-direction: column;
}

.flex.centered {
  justify-content: center;
}

.flex.spaceAround {
  justify-content: space-around;
}

.flex.left {
  justify-content: flex-start;
}

.flex.right {
  justify-content: flex-end;
}

.flex.top {
  align-items: flex-start;
}

.flex.nogap {
  gap: 0px;
}

.flex.autowidth {
  width: auto;
}

.flex.autoheight {
  height: auto;
}

.flex.wrap {
  flex-wrap: wrap;
}

.flex .equal {
  flex: 1 0 1px;
}

.flex .grow {
  flex: 1 0 auto;
}

.flex .nogrow {
  flex: 0 0 auto;
}

.flex .shrink {
  flex: 0 1 auto;
}

.p {
  padding: var(--default-padding);
}

.ph {
  padding-left: var(--default-padding);
  padding-right: var(--default-padding);
}
.pv {
  padding-top: var(--default-padding);
  padding-bottom: var(--default-padding);
}
.pl {
  padding-left: var(--default-padding);
}
.pr {
  padding-right: var(--default-padding);
}
.pt {
  padding-top: var(--default-padding);
}
.pb {
  padding-bottom: var(--default-padding);
}

.p2 {
  padding: calc(2 * var(--default-padding));
}

.ph2 {
  padding-left: calc(2 * var(--default-padding));
  padding-right: calc(2 * var(--default-padding));
}
.pv2 {
  padding-top: calc(2 * var(--default-padding));
  padding-bottom: calc(2 * var(--default-padding));
}
.pl2 {
  padding-left: calc(2 * var(--default-padding));
}
.pr2 {
  padding-right: calc(2 * var(--default-padding));
}
.pt2 {
  padding-top: calc(2 * var(--default-padding));
}
.pb2 {
  padding-bottom: calc(2 * var(--default-padding));
}

.m0 {
  margin: 0px;
}

.p0 {
  padding: 0px;
}

.m {
  margin: var(--default-margin);
}

.mh {
  margin: 0px var(--default-margin);
}

.mv {
  margin: var(--default-margin) 0px;
}

.mt {
  margin-top: var(--default-margin);
}

.mb {
  margin-bottom: var(--default-margin);
}
.ml {
  margin-left: var(--default-margin);
}
.mr {
  margin-right: var(--default-margin);
}

.m2 {
  margin: calc(2 * var(--default-margin));
}

.mh2 {
  margin: 0px calc(2 * var(--default-margin));
}

.mv2 {
  margin: calc(2 * var(--default-margin)) 0px;
}

.mt2 {
  margin-top: calc(2 * var(--default-margin));
}

.mb2 {
  margin-bottom: calc(2 * var(--default-margin));
}
.ml2 {
  margin-left: calc(2 * var(--default-margin));
}
.mr2 {
  margin-right: calc(2 * var(--default-margin));
}

.mt-1 {
  margin-top: calc(-1 * var(--default-margin));
}

.fr {
  font-size: 1rem;
}

.fm {
  font-size: 1.8em;
}
.fh {
  font-size: 1.6em;
}
.fg {
  font-size: 1.4em;
}

.fl {
  font-size: 1.2em;
}

.fs {
  font-size: 0.8em;
}

.ft {
  font-size: 0.6em;
}

.f2 {
  font-size: 2em;
}

.f3 {
  font-size: 3em;
}
.f6 {
  font-size: 6em;
}
.f8 {
  font-size: 8em;
}

.tl {
  text-align: left;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.regularFont {
  font-family: Regular;
}

.boldFont {
  font-family: Bold;
}

.titleFont {
  font-family: Title;
}

.uppercase {
  text-transform: uppercase;
}

.tightlines {
  line-height: 1em;
}

.shadow {
  box-shadow: 1px 1px 3px var(--ion-color-dark);
}

.underline {
  text-decoration: underline;
}

.primary {
  color: var(--ion-color-primary);
}

.secondary {
  color: var(--ion-color-secondary);
}

.tertiary {
  color: var(--ion-color-tertiary);
}

.cw {
  color: var(--ion-color-warning);
}

.cp {
  color: var(--ion-color-primary);
}

.cs {
  color: var(--ion-color-secondary);
}

.ct {
  color: var(--ion-color-tertiary);
}

.cl {
  color: var(--ion-color-light);
}
.cd {
  color: var(--ion-color-dark);
}
.cm {
  color: var(--ion-color-medium);
}
.cw {
  color: var(--ion-color-warning);
}
.cx {
  color: var(--ion-color-danger);
}
.csc {
  color: var(--ion-color-success);
}
.o0 {
  opacity: 0;
}
.o1 {
  opacity: 0.1;
}
.o2 {
  opacity: 0.2;
}
.o3 {
  opacity: 0.3;
}
.o4 {
  opacity: 0.4;
}
.o5 {
  opacity: 0.5;
}
.o6 {
  opacity: 0.6;
}
.o7 {
  opacity: 0.7;
}
.o8 {
  opacity: 0.8;
}
.o9 {
  opacity: 0.9;
}
.o10 {
  opacity: 1;
}

.r1 {
  border-radius: var(--default-radius);
}

.r2 {
  border-radius: var(calc(2 * --default-radius));
}

.bgd {
  background-color: var(--ion-color-primary);
}
.bgs {
  background-color: var(--ion-color-secondary);
}
.bgm {
  background-color: var(--ion-color-medium);
}

.bgl {
  background-color: var(--ion-color-light);
  --background: var(--ion-color-light);
}

.bdd {
  border: 1px solid var(--ion-color-dark);
}

.bdp {
  border: 1px solid var(--ion-color-primary);
}

.bds {
  border: 1px solid var(--ion-color-secondary);
}

.bdm {
  border: 1px solid var(--ion-color-medium);
}

.bdl {
  border: 1px solid var(--ion-color-light);
}

.safe-top {
  padding-top: var(--ion-safe-area-top);
}
.safe-bottom {
  padding-bottom: var(--ion-safe-area-bottom);
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fullwidth {
  width: 100%;
}

.fullheight {
  height: 100%;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--ion-color-light);
}

.action {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Title;
  font-size: 0.9em;
  padding: 20px;
  border-bottom: 1px solid rgba(var(--ion-color-light-rgb), 0.1);
}

.action.light {
  border-bottom: 1px solid rgba(var(--ion-color-light-rgb), 0.3);
}

.actionTitle {
  font-family: Title;
  font-size: 1.8em;
  color: var(--ion-color-light);
  text-transform: uppercase;
}

.calendar-day {
  color: blue !important;
}

.debug {
  border: 3px red solid;
}
.debug2 {
  border: 3px yellow solid;
}

.keepOnTop {
  z-index: 1000;
}

.scroll {
  overflow: auto;
}

.noScroll {
  overflow: hidden;
}

.superscript {
  margin-top: -7px;
}

.firebase-emulator-warning {
  display: none;
}

.footer {
  font-family: Title;
  color: var(--ion-color-secondary);
  font-size: 1.4em;
  width: 100%;
  padding: calc(2 * var(--default-padding));
  text-align: center;
}

ion-toast {
  --background: var(--ion-color-light);
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: var(--ion-color-light-contrast);
}

.toast {
  font-family: Bold;
}

/* ion-button.bright {
  color: var(--ion-color-menu-contrast);
  --background: var(--ion-color-menu);
} */

ion-button.medium {
  --background: var(--ion-color-medium);
  color: var(--ion-color-medium-contrast);
}
ion-button.light {
  --background: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
}
ion-button.dark {
  --background: var(--ion-color-dark);
  color: var(--ion-color-dark-contrast);
}
ion-button.primary {
  --background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
ion-button.danger {
  --background: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}
ion-button.clear {
  --background: rgba(100, 100, 100, 0);
  color: var(--ion-color-light);
}

ion-footer {
  background: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
}

ion-content::part(scroll) {
  background: var(--ion-background);
  color: var(--ion-text-color);
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

ion-content.transparent::part(scroll) {
  background: none;
}

ion-item {
  cursor: pointer;
  font-weight: 200;
  font-size: 1em;
}

ion-title {
  text-transform: uppercase;
  font-size: 0.8em;
  color: var(--ion-color-primary);
}

ion-button {
  height: 3rem;
  /* font-size: 1rem; */
  text-transform: none;
  color: var(--ion-color-tertiary-contrast);
  --background: var(--ion-color-tertiary);
}

ion-header {
  background-color: var(--ion-color-light-tint);
  height: calc(80px + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
}

ion-item.list {
  margin-left: calc(2 * var(--default-margin));
  font-size: 1.2em;
}

ion-item.list .subtitle {
  font-size: 0.6em;
}

ion-item.list .notes {
  font-size: 0.4em;
}

ion-content {
  --overflow: hidden;
}

.alert {
  --background: var(--ion-color-secondary);
  --backdrop-opacity: 0.7;
}

.alert-head h2 {
  color: var(--ion-color-primary) !important;
}

.grid2 {
  position: relative;
  display: grid;
  overflow: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.grid4 {
  position: relative;
  display: grid;
  overflow: auto;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.card {
  /* width: 100%; */
  position: relative;
  padding: var(--default-padding);
  border-radius: var(--default-radius);
  border: 1px solid rgba(var(--ion-color-dark-rgb), 0.4);
}

.card .icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -5px;
  top: -5px;
  border-radius: 10px;
  height: 30px;
  width: 30px;
  color: var(--ion-color-primary);
  background-color: var(--ion-color-primary-contrast);
  border: 1px solid var(--ion-color-primary);
}

.card.highlight .title {
  color: var(--ion-color-primary);
}

.card .photo {
  border-radius: var(--default-radius);
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}

.card .logo {
  border-radius: var(--default-radius);
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
}

.card .title {
  font-family: Bold;
  font-size: 0.8rem;
  text-align: center;
}

.card .subtitle {
  text-align: center;
}

ion-input.darkBorder,
ion-textarea.darkBorder {
  border: 1px solid rgba(var(--ion-color-dark-rgb), 0.1);
  margin: 5px 0px;
  --padding-start: 10px;
  --padding-end: 10px;
}

/* ion-segment {
  --background: var(--ion-color-tertiary);
} */

/* ion-segment-button {
  font-family: Bold;
  --indicator-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
} */

ion-segment-button.warning {
  --indicator-color: var(--ion-color-warning);
  --color: var(--ion-color-warning);
  --color-hover: var(--ion-color-warning-tint);
}

ion-segment-button.danger {
  --indicator-color: var(--ion-color-danger);
  --color: var(--ion-color-danger);
  --color-hover: var(--ion-color-danger-tint);
}

ion-segment-button.closed {
  --indicator-color: var(--ion-color-success);
  --color: var(--ion-color-success);
  --color-hover: var(--ion-color-success-tint);
}

img.logo {
  max-height: 150px;
  width: 100%;
  object-fit: contain;
  padding: 20px;
}

img.logo.mini {
  max-width: 100px;
  padding: 5px;
}

img.portrait {
  height: 250px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 5px;
  border: 3px solid var(--ion-color-light);
}

img.light {
  filter: brightness(0) invert(1);
}

img.dark {
  filter: brightness(0);
}

img.grey {
  filter: grayscale(1);
}

img.fill {
  height: 100% !important;
  width: 100% !important;
}

img.contain {
  object-fit: contain;
}

img.cover {
  object-fit: cover;
}

.square {
  aspect-ratio: 1;
}

.scanner {
  width: 50%;
}

.scanner .top,
.scanner .bottom,
.scanner .left,
.scanner .right {
  height: 20px;
  width: 20px;
  position: absolute;
}

.scanner .top {
  top: 0px;
}

.scanner .bottom {
  bottom: 0px;
}

.scanner .left {
  left: 0px;
}

.scanner .right {
  right: 0px;
}

.homeBackground:before {
  content: "";
  position: absolute;
  top: -80px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-image: var(--theme-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.homeColor {
  position: absolute;
  top: -80px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: var(--ion-color-dark);
  overflow: hidden;
  z-index: -2;
}

.background {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.background img {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(1) brightness(0.3);
  z-index: -1;
}

/* .background:before img {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%) brightness(30%);
  border: 3px red solid;
  z-index: -1;
} */

.background:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  z-index: -1;
  background-size: cover;
  filter: grayscale(100%) brightness(20%);
}

.hideScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hideScrollbar::-webkit-scrollbar {
  display: block;
}

.scrollbar {
  -ms-overflow-style: initial;
  scrollbar-width: initial;
}

.pagination {
  width: 100%;
  background-color: var(--ion-background) !important ;
  display: flex;
  justify-content: center;
  column-gap: 5px;
  padding: 20px 0px 0px;
}

ion-thumbnail {
  --border-radius: 5px;
  background-color: var(--ion-color-light);
}

ion-thumbnail.dark {
  /* border: 2px solid var(--ion-color-light); */
  background-color: transparent;
}

ion-thumbnail img {
  object-fit: contain;
  padding: 2px;
}
ion-accordion-group {
  margin: 0px;
  flex-grow: 0;
}

ion-modal {
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

ion-modal.noblur {
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}

.oilogo {
  background-color: #ccc;
}

.oilogo::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;
  opacity: 0.2;
}

@font-face {
  font-family: "Title";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: "Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
  font-family: "Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
  font-style: normal;
}

ion-action-sheet.popover .action-sheet-button {
  background-color: var(--ion-color-secondary) !important;
  color: var(--ion-color-primary);
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
