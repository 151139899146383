<template>
  <ion-page>
    <av-header />

    <ion-content :scroll-y="false" v-if="organisation">
      <div class="flex vertical nogap left bgd">
        <view-header @back="back" :title="organisation.name" :subtitle="t('edit_logo')" edit="true" />

        <div class="grow fullwidth">
          <div class="flex centered">
            <img :src="organisation.logo || '/img/placeholders/transparent.png'" class="p2 fullwidth" />
          </div>
          <input type="file" @change="addLogo($event)" ref="logoSelector" hidden />
        </div>

        <div class="flex ph2 mb2">
          <ion-button @click="triggerAddLogo" expand="block" class="bright grow">
            {{ t(organisation.logo ? "change_logo" : "add_logo") }}
          </ion-button>
          <ion-button @click="deleteLogo" :disabled="!organisation.logo" color="danger" expand="block">{{ t("delete") }}</ion-button>
        </div>
        <!-- <nav-footer :label="organisation.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { alertController } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default {
  name: "Description",
  setup() {
    const dirty = ref(false);
    const router = useRouter();
    const organisationID = useRoute().params.organisationID;
    const organisationStore = useOrganisationStore();
    const { organisation } = storeToRefs(organisationStore);
    const logoSelector = ref();
    const { t } = useI18n();
    let backup;

    onMounted(() => organisationStore.initialise(organisationID));

    const save = async () => {
      organisationStore.saveRecord("organisations", organisationID, organisation.value, [
        "decisionTrees",
        "locations",
        "articles",
        "videos",
        "issues",
        "loaded",
        "imageLoaded",
      ]);
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const triggerAddLogo = () => {
      logoSelector.value.click();
    };

    const addLogo = async (event) => {
      const files = event.target.files;
      const file = files[0];
      organisationStore.addLogo(organisationID, file);
      logoSelector.value.value = "";
    };

    const deleteLogo = async () => {
      await organisationStore.deleteLogo(organisationID);
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      organisation,
      logoSelector,
      // methods
      markDirty,
      back,
      triggerAddLogo,
      addLogo,
      deleteLogo,
      t,
      // icons
    };
  },
};
</script>

<style scoped>
.logo {
  border: 4px green solid;
  height: 100%;
  width: 100%;
}
</style>
