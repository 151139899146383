// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.card[data-v-7dd316ae] {
  overflow: hidden;
  aspect-ratio: 1;
}
.card .background[data-v-7dd316ae] {
  position: absolute;
  left: 0px;
  top: 0px;
  object-fit: cover;
  filter: grayscale(1) opacity(0.3);
}
.card .title[data-v-7dd316ae] {
  color: var(--ion-color-secondary);
  opacity: 0.7;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
