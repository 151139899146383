<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd" slot="fixed" v-if="resolvedSpace && currentQuestion && resolvedDecisionTree">
        <view-header @back="back" :title="$t('user_guides')" :subtitle="resolvedSpace.name" class="fullwidth" />
        <div class="scroll p fullwidth flex vertical">
          <div class="imessage fullwidth">
            <div v-for="question in answeredQuestions" :key="question.id" class="imessage fullwidth">
              <p class="from-them" @click="revert(question)">{{ question.question }}</p>
              <p class="from-me" @click="revert(question)">{{ question.response }}</p>
            </div>

            <div v-if="currentQuestion.type == 'Question'" class="imessage fullwidth">
              <p class="from-them">{{ currentQuestion?.title }}</p>
              <p class="from-me option" v-for="outcome in currentOutcomes" :key="outcome.id" @click="answered(outcome.id)">
                {{ outcome.label }}
              </p>
            </div>

            <p class="from-them" v-if="currentQuestion.type == 'Article'">
              {{ t("ive_found_the_following_article") }}.<br />
              <ion-button class="mt" expand="block" @click="showArticle(currentQuestion.destinationID, currentQuestion.title)">{{
                currentQuestion.title
              }}</ion-button>
            </p>

            <p class="from-them" v-if="currentQuestion.type == 'Video'">
              {{ t("ive_found_the_following_video") }}.<br />
              <ion-button class="mt" expand="block" @click="showVideo(currentQuestion.destinationID, currentQuestion.title)">{{
                currentQuestion.title
              }}</ion-button>
            </p>

            <p class="from-them" v-if="currentQuestion.type == 'Phone'">
              {{ t("place_a_call") }}.<br />
              <ion-button class="mt" expand="block" @click="placeCall(currentQuestion.phone)">{{ currentQuestion.title }}</ion-button>
            </p>

            <p class="from-them" v-if="currentQuestion.type == 'Email'">
              {{ t("send_an_email") }}.<br />
              <ion-button expand="block" @click="sendEmail(currentQuestion.email)">{{ currentQuestion.title }}</ion-button>
            </p>

            <p class="from-them" v-if="currentQuestion.type == 'PDF'">
              {{ t("ive_found_the_following_document") }}.<br />
              <ion-button class="mt" expand="block" @click="showDocument(currentQuestion)">{{ currentQuestion.title }}</ion-button>
            </p>
          </div>
        </div>
        <div class="grow"></div>

        <div class="mb2 fullwidth">
          <div class="safe-bottom fullwidth pb2 ph2" v-if="allowSOS">
            <ion-button
              v-if="!location?.contact?.sosUseEmail && isPlatform('mobileweb')"
              class="uppercase"
              expand="block"
              color="danger"
              :href="`tel:${location?.contact?.sos}`"
              >{{ location.contact.sos_label || "Call SOS" }}</ion-button
            >
            <SlideSwitch
              v-else
              :label="location.contact.sos_label || 'slide to call SOS'"
              :slidingLabel="location.contact.sos_label_active || 'Contacting SOS'"
              @unlock="callSOS"
            />
          </div>
        </div>
      </div>
      <wait v-else />
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isPlatform, onIonViewWillEnter } from "@ionic/vue";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { toast, objectToArray } from "@/composables/utilities";
import { useI18n } from "vue-i18n";
import SlideSwitch from "@/components/SlideSwitch";
import mixpanel from "mixpanel-browser";

export default {
  components: { SlideSwitch },
  setup() {
    const organisationStore = useOrganisationStore();
    const { anonymous, organisation, location, floor, space, decisionTree } = storeToRefs(organisationStore);
    const route = useRoute();
    const router = useRouter();
    const spaceID = route.params.spaceID;
    const floorID = route.params.floorID;
    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const decisionTreeID = route.params.decisionTreeID;
    const answeredQuestions = ref([]);
    const currentQuestionID = ref();
    const { t } = useI18n();
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    onIonViewWillEnter(async () => {
      await organisationStore.initialise(organisationID, locationID, floorID, spaceID, decisionTreeID);
      mixpanel.track("Decision Tree Started", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.title,
      });
      answeredQuestions.value.length = 0;
      currentQuestionID.value = null;
    });

    const resolvedSpace = computed(() => space.value || anonymous.value.space);
    const resolvedDecisionTree = computed(() => decisionTree.value || anonymous.value.decisionTree);
    const resolvedOrganisation = computed(() => organisation.value || anonymous.value.organisation);

    const currentQuestion = computed(() => {
      return resolvedDecisionTree.value?.destinations?.[currentQuestionID.value || resolvedDecisionTree.value?.rootID];
    });

    const currentOutcomes = computed(() => {
      const outcomeArray = objectToArray(resolvedDecisionTree.value.outcomes)
        .filter((o) => currentQuestion.value.outcomeIDs.includes(o.id))
        .sort((_a, _b) => {
          const a = _a.index || 0;
          const b = _b.index || 0;
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
      return outcomeArray;
    });

    const allowSOS = computed(() => {
      return location.value?.contact?.sos && (user.value.roles?.includes("vip") || !location.value.restrictSOS);
    });

    const callSOS = async () => {
      mixpanel.track("DT Goal Reached", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        type: "SOS",
      });
      if (location.value?.contact?.sosUseEmail) {
        sendEmail(location.value.contact?.sos_email);
      } else {
        placeCall(location.value.contact?.sos);
      }
    };

    const answered = (id) => {
      Haptics.impact({ style: ImpactStyle.Light });
      answeredQuestions.value.push({
        id: currentQuestion.value.id,
        responseID: id,
        question: currentQuestion.value.title,
        response: resolvedDecisionTree.value.outcomes[id].label,
      });

      mixpanel.track("Question Answered", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        question: currentQuestion.value.title,
        answer: resolvedDecisionTree.value.outcomes[id].label,
      });
      currentQuestionID.value = resolvedDecisionTree.value.outcomes[id].childID;
    };

    const revert = (question) => {
      const revertPosition = answeredQuestions.value.findIndex((q) => q.id == question.id);
      answeredQuestions.value.splice(revertPosition);
      currentQuestionID.value = question.id;
      Haptics.impact({ style: ImpactStyle.Light });
    };

    const back = () => {
      if (answeredQuestions.value.length > 0) {
        const question = answeredQuestions.value[answeredQuestions.value.length - 1];
        revert(question);
      } else {
        router.back();
      }
    };

    const showArticle = (articleID, description) => {
      mixpanel.track("DT Goal Reached", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        type: "Article",
      });
      mixpanel.track("Article Shown", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        description: description,
      });
      router.push({ name: "Article", params: { organisationID, articleID } });
    };

    const videosLoaded = () => {
      const checkVideos = (resolve) => {
        if (resolvedOrganisation.value?.videos) {
          resolve();
        } else {
          setTimeout(checkVideos.bind(this, resolve), 50);
        }
      };
      return new Promise(checkVideos);
    };

    const showVideo = async (videoID, description) => {
      await videosLoaded();
      mixpanel.track("DT Goal Reached", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        type: "Video",
      });
      mixpanel.track("Video Shown", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        description: description,
      });
      const video = resolvedOrganisation.value?.videos?.[videoID];
      if (video) {
        router.push({ name: "Video", params: { organisationID, videoID, vimeoID: video.videoID, hash: video.hash } });
      } else {
        toast("Sorry, this video is currently unavailable.");
      }
    };

    const showDocument = async (currentQuestion) => {
      // mixpanel.track("Document Shown", {
      //   organisation: organisation.value?.name,
      //   location: location.value?.name,
      //   floor: floor.value?.name,
      //   space: space.value?.name,
      //   decision_tree: decisionTree.value?.name,
      //   description: description,
      // });
      // mixpanel.track("DT Goal Reached", {
      //   organisation: organisation.value?.name,
      //   location: location.value?.name,
      //   floor: floor.value?.name,
      //   space: space.value?.name,
      //   decision_tree: decisionTree.value?.name,
      //   type: "Document",
      // });
      router.push({
        name: "SpaceDocument",
        params: {
          path: currentQuestion.path,
          description: currentQuestion.filename,
        },
      });
    };

    const placeCall = (phoneNumber) => {
      mixpanel.track("Phone Call Placed", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        number: phoneNumber,
      });
      mixpanel.track("DT Goal Reached", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        type: "Phone Call",
      });
      const link = document.createElement("a");
      link.href = `tel:${phoneNumber}`;
      link.click();
      //window.open(`tel:${phoneNumber}`);
    };

    const sendEmail = (emailAddress) => {
      mixpanel.track("Email Sent", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        email: emailAddress,
      });
      mixpanel.track("DT Goal Reached", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        decision_tree: decisionTree.value?.name,
        type: "Email",
      });
      router.push({ name: "Email" });
    };

    return {
      resolvedSpace,
      answeredQuestions,
      currentQuestion,
      currentOutcomes,
      resolvedDecisionTree,
      back,
      answered,
      revert,
      showArticle,
      showVideo,
      showDocument,
      placeCall,
      sendEmail,
      allowSOS,
      callSOS,
      location,
      isPlatform,
      t,
    };
  },
};
</script>

<style scoped>
.bg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}

.tight {
  line-height: 0.9em;
}

.footer {
  font-family: Title;
  color: var(--ion-color-secondary);
  font-size: 1.6em;
  text-transform: uppercase;
  background: var(--ion-color-light-tint);
}

.smallButton {
  height: 1.5rem;
  font-size: 0.7rem;
}

.errorCount {
  margin: -20px 0px;
}

.bordered {
  flex-direction: column;
  border: 1px solid var(--ion-color-light);
}

.actionCell {
  flex-direction: column;
}

.imessage {
  background-color: var(--ion-color-primary);
  display: flex;
  flex-direction: column;
  font-family: Regular;
  font-size: 1rem;
  max-width: 600px;
}

.imessage p {
  border-radius: 1.15rem;
  max-width: 75%;
  padding: 1.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
}

.imessage p::before,
.imessage p::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

p.from-me {
  align-self: flex-end;
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-light);
}

p.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid var(--ion-color-menu);
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-me::after {
  background-color: var(--ion-color-primary);
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^="from-"] {
  width: 90%;
  margin: 5px;
}

p.from-them {
  align-items: flex-start;
  background-color: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
}

p.from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid var(--ion-color-light);
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-them::after {
  background-color: var(--ion-color-primary);
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^="from-"].emoji {
  background: none;
  font-size: 2.5rem;
}

p[class^="from-"].emoji::before {
  content: none;
}

.option {
  background-color: var(--ion-color-menu) !important;
  color: var(--ion-color-menu-contrast) !important;
}

.option::before,
.option:after {
  display: none;
}

.no-tail::before {
  display: none;
}
</style>
