<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div>Sorry, you don't have access to perform this action. Please contact your administrator.</div>
        <ion-button expand="block" color="warning" router-link="/" class="uppercase">Home</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonHeader, IonTitle, IonButtons, IonButton, IonToolbar, IonContent, IonMenuButton } from "@ionic/vue";
</script>

<style scoped>
.container:before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("@/assets/img/backgrounds/data.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  filter: grayscale(100%) brightness(30%);
  z-index: -1;
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .container div {
  width: 70%;
} */

ion-button {
  margin-top: 20px;
  width: 70%;
  max-width: 300px;
}
</style>
