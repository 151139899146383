<template>
  <ion-page>
    <av-header />

    <ion-content>
      <div class="flex vertical bgd" slot="fixed">
        <view-header @back="back" title="Forgotten Password" subtitle="Get a reset link" class="fullwidth" />
        <div style="max-width: min(90vw, 500px)" class="fullwidth">
          <ion-item>
            <ion-input color="secondary" v-model="email" v-on:keyup.enter="remind()" label="Email" label-placement="floating"></ion-input>
            <ion-note>{{ emailFeedback }}</ion-note>
          </ion-item>
          <ion-button expand="block" color="tertiary" class="mt2" @click="remind()" :disabled="!canSubmit">Send reminder</ion-button>
        </div>
        <div class="fullwidth footerBg">
          <div class="footer safe-bottom">
            <ion-button expand="block" color="secondary" router-direction="root" router-link="/login">
              <ion-label class="fs">Back to Login</ion-label>
            </ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, ref } from "vue";
import { runFunction } from "@/composables/firebase";
import { toast, isEmailValid } from "@/composables/utilities";
import { useRouter } from "vue-router";
export default {
  setup() {
    const email = ref();
    const emailFeedback = ref();
    const router = useRouter();

    const canSubmit = computed(() => {
      return isEmailValid(email.value);
    });

    const back = () => {
      router.back();
    };

    const remind = async () => {
      const passwordReminderSent = window.localStorage.getItem("passwordReminderSent");
      let remainingMinutes = 0;

      if (passwordReminderSent) {
        const elapsedMinutes = Math.floor((new Date() - Date.parse(passwordReminderSent)) / (1000 * 60));
        remainingMinutes = 2 - elapsedMinutes;
      }

      if (remainingMinutes > 0) {
        toast(
          `
          Please wait ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""} before sending another request.
          If you cannot find the email in your inbox, please check your spam folder.
          `,
          5000
        );
        return;
      }

      window.localStorage.setItem("passwordReminderSent", new Date());

      runFunction("resetPassword", { reset_address: email.value });
      toast(`A password reset link has been sent to ${email.value}.`);
      router.push({ Name: "LoginWithPassword" });
    };

    return {
      email,
      emailFeedback,
      canSubmit,
      remind,
      back,
    };
  },
};
</script>

<style scoped>
#loginContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.formItems {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#loginContainer ion-item,
#loginContainer ion-button {
  width: 100%;
  max-width: 500px;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: env(safe-area-inset-bottom);
}
.footerContainer ion-button {
  flex: 1 0 1px;
  font-size: 0.7em;
}
</style>
