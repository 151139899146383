<template>
  <ion-page>
    <div id="loginContainer">
      <av-header />
      <ion-content>
        <div class="flex vertical bgd" slot="fixed">
          <view-header @back="back" title="Login" subtitle="Get Magic Link" class="fullwidth" />

          <div style="max-width: min(90vw, 500px)" class="fullwidth">
            <ion-item lines="full">
              <ion-input v-model="email" type="email" color="secondary" label="Email" label-placement="floating"></ion-input>
              <ion-note color="warning">{{ emailFeedback }}</ion-note>
            </ion-item>

            <div class="tc">
              <ion-button expand="block" class="mt2" @click="getMagicLink()" id="login-button" :disabled="!canSubmit"
                >Get Magic Link</ion-button
              >
              <div class="fs cl mt2" @click="loginWithPassword()"><span class="cm">or</span> login with password</div>
            </div>
          </div>

          <div class="fullwidth footerBg">
            <div class="footer safe-bottom">
              <ion-button expand="block" color="secondary" router-direction="root" router-link="/register">
                <ion-label class="fs">Register</ion-label>
              </ion-button>
            </div>
          </div>
        </div>
      </ion-content>
    </div>
  </ion-page>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { toast, isEmailValid } from "@/composables/utilities";
import { isPlatform } from "@ionic/core";
import { useUserStore } from "@/stores/user";

export default {
  setup() {
    const userStore = useUserStore();
    const route = useRoute();
    const router = useRouter();
    const email = ref(route.query?.email);
    const emailFeedback = ref();
    const isProduction = process.env.VUE_APP_MODE == "production";

    const canSubmit = computed(() => {
      return isEmailValid(email.value);
    });

    const getMagicLink = () => {
      userStore.sendMagicLink(email.value);
      toast(`Magic link sent to ${email.value}. Please check your email.`);
      if (!isPlatform("cordova")) router.push({ name: "CloseMe" });
    };

    const loginWithPassword = () => {
      router.push({
        name: "LoginWithPassword",
        query: { email: email.value },
      });
    };

    const back = () => {
      router.push({ name: "Home" });
    };

    return {
      back,
      email,
      emailFeedback,
      isProduction,
      canSubmit,
      getMagicLink,
      loginWithPassword,
    };
  },
};
</script>

<style scoped>
#loginContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.formItems {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

#loginContainer ion-item,
#loginContainer ion-button {
  width: 100%;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: env(safe-area-inset-bottom);
}
.footerContainer ion-button {
  flex: 1 0 1px;
  font-size: 0.7em;
}
</style>
