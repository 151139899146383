<template>
  <ion-page>
    <av-header />

    <ion-content :scroll-y="false" v-if="organisation">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="organisation?.name" :subtitle="t('edit_details')" edit="true" />
        <div class="fullwidth p scroll">
          <ion-item class="fullwidth">
            <ion-input
              :label="t('organisation_name')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('name')"
              v-model="organisation.name"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
          </ion-item>
          <!-- <ion-item class="fullwidth">
            <ion-label position="stacked" color="secondary">{{ t("shortcode_prefix") }}</ion-label>
            <ion-input
              :placeholder="t('prefix')"
              v-model="organisation.prefix"
              @ionChange="updatePrefix()"
              @ionInput="markDirty()"
              required="true"
              maxlength="3"
            ></ion-input>
          </ion-item> -->
          <ion-item class="fullwidth" v-if="organisation.contact">
            <ion-input
              :label="t('main_contact')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('name')"
              v-model="organisation.contact.name"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
            <ion-input
              color="secondary"
              :placeholder="t('email')"
              v-model="organisation.contact.email"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
            <ion-input
              color="secondary"
              :placeholder="t('phone')"
              v-model="organisation.contact.phone"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
          </ion-item>
          <ion-item>
            <!-- <ion-checkbox slot="start" v-model="organisation.showWelsh" @ionChange="markDirty()"></ion-checkbox> -->
            <ion-toggle color="secondary" v-model="organisation.showWelsh" class="mr2" @ionChange="markDirty()">Show Welsh</ion-toggle>
          </ion-item>

          <ion-item>
            <ion-toggle color="secondary" v-model="organisation.allowAnonymous" class="mr2" @ionChange="markDirty"
              >Allow anonymous access</ion-toggle
            >
          </ion-item>

          <ion-item v-if="organisation.allowAnonymous">
            <ion-toggle color="secondary" v-model="organisation.localAnonymousOnly" class="mr2" @ionChange="markDirty"
              >Local anonymous only</ion-toggle
            >
          </ion-item>
        </div>
        <div class="grow"></div>
        <!-- <nav-footer :label="organisation.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { useShortcodeStore } from "@/stores/shortcode";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default {
  name: "Description",
  setup() {
    const dirty = ref(false);
    const organisationID = useRoute().params.organisationID;
    const organisationStore = useOrganisationStore();
    const shortcodeStore = useShortcodeStore();
    const { organisation } = storeToRefs(organisationStore);
    const { t } = useI18n();

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID);
    });

    const router = useRouter();

    const save = async () => {
      organisationStore.saveRecord("organisations", organisationID, organisation.value, [
        "decisionTrees",
        "locations",
        "articles",
        "videos",
        "issues",
        "loaded",
        "imageLoaded",
      ]);
      shortcodeStore.updateShortcodes(
        organisationID,
        organisation.value.allowAnonymous || false,
        organisation.value.localAnonymousOnly || false
      );
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const updatePrefix = () => {
      organisation.value.prefix = organisation.value.prefix.toUpperCase();
    };

    return {
      // variables
      organisation,
      // methods
      markDirty,
      back,
      t,
      updatePrefix,
      // icons
      chevronForward,
    };
  },
};
</script>
