<template>
  <ion-page>
    <av-header />

    <ion-content :scroll-y="false" v-if="manufacturers">
      <div class="flex vertical nogap left">
        <view-header @back="goHome" :title="t('manufacturers')" />
        <div class="fullwidth p">
          <ion-list>
            <ion-item>
              <ion-input v-model="name" :placeholder="t('type_new_manufacturer_and_press')" v-on:keyup.enter="addRecord()" />
              <ion-icon :icon="returnDownBack" slot="end" color="secondary" class="o4" />
            </ion-item>

            <ion-item v-for="(item, i) in manufacturers" :key="i">
              <ion-input v-model="item.name" @ionChange="item.dirty = true" @ionBlur="save(item)" />
              <ion-icon slot="end" :icon="trashOutline" color="danger" @click="deleteRecord(item)" />
            </ion-item>
          </ion-list>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref, onMounted } from "@vue/runtime-core";
import { chevronForward, trashOutline, returnDownBack } from "ionicons/icons";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  name: "Description",
  setup() {
    const name = ref();
    const organisationStore = useOrganisationStore();
    const { manufacturers } = storeToRefs(organisationStore);
    const router = useRouter();
    const { t } = useI18n();

    onMounted(() => {
      organisationStore.initialise();
    });

    const save = async (item) => {
      if (item.dirty) organisationStore.saveRecord("manufacturers", item.id, item, ["dirty"]);
    };

    const addRecord = () => {
      organisationStore.addRecord("manufacturers", { name: name.value });
      name.value = null;
    };

    const deleteRecord = (item) => {
      organisationStore.deleteRecord("manufacturers", item.id);
    };

    const goHome = () => {
      router.push({ name: "Home" });
    };

    return {
      // variables
      manufacturers,
      name,
      // methods
      goHome,
      addRecord,
      deleteRecord,
      save,
      t,
      // icons
      chevronForward,
      trashOutline,
      returnDownBack,
    };
  },
};
</script>
