<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd">
        <view-header @back="back" :title="$t('documents')" class="fullwidth" />
        <div class="fullwidth scroll bgd">
          <div v-for="(docs, type) in groupedDocuments" :key="type" class="fullwidth">
            <div class="boldFont fs cl pv ph2 doctypeLabel">{{ type }}</div>
            <div class="fullwidth">
              <ion-item v-for="doc in docs" :key="doc.id" @click="showFile(doc)" lines="none">
                <ion-icon :icon="documentText" slot="start" color="secondary" />
                <div class="regularFont cl">{{ doc.description }}</div>
              </ion-item>
            </div>
          </div>
        </div>

        <wait v-if="wait" />

        <div class="grow"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter, isPlatform } from "@ionic/vue";
import { document as documentIcon, attach, download, documentText } from "ionicons/icons";
// import PDFIcon from "@/components/PDFIcon";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import mixpanel from "mixpanel-browser";

export default {
  // components: { PDFIcon },
  setup() {
    const organisationStore = useOrganisationStore();
    const { floor, location, organisation, space, documentTypes } = storeToRefs(organisationStore);
    const route = useRoute();
    const router = useRouter();
    const spaceID = route.params.spaceID;
    const floorID = route.params.floorID;
    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const wait = ref(true);

    const documents = computed(() => space.value?.documents);
    const modelDocuments = computed(() => space.value?.modelDocuments);

    const groupedDocuments = computed(() => groupBy(documents.value, "documentTypeID"));
    const groupedModelDocuments = computed(() => groupModelBy(modelDocuments.value, "documentTypeID"));

    onIonViewWillEnter(async () => {
      await organisationStore.initialise(organisationID, locationID, floorID, spaceID);
      wait.value = false;
    });

    onMounted(async () => {
      await organisationStore.initialise(organisationID, locationID, floorID, spaceID);
      wait.value = false;
    });

    const back = () => {
      router.back();
    };

    const showFile = (file) => {
      mixpanel.track("Document Viewed", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        document: file.description,
      });
      router.push({
        name: "SpaceDocument",
        params: { description: file.description, path: file.path },
      });
    };

    const groupBy = (arr, property) => {
      if (!arr) return;
      const result = {};
      const groupNames = [...new Set(arr.map((item) => item[property]))];
      for (const groupName of groupNames) {
        const type = organisation.value?.documentTypes?.find((dt) => dt.id == groupName)?.name;
        result[type] = arr.filter((item) => item[property] == groupName);
      }
      return result;
    };

    const groupModelBy = (arr, property) => {
      if (!arr) return;
      const result = {};
      const groupNames = [...new Set(arr.map((item) => item[property]))];
      for (const groupName of groupNames) {
        const type = documentTypes.value[groupName]?.name;
        result[type] = [...new Set(arr.filter((item) => item[property] == groupName))];
      }
      return result;
    };

    const downloadURL = (url) => {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "arraybuffer";
        xhr.onreadystatechange = function (evt) {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve(xhr.response);
            } else {
              reject(new Error("Error for " + url + ": " + xhr.status));
            }
          }
        };
        xhr.send();
      });
    };

    const downloadFiles = async (e) => {
      e.cancelBubble = true;
      wait.value = true;
      const zip = new JSZip();
      for (const type in groupedDocuments.value) {
        for (const file of groupedDocuments.value[type]) {
          const fileBlob = await downloadURL(file.url);
          zip
            .folder(`${space.value?.name} documents` || "Alpha Victor")
            .folder(type)
            .file(file.filename, fileBlob);
        }
      }

      const content = await zip.generateAsync({ type: "blob" });

      const zipName = `${space.value?.name} documents` || "Alpha Victor";
      saveAs(content, zipName);
      wait.value = false;
    };

    return {
      space,
      floor,
      location,
      organisation,
      documents,
      groupedDocuments,
      groupedModelDocuments,
      wait,
      isPlatform,
      back,
      downloadFiles,
      showFile,
      documentIcon,
      documentText,
      attach,
      download,
    };
  },
};
</script>

<style scoped>
.file {
  width: 150px;
}

.doctypeLabel {
  border-bottom: 0.5px solid rgba(var(--ion-color-dark-contrast-rgb), 0.5);
}
</style>
