<template>
  <ion-page>
    <av-header />
    <ion-content v-if="organisation" :scroll-y="false">
      <div class="flex vertical left bgd">
        <view-header @back="back" title="Edit Organisation" :subtitle="organisation.name" edit="true" />

        <div class="fullwidth p scroll">
          <div class="action" @click="go('EditOrganisationDetails')">
            <ion-label class="fullwidth keepOnTop">{{ t("details") }}</ion-label>
            <div>&gt;</div>
          </div>
          <div class="action" @click="go('EditOrganisationAddress')">
            <ion-label class="fullwidth keepOnTop">{{ t("address") }}</ion-label>
            <div>&gt;</div>
          </div>
          <div class="action" @click="go('EditOrganisationTheme')">
            <ion-label class="fullwidth keepOnTop">{{ t("theme") }}</ion-label>
            <div>&gt;</div>
          </div>
          <div class="action" @click="go('EditOrganisationSpaceTypes')">
            <ion-label class="fullwidth keepOnTop">{{ t("space_types") }}</ion-label>
            <div>&gt;</div>
          </div>
          <div class="action" @click="go('EditOrganisationSpaceFeatures')">
            <ion-label class="fullwidth keepOnTop">{{ t("space_features") }}</ion-label>
            <div>&gt;</div>
          </div>
          <div class="action" @click="go('EditOrganisationDocumentTypes')">
            <ion-label class="fullwidth keepOnTop">{{ t("document_types") }}</ion-label>
            <div>&gt;</div>
          </div>
          <div class="action" @click="go('EditOrganisationLogo')">
            <ion-label class="fullwidth keepOnTop">{{ t("logo") }}</ion-label>
            <div>&gt;</div>
          </div>
          <div class="action" @click="go('EditOrganisationBackground')">
            <ion-label class="fullwidth keepOnTop">{{ t("background") }}</ion-label>
            <div>&gt;</div>
          </div>
          <div class="action" @click="go('ListOrganisationDecisionTrees')" v-if="decisionTreesVisible">
            <ion-label class="fullwidth keepOnTop">{{ t("decision_trees") }}</ion-label>
            <div>&gt;</div>
          </div>
          <div class="action" @click="go('EditOrganisationIntegrations')">
            <ion-label class="fullwidth keepOnTop">{{ t("integrations") }}</ion-label>
            <div>&gt;</div>
          </div>
          <div class="action" @click="go('EditOrganisationChecklists')">
            <ion-label class="fullwidth keepOnTop">{{ t("checklists") }}</ion-label>
            <div>&gt;</div>
          </div>
          <div class="action" @click="go('ListLocations')">
            <ion-label class="fullwidth keepOnTop">{{ t("locations") }}</ion-label>
            <div>&gt;</div>
          </div>
        </div>

        <div class="grow"></div>
        <div class="fullwidth">
          <ion-button expand="block" color="danger" class="m2" @click="confirmDelete">{{ t("delete_organisation") }}</ion-button>
        </div>
        <!-- <nav-footer label="Organisation List" @back="cancel" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, isPlatform, onIonViewWillEnter } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/stores/user";
import { computed } from "vue";

const route = useRoute();
const router = useRouter();
const organisationID = route.params.organisationID;
const organisationStore = useOrganisationStore();
const { organisation } = storeToRefs(organisationStore);
const { t } = useI18n();
const userStore = useUserStore();
const userData = userStore.data;

const decisionTreesVisible = computed(() => {
  const roles = userData.user?.roles;
  if (!(roles?.length > 0 && isPlatform("desktop"))) return false;
  return roles.includes("decisionTreeAdmin");
});

onIonViewWillEnter(() => {
  organisationStore.initialise(organisationID);
});

const go = (name) => {
  router.push({ name: name, params: { organisationID: organisationID } });
};

const back = () => {
  router.back();
};

const confirmDelete = async () => {
  const alert = await alertController.create({
    cssClass: "alert",
    header: "Are you sure?",
    message: "Deleting this organisation is final and cannot be undone.",
    buttons: [
      {
        text: "Delete",
        role: "destructive",
        handler: () => {
          organisationStore.deleteOrganisation();
          router.back();
        },
      },
      { text: "Cancel" },
    ],
  });
  await alert.present();
};
</script>
