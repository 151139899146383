<template>
  <ion-page>
    <av-header />

    <ion-content>
      <div class="flex vertical" slot="fixed">
        <view-header @back="back" title="Verify Email" subtitle="Check your inbox" class="fullwidth" />
        <div style="max-width: min(90vw, 500px)" class="fullwidth">
          <div class="tc">
            Your email has not yet been verified. Please check your email and click on the link we sent you to confirm your email address,
            then click on the button below to retry.
          </div>
          <ion-button class="mt2" expand="block" color="secondary" @click="retry()">Retry</ion-button>
        </div>

        <div class="footer safe-bottom">
          <ion-button expand="block" color="secondary" @click="resend()"
            ><ion-label class="fs">Resend Verification Email</ion-label></ion-button
          >
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { toast } from "@/composables/utilities";
import { useUserStore } from "@/stores/user";

export default {
  setup() {
    const userStore = useUserStore();
    const userData = userStore.data;

    const router = useRouter();
    const email = ref();
    const emailFeedback = ref();

    const retry = async () => {
      await userStore.updateUser();

      if (userData.firebaseUser.emailVerified) {
        router.push({ name: "Home" });
      } else {
        toast(
          "Email is still not verified. Please try resending the verification email. If you still experience issues, you can contact us from the main menu.",
          3000
        );
      }
    };

    const resend = () => {
      let remainingMinutes = 0;

      if (userData.profile.emailVerificationSent) {
        const sentDate = userData.profile.emailVerificationSent.toDate();
        const elapsedMinutes = Math.ceil((new Date() - sentDate) / (1000 * 60));
        remainingMinutes = 2 - elapsedMinutes;
      }

      if (remainingMinutes > 0) {
        toast(
          `
          Please wait ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""} before sending another request.
          If you cannot find the email in your inbox, please check your spam folder.
          `,
          5000
        );
        return;
      }

      userStore.sendVerificationEmail();
      toast(`Verification email resent to ${userData.firebaseUser.email}. Please check your inbox.`);
    };

    return {
      email,
      emailFeedback,
      resend,
      retry,
    };
  },
};
</script>

<style scoped>
#loginContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.formItems {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#loginContainer ion-item,
#loginContainer ion-button {
  width: 100%;
  max-width: 500px;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: env(safe-area-inset-bottom);
}
.footerContainer ion-button {
  flex: 1 0 1px;
  font-size: 0.7em;
}
</style>
