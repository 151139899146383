// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.swatch[data-v-36542ecf] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 5px;
}
.capitals[data-v-36542ecf] {
  text-transform: capitalize;
}
.tooltip[data-v-36542ecf] {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltipText[data-v-36542ecf] {
  visibility: hidden;
  width: 200px;
  background-color: var(--ion-color-light);
  border: 1px solid var(--ion-color-dark);
  color: var(--ion-color-primary);
  text-align: center;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: 2px 2px 5px var(--ion-color-dark);
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -3px;
  left: calc(100% + 3px);
}
.tooltip .tooltipText[data-v-36542ecf]::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--ion-color-dark) transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltipText[data-v-36542ecf] {
  visibility: visible;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
