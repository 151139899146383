<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd" slot="fixed">
        <view-header
          @back="back"
          :title="location?.name"
          :subtitle="`${location?.address?.city ?? ''}${location?.address?.postcode ? ',' : ''} ${location?.address?.postcode ?? ''}`"
          class="fullwidth"
        />
        <div class="scroll p fullwidth">
          <div v-for="floor in sortedFloors" :key="floor.id" class="action openStart" @click="showFloor(floor)">
            <div>
              <div class="titleFont">{{ floor.name }}</div>
              <div class="regularFont" style="padding-top: 4px">{{ floor.shortName }}</div>
            </div>
            <div>&gt;</div>
          </div>
        </div>
        <div class="grow"></div>
        <!-- <nav-footer label="Browse Locations" @back="browseLocations" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { sortObjectByProperty } from "@/composables/utilities";
import { onIonViewWillEnter } from "@ionic/vue";

export default {
  setup() {
    const organisationStore = useOrganisationStore();
    const { location } = storeToRefs(organisationStore);
    const route = useRoute();
    const router = useRouter();
    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID);
    });

    const sortedFloors = computed(() => {
      return sortObjectByProperty(location.value?.floors, "index");
    });

    const showFloor = (floor) => {
      router.push({ name: "Floor", params: { organisationID: organisationID, locationID: locationID, floorID: floor.id } });
    };

    const back = () => {
      router.push({ name: "Organisation" });
    };

    return { location, sortedFloors, showFloor, back };
  },
};
</script>

<style scoped>
.floorShortName {
  font-family: Title;
  font-size: 3em;
  line-height: 1em;
  text-transform: uppercase;
  color: var(--ion-color-primary);
}
.floorName {
  font-family: Title;
  font-size: 1.4em;
  text-transform: uppercase;
  color: var(--ion-color-secondary);
}
.bg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}
</style>
