// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bg[data-v-130d674a] {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}
.bg img[data-v-130d674a] {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}
.tight[data-v-130d674a] {
  line-height: 0.9em;
}
.footer[data-v-130d674a] {
  font-family: Title;
  color: var(--ion-color-secondary);
  font-size: 1.6em;
  text-transform: uppercase;
  background: var(--ion-color-light-tint);
}
.smallButton[data-v-130d674a] {
  height: 1.5rem;
  font-size: 0.7rem;
}
.errorCount[data-v-130d674a] {
  margin: -20px 0px;
}
.bottomBorder[data-v-130d674a] {
  border: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.1);
}
.itemTitle[data-v-130d674a] {
  font-family: Title;
  color: var(--ion-color-light);
  padding-bottom: 3px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
