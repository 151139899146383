<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical centered boldFont" slot="fixed">
        <div class="ct f2 titleFont uppercase">Link Sent</div>
        <div class="cp fl tc fullwidth" style="max-width: min(500px, 80vw)">Check your email for your magic link.</div>
        <div class="cp fs tc fullwidth mb2" style="max-width: min(500px, 80vw)">(You can close this window.)</div>
        <ion-button expand="block" color="primary" @click="goHome()">Return to Homepage</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const goHome = () => {
      router.push({ name: "Home" });
    };
    return { goHome };
  },
};
</script>

<style></style>
