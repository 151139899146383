<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="space">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="space.name" :subtitle="t('edit_details')" edit="true" />

        <div class="fullwidth ph pt scroll">
          <ion-item class="fullwidth">
            <ion-input
              :label="t('space_name')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('name')"
              v-model="space.name"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
          </ion-item>

          <ion-item class="fullwidth cl">
            <ion-label position="stacked" color="secondary">{{ t("room_type") }}</ion-label>
            <ion-select
              color="secondary"
              interface="action-sheet"
              :interface-options="actionSheetOptions"
              mode="ios"
              :placeholder="t('select_type')"
              v-model="space.spaceTypeID"
              @ionChange="markDirty()"
            >
              <ion-select-option value="all">{{ t("all_types") }}</ion-select-option>
              <ion-select-option v-for="spaceType in organisation.spaceTypes" :key="spaceType.id" :value="spaceType.id">{{
                spaceType.name
              }}</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item class="fullwidth">
            <ion-input
              :label="t('capacity')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('capacity')"
              v-model="space.capacity"
              @ionInput="markDirty()"
              required="true"
              type="number"
            ></ion-input>
          </ion-item>

          <ion-item class="fullwidth">
            <ion-label position="stacked" color="secondary">{{}} </ion-label>
            <ion-input
              :label="t('shortcode')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('shortcode')"
              v-model="space.shortcode"
              @ionInput="markDirty()"
              required="true"
              @ionChange="updateShortcode"
            ></ion-input>
          </ion-item>

          <ion-item class="fullwidth">
            <ion-input
              :label="t('typeform_id')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('typeform_id')"
              v-model="space.typeformID"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
          </ion-item>
        </div>

        <div class="grow"></div>
        <!-- <nav-footer :label="space.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useShortcodeStore } from "@/stores/shortcode";
import { toast, objectToArray } from "@/composables/utilities";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;

    const organisationStore = useOrganisationStore();
    const shortcodeStore = useShortcodeStore();

    const { t } = useI18n();

    const { organisation, location, floor, space } = storeToRefs(organisationStore);
    const shortcodes = shortcodeStore.shortcodes;
    let originalShortcode;

    const dirty = ref(false);

    let backup;

    const actionSheetOptions = {
      cssClass: "popover",
    };

    const updateShortcode = () => {
      space.value.shortcode = space.value.shortcode.toUpperCase();
    };

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
      originalShortcode = space.value?.shortcode;
    });

    const save = async () => {
      if (
        objectToArray(shortcodes)
          .map((s) => s.id)
          .includes(space.value.shortcode) &&
        space.value.shortcode != originalShortcode
      ) {
        toast("Shortcode is already in use. Please choose a different value.");
        return;
      }
      organisationStore.saveRecord(
        `organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces/`,
        spaceID,
        space.value
      );

      if (originalShortcode && originalShortcode != space.value.shortcode) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Changed shortcode",
          message: "You have changed the shortcode for this space. Do you want to delete the old shortcode?",
          buttons: [
            {
              text: "No",
              handler: () => {
                router.back();
              },
            },
            {
              text: "Yes",
              role: "destructive",
              handler: () => {
                organisationStore.deleteRecord("shortcodes", originalShortcode);
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
      await organisationStore.saveRecord("shortcodes", space.value.shortcode, {
        type: "space",
        organisationID,
        locationID,
        floorID,
        spaceID,
        allowAnonymous: organisation.value.allowAnonymous || false,
        localAnonymousOnly: organisation.value.localAnonymousOnly || false,
      });
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const createBackup = () => {
      backup = space.value || {};
    };

    watch(space, createBackup);
    createBackup();

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                space.value = backup;
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const go = (name) => {
      router.push({ name: name, params: { locationID } });
    };

    return {
      // variables
      organisation,
      location,
      floor,
      space,
      //methods
      go,
      markDirty,
      back,
      updateShortcode,
      actionSheetOptions,
      t,
      // icons
      chevronForward,
    };
  },
};
</script>
