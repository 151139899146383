<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div class="flex vertical centered p2">
        <div v-if="validCode">Email verified. Redirecting you...</div>
        <div v-else class="flex vertical centered">
          <div class="p2 tc">This email verification link has expired. Please request a new link.</div>
          <ion-button class="bdl" @click="login()">Return to Login</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { applyActionCode, getAuth } from "firebase/auth";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
export default {
  setup() {
    const validCode = ref(true);
    let actionCode;
    const route = useRoute();
    const router = useRouter();
    const auth = getAuth();
    const userStore = useUserStore();
    const userData = userStore.data;
    const checkLink = async () => {
      const link = route.params.link;
      actionCode = new URL(link).searchParams.get("oobCode");
      applyActionCode(auth, actionCode)
        .then(async (resp) => {
          validCode.value = true;
          userStore.updateUser();
          setTimeout(() => {
            router.push({ name: "Home" });
          }, 1000);
        })
        .catch((error) => {
          validCode.value = false;
        });
    };
    onMounted(() => {
      checkLink();
    });
    const login = () => {
      router.push({ name: "Login" });
    };
    return {
      validCode,
      login,
    };
  },
};
</script>
