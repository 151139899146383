<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd" slot="fixed">
        <view-header @back="back" :title="organisation?.name" :subtitle="$t('locations')" class="fullwidth" />
        <div class="scroll pv ph2 fullwidth">
          <div v-for="location in organisation?.locations" :key="location.id" class="action openStart" @click="showLocation(location)">
            <div>
              <div class="titleFont">{{ location.name }}</div>
              <div>{{ location.address?.city }}<span v-show="location.address?.postcode">, </span>{{ location.address?.postcode }}</div>
            </div>
            <div>&gt;</div>
          </div>
        </div>
        <div class="grow"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";

export default {
  setup() {
    const organisationStore = useOrganisationStore();
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const { organisation } = storeToRefs(organisationStore);
    const router = useRouter();

    onMounted(async () => {
      await organisationStore.initialise();
    });

    onIonViewWillEnter(() => {
      if (
        user.value?.organisations &&
        user.value?.organisations.length > 0 &&
        !user.value?.organisations?.includes(organisation.value?.id)
      ) {
        organisationStore.initialise(user.value.organisations[0]);
      }
    });

    const showLocation = (location) => {
      router.push({ name: "Location", params: { organisationID: organisation.value.id, locationID: location.id } });
    };

    const back = () => {
      router.push({ name: "Home" });
    };

    return { organisation, showLocation, back };
  },
};
</script>

<style scoped>
.locationName {
  font-family: Title;
  font-size: 1.8em;
  line-height: 1em;
  text-transform: uppercase;
  color: var(--ion-color-primary);
}
.locationAddress {
  font-family: Title;
  font-size: 1.4em;
  text-transform: uppercase;
  color: var(--ion-color-secondary);
}
.bgOrg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.bgOrg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}
</style>
