<template>
  <div class="container">
    <div class="outer" ref="outer">
      <div class="message">
        <span>{{ progress == 0 ? label : slidingLabel }}</span>
      </div>
      <div class="message sliding" :style="{ opacity: progress }">
        <span>{{ slidingLabel }}</span>
      </div>
      <div class="lock" ref="lock" @mousedown="dragStart" @touchstart="dragStart"></div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  props: ["label", "slidingLabel"],
  emits: ["unlock"],
  setup(props, { emit }) {
    const outer = ref();
    const lock = ref();
    const message = ref();
    const progress = ref(0);

    let lockRect = null;
    let dragProps = null;

    const outerRect = computed(() => outer.value?.getBoundingClientRect());
    const isTouch = computed(() => "ontouchstart" in document.documentElement);

    const dragStart = (e) => {
      lockRect = lock.value.getBoundingClientRect();
      const x = getX(e);

      dragProps = {
        start: lockRect.left - outerRect.value.left,
        mouseStart: x,
        newX: 0,
      };

      lock.value.classList.add("dragging");
      document.addEventListener("mousemove", dragLock, false);
      document.addEventListener("touchmove", dragLock, false);
      document.addEventListener("mouseup", dragStop);
      document.addEventListener("touchend", dragStop);
    };

    const dragStop = (reset) => {
      lock.value.classList.remove("dragging");

      if (reset !== false) {
        setTimeout(() => {
          if (lock.value) lock.value.style.left = "0px";
          progress.value = 0;
        }, 500);
      }
      document.removeEventListener("mousemove", dragLock, false);
      document.removeEventListener("touchmove", dragLock, false);
      document.removeEventListener("mouseup", dragStop);
      document.removeEventListener("touchend", dragStop);
    };

    const unlock = () => {
      dragStop(true);
      emit("unlock");
    };

    const dragLock = (e) => {
      e.preventDefault();
      const posX = getX(e);
      const mouseDiff = posX - dragProps.mouseStart;
      const maxX = outerRect.value.width - lockRect.width;
      let newX = dragProps.start + mouseDiff;
      newX = clamp(newX, 0, maxX);
      progress.value = newX / (outerRect.value.width - lockRect.width);
      lock.value.style.left = newX + "px";
      if (newX >= maxX) {
        unlock();
      }
    };

    const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

    const getX = (event) => (isTouch.value ? event.touches[0].pageX : event.clientX);

    return { dragStart, dragStop, dragLock, unlock, outer, lock, message, progress };
  },
};
</script>

<style scoped>
.container {
  position: relative;
  height: 50px;
}
.message {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-danger-contrast);
  background: var(--ion-color-danger);
  text-transform: uppercase;
}

.message.sliding {
  color: var(--ion-color-warning-contrast);
  background: var(--ion-color-warning);
}

.outer {
  background: var(--ion-color-warning);
  display: inline-block;
  width: 100%;
  position: relative;
  user-select: none;
  border-radius: 5px;
  overflow: hidden;
}
.outer .lock {
  height: 50px;
  aspect-ratio: 1;
  background: var(--ion-color-danger-tint);
  cursor: pointer;
  position: relative;
  left: 0;
  transition: background 0.2s ease-out, left 0.3s ease-out;
  border-radius: 5px;
  border: 3px solid var(--ion-color-light);
}
.outer .lock.dragging {
  transition: none;
}
.container .outer.unlocked {
  opacity: 0.8;
}
.outer.unlocked .lock {
  background: var(--ion-color-warning);
  cursor: none;
}
</style>
