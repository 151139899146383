<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical cd safe-bottom bgd">
        <view-header @back="back" title="Contact Us" />
        <div class="flex vertical left autoheight grow ph2">
          <ion-input class="nogrow" label="Name" label-placement="stacked" placeholder="Your name" v-model="data.name"></ion-input>
          <ion-input class="nogrow" label="Email" label-placement="stacked" placeholder="Your email" v-model="data.email"></ion-input>
          <ion-input
            class="nogrow"
            label="Phone"
            label-placement="stacked"
            placeholder="Your phone number"
            v-model="data.phone"
          ></ion-input>
          <ion-input class="nogrow" label="Subject" label-placement="stacked" placeholder="Subject" v-model="data.subject"></ion-input>
          <ion-textarea
            class="grow"
            label="Your message"
            label-placement="stacked"
            auto-grow="true"
            placeholder="Message"
            v-model="data.message"
          ></ion-textarea>
        </div>
        <div class="fullwidth p">
          <ion-button expand="block" @click="performAction" class="bright ph">Send message</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, onMounted, reactive } from "vue";
import { runFunction } from "@/composables/firebase";
import { toast } from "@/composables/utilities";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import AvHeader from "../../components/AvHeader.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { AvHeader },
  setup() {
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const router = useRouter();

    const data = reactive({});
    onMounted(async () => {
      data.name = `${user.value?.firstname || ""} ${user.value?.lastname || ""}`;
      if (data.name == " ") data.name = "";
      data.email = user.value?.id;
      data.phone = user.value?.phone;
      data.subject = `Alpha Victor enquiry`;
    });

    const performAction = async () => {
      await runFunction("responseRequest", { type: data.action, ...data });
      toast("Message sent.");
    };

    const back = () => {
      router.back();
    };

    return {
      // methods
      close,
      performAction,
      back,
      //variables
      data,
      //icons
    };
  },
});
</script>
<style scoped>
ion-input,
ion-textarea {
  --padding-start: 5px;
  margin: 5px 0px;
  border: 1px solid var(--ion-color-light);
  background: var(--ion-color-dark);
  color: var(--ion-color-dark-contrast);
}
</style>
