<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical bgd" slot="fixed">
        <view-header @back="back" :title="floor?.name" :subtitle="location?.name" class="fullwidth" />
        <div class="scroll p fullwidth">
          <div v-for="space in sortedSpaces" :key="space.id" class="action openStart" @click="showSpace(space)">
            <div>
              <div class="titleFont">{{ space.name }}</div>
              <div class="regularFont" style="padding-top: 4px">
                {{ space.capacity || 0 }} {{ t("person") }}
                {{ organisation.spaceTypes?.find((st) => st.id == space.spaceTypeID)?.name || "space" }}
              </div>
            </div>
            <div>&gt;</div>
          </div>
        </div>
        <div class="grow"></div>
        <!-- <nav-footer label="Browse Floors" @back="browseFloors" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { sortObjectByProperty } from "@/composables/utilities";

export default {
  setup() {
    const organisationStore = useOrganisationStore();
    const { floor, location, organisation } = storeToRefs(organisationStore);
    const route = useRoute();
    const router = useRouter();
    const floorID = route.params.floorID;
    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const { t } = useI18n();

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID);
    });

    const showSpace = (space) => {
      router.push({ name: "Space", params: { organisationID, locationID, floorID, spaceID: space.id } });
    };

    const back = () => {
      router.push({ name: "Location", params: { organisationID, locationID } });
    };

    const sortedSpaces = computed(() => {
      return sortObjectByProperty(floor.value?.spaces, "index");
    });

    return { floor, location, organisation, sortedSpaces, showSpace, back, t };
  },
};
</script>

<style scoped>
.spaceName {
  font-family: Title;
  font-size: 1.8em;
  line-height: 1em;
  text-transform: uppercase;
  color: var(--ion-color-primary);
}
.spaceType {
  font-family: Title;
  font-size: 1.4em;
  text-transform: uppercase;
  color: var(--ion-color-secondary);
}
.bg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}
</style>
