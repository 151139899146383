// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#onboarding-pagination .swiper-pagination-bullet {
  background-color: var(--ion-color-menu);
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  opacity: 0.1;
}
#onboarding-pagination .swiper-pagination-bullet-active {
  opacity: 1;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
