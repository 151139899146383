<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="flex vertical fullheight bgd" slot="fixed">
        <view-header @back="back" title="Account" subtitle="My Details" class="fullwidth" />

        <div slot="content" class="fullwidth p2">
          <ion-item>
            <ion-input
              label="First name"
              label-placement="stacked"
              v-model="userData.profile.firstname"
              @ionChange="markDirty()"
              autocomplete="name"
              color="secondary"
            />
          </ion-item>
          <ion-item>
            <ion-input
              label="Last name"
              label-placement="stacked"
              v-model="userData.profile.lastname"
              @ionChange="markDirty()"
              autocomplete="family-name"
              color="secondary"
            />
          </ion-item>

          <ion-item lines="none">
            <ion-input
              label="Phone number"
              label-placement="stacked"
              v-model="userData.profile.phone"
              @ionChange="markDirty()"
              autocomplete="tel"
              color="secondary"
            />
          </ion-item>
        </div>
        <div class="fullwidth p2"><ion-button expand="block" color="danger" @click="deleteUser">Delete Account</ion-button></div>
        <div class="grow"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { alertController } from "@ionic/vue";
import { useUserStore } from "@/stores/user";
import { ref } from "vue";

export default {
  setup() {
    const dirty = ref(false);
    const router = useRouter();
    const userStore = useUserStore();
    const userData = userStore.data;

    const markDirty = () => {
      dirty.value = true;
    };

    const save = async () => {
      userStore.saveProfile();
      dirty.value = false;
    };

    const deleteUser = () => {
      router.push({ name: "DeleteAccount" });
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: async () => {
                await save();
                router.push({ name: "Home" });
              },
            },
            {
              text: "Cancel",
            },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.push({ name: "Home" });
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.push({ name: "Home" });
      }
    };

    return { userData, dirty, markDirty, back, save, deleteUser };
  },
};
</script>

<style scoped></style>
