<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="floor">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="floor.name" :ubtitle="t('details')" edit="true" />

        <div class="fullwidth ph pt scroll">
          <ion-item class="fullwidth">
            <ion-input
              :label="t('floor_name')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('name')"
              v-model="floor.name"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
          </ion-item>
          <ion-item class="fullwidth">
            <ion-input
              :label="t('short_name')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('short_name')"
              v-model="floor.shortName"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
          </ion-item>
        </div>

        <div class="grow"></div>
        <!-- <nav-footer :label="floor.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const floorID = route.params.floorID;

    const organisationStore = useOrganisationStore();
    const { organisation, location, floor } = storeToRefs(organisationStore);
    const { t } = useI18n();

    const dirty = ref(false);

    let backup;

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID);
    });

    const save = async (floor) => {
      organisationStore.saveRecord(`organisations/${organisationID}/locations/${locationID}/floors`, floor.value.id, floor.value, [
        "spaces",
      ]);
      router.back();
    };

    const createBackup = () => {
      backup = floor.value || {};
    };

    watch(floor, createBackup);
    createBackup();

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save(floor);
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                floor.value = backup;
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const go = (name) => {
      router.push({ name: name, params: { locationID } });
    };

    return {
      // variables
      organisation,
      location,
      floor,
      //methods
      go,
      markDirty,
      back,
      t,
      // icons
      chevronForward,
    };
  },
};
</script>
