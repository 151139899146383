// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.toolbarIcon[data-v-14f60b98] {
  width: 32px;
  height: 32px;
  color: var(--ion-color-light);
}
.disabled[data-v-14f60b98] {
  pointer-events: none;
  opacity: 0.4;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
