// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
ion-datetime-button[data-v-930cf6cc]::part(native) {
  background: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  font-size: 0.8em;
}
.noValue[data-v-930cf6cc] {
  opacity: 0.5;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
