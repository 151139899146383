<template>
  <ion-page>
    <av-header />
    <ion-content v-if="space">
      <input type="file" @change="addPanorama()" ref="panoramaSelector" hidden />
      <div class="flex fullheight vertical bgd" slot="fixed">
        <view-header @back="back" :title="space?.name" :subtitle="t('look_around')" />
        <div ref="pano" class="fullwidth fullheight"></div>
        <div id="galleryIcon">
          <img src="/icons/gallery.svg" @click="showGallery" />
        </div>
      </div>
    </ion-content>

    <ion-modal :is-open="deviceDetailsVisible" @didDismiss="closeModal" :breakpoints="[0, 1]" :initial-breakpoint="1">
      <div class="block pv2">
        <div class="flex mb2 ph2">
          <div class="flex vertical top">
            <div class="titleFont">{{ selectedHotspot.modelDetails }}</div>
            <div class="boldFont fs">device.information</div>
          </div>
          <img :src="selectedHotspot.photo" v-if="selectedHotspot.photo" style="width: 30%" class="r1" />
        </div>

        <div class="fs scroll ph2">
          <div class="titleFont mt2 pb">Description</div>
          <div class="information mb2">
            <div>{{ selectedDevice.description }}</div>
          </div>

          <div class="titleFont pb">Technical</div>
          <div class="information">
            <div>{{ t("manufacturer") }}</div>
            <div>{{ selectedDevice.manufacturer?.name }}</div>
          </div>
          <div class="information">
            <div>Asset type</div>
            <div>{{ selectedDevice.assetType?.name }}</div>
          </div>
          <div class="information">
            <div>{{ t("location") }}</div>
            <div>{{ location?.name }}</div>
          </div>
          <div class="information">
            <div>Space</div>
            <div>{{ space?.name }}</div>
          </div>
          <div class="information">
            <div>{{ t("serial_number") }}</div>
            <div>{{ selectedDevice.serialNumber }}</div>
          </div>
          <div class="information">
            <div>{{ t("warranty_expiration_date") }}</div>
            <div>{{ selectedDevice.warrantyExpirationDate }}</div>
          </div>
          <div class="information">
            <div>MAC Address</div>
            <div>{{ selectedDevice.macAddress }}</div>
          </div>

          <div class="titleFont mt2 pb">Installation</div>
          <div class="information">
            <div>{{ t("installer") }}</div>
            <div>{{ selectedDevice.installer }}</div>
          </div>
          <div class="information">
            <div>Installation Date</div>
            <div>{{ selectedDevice.installDate }}</div>
          </div>

          <div class="information">
            <div>{{ t("serial_number") }}</div>
            <div>{{ selectedDevice.serialNumber }}</div>
          </div>

          <!-- <div class="information documents mt2" @click="showDocuments">
            <div>{{ t("documents") }}</div>
            <div><ion-icon :icon="chevronForward" /></div>
          </div> -->
        </div>
        <div class="flex mt2 ph2">
          <ion-button expand="block" class="bright fullwidth" @click="showDocuments">Documents</ion-button>
          <ion-button expand="block" color="light fullwidth" @click="showReportIssue">Report Issue</ion-button>
        </div>
      </div>
    </ion-modal>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { alertController, onIonViewDidEnter } from "@ionic/vue";
import { chevronForward, locate } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storage } from "@/composables/firebase";
import { getDownloadURL, ref as fbRef } from "firebase/storage";
import "pannellum";
import "pannellum/build/pannellum.css";
import mixpanel from "mixpanel-browser";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;
    const organisationStore = useOrganisationStore();
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const { organisation, location, floor, space, models, manufacturers, assetTypes } = storeToRefs(organisationStore);
    const dirty = ref(false);
    const hotspots = [];
    let backup;
    const { t } = useI18n();
    const pano = ref();
    const selectedHotspot = ref();
    const selectedDevice = ref();
    let panoramaRendered = false;
    let click;
    const rootElement = ref();
    const deviceDetailsVisible = ref(false);

    let viewer;

    // watch(
    //   () => space.value?.pano,
    //   () => {
    //     if (space.value?.pano) setupPanorama();
    //   }
    // );

    onMounted(() => {
      rootElement.value = document.getElementById("main-content");
    });

    const setupPanorama = async () => {
      if (!space.value?.pano?.path || panoramaRendered || !pano.value) {
        return;
      }
      space.value.pano.url = await getDownloadURL(fbRef(storage, space.value.pano.path));
      const options = {
        type: "equirectangular",
        panorama: space.value.pano.url,
        autoLoad: true,
        autoRotate: -3,
        autoRotateStopDelay: 1,
        autoRotateInactivityDelay: 60000,
        orientationOnByDefault: false,
        draggable: true,
        mouseZoom: true,
        doubleClickZoom: true,
        compass: false,
        hfov: 90,
        yaw: 0,
        pitch: 0,
        minHfov: 30,
        maxHfov: 120,
      };
      if (viewer) viewer.destroy();
      viewer = window.pannellum.viewer(pano.value, options);
      if (space.value.pano.initialView?.pitch) viewer.setPitch(space.value.pano.initialView.pitch);
      if (space.value.pano.initialView?.yaw) viewer.setYaw(space.value.pano.initialView?.yaw);
      if (space.value.pano.initialView?.hfov) viewer.setHfov(space.value.pano.initialView?.hfov);
      viewer.on("load", drawHotspots);
    };

    const drawHotspots = () => {
      if (!viewer.isLoaded()) return;
      space.value?.pano?.hotspots?.forEach((hotspot) => {
        if (hotspot.visible || isTech.value) drawHotspot(hotspot);
      });
    };

    const drawHotspot = (hotspot) => {
      const newHotspot = {
        ...hotspot,
        clickHandlerFunc: hotspotClicked,
        clickHandlerArgs: { id: hotspot.id },
      };
      viewer.addHotSpot(newHotspot);
    };

    const showGallery = () => {
      router.push({ name: "SpaceGallery", params: { organisationID, locationID, floorID, spaceID } });
    };

    const isTech = computed(() => user.value?.roles?.includes("technician"));

    const hotspotClicked = async (event, args) => {
      selectedHotspot.value = space.value.pano.hotspots.find((h) => h.id == args.id);
      selectedDevice.value = space.value.devices.find((d) => d.id == selectedHotspot.value.deviceID);
      if (!selectedDevice.value) return;
      selectedDevice.value.model = models.value[selectedDevice.value.modelID];
      selectedDevice.value.assetType = assetTypes.value[selectedDevice.value?.model?.assetTypeID];
      selectedDevice.value.manufacturer = manufacturers.value[selectedDevice.value?.model?.manufacturerID];
      const path = selectedDevice.value?.photo?.path || selectedDevice.value.model?.photo?.path;
      if (path) selectedHotspot.value.photo = await getDownloadURL(fbRef(storage, path));
      viewer.lookAt({ pitch: selectedHotspot.value.pitch, yaw: selectedHotspot.value.yaw });
      viewer.setPitch(selectedHotspot.value.pitch - 15);
      viewer.setYaw(selectedHotspot.value.yaw);

      deviceDetailsVisible.value = true;
    };

    const closeModal = () => {
      deviceDetailsVisible.value = false;
    };

    // watch(pano, () => {
    //   //panorama element ref ready
    //   if (pano.value) setupPanorama();
    // });

    onIonViewDidEnter(async () => {
      await organisationStore.initialise(organisationID, locationID, floorID, spaceID);
      setupPanorama();
    });

    const back = async () => {
      router.back();
    };

    const showDetails = () => {
      deviceDetailsVisible.value = false;
      mixpanel.track("Panorama Device Selected", {
        organisation: organisation.value?.name,
        location: location.value?.name,
        floor: floor.value?.name,
        space: space.value?.name,
        device: selectedHotspot.value.text,
      });
      router.push({ name: "Device", params: { organisationID, locationID, floorID, spaceID, deviceID: selectedHotspot.value.deviceID } });
    };

    const go = (name) => {
      router.push({ name: name, params: { locationID } });
    };

    const showUserGuide = () => {
      closeModal();
      router.push({
        name: "SpaceHowTo",
        params: { organisationID, locationID, floorID, spaceID, decisionTreeID: space.value.decisionTreeID },
      });
    };

    const showReportIssue = () => {
      closeModal();
      router.push({ name: "SpaceReportIssue", params: { organisationID, locationID, floorID } });
    };

    const showDocuments = () => {
      closeModal();
      router.push({
        name: "DeviceDocuments",
        params: { organisationID, locationID, floorID, spaceID, modelID: selectedDevice.value?.modelID },
      });
    };

    return {
      // variables
      organisation,
      location,
      floor,
      space,
      dirty,
      pano,
      deviceDetailsVisible,
      selectedHotspot,
      selectedDevice,
      //methods
      go,
      back,
      closeModal,
      showDetails,
      showReportIssue,
      showUserGuide,
      showDocuments,
      showGallery,
      t,
      // icons
      chevronForward,
      locate,
    };
  },
};
</script>

<style scoped>
.information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.1);
}

.information.documents {
  font-family: Title;
}
.block {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 90vh;
  background: var(--ion-color-dark);
}

ion-modal {
  --height: auto;
}
</style>

<style>
span.pnlm-pointer {
  width: 200px !important;
  margin-left: -98px !important;
  margin-top: -45px !important;
  font-family: Regular;
}

#galleryIcon {
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin: 20px;
  z-index: 1000;
}
</style>
