<template>
  <ion-page>
    <av-header title="About Alpha Victor" />

    <ion-content scroll-y="false">
      <div class="fullwidth flex vertical bgd" slot="fixed">
        <div class="p2 mb2 tc fs fullwidth scroll">
          <img src="/img/logos/large_black.png" alt="logo" style="max-width: 100px" class="mt2" />

          <div class="uppercase">&copy; {{ year }} Uncoded Limited</div>
          <div class="titleFont f2 mt2">Alpha Victor</div>
          <div class="fr flex centered">
            <div>
              <p style="max-width: 600px">
                Alpha Victor seeks to continually enhance the user experience in the workspace. The platform also provides workplace teams
                with the information they need to make future decisions on the design of new, better performing spaces.
              </p>
              <p style="max-width: 600px">
                Alpha Victor delivers dynamic user guides for users of the space alongside a simple way for them to give their feedback,
                whether at home or in the office.
              </p>
            </div>
          </div>
          <div class="grow"></div>
          <div class="ft">{{ rcValues.beaconRangeCount }}.{{ rcValues.beaconRequiredAccuracy }}.{{ rcValues.minimumRequiredVersion }}</div>
        </div>

        <div class="actionList fullwidth p2 safe-bottom">
          <div class="action" @click="contact()">
            <ion-label>Contact Us</ion-label>
            <ion-icon :icon="chevronForward" />
          </div>
          <div class="action" @click="showNotices()">
            <ion-label>Legal Notices</ion-label>
            <ion-icon :icon="chevronForward" />
          </div>
          <div class="action" @click="showOnboarding()">
            <ion-label>Welcome Message</ion-label>
            <ion-icon :icon="chevronForward" />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { chevronForward } from "ionicons/icons";
import { useRouter } from "vue-router";
import { rcValues } from "@/composables/firebase";

export default {
  setup() {
    const router = useRouter();
    const year = new Date().getFullYear();

    const contact = () => {
      router.push({ name: "Contact" });
    };

    const showNotices = () => {
      router.push({ name: "LegalNotices" });
    };

    const showOnboarding = () => {
      router.push({ name: "Onboarding" });
    };

    return { year, showNotices, showOnboarding, contact, chevronForward, rcValues };
  },
};
</script>

<style></style>
