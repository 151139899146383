<template>
  <ion-page>
    <ion-content>
      <div class="flex vertical nogap" slot="fixed" v-if="space">
        <av-header />
        <div class="flex vertical fullwidth noScroll"></div>
      </div>
      <wait v-else />
    </ion-content>
  </ion-page>
</template>

<script setup></script>

<style lang="scss" scoped></style>
