<template>
  <ion-page>
    <av-header />
    <ion-content v-if="space">
      <div class="flex vertical nogap bgd" slot="fixed">
        <view-header @back="back" :title="space.name" :subtitle="t('ubiqisense_configuration')" edit="true" />
        <div class="fullwidth p scroll">
          <ion-label class="titleFont p2">{{ t("select_location") }}</ion-label>
          <ion-list class="fullwidth p">
            <ion-radio-group v-model="space.ubi_id" @ionChange="markDirty">
              <ion-item v-for="loc in locations" :key="loc.id">
                <ion-label color="secondary">{{ loc.name }}</ion-label>
                <ion-radio color="secondary" mode="ios" :value="`${loc.id}`" slot="end"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </div>

        <div class="grow"></div>
        <div class="fullwidth p2"><ion-button expand="block" color="danger" @click="clear">Clear</ion-button></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { useUbiStore } from "@/stores/ubi";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default {
  name: "Description",
  setup() {
    const dirty = ref(false);
    const route = useRoute();
    const router = useRouter();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;
    const organisationStore = useOrganisationStore();
    const ubiStore = useUbiStore();
    const { space } = storeToRefs(organisationStore);
    const locations = ref([]);
    const { t } = useI18n();
    let backup;

    onIonViewWillEnter(async () => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
      locations.value = await ubiStore.getLocations();
    });

    const createBackup = () => (backup = { ...space });
    createBackup();
    watch(space, createBackup);

    const save = async () => {
      organisationStore.saveRecord(
        `organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces`,
        space.value.id,
        space.value
      );
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const clear = () => {
      space.value.ubi_id = null;
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                space.value = backup;
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      space,
      locations,
      // methods
      markDirty,
      back,
      clear,
      t,
      // icons
      chevronForward,
    };
  },
};
</script>
