import { getApp, initializeApp } from "firebase/app";

import {
  getFirestore,
  collection,
  collectionGroup,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  onSnapshot,
  connectFirestoreEmulator,
} from "@firebase/firestore";
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL, deleteObject, connectStorageEmulator } from "firebase/storage";
import {
  initializeAuth,
  signInAnonymously,
  browserLocalPersistence,
  onAuthStateChanged,
  getAuth,
  connectAuthEmulator,
  AuthCredential,
} from "firebase/auth";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { isPlatform } from "@ionic/vue";

export const rcValues = {};

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};
if (process.env.VUE_APP_FIREBASE_MEASUREMENT_ID) firebaseConfig.measurementID = process.env.VUE_APP_FIREBASE_MEASUREMENT_ID;

const isDevelopment = process.env.VUE_APP_MODE == "development";

const app = initializeApp(firebaseConfig);
export const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
});

export const db = getFirestore();
export const functions = getFunctions(getApp(), "europe-west2");
export const storage = getStorage();
export const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = {
  beaconRangeCount: 8,
  beaconRequiredAccuracy: 10,
  minimumRequiredVersion: 1,
};
remoteConfig.settings.minimumFetchIntervalMillis = process.env.VUE_APP_REMOTE_CONFIG_INTERVAL;

fetchAndActivate(remoteConfig).then(() => {
  rcValues.beaconRangeCount = getValue(remoteConfig, "beaconRangeCount").asNumber();
  rcValues.beaconRequiredAccuracy = getValue(remoteConfig, "beaconRequiredAccuracy").asNumber();
  rcValues.minimumRequiredVersion = getValue(remoteConfig, "minimumRequiredVersion").asNumber();
});

export const checkVersion = () => {
  const actualVersion = parseInt(process.env.VUE_APP_VERSION);
  try {
    return !isPlatform("cordova") || actualVersion >= getValue(remoteConfig, "minimumRequiredVersion").asNumber();
  } catch (error) {
    console.log(error);
    return true;
  }
};

export const runFunction = async (functionName, data) => {
  const fn = httpsCallable(functions, functionName, { timeout: 540000 });
  try {
    const result = await fn(data);
    return result.data;
  } catch (error) {
    if (isDevelopment) console.log("Error calling function", functionName, data, error);
  }
};

export const deleteFirebaseFile = async (path) => {
  try {
    const storageRef = ref(storage, path);
    await deleteObject(storageRef);
  } catch (error) {
    console.log("Error deleting file", path);
  }
};

export async function uploadFirebaseBase64(path, filename, data, mimeType) {
  try {
    const storageRef = ref(storage, `${path}/${filename}`);
    const snapshot = await uploadString(storageRef, data, "base64", { contentType: mimeType });
    const url = await getDownloadURL(storageRef);
    return { ...snapshot, url: url };
  } catch (error) {
    if (isDevelopment) console.log("Error uploading document (64)", path, filename, error);
  }
}

export const getShortcode = (length = 4) => {
  let text;
  let isUnique = false;

  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  text = "";
  for (let i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const formatFirestoreDate = (firestoreDate) => {
  const date = new Date(firestoreDate.seconds * 1000);
  var strArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var d = date.getDate();
  var m = strArray[date.getMonth()];
  return "" + (d <= 9 ? "0" + d : d) + " " + m;
};
