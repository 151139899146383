<template>
  <ion-page>
    <av-header />
    <ion-content v-if="organisations">
      <div class="flex vertical left bgd" slot="fixed">
        <view-header
          @back="back"
          :title="$t('favourites')"
          :subtitle="`${profile.favouriteSpaces?.length || 'No'} spaces`"
          class="fullwidth"
        />
        <div v-if="profile" style="height: 100%" class="scroll fullwidth">
          <div class="fullwidth ph2" style="height: 100%">
            <div
              class="card mb shadow flex"
              style="gap: 10px"
              v-for="space in profile.favouriteSpaces"
              :key="space.spaceID"
              @click="openSpace(space)"
            >
              <img :src="space.photoURL" class="photo" style="width: 50%; max-width: 100px" />
              <div class="flex vertical">
                <div class="titleFont fl tightlines fullwidth">{{ space.name }}</div>
                <div class="fullwidth tightlines">{{ space.organisationName }}</div>
                <div class="fullwidth tightlines">{{ space.locationName }}</div>
                <div class="fullwidth tightlines fs">{{ space.floorName }}</div>
              </div>
            </div>

            <div class="fullwidth pb2">
              <ion-button
                expand="block"
                class="bright mv2"
                @click="back()"
                v-if="profile.favouriteSpaces && profile.favouriteSpaces.length > 0"
                >Find another space</ion-button
              >
              <ion-button expand="block" class="bright m2" @click="back()" v-else>Find your first space</ion-button>
            </div>
          </div>
        </div>

        <!-- <nav-footer label="Home" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

const userStore = useUserStore();
const organisationStore = useOrganisationStore();
const { organisations } = storeToRefs(organisationStore);
const { profile } = storeToRefs(userStore);
const router = useRouter();

onMounted(() => {
  organisationStore.initialise();
});

const openSpace = (space) => {
  router.push({
    name: "Space",
    params: { organisationID: space.organisationID, locationID: space.locationID, floorID: space.floorID, spaceID: space.spaceID },
  });
};

const back = () => {
  router.push({ name: "Home" });
};
</script>
