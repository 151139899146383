<template>
  <ion-page>
    <ion-content>
      <div class="flex vertical centered boldFont" slot="fixed">
        <div class="f8 titleFont">404</div>
        <div class="f2">Page not found</div>
        <div class="fl tc fullwidth" style="max-width: min(500px, 80vw)">
          Sorry, the page you're looking for can't be found. Either it doesn't exist or one of the developers fell asleep.
        </div>
        <div>
          <ion-button class="mt2" color="primary" @click="goBack()">Let's try that again...</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const goBack = () => {
      router.push({ name: "Home" });
    };
    return { goBack };
  },
};
</script>

<style></style>
