// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar[data-v-74b98ed4] {
  border-radius: 3em;
  margin: calc(20px + var(--ion-safe-area-top)) 20px 20px;
  width: calc(100% - 40px);
}
.toolbar a[data-v-74b98ed4] {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}
.toolbar .headerLogo[data-v-74b98ed4] {
  height: 2em;
}
.success[data-v-74b98ed4] {
  color: var(--ion-color-success);
}
.fail[data-v-74b98ed4] {
  color: var(--ion-color-danger);
}
.counter[data-v-74b98ed4] {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  padding-top: 2px;
  margin-left: 5px;
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}
.counter.warning[data-v-74b98ed4] {
  background-color: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
}
.counter.danger[data-v-74b98ed4] {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}
.forward[data-v-74b98ed4] {
  margin-right: 0.3rem;
}
.wifiDetails[data-v-74b98ed4] {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  border: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.5);
  border-radius: 5px;
  font-family: Title;
  font-size: 1.1em;
}
.wifiDetails .ssid[data-v-74b98ed4],
.wifiDetails .password[data-v-74b98ed4] {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 10px 20px;
  padding: 5px 0px;
}
.wifiDetails .ssid .label[data-v-74b98ed4],
.wifiDetails .password .label[data-v-74b98ed4] {
  font-family: Bold;
  font-size: 0.5em;
  font-weight: 300;
  margin-bottom: 3px;
}
.wifiDetails .ssid[data-v-74b98ed4] {
  border-right: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.5);
}
.features[data-v-74b98ed4] {
  display: flex;
  margin: 5px 20px;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 20px;
}
.features div[data-v-74b98ed4] {
  border: 1px solid var(--ion-color-light);
  padding: 5px 10px;
  background: var(--ion-color-dark);
  color: var(--ion-color-dark-contrast);
  border-radius: 8px;
  font-family: Bold;
  font-size: 12px;
}
.featureImage[data-v-74b98ed4] {
  height: 50%;
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.featureImage .featureBackground[data-v-74b98ed4] {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.featureImage .featureBackground img[data-v-74b98ed4] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.featureImage .featureOverlay[data-v-74b98ed4] {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgb(0, 0, 0));
}
.featureImage .featureContent[data-v-74b98ed4] {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Bold;
}
.featureImage .featureContent .featureTop[data-v-74b98ed4] {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.featureImage .featureContent .featureTop .featureLeft[data-v-74b98ed4] {
  color: var(--ion-color-light);
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
}
.featureImage .featureContent .featureTop .featureRight[data-v-74b98ed4] {
  color: var(--ion-color-light);
  font-size: 1.4em;
  display: flex;
  flex-direction: column;
}
.featureImage .featureContent .featureBottom[data-v-74b98ed4] {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.featureImage .featureContent .featureBottom .featureLeft[data-v-74b98ed4] {
  color: var(--ion-color-light);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.featureImage .featureContent .featureBottom .featureLeft .featureOccupancy[data-v-74b98ed4] {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.2em;
}
.featureImage .featureContent .featureBottom .featureLeft .featureOccupancy div[data-v-74b98ed4] {
  font-size: 0.6em;
  color: var(--ion-color-light);
}
.featureImage .featureContent .featureBottom .featureLeft .featureName[data-v-74b98ed4] {
  color: var(--ion-color-light);
  font-family: Title;
  font-size: 1.5em;
}
.featureImage .featureContent .featureBottom .featureLeft .featureLocation[data-v-74b98ed4] {
  display: flex;
  align-items: center;
  gap: 5px;
}
.featureImage .featureContent .featureBottom .featureLeft .featureLocation div[data-v-74b98ed4] {
  padding-top: 3px;
  font-size: 0.7em;
  color: var(--ion-color-light);
}
.featureImage .featureContent .featureBottom .featureRight[data-v-74b98ed4] {
  color: var(--ion-color-light);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.featureImage .featureContent .featureBottom .featureRight .featureOccupancy[data-v-74b98ed4] {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 0.8em;
}
.featureImage .featureContent .featureBottom .featureRight .featureOccupancy .featureOccupied[data-v-74b98ed4] {
  color: var(--ion-color-danger);
}
.featureImage .featureContent .featureBottom .featureRight .featureOccupancy .featureVacant[data-v-74b98ed4] {
  color: var(--ion-color-success);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
