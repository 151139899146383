<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div class="flex vertical centered p2" slot="fixed">
        <div v-if="linkError" class="flex vertical centered">
          <div class="p2 tc">{{ linkError }}</div>
          <ion-button class="bdl" @click="login">Return to Login</ion-button>
        </div>

        <div v-else class="flex vertical centered">
          <div class="p2 tc">Thank you for logging in to Alpha Victor.</div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { signInWithEmailLink, getAuth } from "firebase/auth";
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { onIonViewWillEnter } from "@ionic/vue";
import { storeToRefs } from "pinia";
export default {
  setup() {
    const email = ref(window.localStorage.getItem("emailForSignIn"));
    const linkError = ref();
    const auth = getAuth();
    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const { firebaseUser } = storeToRefs(userStore);
    const verify = async () => {
      if (!firebaseUser.value.isAnonymous) {
        router.push({ name: "Home" });
      }
      try {
        const result = await signInWithEmailLink(auth, email.value, route.params.link);
        window.localStorage.setItem("emailForSignIn", email.value);
        setTimeout(() => {
          router.push({ name: "Home" });
        }, 1000);
        await userStore.updateUser();
      } catch (error) {
        console.log(error, email.value, route.params.link);
        linkError.value = "The magic link you have used is no longer valid. Please request a new Magic Link.";
      }
    };
    onIonViewWillEnter(() => {
      if (!email.value) {
        linkError.value = "The magic link you have used is no longer valid. Please request a new Magic Link.";
        return;
      }
      verify();
    });
    const login = () => {
      router.push({ name: "Login" });
    };
    return { email, linkError, login };
  },
};
</script>
