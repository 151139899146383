<template>
  <ion-page>
    <av-header />
    <ion-content>
      <div class="fullheight" v-if="device">
        <div class="flex vertical bgd" slot="fixed">
          <view-header @back="back" title="Device Info" :subtitle="assetType?.name" class="fullwidth" />

          <div class="flex mb2 ph2">
            <div class="flex vertical top">
              <div class="titleFont">{{ model.name }}</div>
              <div class="boldFont fs">device.information</div>
            </div>
            <img :src="device.photo?.url" v-if="device.photo" style="width: 30%" class="r1" />
          </div>

          <div class="fs scroll ph2 fullwidth">
            <div class="boldFont mt2 pb">Description</div>
            <div class="information mb2">
              <div>{{ device.description }}</div>
            </div>

            <div class="boldFont pb">Technical</div>
            <div class="information">
              <div>{{ t("manufacturer") }}</div>
              <div>{{ manufacturer?.name }}</div>
            </div>
            <div class="information">
              <div>Asset type</div>
              <div>{{ assetType?.name }}</div>
            </div>
            <div class="information">
              <div>{{ t("location") }}</div>
              <div>{{ location.name }}</div>
            </div>
            <div class="information">
              <div>Space</div>
              <div>{{ resolvedSpace.name }}</div>
            </div>
            <div class="information">
              <div>{{ t("serial_number") }}</div>
              <div>{{ device.serialNumber }}</div>
            </div>
            <div class="information">
              <div>{{ t("warranty_expiration_date") }}</div>
              <div>{{ device.warrantyExpirationDate }}</div>
            </div>
            <div class="information">
              <div>MAC Address</div>
              <div>{{ device.macAddress }}</div>
            </div>

            <div class="boldFont mt2 pt2 pb2">Installation</div>
            <div class="information">
              <div>{{ t("installer") }}</div>
              <div>{{ device.installer }}</div>
            </div>
            <div class="information">
              <div>Installation Date</div>
              <div>{{ device.installDate }}</div>
            </div>

            <div class="information">
              <div>{{ t("serial_number") }}</div>
              <div>{{ device.serialNumber }}</div>
            </div>

            <div class="information documents mt2" @click="showDocuments">
              <div>{{ t("documents") }}</div>
              <div><ion-icon :icon="chevronForward" /></div>
            </div>
          </div>

          <div class="grow"></div>
          <div class="flex mt2 ph2">
            <ion-button expand="block" class="bright fullwidth" @click="showUserGuide">User Guide</ion-button>
            <ion-button expand="block" color="light fullwidth" @click="reportIssue">Report Issue</ion-button>
          </div>

          <div class="grow"></div>
          <!-- <nav-footer label="Back" @back="goBack" /> -->
        </div>
      </div>
      <wait v-else />
    </ion-content>
  </ion-page>
</template>

<script>
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";
import { computed } from "vue";
import { eye, locate, chevronForward } from "ionicons/icons";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const organisationStore = useOrganisationStore();
    const { anonymous, location, space, models, manufacturers, assetTypes } = storeToRefs(organisationStore);
    const route = useRoute();
    const router = useRouter();
    const deviceID = route.params.deviceID;
    const spaceID = route.params.spaceID;
    const floorID = route.params.floorID;
    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const { t } = useI18n();

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
    });

    const resolvedSpace = computed(() => space.value || anonymous.value.space);

    const device = computed(() => resolvedSpace.value?.devices?.find((d) => d.id == deviceID));
    const model = computed(() => models.value && models.value[device.value.modelID]);
    const manufacturer = computed(() => manufacturers.value && manufacturers.value[model.value?.manufacturerID]);
    const assetType = computed(() => assetTypes.value && assetTypes.value[model.value?.assetTypeID]);

    const reportIssue = () => {
      router.push({ name: "SpaceReportIssue", params: { organisationID, locationID, floorID } });
    };

    const showUserGuide = () => {
      router.push({
        name: "SpaceHowTo",
        params: { organisationID, locationID, floorID, spaceID, decisionTreeID: space.value.decisionTreeID },
      });
    };

    const isTech = computed(() => user.value?.roles?.includes("technician"));

    const showDocuments = () => {
      router.push({
        name: "DeviceDocuments",
        params: { organisationID, locationID, floorID, spaceID, modelID: device.value?.modelID },
      });
    };

    const back = () => {
      router.back();
    };

    return {
      resolvedSpace,
      location,
      device,
      model,
      manufacturer,
      assetType,
      isTech,
      back,
      showUserGuide,
      showDocuments,
      reportIssue,
      t,
      eye,
      locate,
      chevronForward,
    };
  },
};
</script>

<style scoped>
.bg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}

.card {
  background-color: var(--ion-color-light);
}

.information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.1);
}

.information.documents {
  font-family: Title;
}
</style>
