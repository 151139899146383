// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar[data-v-4c709238] {
  border-radius: 3em;
  margin: calc(20px + var(--ion-safe-area-top)) 20px 20px;
  width: calc(100% - 40px);
}
.toolbar a[data-v-4c709238] {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}
.toolbar .headerLogo[data-v-4c709238] {
  height: 2em;
}
.organisation[data-v-4c709238] {
  color: yellow;
  font-size: 1.4em;
  padding-top: 20px;
}
.location[data-v-4c709238] {
  font-size: 1.2em;
  padding-left: 20px;
  margin: 5px;
}
.floor[data-v-4c709238] {
  font-size: 1em;
  margin: 5px;
  padding-left: 40px;
}
.space[data-v-4c709238] {
  padding-left: 60px;
  font-size: 0.8em;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
