// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar[data-v-55e115cd] {
  border-radius: 3em;
  margin: calc(20px + var(--ion-safe-area-top)) 20px 20px;
  width: calc(100% - 40px);
}
.toolbar a[data-v-55e115cd] {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}
.toolbar .headerLogo[data-v-55e115cd] {
  height: 2em;
}
.panel[data-v-55e115cd] {
  margin-bottom: 10px;
}
.panel Textarea[data-v-55e115cd] {
  width: 100%;
  margin-bottom: 10px;
}
.taskLabel[data-v-55e115cd] {
  margin-left: 10px;
}
.taskPhotos[data-v-55e115cd] {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.taskPhotos img[data-v-55e115cd] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.taskPhoto[data-v-55e115cd] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  max-width: 50%;
  aspect-ratio: 1;
  border: 2px solid var(--ion-color-light);
  border-radius: 5px;
}
[data-v-55e115cd] .pass ~ .p-checkbox-box {
  background: green;
}
[data-v-55e115cd] .fail ~ .p-checkbox-box {
  background: red;
}
[data-v-55e115cd] .p-checkbox-icon {
  color: white;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
