// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.headerLogo[data-v-a23ef6f6] {
  height: 120px;
  margin-top: 3px;
}
.avoidMenubar[data-v-a23ef6f6] {
  padding-bottom: 50px;
}
.link[data-v-a23ef6f6] {
  padding-bottom: 3px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--ion-color-secondary);
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
