// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.homeTitle[data-v-28bc0213] {
  font-size: 4em;
  font-weight: 900;
  line-height: 1em;
}
.mobile .background[data-v-28bc0213] {
  position: absolute;
  left: 0px;
  top: 0px;
  object-fit: contain;
  height: 100%;
  width: 100%;
  opacity: 0.15;
  z-index: -100;
  margin: 3em 0em 0em 3em;
}
.mobile .homeTitle[data-v-28bc0213] {
  font-size: 3em;
}
.homeSubtitle[data-v-28bc0213] {
  margin: 1em 0rem;
  font-weight: 300;
  line-height: 1em;
  font-size: 2em;
  max-width: 400px;
}
.mobile .homeSubtitle[data-v-28bc0213] {
  font-size: 1.5em;
}
.homeText[data-v-28bc0213] {
  font-size: 1.5rem;
  font-weight: 100;
}
.mobile .homeText[data-v-28bc0213] {
  font-size: 1.2em;
}
.homeInput[data-v-28bc0213] {
  margin: 1.5rem 0rem;
  border: 1px solid lightgray;
  width: 100%;
  max-width: 400px;
}
.signupButton[data-v-28bc0213] {
  max-width: 200px;
}
.mobile .signupButton[data-v-28bc0213] {
  max-width: 100%;
}
.iphone[data-v-28bc0213] {
  width: 100%;
  max-width: 600px;
  margin-left: 6em;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
