<template>
  <ion-page>
    <!-- <av-header /> -->
    <ion-content class="p">
      <div class="fullwidth flex vertical left" slot="fixed">
        <!-- <view-header @back="back" :title="t('organisations')" :subtitle="t('select_organisation_to_edit')" edit="true" /> -->
        <Toolbar class="toolbar">
          <template #start>
            <Button icon="pi pi-angle-double-left" rounded @click="back" />
          </template>
          <template #center>
            <router-link to="/"><img src="/img/logos/logo_white.png" class="headerLogo" />{{ t("organisations") }}</router-link>
          </template>
          <template #end>
            <Button icon="pi pi-plus" rounded @click="addOrganisation" />
          </template>
        </Toolbar>

        <div class="organisationsContainer">
          <Card
            v-for="organisation in filteredOrganisations"
            :key="organisation.id"
            class="organisationCard"
            @click="editOrganisation(organisation.id)"
          >
            <template #content>
              <img :src="organisation.logo" />
            </template>
            <template #footer>
              <div class="cardFooter cl fs">{{ organisation.name }}</div>
            </template>
          </Card>
        </div>

        <!-- <ion-list class="fullwidth scroll">
          <ion-item v-for="organisation in filteredOrganisations" :key="organisation.id" @click="editOrganisation(organisation.id)">
            <ion-thumbnail class="dark" slot="start">
              <img :src="organisation?.logo" alt="" @load="organisation.imageLoaded = true"
            /></ion-thumbnail>
            <ion-label>{{ organisation.name }}</ion-label>
          </ion-item>
        </ion-list> -->
        <!-- <div class="fullwidth">
          <ion-button @click="addOrganisation" expand="block" color="tertiary" class="m2">{{ $t("add_organisation") }}</ion-button>
        </div> -->
        <!-- <nav-footer label="Home" @back="cancel" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";

const router = useRouter();
const organisationStore = useOrganisationStore();
const userStore = useUserStore();
const { profile } = storeToRefs(userStore);
const { filteredOrganisations } = storeToRefs(organisationStore);
const { t } = useI18n();

onMounted(() => {
  organisationStore.initialise();
});

const addOrganisation = async () => {
  const newOrganisationID = await organisationStore.addOrganisation();
  editOrganisation(newOrganisationID);
};

const editOrganisation = (id) => {
  profile.value.currentOrganisationID = id;
  router.push({ name: "EditOrganisation", params: { organisationID: id } });
};

const back = () => {
  router.push({ name: "Home" });
};
</script>

<style lang="scss" scoped>
.organisationsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  grid-auto-flow: row;
  grid-auto-rows: 150px;
  grid-gap: 20px;
  padding: 20px;
  justify-content: center;
  overflow-y: auto;

  .organisationCard {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 70px;
      padding: 5px;
      object-fit: contain;
    }

    @media (max-width: 500px) {
      img {
        height: 40px;
        padding: 0px;
      }
    }

    .cardFooter {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
}
</style>
