<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="checklist">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="organisation.name" :subtitle="t('integrations')" edit="true" />
        <div class="fullwidth ph pt scroll">
          <Toolbar>
            <template #start>
              <Button icon="pi pi-plus-circle" label="New task" type="submit" @click="addTask" />
            </template>
            <template #center>
              {{ checklist.name }}
            </template>
            <template #end>
              <SplitButton menuButtonIcon="pi pi-save" label="Save" :model="saveButtonItems" @click="save" />
            </template>
          </Toolbar>

          <div class="settings mt2">
            <FloatLabel class="mt2">
              <InputText v-model="checklist.name" id="checklistName" />
              <label for="checklistName">Checklist name</label>
            </FloatLabel>

            <div class="frequency">
              <Dropdown v-model="checklist.frequency" :options="frequencyOptions" optionLabel="label" optionValue="value" checkmark />&nbsp;
              <div v-if="checklist.frequency == 'weekly'" class="frequency">
                <div>every</div>
                <MultiSelect v-model="checklist.daysDue" :options="dayOfWeekOptions" optionLabel="label" optionValue="value" checkmark />
              </div>
              <div v-else class="frequency">
                on the
                <Dropdown
                  v-model="checklist.dueDayOfMonth"
                  :options="dayOfMonthOptions"
                  optionLabel="label"
                  optionValue="value"
                  checkmark
                />
                of the month
              </div>
            </div>
          </div>
          <ion-list>
            <ion-reorder-group :disabled="false" @ionItemReorder="handleReorder($event)">
              <ion-item v-if="!checklist.tasks || checklist.tasks?.length == 0" lines="none"> No tasks added yet. </ion-item>
              <ion-item v-for="task in checklist.tasks" :key="task.index" lines="none">
                <ion-reorder slot="start"></ion-reorder>

                <i slot="start" class="pi pi-times deleteTaskButton" @click="confirmTaskDelete(task, $event)" />
                <InputText v-model="task.description" style="flex-grow: 1" placeholder="Task description" />

                <div slot="end" class="taskOptions">
                  <ToggleButton
                    v-model="task.allowedNotes"
                    onLabel="Notes"
                    offLabel="Notes"
                    onIcon="pi pi-file"
                    offIcon="pi pi-times-circle"
                    aria-label="Do you confirm"
                  />

                  <ToggleButton
                    v-model="task.allowedPhoto"
                    onLabel="Photo"
                    offLabel="Photo"
                    onIcon="pi pi-image"
                    offIcon="pi pi-times-circle"
                    aria-label="Do you confirm"
                  />
                </div> </ion-item
            ></ion-reorder-group>
          </ion-list>
        </div>
        <div class="grow"></div>
      </div>
      <ConfirmPopup></ConfirmPopup>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter, alertController } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";

const route = useRoute();
const router = useRouter();
const confirm = useConfirm();
const isDirty = ref(false);
let organisationID = route.params.organisationID;
let checklistID = route.params.checklistID;
const organisationStore = useOrganisationStore();
const { organisation } = storeToRefs(organisationStore);
const { t } = useI18n();
let backup;
const checklist = computed(() => organisation.value?.checklists?.[checklistID]);

const confirmTaskDelete = (task, event) => {
  confirm.require({
    target: event.currentTarget,
    message: "Are you sure you want to delete this task?",
    icon: "pi pi-exclamation-triangle",
    rejectClass: "p-button-secondary p-button-outlined p-button-sm",
    acceptClass: "p-button-sm",
    rejectLabel: "Cancel",
    acceptLabel: "Delete",
    accept: () => {
      deleteTask(task);
    },
  });
};

const frequencyOptions = ref([
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
]);

const dayOfMonthOptions = ref([
  { label: "1st", value: 1 },
  { label: "2nd", value: 2 },
  { label: "3rd", value: 3 },
  { label: "4th", value: 4 },
  { label: "5th", value: 5 },
  { label: "6th", value: 6 },
  { label: "7th", value: 7 },
  { label: "8th", value: 8 },
  { label: "9th", value: 9 },
  { label: "10th", value: 10 },
  { label: "11th", value: 11 },
  { label: "12th", value: 12 },
  { label: "13th", value: 13 },
  { label: "14th", value: 14 },
  { label: "15th", value: 15 },
  { label: "16th", value: 16 },
  { label: "17th", value: 17 },
  { label: "18th", value: 18 },
  { label: "19th", value: 19 },
  { label: "20th", value: 20 },
  { label: "21st", value: 21 },
  { label: "22nd", value: 22 },
  { label: "23rd", value: 23 },
  { label: "24th", value: 24 },
  { label: "25th", value: 25 },
  { label: "26th", value: 26 },
  { label: "27th", value: 27 },
  { label: "last day", value: 28 },
]);

const dayOfWeekOptions = ref([
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
]);

const deleteTask = (task) => {
  const index = task.index;
  console.log(checklist.value);
  checklist.value.tasks = checklist.value.tasks.filter((t) => t.index != task.index);
  checklist.value.tasks.filter((t) => t.index > index).forEach((t) => t.index--);
  isDirty.value = true;
};

const deleteChecklist = () => {
  console.log("Del cl");
};

const saveButtonItems = ref([
  {
    label: "Delete",
    icon: "pi pi-times",
    command: deleteChecklist,
    class: "danger",
  },
]);

const handleReorder = (event) => {
  checklist.value.tasks = event.detail.complete(checklist.value.tasks);
  let i = 0;
  for (const task of checklist.value.tasks) {
    task.index = i;
    i++;
  }
};

onIonViewWillEnter(() => {
  organisationStore.initialise(organisationID);
});

const createBackup = () => (backup = { ...checklist.value });
createBackup();
watch(checklist, createBackup);

const addTask = () => {
  if (!checklist.value.tasks) checklist.value.tasks = [];
  checklist.value.tasks.push({ index: checklist.value.tasks.length, allowedNotes: true, allowedPhoto: true });
  isDirty.value = true;
};

const back = async () => {
  if (isDirty.value) {
    const alert = await alertController.create({
      cssClass: "alert",
      header: "Are you sure?",
      message: "You have unsaved changes.",
      buttons: [
        {
          text: "Save & Close",
          handler: () => {
            save();
          },
        },
        { text: "Cancel" },
        {
          text: "Discard Changes",
          role: "destructive",
          handler: () => {
            organisation.value.checklists[checklistID] = backup;
            router.back();
          },
        },
      ],
    });
    await alert.present();
  } else {
    router.back();
  }
};

const save = async () => {
  organisationStore.saveRecord(`organisations/${organisationID}/checklists`, checklist.value.id, checklist.value);
  router.back();
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .frequency {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.taskOptions {
  display: flex;
  gap: 10px;
}

.deleteTaskButton {
  color: red;
}
</style>

<style lang="scss">
.p-tieredmenu {
  background: var(--ion-color-light);
  .p-menuitem.danger {
    .p-menuitem-link,
    .p-menuitem-icon {
      color: var(--ion-color-danger);
    }
  }
}
</style>
