<template>
  <ion-page>
    <av-header />

    <ion-content class="ion-padding">
      <div id="articleContainer">
        <div id="vimeoWrapper"></div>

        <div class="flex" v-if="!loaded">
          <ion-item style="flex-grow: 1">
            <ion-input
              :label="t('vimeo_url')"
              label-placement="stacked"
              :placeholder="t('enter_a_valid_vimeo_url')"
              v-model="vimeoURL"
              @ionInput="dirty = true"
            >
            </ion-input>
          </ion-item>
          <ion-button @click="retrieveVideo">{{ t("fetch_video") }}</ion-button>
        </div>

        <ion-accordion-group value="details" v-if="loaded">
          <ion-accordion value="details">
            <ion-item slot="header"
              ><ion-label>{{ t("video_details") }}</ion-label>
            </ion-item>
            <ion-item slot="content">
              <ion-input
                :label="t('title')"
                label-placement="stacked"
                placeholder="Title"
                v-model="video.title"
                @ionInput="dirty = true"
              ></ion-input
            ></ion-item>
          </ion-accordion>
          <ion-accordion v-for="chapter in video?.chapters" :key="chapter.index" :value="chapter.index">
            <ion-item slot="header"
              ><ion-label>{{ t("chapter") }} {{ chapter.index }} ({{ t("starts at") }} {{ chapter.startTime }}s)</ion-label>
            </ion-item>

            <ion-item slot="content">
              <div>
                <ion-item>
                  <ion-input :label="t('title')" label-placement="stacked" v-model="chapter.title" @ionInput="dirty = true"></ion-input>
                </ion-item>
                <ion-item>
                  <ion-input
                    :label="t('subtitle')"
                    label-placement="stacked"
                    v-model="chapter.subtitle"
                    @ionInput="dirty = true"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <QuillEditor
                    class="editor"
                    theme="bubble"
                    v-model:content="chapter.html"
                    contentType="html"
                    @textChange="dirty = true"
                    style="border: 1px solid var(--ion-color-primary); background: #ffffff11; flex: 1 1 auto; width: 100%; height: 200px"
                  ></QuillEditor>
                </ion-item>
              </div>
            </ion-item>
          </ion-accordion>
        </ion-accordion-group>
      </div>
    </ion-content>
    <ion-footer>
      <div class="main buttons">
        <ion-button @click="save" :disabled="!dirty">{{ t("save") }}</ion-button>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import { computed, ref, watch } from "@vue/runtime-core";
import { QuillEditor } from "@vueup/vue-quill";
import Player from "@vimeo/player";

import "@vueup/vue-quill/dist/vue-quill.bubble.css";
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { loadingController, onIonViewWillEnter } from "@ionic/vue";
import { useI18n } from "vue-i18n";

export default {
  components: { QuillEditor },
  setup() {
    const organisationStore = useOrganisationStore();
    const { videos } = storeToRefs(organisationStore);
    const { t } = useI18n();

    const dirty = ref();
    const route = useRoute();
    const router = useRouter();
    const organisationID = route.params.organisationID;
    const videoID = route.params.videoID;

    const video = computed(() => videos.value && videos.value[videoID]);
    const vimeoURL = ref();
    const loaded = ref(false);

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID);
      setup(false);
    });

    const setup = (wait = true) => {
      setTimeout(
        () => {
          loadVideo(video.value?.vimeoID, video.value?.hash);
        },
        wait ? 0 : 500
      );
    };

    watch(videos, setup);

    const save = async () => {
      organisationStore.saveRecord(`organisations/${organisationID}/videos/`, videoID, video.value);
      router.back();
    };

    const loadVideo = async (vimeoID, hash) => {
      if (!(hash || vimeoID)) return;
      const url = `https://vimeo.com/video/${vimeoID || video.value.videoID}?h=${hash || video.value.hash}`;
      const existingPlayerDiv = document.getElementById("vimeoPlayer");
      existingPlayerDiv?.remove();
      const wrapper = document.getElementById("vimeoWrapper");
      const playerDiv = document.createElement("div");
      playerDiv.id = "vimeoPlayer";
      wrapper.appendChild(playerDiv);
      const player = new Player(playerDiv, {
        url: url,
        loop: false,
        reactive: true,
      });
      loaded.value = true;
      return player;
    };

    const retrieveVideo = async () => {
      const url = new URL(vimeoURL.value);
      const components = url.pathname.substring(1).split("/");
      const _videoID = components[components.length == 2 ? 0 : 2];
      const _hash = components[components.length == 2 ? 1 : 3];
      const player = await loadVideo(_videoID, _hash);
      const title = await player.getVideoTitle();
      const chapters = await player.getChapters();
      for (const chapter of chapters) {
        chapter.html = "";
      }
      const video = videos.value[videoID];
      video.videoID = _videoID;
      video.hash = _hash;
      video.title = title;
      video.chapters = chapters;
    };

    return { video, dirty, vimeoURL, loaded, retrieveVideo, save, t };
  },
};
</script>

<style scoped>
ion-footer {
  display: flex;
  justify-content: center;
  background: var(--ion-color-dark);
  padding: 20px;
}

#articleContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow: auto;
}

ion-item {
  width: 100%;
}
ion-item > div {
  width: 100%;
}

ion-accordion-group {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

ion-accordion > ion-list > ion-item {
  padding-left: 30px;
}

ion-accordion {
  margin-left: 0px;
}

ion-accordion ion-item[slot="header"] {
  --color: var(--ion-color-secondary);
}

ion-accordion.accordion-expanding,
ion-accordion.accordion-expanded {
  width: calc(100% - 16px);
  margin-left: 16px;
}

ion-accordion ion-icon {
  color: var(--ion-color-primary);
}
</style>
