// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./backgrounds/lines-no-logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-font-family: Regular;
  --theme-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});

  --ion-background: var(--ion-color-primary);
  --ion-background-color: transparent;
  --ion-text-color: var(--ion-color-secondary);
  --ion-item-background: none;
  --ion-toolbar-background: none;

  --ion-color-primary: #000;
  --ion-color-primary-rgb: 0, 0, 0;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #000;
  --ion-color-primary-tint: #111;

  --ion-color-secondary: #fff;
  --ion-color-secondary-rgb: 255, 255, 255;
  --ion-color-secondary-contrast: #000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #eee;
  --ion-color-secondary-tint: #fff;

  --ion-color-tertiary: #00f;
  --ion-color-tertiary-rgb: 0, 0, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #00e;
  --ion-color-tertiary-tint: #00f;

  --ion-color-success: #0ea048;
  --ion-color-success-rgb: 14, 160, 72;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0c8d3f;
  --ion-color-success-tint: #26aa5a;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #c6011b;
  --ion-color-danger-rgb: 198, 1, 27;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ae0118;
  --ion-color-danger-tint: #cc1a32;

  --ion-color-dark: #000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000;
  --ion-color-dark-tint: #111;

  --ion-color-menu: #0000fe;
  --ion-color-menu-rgb: 0, 0, 253;
  --ion-color-menu-contrast: #ffffff;
  --ion-color-menu-contrast-rgb: 255, 255, 255;
  --ion-color-menu-shade: #0000ee;
  --ion-color-menu-tint: #0000ff;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f1f1f9;
  --ion-color-light-rgb: 241, 241, 249;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d4d4db;
  --ion-color-light-tint: #f2f2fa;

  --ion-color-step-50: #e8e8f0;
  --ion-color-step-100: #e0e0e7;
  --ion-color-step-150: #d7d7de;
  --ion-color-step-200: #cfcfd5;
  --ion-color-step-250: #c6c6cc;
  --ion-color-step-300: #bdbdc3;
  --ion-color-step-350: #b5b5ba;
  --ion-color-step-400: #acacb1;
  --ion-color-step-450: #a4a4a8;
  --ion-color-step-500: #9b9b9f;
  --ion-color-step-550: #929296;
  --ion-color-step-600: #8a8a8d;
  --ion-color-step-650: #818184;
  --ion-color-step-700: #79797b;
  --ion-color-step-750: #707072;
  --ion-color-step-800: #676769;
  --ion-color-step-850: #5f5f60;
  --ion-color-step-900: #565657;
  --ion-color-step-950: #4e4e4e;

  --default-color-primary: ##000000;
  --default-color-primary-contrast: #ffffff;

  --default-color-secondary: #ffffff;
  --default-color-secondary-contrast: #000000;

  --default-color-tertiary: #0000ff;
  --default-color-tertiary-contrast: #ffffff;

  --default-color-success: #0ea048;
  --default-color-success-contrast: #ffffff;

  --default-color-warning: #ffc409;
  --default-color-warning-contrast: #000000;

  --default-color-danger: #c6011b;
  --default-color-danger-contrast: #ffffff;

  --default-color-dark: #222428;
  --default-color-dark-contrast: #f1f1f9;

  --default-color-medium: #92949c;
  --default-color-medium-contrast: #000000;

  --default-color-light: #f1f1f9;
  --default-color-light-contrast: #000000;
}

/* 
@media (prefers-color-scheme: dark) {
    
  --ion-background: var(--ion-color-light);
  --ion-background-color: transparent;
  --ion-text-color: var(--ion-color-primary);
  --ion-item-background: none;
  --ion-toolbar-background: none;

  --ion-color-black: #111;
  --ion-color-black-rgb: 16, 16, 16;
  --ion-color-black-contrast: #fff;
  --ion-color-black-contrast-rgb: 0, 0, 0;
  --ion-color-black-shade: ##000;
  --ion-color-black-tint: #222;

  --ion-color-primary: #222428;
  --ion-color-primary-rgb: 34, 36, 40;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1e2023;
  --ion-color-primary-tint: #383a3e;

  --ion-color-secondary: #189fec;
  --ion-color-secondary-rgb: 214, 255, 60;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #6395d9;
  --ion-color-secondary-tint: #7fb2f8;

  --ion-color-tertiary: #eeeeee;
  --ion-color-tertiary-rgb: 238, 238, 238;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #d1d1d1;
  --ion-color-tertiary-tint: #f0f0f0;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #d6ff3c;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #444;
  --ion-color-medium-rgb: 64, 64, 64;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #333;
  --ion-color-medium-tint: #555;

  --ion-color-light: #f2f2f2;
  --ion-color-light-rgb: 242, 242, 242;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #ededed;
  --ion-color-light-tint: #ffffff;

  --ion-color-step-50: #2b2f2c;
  --ion-color-step-100: #353a30;
  --ion-color-step-150: #3e4534;
  --ion-color-step-200: #485038;
  --ion-color-step-250: #515b3d;
  --ion-color-step-300: #5b6641;
  --ion-color-step-350: #647145;
  --ion-color-step-400: #6e7c49;
  --ion-color-step-450: #77874d;
  --ion-color-step-500: #819251;
  --ion-color-step-550: #8a9c55;
  --ion-color-step-600: #93a759;
  --ion-color-step-650: #9db25d;
  --ion-color-step-700: #a6bd61;
  --ion-color-step-750: #b0c866;
  --ion-color-step-800: #b9d36a;
  --ion-color-step-850: #c3de6e;
  --ion-color-step-900: #cce972;
  --ion-color-step-950: #d6f476;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-350);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
} */
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
