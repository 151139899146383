import { pSBC } from "@/composables/utilities";

export const updateTheme = (theme) => {
  const root = document.querySelector(":root");
  const colourNames = ["primary", "secondary", "tertiary", "light", "medium", "dark", "success", "warning", "danger"];
  for (const colourName of colourNames) {
    setColour(colourName, theme);
  }
  const textColour = window.getComputedStyle(document.querySelector(":root")).getPropertyValue(`--ion-color-text`);
  const primaryColour = window.getComputedStyle(document.querySelector(":root")).getPropertyValue(`--ion-color-primary`);
  for (let i = 1; i < 20; i = i + 1) {
    root.style.setProperty(`--ion-color-step-${i * 50}`, pSBC(i * 0.05, primaryColour, textColour));
  }
  //root.style.setProperty("--theme-image", theme?.background ? `url('${theme.background}')` : "url('/img/backgrounds/lines-no-logo.svg')");
};

const setColour = (name, theme) => {
  const root = document.querySelector(":root");

  let hex = "#000000";
  let contrastHex = "#ffffff";

  hex = isHex(theme[name]?.colour) ? theme[name].colour : standardColour(theme[name]?.colour || getDefault(name));
  contrastHex = isHex(theme[name]?.contrast)
    ? theme[name].contrast
    : standardColour(theme[name]?.contrast || getDefault(`${name}-contrast`));

  root.style.setProperty(`--ion-color-${name}`, hex);
  root.style.setProperty(`--ion-color-${name}-rgb`, pSBC(0, hex, "c"));
  root.style.setProperty(`--ion-color-${name}-contrast`, contrastHex);
  root.style.setProperty(`--ion-color-${name}-contrast-rgb`, pSBC(0, contrastHex, "c"));
  root.style.setProperty(`--ion-color-${name}-tint`, pSBC(0.1, hex));
  root.style.setProperty(`--ion-color-${name}-shade`, pSBC(-0.1, hex));
};

const isHex = (colour) => colour?.[0] == "#" && (colour.length == 4 || colour.length == 7);
const getDefault = (name) => {
  return window.getComputedStyle(document.querySelector(":root")).getPropertyValue(`--default-color-${name}`);
};

const standardColour = (str) => {
  var ctx = document.createElement("canvas").getContext("2d");
  ctx.fillStyle = str;
  return ctx.fillStyle;
};

const hexToRGB = (h, d) => {
  if (!h) h = d;

  let r,
    g,
    b = 0;

  // 3 digits
  if (h.length == 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length == 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  const result = `${parseInt(r, 16)},${parseInt(g, 16)},${parseInt(b, 16)}`;
  return result;
};
