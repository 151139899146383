<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="location">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="location.name" :subtitle="t('edit_details')" edit="true" />

        <div class="fullwidth scroll">
          <ion-item class="fullwidth">
            <ion-input
              :label="t('installer_name')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('name')"
              v-model="location.defaultInstallerName"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-label
              ><div class="cl">{{ t("install_date") }}</div></ion-label
            ><ion-datetime-button datetime="installDateTime" />
            <ion-modal :keep-contents-mounted="true"
              ><ion-datetime
                id="installDateTime"
                v-model="location.defaultInstallDate"
                presentation="date"
                max="2050"
                @ionChange="markDirty"
            /></ion-modal>
          </ion-item>
          <ion-item>
            <ion-label
              ><div class="cl">{{ t("warranty_expiration_date") }}</div></ion-label
            ><ion-datetime-button datetime="warrantyExpirationDateTime" />
            <ion-modal :keep-contents-mounted="true"
              ><ion-datetime
                id="warrantyExpirationDateTime"
                v-model="location.defaultWarrantyExpirationDate"
                presentation="date"
                max="2050"
                @ionChange="markDirty"
            /></ion-modal>
          </ion-item>
        </div>

        <div class="grow"></div>
        <!-- <nav-footer :label="location.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const route = useRoute();
const router = useRouter();

const locationID = route.params.locationID;
const organisationID = route.params.organisationID;

const organisationStore = useOrganisationStore();
const { location } = storeToRefs(organisationStore);
const { t } = useI18n();

const dirty = ref(false);

let backup;

onIonViewWillEnter(() => {
  organisationStore.initialise(organisationID, locationID);
});

const save = async () => {
  organisationStore.saveRecord(`organisations/${organisationID}/locations`, locationID, location.value, ["floors"]);

  const alert = await alertController.create({
    cssClass: "alert",
    header: "Update existing records",
    message: "Do you want to update existing equipment in this building?",
    buttons: [
      {
        text: "Yes, overwrite all existing values",
        handler: () => {
          organisationStore.updateEquipment(location.value, true);
          router.back();
        },
      },
      {
        text: "Update equipment without existing values",
        handler: () => {
          organisationStore.updateEquipment(location.value, false);
          router.back();
        },
      },
      {
        text: "No, just use these defaults for future equipment",
        role: "destructive",
        handler: () => {
          router.back();
        },
      },
    ],
  });
  await alert.present();
};

const markDirty = () => {
  dirty.value = true;
};

const createBackup = () => {
  backup = location.value || {};
};

watch(location, createBackup);
createBackup();

const back = async () => {
  if (dirty.value) {
    const alert = await alertController.create({
      cssClass: "alert",
      header: "Are you sure?",
      message: "You have unsaved changes.",
      buttons: [
        {
          text: "Save & Close",
          handler: () => {
            save();
          },
        },
        { text: "Cancel" },
        {
          text: "Discard Changes",
          role: "destructive",
          handler: () => {
            location.value = backup;
            router.back();
          },
        },
      ],
    });
    await alert.present();
  } else {
    router.back();
  }
};

const go = (name) => {
  router.push({ name: name, params: { locationID } });
};
</script>
