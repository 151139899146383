<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="organisation && space">
      <div class="flex vertical nogap scroll bgd">
        <view-header @back="back" :title="space.name" :subtitle="t('select_decision_tree')" edit="true" />
        <ion-list class="fullwidth p">
          <ion-radio-group v-model="space.decisionTreeID" @ionChange="markDirty">
            <ion-item v-for="tree in organisation?.decisionTrees" :key="tree.id">
              <ion-radio color="secondary" mode="ios" :value="tree.id">{{ tree.title }}</ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
        <div class="grow"></div>
        <!-- <nav-footer :label="space.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController } from "@ionic/vue";
import { chevronForward, checkmarkCircleOutline } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;

    const organisationStore = useOrganisationStore();
    const { organisation, location, floor, space } = storeToRefs(organisationStore);
    const { t } = useI18n();
    const dirty = ref(false);

    let backup;

    onMounted(() => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
    });

    const save = async () => {
      organisationStore.saveRecord(`organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces`, space.value.id, {
        ...space.value,
      });
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const toggleFeature = (feature) => {
      if (space.value.featureIDs.includes(feature.id)) {
        space.value.featureIDs = space.value.featureIDs.filter((fid) => fid != feature.id);
      } else {
        space.value.featureIDs.push(feature.id);
      }
      markDirty();
    };

    const createBackup = () => {
      backup = space.value?.decisionTreeID;
    };
    watch(space, createBackup);
    createBackup();

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                space.value.decisionTreeID = backup;
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      organisation,
      location,
      floor,
      space,
      //methods
      markDirty,
      back,
      toggleFeature,
      t,
      // icons
      chevronForward,
      checkmarkCircleOutline,
    };
  },
};
</script>
