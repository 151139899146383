<template>
  <div class="fullwidth">
    <div class="main fullwidth relative flex p2" style="align-items: flex-start" @click="goBack">
      &lt;&lt; back
      <!--       
      <div class="flex autowidth">
        <ion-icon :icon="chevronBack" color="secondary" size="large" class="ml2" />
      </div> -->
      <div class="titleFont fr">{{ title }}</div>
      <div class="o0">&lt;&lt; back</div>
      <!-- <div>
        <ion-icon :icon="chevronBack" color="secondary" size="large" class="ml2 o0" />
      </div> -->
    </div>

    <!-- <div class="main subtitle fullwidth tc pb" v-if="subtitle">{{ subtitle }}</div> -->
  </div>
</template>
<script>
import { chevronBack } from "ionicons/icons";
import DynamicText from "./DynamicText.vue";

export default {
  props: ["title", "subtitle", "size"],
  emits: ["back"],
  // components: { DynamicText },
  setup(props, { emit }) {
    const goBack = () => {
      emit("back");
    };

    return { goBack, chevronBack };
  },
};
</script>

<style scoped>
.main {
  background-color: var(--ion-color-primary);
  font-family: Bold;
  font-size: 0.8rem;
}

.title {
  font-family: Title;
  font-size: 2em;
  text-transform: uppercase;
  color: var(--ion-color-primary);
}

.subtitle {
  font-family: Title;
  font-size: 1.2em;
  text-transform: uppercase;
  color: var(--ion-color-secondary);
}

.editMode {
  color: var(--ion-color-tertiary);
}

.backButton {
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px red solid;
}
</style>
