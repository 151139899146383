// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.locationName[data-v-cf70710c] {
  font-family: Title;
  font-size: 1.8em;
  line-height: 1em;
  text-transform: uppercase;
  color: var(--ion-color-primary);
}
.locationAddress[data-v-cf70710c] {
  font-family: Title;
  font-size: 1.4em;
  text-transform: uppercase;
  color: var(--ion-color-secondary);
}
.bgOrg[data-v-cf70710c] {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}
.bgOrg img[data-v-cf70710c] {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1) opacity(0.1);
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
