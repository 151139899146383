// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
ion-header[data-v-bf8a435c] {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
.container[data-v-bf8a435c] {
  display: flex;
  align-items: center;
}
.box[data-v-bf8a435c] {
  flex: 1 0 10px;
  display: flex;
  justify-content: center;
}
.box.main[data-v-bf8a435c] {
  flex: 3 0 10px;
}
.box.main[data-v-bf8a435c],
.box.main img[data-v-bf8a435c] {
  height: 30px;
  object-fit: contain;
}
.box:first-child > span[data-v-bf8a435c] {
  margin-right: auto;
}
.box:last-child > div[data-v-bf8a435c] {
  margin-left: auto;
  text-align: right;
}
.languageSelector[data-v-bf8a435c] {
  color: var(--ion-color-dark-contrast);
  font-family: Bold;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
