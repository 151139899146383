import { defineStore, storeToRefs } from "pinia";
import { db } from "@/composables/firebase";
import { collection, doc, onSnapshot, setDoc, writeBatch } from "firebase/firestore";
import { useOrganisationStore } from "@/stores/organisation";
import { useUserStore } from "@/stores/user";
import { objectToArray } from "../composables/utilities";
import { ref } from "vue";
import * as Sentry from "@sentry/vue";

export const useShortcodeStore = defineStore("shortcodeStore", () => {
  const organisationStore = useOrganisationStore();
  const { location } = storeToRefs(organisationStore);
  const userStore = useUserStore();
  const { firebaseUser } = storeToRefs(userStore);
  let loaded = false;

  let unsubscribe;

  const shortcodes = ref({});

  const init = async () => {
    unsubscribe = onSnapshot(collection(db, "shortcodes"), (results) => {
      //Add all new items in collection
      Sentry.addBreadcrumb({ message: `Got ${results.docs?.length} shortcodes` });
      for (const doc of results.docs) {
        shortcodes.value[doc.id] = { id: doc.id, ...shortcodes.value[doc.id], ...doc.data() };
      }

      //Remove any items that have been removed from the collection
      const existingIDs = results.docs.map((item) => item.id);
      for (const id in shortcodes.value) {
        if (!existingIDs.includes(id)) delete shortcodes.value[id];
      }
      loaded = true;
    });
  };

  const shortcodesLoaded = () => {
    const checkShortcodes = (resolve) => {
      if (loaded) {
        resolve();
      } else {
        setTimeout(checkShortcodes.bind(this, resolve), 50);
      }
    };
    return new Promise(checkShortcodes);
  };

  const flush = async () => {
    shortcodes.value = {};
    if (unsubscribe) unsubscribe();
  };

  const updateShortcodes = async (organisationID, allowAnonymous, localAnonymousOnly) => {
    const shortcodesArray = objectToArray(shortcodes.value).filter((s) => s.organisationID == organisationID);
    // IMPORTANT - FOR FUTURE FIX: Limitation of 500 documents per transaction in Firestore
    const batch = writeBatch(db);

    for (const shortcode of shortcodesArray) {
      shortcode.allowAnonymous = allowAnonymous;
      // shortcode.localAnonymousOnly = localAnonymousOnly;
      const saveDoc = doc(db, "shortcodes", shortcode.id);
      batch.set(saveDoc, shortcode);
    }
    batch.commit();
  };

  const getShortcode = (_length = 3) => {
    let text;
    let length = _length;
    let isUnique = false;
    const prefix = location.value?.prefix;
    if (!prefix) length = 6;
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    while (!isUnique) {
      text = prefix;
      for (let i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
      isUnique = !objectToArray(shortcodes.value).includes(text);
    }

    return text;
  };

  return {
    // state:
    shortcodes,
    // actions:
    shortcodesLoaded,
    init,
    getShortcode,
    flush,
    updateShortcodes,
  };
});
