// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
span.pnlm-pointer {
  width: 200px !important;
  margin-left: -98px !important;
  margin-top: -45px !important;
  font-family: Regular;
}
#galleryIcon {
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin: 20px;
  z-index: 1000;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
