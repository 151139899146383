<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="floor?.name" edit="true" />

        <div class="fullwidth ph pt scroll">
          <div class="action" @click="go('EditFloorDetails')">
            <ion-label class="fullwidth keepOnTop cl">{{ t("details") }}</ion-label
            ><ion-icon color="secondary" :icon="chevronForward" />
          </div>
          <div class="action" @click="go('EditFloorSpaces')">
            <ion-label class="fullwidth keepOnTop cl">{{ t("spaces") }}</ion-label
            ><ion-icon color="secondary" :icon="chevronForward" />
          </div>
        </div>

        <div class="grow"></div>
        <div class="fullwidth">
          <ion-button expand="block" color="danger" class="m2" @click="confirmDelete">{{ t("delete_floor") }}</ion-button>
        </div>
        <!-- <nav-footer label="Floor list" @back="cancel" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    let organisationID = route.params.organisationID;
    let locationID = route.params.locationID;
    let floorID = route.params.floorID;
    let organisationStore = useOrganisationStore();
    const { organisation, location, floor } = storeToRefs(organisationStore);
    const { t } = useI18n();

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID, floorID);
    });

    const go = (name) => {
      router.push({ name: name, params: { organisationID, locationID, floorID } });
    };

    const back = () => {
      router.back();
    };

    const confirmDelete = async () => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "Deleting this floor is final and cannot be undone.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              organisationStore.deleteFloor(organisationID, locationID, floorID);
              router.back();
            },
          },
          { text: "Cancel" },
        ],
      });
      await alert.present();
    };

    return {
      // variables
      organisation,
      location,
      floor,
      //methods
      go,
      confirmDelete,
      back,
      t,
      // icons
      chevronForward,
    };
  },
};
</script>
