<template>
  <ion-page>
    <av-header />

    <ion-content :scroll-y="false" v-if="organisation?.theme">
      <div class="flex vertical nogap bgd" slot="fixed">
        <view-header @back="back" :title="t('theme')" :subtitle="t('set_corporate_palette')" class="fullwidth" edit="true" />
        <div class="grid2 p fullwidth" style="overflow-x: hidden">
          <div class="card" style="border: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.5)" v-for="(colour, i) in colours" :key="i">
            <div class="tc boldFont swatch mb">
              <div
                class="pv"
                :style="{ backgroundColor: `var(--ion-color-${colour}-shade)`, color: `var(--ion-color-${colour}-shade)`, width: '30px' }"
              >
                .
              </div>
              <div class="grow pv" :style="{ backgroundColor: `var(--ion-color-${colour})`, color: `var(--ion-color-${colour}-contrast)` }">
                {{ colour }}
              </div>
              <div
                class="pv"
                :style="{ backgroundColor: `var(--ion-color-${colour}-tint)`, color: `var(--ion-color-${colour}-tint)`, width: '30px' }"
              >
                .
              </div>
            </div>
            <div class="flex">
              <span class="tooltip">
                <ion-icon :icon="backspace" color="secondary" size="large" @click="setDefaultColor(colour)" />
                <span class="tooltipText">{{ t("revert_to_default_colour") }}</span>
              </span>
              <div class="grow"></div>
              <color-picker v-model:pureColor="organisation.theme[colour].colour" @update:pureColor="markDirty"></color-picker>
              <color-picker v-model:pureColor="organisation.theme[colour].contrast" @update:pureColor="markDirty"></color-picker>
            </div>
          </div>
          <div class="card">
            <div class="title mb">{{ t("gradient") }}</div>
            <div>
              <div class="flex nogap">
                <div
                  v-for="i in 19"
                  :key="i"
                  class="grow"
                  style="width: 1px; height: 50px"
                  :style="{ background: `var(--ion-color-step-${i * 50})` }"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="grow"></div>
        <!-- <nav-footer :label="organisation.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { onMounted, ref, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward, backspace } from "ionicons/icons";
import { alertController } from "@ionic/vue";
import { useOrganisationStore } from "@/stores/organisation";
import { updateTheme } from "@/composables/theme";
import { ColorPicker } from "vue3-colorpicker";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import "vue3-colorpicker/style.css";

export default {
  name: "Description",
  components: { ColorPicker },
  setup() {
    const dirty = ref(false);
    const router = useRouter();
    const organisationID = useRoute().params.organisationID;
    const organisationStore = useOrganisationStore();
    const { organisation } = storeToRefs(organisationStore);
    const { t } = useI18n();
    let backup;

    const swatches = [];

    const colours = ["primary", "secondary", "tertiary", "light", "medium", "dark", "success", "warning", "danger"];

    const setColor = (colour) => {
      if (!organisation.value.theme[colour]) organisation.value.theme[colour] = {};
      if (!organisation.value.theme[colour].colour) organisation.value.theme[colour].colour = getDefault(colour);
      if (!organisation.value.theme[colour].contrast) organisation.value.theme[colour].contrast = getDefault(`${colour}-contrast`);
    };

    const getDefault = (name) => window.getComputedStyle(document.querySelector(":root")).getPropertyValue(`--default-color-${name}`);

    onMounted(() => organisationStore.initialise(organisationID));

    const createBackup = () => {
      if (!organisation.value?.theme) return;
      backup = JSON.parse(JSON.stringify(organisation.value.theme));
      for (const colour of colours) setColor(colour);
    };

    createBackup();
    watch(organisation, createBackup);

    const setDefaultColor = (colour) => {
      const defaultColour = getDefault(`${colour}`);
      const defaultContrast = getDefault(`${colour}-contrast`);
      organisation.value.theme[colour] = {
        colour: defaultColour,
        contrast: defaultContrast,
      };
      updateTheme(organisation.value.theme);
      markDirty();
    };

    const save = async () => {
      organisationStore.saveRecord("organisations", organisationID, organisation.value, [
        "decisionTrees",
        "locations",
        "articles",
        "videos",
        "issues",
        "loaded",
        "imageLoaded",
      ]);
      router.back();
    };

    const markDirty = () => {
      updateTheme(organisation.value.theme);
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                organisation.value.theme = { ...backup };
                updateTheme(organisation.value.theme);
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      organisation,
      swatches,
      colours,
      // methods
      markDirty,
      back,
      setDefaultColor,
      t,
      // icons
      chevronForward,
      backspace,
    };
  },
};
</script>

<style scoped>
.swatch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 5px;
}

.capitals {
  text-transform: capitalize;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltipText {
  visibility: hidden;
  width: 200px;
  background-color: var(--ion-color-light);
  border: 1px solid var(--ion-color-dark);
  color: var(--ion-color-primary);
  text-align: center;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: 2px 2px 5px var(--ion-color-dark);
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -3px;
  left: calc(100% + 3px);
}

.tooltip .tooltipText::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--ion-color-dark) transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltipText {
  visibility: visible;
}
</style>
