// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.information[data-v-07f5af35] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.1);
}
.information.documents[data-v-07f5af35] {
  font-family: Title;
}
.block[data-v-07f5af35] {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 90vh;
  background: var(--ion-color-dark);
}
ion-modal[data-v-07f5af35] {
  --height: auto;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
