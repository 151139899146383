<template>
  <ion-page>
    <page-header title="Terms &amp; Conditions" />
    <ion-content scroll-y="false">
      <iframe :src="source" class="fullwidth fullheight" frameBorder="0" />
    </ion-content>
  </ion-page>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  setup() {
    const source = computed(() => `${process.env.VUE_APP_DOMAIN}/static/legal-notices.html`);

    return { source };
  },
};
</script>
