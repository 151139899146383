<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="model">
      <div class="flex vertical nogap ph">
        <view-header @back="back" :subtitle="t('edit_model')" :title="model?.name" edit="true" />

        <div class="fullwidth">
          <ion-item>
            <ion-input label="Name" label-placement="stacked" @change="markDirty" v-model="model.name"></ion-input>
          </ion-item>
        </div>

        <div class="fullwidth ph pt scroll">
          <input type="file" @change="addDocument" ref="documentSelector" multiple hidden accept=".pdf" />
          <input type="file" @change="addPhoto" ref="photoSelector" hidden accept="image/*" />
          <ion-list class="fullwidth">
            <ion-item v-for="document in model.documents" :key="document.id">
              <ion-icon :icon="documentOutline" color="primary" slot="start" />
              <div class="flex vertical nogap left">
                <ion-input v-model="document.description" placeholder="Description" @ionInput="markDirty()"></ion-input>
                <ion-note class="fullwidth mb" @click="selectDocument(document)" color="secondary" v-if="documentTypes">{{
                  getTypeName(document.documentTypeID)
                }}</ion-note>
              </div>
              <ion-icon :icon="trashOutline" slot="end" color="danger" @click="deleteDocument(document)" />
            </ion-item>
          </ion-list>
          <img :src="model.photo.url" v-if="model.photo" style="height: 200px" />
          <ion-button @click="triggerAddDocument" class="fullwidth" color="primary">Add Document</ion-button>
          <ion-button @click="triggerSelectPhoto" class="fullwidth" color="primary" v-if="!model.photo">Add Photo</ion-button>
          <ion-button @click="deletePhoto" class="fullwidth" color="primary" v-else>Delete Photo</ion-button>
        </div>
        <div class="grow"></div>
        <div class="fullwidth">
          <ion-button class="fullwidth danger mb2" @click="confirmDelete">{{ t("delete_model") }}</ion-button>
        </div>
      </div>
    </ion-content>
    <ion-modal :is-open="!!selectedDocument" :can-dismiss="true" @didDismiss="selectedDocument = null" :presenting-element="rootElement">
      <ion-header>
        <ion-toolbar>
          <ion-title>Select Document Type</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list>
          <ion-item v-for="item in documentTypes" :key="item.id" @click="setDocumentType(item)">
            <ion-label>{{ item.name }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController } from "@ionic/vue";
import { chevronForward, documentOutline, trashOutline } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const modelID = route.params.modelID;
    const organisationStore = useOrganisationStore();
    const { models, documentTypes } = storeToRefs(organisationStore);
    const { t } = useI18n();
    const documentSelector = ref();
    const dirty = ref(false);
    const selectedDocument = ref();
    const rootElement = ref();
    const photoSelector = ref();

    onMounted(() => {
      rootElement.value = document.getElementById("main-content");
    });

    const markDirty = () => {
      dirty.value = true;
    };

    onMounted(() => {
      organisationStore.initialise();
    });

    const triggerSelectPhoto = () => {
      photoSelector.value.click();
    };

    const selectDocument = (document) => {
      selectedDocument.value = document;
    };

    const setDocumentType = (type) => {
      selectedDocument.value.documentTypeID = type.id;
      save();
      selectedDocument.value = null;
      markDirty();
    };

    const model = computed(() => models.value[modelID]);

    const go = (name) => {
      router.push({ name: name, params: { modelID } });
    };

    const getTypeName = (id) => {
      return documentTypes.value[id]?.name || "Select document type";
    };

    const addDocument = async () => {
      const files = Array.from(event.target.files);
      files.forEach(async (file) => {
        const newDocument = await organisationStore.addModelDocument(file, model.value);
        documentSelector.value.value = "";
      });
    };

    const addPhoto = async () => {
      const files = Array.from(event.target.files);
      if (files.length == 0) {
        photoSelector.value.value = "";
        return;
      }

      await organisationStore.addModelPhoto(model, files[0]);
      photoSelector.value.value = "";
    };

    const save = async () => {
      organisationStore.saveRecord(`models`, modelID, model.value);
    };

    const deletePhoto = async () => {
      await organisationStore.deleteModelPhoto(model);
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
                router.back();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const confirmDelete = async () => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "Deleting this organisation is final and cannot be undone.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              organisationStore.deleteModel(modelID);
              router.back();
            },
          },
          { text: "Cancel" },
        ],
      });
      await alert.present();
    };

    const triggerAddDocument = () => {
      documentSelector.value.click();
    };

    return {
      // variables
      model,
      documentSelector,
      selectedDocument,
      rootElement,
      documentTypes,
      photoSelector,
      //methods
      go,
      confirmDelete,
      triggerAddDocument,
      triggerSelectPhoto,
      addPhoto,
      markDirty,
      back,
      getTypeName,
      addDocument,
      selectDocument,
      setDocumentType,
      deletePhoto,
      t,
      // icons
      chevronForward,
      documentOutline,
      trashOutline,
    };
  },
};
</script>
