// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#swiper-pagination {
  position: absolute;
  right: 10px;
  width: 2rem;
  border: 1px solid var(--ion-color-light);
  border-radius: 1rem;
  background: var(--ion-color-secondary-contrast);
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
}
.swiper-pagination-bullet {
  background-color: var(--ion-color-secondary);
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  opacity: 0.5;
}
.swiper-pagination-bullet-active {
  opacity: 1;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
