// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#modalContainer[data-v-18927499] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
#modalContainer ion-item[data-v-18927499] {
  width: 100%;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
