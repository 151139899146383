<template>
  <ion-page>
    <av-header />
    <ion-content v-if="organisations">
      <div class="flex vertical left bgd" slot="fixed">
        <view-header @back="back" :title="$t('recently_viewed')" :subtitle="`${sortedSpaces?.length || 'No'} spaces`" class="fullwidth" />
        <div v-if="profile" style="height: 100%" class="scroll fullwidth">
          <div class="fullwidth ph2" style="height: 100%">
            <div class="card mb flex" style="gap: 10px" v-for="space in sortedSpaces" :key="space.spaceID" @click="openSpace(space)">
              <img :src="space.photoURL" class="photo" style="width: 50%; max-width: 100px" />
              <div class="flex vertical">
                <div class="titleFont fl tightlines fullwidth cl">{{ space.spaceName }}</div>
                <div class="fullwidth tightlines cl">{{ space.organisationName }}</div>
                <div class="fullwidth tightlines cl">{{ space.locationName }}</div>
                <div class="fullwidth tightlines cl fs">{{ space.floorName }}</div>
              </div>
            </div>
            <div class="fullwidth pb2">
              <ion-button
                expand="block"
                class="bright mv2"
                @click="back()"
                v-if="profile.recentSpaces && profile.recentSpaces.length > 0"
                >{{ t("find_another_space") }}</ion-button
              >
              <ion-button expand="block" class="bright m2" @click="back()" v-else>Find your first space</ion-button>
            </div>
          </div>
        </div>

        <!-- <nav-footer label="Home" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useOrganisationStore } from "@/stores/organisation";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const userStore = useUserStore();
    const organisationStore = useOrganisationStore();
    const { organisations } = storeToRefs(organisationStore);
    const { profile } = storeToRefs(userStore);
    const router = useRouter();
    const { t } = useI18n();

    onMounted(() => {
      organisationStore.initialise();
    });

    const sortedSpaces = computed(() =>
      profile.value?.recentSpaces
        ?.slice()
        .sort(function (a, b) {
          return b.date.seconds - a.date.seconds;
        })
        .slice(0, 5)
    );

    const openSpace = (space) => {
      router.push({
        name: "Space",
        params: { organisationID: space.organisationID, locationID: space.locationID, floorID: space.floorID, spaceID: space.spaceID },
      });
    };

    const back = () => {
      router.push({ name: "Home" });
    };

    return {
      profile,
      organisations,
      sortedSpaces,
      openSpace,
      back,
      t,
    };
  },
};
</script>
