<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="organisation && space">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="space.name" :subtitle="$t('edit_space_features')" edit="true" />

        <div class="fullwidth ph pt scroll">
          <ion-chip
            v-for="feature in organisation.spaceFeatures"
            :key="feature.id"
            @click="toggleFeature(feature)"
            :color="space.featureIDs.includes(feature.id) ? 'success' : 'warning'"
            class="bdl"
          >
            <ion-icon color="secondary" :icon="checkmarkCircleOutline" v-if="space.featureIDs.includes(feature.id)" />
            <ion-label color="secondary">{{ feature.name }}</ion-label>
          </ion-chip>
        </div>

        <div class="grow"></div>
        <!-- <nav-footer :label="space.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController } from "@ionic/vue";
import { chevronForward, checkmarkCircleOutline } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;

    const organisationStore = useOrganisationStore();
    const { organisation, location, floor, space } = storeToRefs(organisationStore);

    const dirty = ref(false);

    let backup;

    onMounted(() => {
      organisationStore.initialise(organisationID, locationID, floorID, spaceID);
    });

    const save = async () => {
      organisationStore.saveRecord(`organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces`, space.value.id, {
        ...space.value,
      });
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const toggleFeature = (feature) => {
      if (space.value.featureIDs.includes(feature.id)) {
        space.value.featureIDs = space.value.featureIDs.filter((fid) => fid != feature.id);
      } else {
        space.value.featureIDs.push(feature.id);
      }
      markDirty();
    };

    const createBackup = () => {
      backup = (space.value && [...space.value?.featureIDs]) || {};
    };
    watch(space, createBackup);
    createBackup();

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                space.value.featureIDs = backup;
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      organisation,
      location,
      floor,
      space,
      //methods
      markDirty,
      back,
      toggleFeature,
      // icons
      chevronForward,
      checkmarkCircleOutline,
    };
  },
};
</script>
