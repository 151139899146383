<template>
  <ion-page>
    <ion-content>
      <div ref="viewer"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute } from "vue-router";
import { getDownloadURL, ref as fbRef } from "firebase/storage";
import { ref } from "@vue/reactivity";
import WebViewer from "@pdftron/pdfjs-express-viewer";
import { onMounted } from "@vue/runtime-core";
import { useOrganisationStore } from "@/stores/organisation";
import { storage } from "@/composables/firebase";

export default {
  setup() {
    const route = useRoute();
    const path = route.params.path;
    const viewer = ref();
    const organisationStore = useOrganisationStore();
    const url = ref();

    const setupPDF = async () => {
      await organisationStore.userLoaded();
      url.value = await getDownloadURL(fbRef(storage, path));
      console.log(url.value);
      WebViewer(
        {
          path: "/pdf",
          licenseKey: "KEuYi5KCJeZ4CGCZwCw1",
          initialDoc: url.value,
        },
        viewer.value
      ).catch((e) => {
        console.log("ERROR", e);
      });
    };

    onMounted(() => {
      setupPDF();
    });

    return { viewer };
  },
};
</script>
