<template>
  <ion-page>
    <av-header />
    <ion-content :scroll-y="false" v-if="location">
      <div class="flex vertical nogap bgd">
        <view-header @back="back" :title="location.name" subtitle="Address" edit="true" />

        <div class="fullwidth scroll">
          <ion-item class="fullwidth" v-if="location.address">
            <ion-input
              :label="t('address')"
              label-placement="stacked"
              color="secondary"
              :placeholder="t('line_1')"
              v-model="location.address.line1"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
            <ion-input color="secondary" :placeholder="t('line_2')" v-model="location.address.line2" @ionInput="markDirty()"></ion-input>
            <ion-input color="secondary" :placeholder="t('line_3')" v-model="location.address.line3" @ionInput="markDirty()"></ion-input>
            <ion-input
              color="secondary"
              :placeholder="t('town')"
              v-model="location.address.city"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
            <ion-input
              color="secondary"
              :placeholder="t('postcode')"
              v-model="location.address.postcode"
              @ionInput="markDirty()"
              required="true"
            ></ion-input>
          </ion-item>
        </div>

        <div class="grow"></div>
        <!-- <nav-footer :label="location.name" @back="back" /> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useOrganisationStore } from "@/stores/organisation";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const locationID = route.params.locationID;
    const organisationID = route.params.organisationID;

    const organisationStore = useOrganisationStore();
    const { organisation, location } = storeToRefs(organisationStore);
    const { t } = useI18n();

    const dirty = ref(false);

    onIonViewWillEnter(() => {
      organisationStore.initialise(organisationID, locationID);
    });

    const save = async () => {
      organisationStore.saveRecord(`organisations/${organisationID}/locations`, locationID, location.value, ["floors"]);
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const go = (name) => {
      router.push({ name: name, params: { locationID } });
    };

    return {
      // variables
      organisation,
      location,
      //methods
      go,
      markDirty,
      back,
      t,
      // icons
      chevronForward,
    };
  },
};
</script>
