import { defineStore } from "pinia";
import { functions, db, auth, storage } from "@/composables/firebase";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  applyActionCode,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";
import { useOrganisationStore } from "./organisation";
import { doc, getDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { computed, reactive, watch } from "vue";
import { httpsCallable } from "firebase/functions";
import { useBeaconStore } from "./beacons";
import { useShortcodeStore } from "./shortcode";
import * as Sentry from "@sentry/vue";
import { getDownloadURL, ref } from "firebase/storage";

export const useUserStore = defineStore("userStore", () => {
  const organisationStore = useOrganisationStore();
  const beaconStore = useBeaconStore();
  const shortcodeStore = useShortcodeStore();

  let unsubscriptions = {};

  const data = reactive({
    firebaseUser: {},
    user: {},
    profile: {},
    userLoaded: false,
    profileLoaded: false,
  });

  const user = computed(() => data.user);
  const profile = computed(() => data.profile);
  const firebaseUser = computed(() => data.firebaseUser);
  const anonymous = computed(() => data.firebaseUser?.isAnonymous);
  const currentOrganisationID = computed(() => data.profile.currentOrganisationID);

  const subscribe = (tableName, id, fieldName, callback) => {
    if (unsubscriptions[tableName]) {
      unsubscriptions[tableName];
    }
    const docRef = doc(db, tableName, id);
    const unsubscribe = onSnapshot(docRef, (result) => {
      data[fieldName] = result.data();
      if (callback) callback(data[fieldName]);
    });
    unsubscriptions[tableName] = unsubscribe;
  };

  const flush = async () => {
    data.user = {};
    data.profile = {};
    for (const table in unsubscriptions) {
      unsubscriptions[table]();
    }
    unsubscriptions = {};
  };

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.log("Error", error);
      let errors;
      switch (error.code) {
        case "auth/missing-email":
          errors = { email: "Enter your email address", password: "" };
          break;
        case "auth/invalid-email":
          errors = { email: "Invalid email format", password: "" };
          break;
        case "auth/internal-error":
          errors = { email: "", password: "Invalid password" };
          break;
        case "auth/wrong-password":
          errors = { email: "Incorrect email/password", password: "" };
          break;
        default:
          errors = { email: "Error logging in", password: "" };
          break;
      }
      throw errors;
    }
  };

  const logout = async () => {
    flush();
    shortcodeStore.flush();
    beaconStore.flush();
    organisationStore.flush();
    await auth.signOut();
  };

  watch(profile, () => {
    if (!profile.value) return;
    if (!profile.value.recentSpaces) profile.value.recentSpaces = [];
    if (!profile.value.favouriteSpaces) profile.value.favouriteSpaces = [];
    profile.value.recentSpaces?.forEach(async (recentSpace) => {
      const space = await getDoc(
        doc(
          db,
          `organisations/${recentSpace.organisationID}/locations/${recentSpace.locationID}/floors/${recentSpace.floorID}/spaces`,
          recentSpace.spaceID
        )
      );
      const spaceData = await space.data();
      if (spaceData) {
        if (spaceData.featurePhotoPath == "/img/placeholders/transparent.png") {
          recentSpace.photoURL = "/img/placeholders/transparent.png";
        } else {
          recentSpace.photoURL = await getDownloadURL(ref(storage, spaceData.featurePhotoPath));
        }
      }
    });
    profile.value.favouriteSpaces?.forEach(async (favouriteSpace) => {
      const space = await getDoc(
        doc(
          db,
          `organisations/${favouriteSpace.organisationID}/locations/${favouriteSpace.locationID}/floors/${favouriteSpace.floorID}/spaces`,
          favouriteSpace.spaceID
        )
      );
      const organisation = await getDoc(doc(db, `organisations`, favouriteSpace.organisationID));
      const location = await getDoc(doc(db, `organisations/${favouriteSpace.organisationID}/locations`, favouriteSpace.locationID));
      const floor = await getDoc(
        doc(db, `organisations/${favouriteSpace.organisationID}/locations/${favouriteSpace.locationID}/floors`, favouriteSpace.floorID)
      );
      const organisationData = await organisation.data();
      const locationData = await location.data();
      const floorData = await floor.data();
      const spaceData = await space.data();
      favouriteSpace.name = spaceData.name;
      favouriteSpace.organisationName = organisationData.name;
      favouriteSpace.locationName = locationData.name;
      favouriteSpace.floorName = floorData.name;
      if (spaceData.featurePhotoPath == "/img/placeholders/transparent.png") {
        favouriteSpace.photoURL = "/img/placeholders/transparent.png";
      } else {
        favouriteSpace.photoURL = await getDownloadURL(ref(storage, spaceData.featurePhotoPath));
      }
    });
  });

  const sendMagicLink = async (email) => {
    const fn = httpsCallable(functions, "magicLink", { timeout: 540000 });
    try {
      await fn({ login_email: email });
    } catch (error) {
      console.log("Could not send magic link", error, email);
    }
    window.localStorage.setItem("emailForSignIn", email);
  };

  const sendVerificationEmail = async () => {
    const profileDoc = doc(db, "profiles", data.firebaseUser?.email);
    const fn = httpsCallable(functions, "validateEmail", { timeout: 540000 });
    try {
      fn({ validate_email: data.firebaseUser?.email });
    } catch (error) {
      console.log("Couldn't send validation email", error);
    }
  };

  const getCurrentUser = () => {
    return new Promise((resolve) => {
      const auth = getAuth();
      if (auth?.currentUser) {
        resolve(auth?.currentUser);
        return;
      }
      auth.onAuthStateChanged((user) => {
        resolve(user);
      });
    });
  };

  const updateUser = async () => {
    data.firebaseUser = await getCurrentUser();
    shortcodeStore.init();
    if (!data.firebaseUser || data.firebaseUser?.isAnonymous) return;
    await data.firebaseUser.reload();

    subscribe("users", data.firebaseUser?.email, "user", async () => {
      if (!data.user) {
        const domain = data.firebaseUser?.email?.replace(/.*@/, "");
        const userDomainDoc = await getDoc(doc(db, "userDomains", domain));
        if (userDomainDoc.data()) {
          const userOrganisationID = userDomainDoc?.data().organisationID;
          setDoc(doc(db, "users", data.firebaseUser?.email), {
            id: data.firebaseUser?.email,
            currentOrganisationID: userOrganisationID,
            organisations: [userOrganisationID],
            roles: [],
          });
        }
      } else {
        data.userLoaded = true;
      }
    });

    subscribe("profiles", data.firebaseUser?.email, "profile", () => {
      if (!data.profile) {
        setDoc(doc(db, "profiles", data.firebaseUser?.email), {
          firstname: "",
          lastname: "",
          id: data.firebaseUser?.email,
          phone: "",
          organisations: [],
          roles: [],
        });
        organisationStore.selectedOrganisationID = data.profile?.currentOrganisationID;
      } else {
        data.profileLoaded = true;
      }
    });
  };

  const deleteAccount = async () => {
    const fn = httpsCallable(functions, "deleteAccount", { timeout: 540000 });
    try {
      await fn({ id: data.firebaseUser.email });
    } catch (error) {
      console.log("Could not delete account", error);
    }
  };

  const setCurrentOrganisationID = (id) => {
    if (!id) return;
    const profileDoc = doc(db, "profiles", data.firebaseUser?.email);
    updateDoc(profileDoc, { currentOrganisationID: id });
  };

  const actionCode = async (actionCode) => {
    return applyActionCode(auth, actionCode);
  };

  const verifyResetCode = async (actionCode) => {
    return await verifyPasswordResetCode(auth, actionCode);
  };

  const confirmReset = async (actionCode, newPassword) => {
    return await confirmPasswordReset(auth, actionCode, newPassword);
  };

  const createUser = async (email, password) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      window.localStorage.setItem("emailForSignIn", email);
      login(email, password);
      sendVerificationEmail();
    } catch (error) {
      let errors;
      switch (error.code) {
        case "auth/missing-email":
          errors = { email: "Enter your email address", password: "" };
          break;
        case "auth/invalid-email":
          errors = { email: "Invalid email format", password: "" };
          break;
        case "auth/internal-error":
          errors = { email: "", password: "Invalid password" };
          break;
        case "auth/email-already-in-use":
          errors = { email: "Email already in use", password: "" };
          break;
        default:
          errors = { email: "Error registering", password: "" };
          break;
      }
      throw errors;
    }
  };

  const saveProfile = async () => {
    if (!data.firebaseUser?.email) return;
    if (!data.currentSpaceID) data.currentSpaceID = "";
    Sentry.addBreadcrumb({ message: "Saving profile", data: data.firebaseUser?.email });
    const profileDoc = doc(db, "profiles", data.firebaseUser?.email);
    updateDoc(profileDoc, data.profile);
  };

  const saveUser = async () => {
    const userDoc = doc(db, "users", data.user.id);
    updateDoc(userDoc, data.user);
  };

  const toggleTechnician = () => {
    if (!data.profile?.enableTechnicianMode && data.user?.roles?.includes("technician"))
      data.user.roles = data.user.roles.filter((r) => r != "technician");
    if (data.profile?.enableTechnicianMode && !data.user?.roles?.includes("technician")) data.user.roles.push("technician");
    saveUser();
    saveProfile();
  };

  return {
    // state:
    data,
    user,
    profile,
    firebaseUser,
    anonymous,
    currentOrganisationID,
    // actions:
    login,
    logout,
    updateUser,
    sendMagicLink,
    sendVerificationEmail,
    actionCode,
    confirmReset,
    verifyResetCode,
    setCurrentOrganisationID,
    createUser,
    saveProfile,
    getCurrentUser,
    flush,
    deleteAccount,
    toggleTechnician,
    saveUser,
  };
});
