import { createI18n } from "vue-i18n";

const i18n = createI18n({
  legacy: false,
  locale: "en",

  messages: {
    en: {
      reports: "Reports",
      support: "Support",
      select_checklist: "Select checklist",
      checklist: "Checklist",
      checklists: "Checklists",
      close: "Close",
      sos_details: "SOS Details",
      sos_email: "SOS email",
      custom_sos_label: "Custom SOS label",
      custom_sos_label_active: "Custom active SOS label",
      people: "People",
      wifi_network: "WiFi network",
      wifi_password: "WiFi password",
      edit_space: "Edit space",
      user_guides: "Step-by-step guides",
      equipment: "Equipment",
      documents: "Documents",
      known_issues: "Known issues",
      report_issue: "Report issue",
      give_feedback: "Give feedback",
      occupied: "Occupied",
      vacant: "Vacant",
      enter_a_shortcode: "Enter a shortcode",
      shortcode: "shortcode",
      or_touch_here_if_you_have_a_qr_code: "Or touch here if you have a QR code",
      recently_viewed: "Recently viewed",
      favourite_spaces: "Favourite spaces",
      find_a_space: "Find a space",
      organisations: "Organisations",
      asset_types: "Asset types",
      manufacturers: "Manufacturers",
      manufacturer: "Manufacturer",
      models: "Models",
      my_details: "My details",
      settings: "Settings",
      change_organisation: "Change organisation",
      terms_and_conditions: "Terms and conditions",
      about_alpha_victor: "About Alpha Victor",
      contact_us: "Contact us",
      logout: "Logout",
      login: "Login",
      name: "Name",
      last_name: "Last name",
      email_address: "Email address",
      phone_number: "Phone number",
      account: "Account",
      delete_account: "Delete account",
      permissions: "Permissions",
      location: "Location",
      camera: "Camera",
      select_organisation_to_edit: "Select organisation to edit",
      add_organisation: "Add organisation",
      edit_organisation: "Edit organisation",
      delete_organisation: "Delete organisation",
      details: "Details",
      address: "Address",
      theme: "Theme",
      space_types: "Space types",
      space_features: "Space features",
      document_types: "Document types",
      logo: "Logo",
      background: "Background",
      decision_trees: "Decision trees",
      decision_tree: "Decision tree",
      locations: "Locations",
      organisation_name: "Organisation name",
      shortcode_prefix: "Shortcode prefix",
      prefix: "Prefix",
      main_contact: "Main contact",
      edit_details: "Edit details",
      edit_address: "Edit address",
      line_1: "Line 1",
      line_2: "Line 2",
      line_3: "Line 3",
      town: "Town",
      county: "County",
      postcode: "Postcode",
      primary: "Primary",
      secondary: "Secondary",
      tertiary: "Tertiary",
      light: "Light",
      medium: "Medium",
      dark: "Dark",
      success: "Success",
      warning: "Warning",
      danger: "Danger",
      gradient: "Gradient",
      revert_to_default_colour: "Revert to default colour",
      set_corporate_palette: "Set corporate palette",
      edit_space_types: "Edit space types",
      enter_new_type_and_press: "Enter new type and press",
      enter_new_feature_and_press: "Enter new feature and press",
      edit_space_features: "Edit space features",
      edit_document_types: "Edit document types",
      change_logo: "Change logo",
      delete: "Delete",
      add_logo: "Add logo",
      change_background: "Change background",
      add_background: "Add background",
      edit_logo: "Edit logo",
      edit_background: "Edit background",
      edit_decision_trees: "Edit decision trees",
      add_decision_tree: "Add decision tree",
      save: "Save",
      cancel: "Cancel",
      outcome: "Outcome",
      outcome_label: "Answer",
      destination: "Destination",
      edit_destination: "Edit destination",
      title: "Title",
      question: "Question",
      article: "Article",
      video: "Video",
      phone: "Phone",
      email: "Email",
      label: "Label",
      search_for_an_article: "Search for an article",
      add_new_article: "Add new article",
      add_new_video: "Add new video",
      video_details: "Video details",
      fetch_video: "Fetch video",
      vimeo_url: "Vimeo URL",
      enter_a_valid_vimeo_url: "Enter a valid Vimeo URL",
      starts_at: "starts at",
      chapter: "Chapter",
      subtitle: "Subtitle",
      search_for_a_video: "Search for a video",
      integrations: "Integrations",
      client_id: "Client ID",
      client_secret: "Client secret",
      ubiqisense_client_id: "Ubiqisense client ID",
      ubiqisense_client_secret: "Ubiqisense client secret",
      select_organisation: "Select organisation",
      ubiqisense_configuration: "Ubiqisense configuration",
      pronestor_configuration: "Pronestor configuration",
      organisation_id: "Organisation ID",
      photos: "Photos",
      ssid: "SSID",
      password: "Password",
      public_wifi: "Public WiFi",
      location_name: "Location name",
      reorder_photos: "Reorder photos",
      add_photo: "Add photo",
      edit_location: "Edit location",
      edit_locations: "Edit locations",
      add_location: "Add location",
      delete_location: "Delete location",
      export: "Export",
      export_qr_photos: "Export QR photos",
      export_files: "Export files",
      select_files_to_export: "Select files to export",
      start_export: "Start export",
      export_qr_codes: "Export QR codes",
      export_teams_backgrounds: "Export Teams backgrounds",
      coming_soon: "Coming soon",
      floors: "Floors",
      add_floor: "Add floor",
      reorder_floors: "Reorder floors",
      spaces: "Spaces",
      delete_floor: "Delete floor",
      floor_name: "Floor name",
      short_name: "Short name",
      are_you_sure: "Are you sure?",
      you_have_unsaved_changes: "You have unsaved changes",
      save_and_close: "Save and close",
      discard_changes: "Discard changes",
      add_space: "Add space",
      reorder_spaces: "Reorder spaces",
      room_type: "Room type",
      capacity: "Capacity",
      typeform_id: "Typeform ID",
      space_name: "Space name",
      select_decision_tree: "Select decision tree",
      devices: "Devices",
      serial_number: "Serial number",
      installer: "Installer",
      installer_name: "Installer name",
      install_date: "Install date",
      warranty_expiration_date: "Warranty expiration date",
      description: "Description",
      add_device: "Add device",
      panorama: "Panorama",
      add_panorama: "Add panorama",
      edit_panorama: "Edit panorama",
      set_starting_view: "Set starting view",
      replace_panorama: "Replace panorama",
      delete_panorama: "Delete panorama",
      edit_hotspot: "Edit hotspot",
      delete_hotspot: "Delete hotspot",
      device: "Device",
      annotation: "Annotation",
      features: "Features",
      edit_devices: "Edit devices",
      edit_documents: "Edit documents",
      edit_photos: "Edit photos",
      add_document: "Add document",
      select_document_type: "Select document type",
      beacon: "Beacon",
      edit_beacon: "Edit beacon",
      major: "Major",
      minor: "Minor",
      stop_scanning: "Stop scanning",
      scan_for_beacons: "Scan for beacons",
      select_location: "Select location",
      location_id: "Location ID",
      qr_code: "QR code",
      delete_space: "Delete space",
      find_another_space: "Find another space",
      all_manufacturers: "All manufacturers",
      all_models: "All models",
      type_new_model_name_and_press: "Type new model name and press",
      point_your_camera_at_the_qr_code: "Point your camera at the QR code",
      favourites: "Favourites",
      find_space: "Find space",
      administration: "Administration",
      options: "Options",
      development_environment: "Development Environment",
      verify_your_email: "Verify your email",
      edit_model: "Edit model",
      delete_model: "Delete model",
      all_types: "All types",
      select_manufacturer: "Select manufacturer",
      select_type: "Select type",
      type_new_manufacturer_and_press: "Type new manufacturer and press",
      type_new_type_name_and_press: "Type new type name and press",
      person: "person",
      room_equipment: "Room equipment",
      ive_found_the_following_video: "I've found the following video that I think you'll find useful",
      ive_found_the_following_article: " I've found the following article that I think you'll find useful",
      place_a_call: "I think it's best you speak to someone - click below to place a call",
      send_an_email: "We'd really appreciate some more details - click below to send us an email",
      issues: "Issues",
      open_issues: "open.issues",
      closed_issues: "closed.issues",
      no_updates: "No updates",
      minutes_ago: "minutes ago",
      hours_ago: "hours ago",
      days_ago: "days ago",
      no_description: "no description",
      low_impact: "low impact",
      high_impact: "high impact",
      resolved: "resolved",
      new_comment: "New comment",
      add_comment: "Add comment",
      enter_a_new_comment_or_status: "Enter a new comment or status",
      issue: "Issue",
      look_around: "Look around",
      reset_view: "Reset view",
      all_images: "All images",
      gallery: "Gallery",
      call_sos: "Call SOS",
      sos_number: "SOS number",
      ive_found_the_following_document: "I've found the following document that I think you'll find helpful",
      bluetooth: "Bluetooth",
      declined: "Declined",
      not_requested: "Not requested",
      granted: "Granted",
      disable_beacons: "Disable beacons",
      no_settings: "No settings",
    },

    cy: {
      people: "Pobl",
      wifi_network: "Rhwydwaith WiFi",
      wifi_password: "Cyfrinair WiFi",
      edit_space: "Gofod golygu",
      user_guides: "Canllawiau defnyddwyr",
      equipment: "Offer",
      documents: "Dogfennau",
      known_issues: "Materion hysbys",
      report_issue: "Adrodd mater",
      give_feedback: "Rhoi adborth",
      occupied: "Prysur",
      vacant: "Gwag",
      enter_a_shortcode: "Rhowch god byr",
      or_touch_here_if_you_have_a_qr_code: "Neu cysylltwch yma os oes gennych god QR",
      recently_viewed: "Gwelwyd yn ddiweddar",
      favourite_spaces: "Hoff lefydd",
      find_a_space: "Dod o hyd i le",
      organisations: "Sefydliadau",
      asset_types: "Mathau o asedau",
      manufacturers: "Gweithgynhyrchwyr",
      models: "Modelau",
      my_details: "Fy manylion",
      settings: "Gosodiadau",
      change_organisation: "Newid sefydliad",
      terms_and_conditions: "Telerau ac Amodau",
      about_alpha_victor: "Ynghylch Alpha Victor",
      contact_us: "Cysylltu â ni",
      logout: "Allgofnodi",
      login: "Mewngofnod",
      name: "Enw",
      last_name: "Enw olaf",
      email_address: "CYFEIRIAD E-BOST",
      phone_number: "Rhif ffôn",
      account: "Cyfrif",
      delete_account: "Dileu cyfrif",
      permissions: "Caniatad",
      location: "Lleoliad",
      camera: "Camera",
      select_organisation_to_edit: "Dewiswch sefydliad i'w olygu",
      add_organisation: "Ychwanegu sefydliad",
      edit_organisation: "Golygu sefydliad",
      delete_organisation: "Dileu sefydliad",
      details: "Manylion",
      address: "Cyfeiriad",
      theme: "Thema",
      space_types: "Mathau o ofod",
      space_features: "Nodweddion gofod",
      document_types: "Mathau o ddogfennau",
      logo: "Logo",
      background: "Cefndir",
      decision_trees: "Coed penderfyniadau",
      decision_tree: "Coeden benderfynu",
      locations: "Lleoliadau",
      organisation_name: "Enw sefydliadau",
      shortcode_prefix: "Rhagddodiad cod byr",
      shortcode: "cod byr",
      prefix: "Rhagddodiad",
      main_contact: "Prif gyswllt",
      edit_details: "Golygu manylion",
      edit_address: "Golygu cyfeiriad",
      line_1: "Llinell 1",
      line_2: "Llinell 2",
      line_3: "Llinell 3",
      town: "Tref",
      county: "Sir",
      postcode: "Côd post",
      primary: "Cynradd",
      secondary: "Eilradd",
      tertiary: "Trydyddol",
      light: "Ysgafn",
      medium: "Canolig",
      dark: "Tywyll",
      success: "Llwyddiant",
      warning: "Rhybudd",
      danger: "Perygl",
      gradient: "Graddiant",
      revert_to_default_colour: "Dychwelyd i'r lliw rhagosodedig",
      set_corporate_palette: "Gosod palet corfforaethol",
      edit_space_types: "Golygu mathau o ofod",
      enter_new_type_and_press: "Rhowch fath newydd a gwasgwch",
      enter_new_feature_and_press: "Rhowch nodwedd newydd a gwasgwch",
      edit_space_features: "Golygu nodweddion gofod",
      edit_document_types: "Golygu mathau o ddogfennau",
      change_logo: "Newid logo",
      delete: "Dileu",
      add_logo: "Ychwanegu logo",
      change_background: "Newid cefndir",
      add_background: "Ychwanegu cefndir",
      edit_logo: "Golygu logo",
      edit_background: "Golygu cefndir",
      edit_decision_trees: "Golygu coed penderfyniadau",
      add_decision_tree: "Ychwanegu coeden penderfynu",
      save: "Arbed",
      cancel: "Canslo",
      outcome: "Canlyniad",
      outcome_label: "Label canlyniad",
      destination: "Cyrchfan",
      edit_destination: "Golygu cyrchfan",
      title: "Teitl",
      question: "Cwestiwn",
      article: "Erthygl",
      video: "Fideo",
      phone: "Ffonio",
      email: "E-bostio",
      label: "Label",
      search_for_an_article: "Chwilio am erthygl",
      add_new_article: "Ychwanegu erthygl newydd",
      add_new_video: "Ychwanegu fideo newydd",
      video_details: "Manylion fideo",
      fetch_video: "Cyrchu fideo",
      vimeo_url: "URL Vimeo",
      enter_a_valid_vimeo_url: "Rhowch URL Vimeo dilys",
      chapter: "Pennod",
      starts_at: "yn dechrau yn",
      subtitle: "Isdeitl",
      search_for_a_video: "Chwilio am fideo",
      integrations: "Integreiddiadau",
      client_id: "ID cleient",
      client_secret: "Cyfrinach cleient",
      ubiqisense_client_id: "ID cleient Ubiqisense",
      ubiqisense_client_secret: "Cyfrinach cleient Ubiqisense",
      select_organisation: "Dewis sefydliad",
      ubiqisense_configuration: "Ffurfweddiad Ubiqisense",
      pronestor_configuration: "Ffurfweddiad Pronestor",
      organisation_id: "ID Sefydliad",
      photos: "Lluniau",
      ssid: "SSID",
      password: "Cyfrinair",
      public_wifi: "WiFi Cyhoeddus",
      location_name: "Enw lleoliad",
      reorder_photos: "Ail-archebu lluniau",
      add_photo: "Ychwanegu llun",
      add_location: "Ychwanegu lleoliad",
      edit_location: "Golygu lleoliad",
      edit_locations: "Golygu lleoliadau",
      delete_location: "Dileu lleoliad",
      export: "Allforio",
      export_qr_photos: "Allforio lluniau QR",
      export_files: "Allforio ffeiliau",
      select_files_to_export: "Dewiswch ffeiliau i'w hallforio",
      start_export: "Dechrau allforio",
      export_qr_codes: "Allforio codau QR",
      export_teams_backgrounds: "Allforio cefndiroedd Teams",
      coming_soon: "Yn dod yn fuan",
      floors: "Lloriau",
      add_floor: "Ychwanegu lloriau",
      reorder_floors: "Aildrefnu lloriau",
      spaces: "Gofodau",
      delete_floor: "Dileu llawr",
      floor_name: "Enw llawr",
      short_name: "Enw byr",
      are_you_sure: "Ydych chi'n siŵr?",
      you_have_unsaved_changes: "Mae gennych newidiadau heb eu cadw",
      save_and_close: "Arbed a chau",
      discard_changes: "Gwaredu newidiadau",
      add_space: "Ychwanegu gofod",
      reorder_spaces: "Ychwanegu gofod",
      room_type: "Math o ystafell",
      capacity: "Lle",
      typeform_id: "ID Typeform",
      space_name: "Enw gofod",
      select_decision_tree: "Dewis coeden benderfynu",
      devices: "Dyfeisiau",
      serial_number: "Rhif serial",
      installer: "Gosodwr",
      installer_name: "Enw gosodwr",
      install_date: "Dyddiad gosod",
      warranty_expiration_date: "Dyddiad dod i ben gwarant",
      description: "Disgrifiad",
      add_device: "Ychwanegu dyfais",
      panorama: "Panorama",
      edit_panorama: "Golygu panorama",
      set_starting_view: "Gosod man cychwyn",
      add_panorama: "Ychwanegu panorama",
      replace_panorama: "Disodli panorama",
      delete_panorama: "Dileu panorama",
      edit_hotspot: "Golygu hotspot",
      delete_hotspot: "Dileu hotspot",
      device: "Dyfais",
      annotation: "anodiad",
      features: "Nodweddion",
      edit_devices: "Golygu dyfeisiau",
      edit_documents: "Golygu dogfennau",
      edit_photos: "Golygu lluniau",
      add_document: "Ychwanegu dogfen",
      select_document_type: "Dewiswch y math o ddogfen",
      beacon: "Ffagl",
      edit_beacon: "Golygu ffagl",
      major: "Mwyaf",
      minor: "Lleiaf",
      stop_scanning: "Stopio sganio",
      scan_for_beacons: "Sgan am ffaglau",
      select_location: "Dewis lleoliad",
      location_id: "ID lleoliad",
      qr_code: "Côd QR",
      delete_space: "Dileu gofod",
      find_another_space: "Dod o hyd i ofod arall",
      all_manufacturers: "Pob gwneuthurwr",
      manufacturer: "'Gwneuthurwr",
      all_models: "Pob model",
      type_new_model_name_and_press: "Teipiwch enw model newydd a gwasgwch",
      point_your_camera_at_the_qr_code: "Pwyntiwch eich camera at y cod QR",
      favourites: "Ffefrynnau",
      find_space: "Dod o hyd i le",
      administration: "Gweinyddiaeth",
      options: "Dewisiadau",
      development_environment: "Amgylchedd Datblygu",
      verify_your_email: "Dilyswch eich e-bost",
      edit_model: "Golygu model",
      delete_model: "Dileu model",
      all_types: "Pob math",
      select_manufacturer: "Dewiswch gwneuthurwr",
      select_type: "Dewiswch fath",
      type_new_manufacturer_and_press: "Teipiwch wneuthurwr newydd a gwasgwch",
      type_new_type_name_and_press: "Teipiwch enw math newydd a gwasgwch",
      person: "person",
      room_equipment: "Offer ystafell",
      ive_found_the_following_video: "Rwyf wedi dod o hyd i'r fideo canlynol yr wyf yn meddwl y byddwch yn ei chael yn ddefnyddiol",
      ive_found_the_following_article: "Rwyf wedi dod o hyd i'r erthygl ganlynol yr wyf yn meddwl y byddwch yn ei chael yn ddefnyddiol",
      place_a_call: "Rwy'n meddwl ei bod yn well siarad â rhywun - cliciwch isod i roi galwad",
      send_an_email: "Byddem yn gwerthfawrogi mwy o fanylion - cliciwch isod i anfon e-bost atom",
      issues: "Materion",
      open_issues: "materion.agored",
      closed_issues: "materion.caeedig",
      no_updates: "Dim diweddariadau",
      minutes_ago: "munudau yn ôl",
      hours_ago: "oriau yn ôl",
      days_ago: "dyddiau yn ôl",
      no_description: "dim disgrifiad",
      low_impact: "effaith isel",
      high_impact: "effaith uchel",
      resolved: "wedi'i ddatrys",
      new_comment: "Sylw newydd",
      add_comment: "Ychwanegu sylw",
      enter_a_new_comment_or_status: "Rhowch sylw neu statws newydd",
      issue: "Mater",
      look_around: "Edrych o gwmpas",
      reset_view: "Ailosod diwyg",
      all_images: "Pob llun",
      gallery: "Oriel",
      call_sos: "Ffoniwch SOS",
      sos_number: "Rhif SOS",
      ive_found_the_following_document: "Rwyf wedi dod o hyd i'r ddogfen ganlynol yr wyf yn meddwl y byddwch yn ei chael yn ddefnyddiol",
      bluetooth: "Bluetooth",
      declined: "Gwrthod",
      not_requested: "Heb ofyn",
      granted: "Caniatawyd",
      disable_beacons: "Analluogi ffaglau",
      no_settings: "Dim gosodiadau",
    },
  },
});

export default i18n;
